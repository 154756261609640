import React from 'react';
import { string, array, func } from 'prop-types';
import { Button } from 'elements';
import cx from 'classnames';
import ArrowSvg from 'images/icons/chevron.svg';
import './ApiCard.scss';

const ApiCard = ({ className, title, desc, icon, links }) => {
  const MainIcon = icon;
  const iconName = title.toLowerCase();
  return (
    <div className={cx('api-card', className)}>
      <div className="api-card__header">
        <div className="api-card__title">{title}</div>
        <div className="api-card__icon">
          <MainIcon className={iconName} />
        </div>
      </div>
      <p className="api-card__desc">{desc}</p>
      <div className="api-card__links">
        {links.map((c, indx) => {
          const Icon = c.icon;

          return (
            <Button
              background="grey-2"
              color="text-black"
              size="smart"
              className="api-card__link"
              key={indx.toString()}
            >
              <Icon className="api-card__link__icon api-card__link__icon--left" />
              {c.label}
              <ArrowSvg className="api-card__link__icon api-card__link__icon--right" />
            </Button>
          );
        })}
      </div>
    </div>
  );
};

ApiCard.propTypes = {
  className: string,
  title: string,
  desc: string,
  icon: func,
  links: array,
};

export default ApiCard;
