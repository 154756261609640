import React from 'react';
import { useSelector } from 'react-redux';
import {
  GetStarted,
  ContentSwitcher,
  FeatureCard,
  ProgressSlider,
  Tag,
  Title,
  Description,
  Container,
  BankCard,
  TriggerOnEnter,
} from 'elements';
import terminalIcon from 'images/icons/terminal.svg';
import resizeIcon from 'images/icons/resize.svg';
import chartIcon from 'images/icons/chart-white.svg';
import ArrowSvg from 'images/icons/arrowTop.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './PaasHero.scss';

const sliderData = [
  {
    title: 'Improve CX',
    description:
      'Outstrip the competition with the latest fintech innovations, proven to delight users and increase customer acquisition. ',
    Icon: terminalIcon,
  },
  {
    title: 'Drive growth',
    description:
      'Utilise spending data to unearth common user patterns and develop new innovative personalised products and services.',
    Icon: resizeIcon,
  },
  {
    title: 'Optimise efficiancy',
    description:
      'Use Finto’s existing infrastructure to drastically reducing set up costs and time to launch.',
    Icon: chartIcon,
  },
];

const PaasHero = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="paas-hero__section">
      <Container className="paas-hero__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked}>
          <div className="paas-hero__top-content hero-animations">
            <div className="paas-hero__declaration">
              <Tag>PAYMENTS AS A SERVICE</Tag>
              <Title tagName="h1" sizeText="size-3" color="vulcan">
                <div className="title__part">Create the</div>
                <div className="title__part">perfect payment</div>
                <div className="title__part">experience</div>
              </Title>
              <Description sizeText="size-2">
                Create a seamless payment experience for your customers with Finto’s white label
                debit, prepaid, virtual and loyalty cards.
              </Description>
              <div className="paas-hero__buttons buttons-holder">
                <GetStarted
                  background="black"
                  border="border-bold-black"
                  color="text-white"
                  size="medium"
                  className="paas-hero__button button--with-icon"
                  to="https://developer.finto.io/"
                  target="_blank"
                  buttontype="hoverable"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Get started</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </GetStarted>
              </div>
            </div>
            <div className="paas-hero__images">
              <div className="paas-hero__images__row">
                <BankCard
                  colorVariant="red"
                  textVariant="prepaid"
                  className="paas-hero__bank-card"
                />
                <BankCard
                  colorVariant="orange"
                  textVariant="credit"
                  className="paas-hero__bank-card"
                />
                <BankCard
                  colorVariant="purple"
                  textVariant="debit"
                  className="paas-hero__bank-card"
                />
                <BankCard
                  colorVariant="coral"
                  textVariant="virtual"
                  className="paas-hero__bank-card"
                />
              </div>
              <div className="paas-hero__images__row">
                <BankCard
                  colorVariant="purple"
                  textVariant="debit"
                  className="paas-hero__bank-card"
                />
                <BankCard
                  colorVariant="coral"
                  textVariant="virtual"
                  className="paas-hero__bank-card"
                />
                <BankCard
                  colorVariant="orange"
                  textVariant="credit"
                  className="paas-hero__bank-card"
                />
                <BankCard
                  colorVariant="red"
                  textVariant="prepaid"
                  className="paas-hero__bank-card"
                />
              </div>
            </div>
          </div>
        </TriggerOnEnter>
        <ContentSwitcher
          mobileContent={
            <ProgressSlider className="paas-hero__slider">
              {sliderData.map((c) => (
                <FeatureCard key={c.title} {...c} />
              ))}
            </ProgressSlider>
          }
          desktopContent={
            <div className="paas-hero__cards">
              {sliderData.map((c) => (
                <FeatureCard key={c.title} {...c} />
              ))}
            </div>
          }
        />
      </Container>
    </section>
  );
};

export default PaasHero;
