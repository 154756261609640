import { bool, string } from 'prop-types';
import React from 'react';
import Lottie from 'react-lottie';
import loaderData from '/static/lottie/loader.json';
import './Loader.scss';

function Loader({ loop, autoplay, width, isStopped, isPaused }) {
  return (
    <div className="lottie-loader">
      <Lottie
        options={{
          // default options
          loop,
          autoplay,
          animationData: loaderData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={width}
        isStopped={isStopped}
        isPaused={isPaused}
      />
    </div>
  );
}

Loader.propTypes = {
  loop: bool,
  autoplay: bool,
  width: string,
  isStopped: bool,
  isPaused: bool,
};

Loader.defaultProps = {
  loop: true,
  autoplay: true,
  width: '140px',
};

export default Loader;
