import React from 'react';
import { GetStarted, Image, Tag, Title, Description, Container } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import phone from 'images/phones/phone-purple.webp';
import phoneMobile from 'images/mobile/phones/phone-purple.webp';
import './BaapAccelerate.scss';

const BaapAccelerate = () => (
  <section className="baap-accelerate__section">
    <Container className="baap-accelerate__container">
      <div className="baap-accelerate__images">
        <div className="baap-accelerate__image">
          <Image src={phone} srcMobile={phoneMobile} alt="phone" />
        </div>
      </div>
      <div className="baap-accelerate__declaration">
        <Tag color="vulcan">accelerate GROWTH</Tag>
        <Title tagName="h2" alignText="left" sizeText="size-2" color="vulcan">
          Onboard users in a flash
        </Title>
        <Description sizeText="size-2" color="vulcan">
          Increase customer retention and optimise sales by seamlessly integrating payments into
          your products, enabling your customers to pay friends, bills and businesses in a flash.
        </Description>
        <div className="baap-accelerate__buttons">
          <GetStarted
            to="https://developer.finto.io/"
            target="_blank"
            background="black"
            border="border-black"
            color="text-white"
            size="medium"
            className="baap-accelerate__button button--with-icon"
            buttontype="hoverable"
            isCentered
          >
            <div className="button__inner">
              <span>Get started</span>
              <div className="button__icons">
                <ArrowSvg className="button__icon" />
                <ArrowSvg className="button__icon button__icon--next" />
              </div>
            </div>
          </GetStarted>
        </div>
      </div>
    </Container>
  </section>
);

export default BaapAccelerate;
