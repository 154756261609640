import React from 'react';
import { array, func, number } from 'prop-types';
import { Button } from 'elements';
import './styles.scss';

const MobileTags = ({ data, onTagPress }) => (
  <div className="mobile-tags__container">
    {data.map((c, index) =>
      c.label ? (
        <Button
          key={index.toString()}
          onClick={() => onTagPress(c)}
          className="mobile-tags__button"
          buttontype="gradient-hoverable"
          color="text-white"
          size="medium"
          isCentered
        >
          <div className="button__inner">
            <span>{c.label}</span>
            <SvgIcon index={index} />
          </div>
        </Button>
      ) : null
    )}
  </div>
);

MobileTags.propTypes = {
  data: array,
  onTagPress: func,
};

const SvgIcon = ({ index }) => (
  <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.47132 1.47132C9.73167 1.21097 9.73167 0.788864 9.47132 0.528514C9.21097 0.268165 8.78886 0.268165 8.52851 0.528514L4.99992 4.05711L1.47132 0.528514C1.21097 0.268165 0.788864 0.268165 0.528514 0.528514C0.268165 0.788864 0.268165 1.21097 0.528514 1.47132L4.05711 4.99992L0.528514 8.52851C0.268165 8.78886 0.268165 9.21097 0.528514 9.47132C0.788864 9.73167 1.21097 9.73167 1.47132 9.47132L4.99992 5.94273L8.52851 9.47132C8.78886 9.73167 9.21097 9.73167 9.47132 9.47132C9.73167 9.21097 9.73167 8.78886 9.47132 8.52851L5.94273 4.99992L9.47132 1.47132Z"
      fill={`url(#paint${index}_linear)`}
    />
    <defs>
      <linearGradient
        id={`paint${index}_linear`}
        x1="4.99992"
        y1="-10.1667"
        x2="4.99992"
        y2="20.6751"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
    </defs>
  </svg>
);

SvgIcon.propTypes = {
  index: number,
};

export default MobileTags;
