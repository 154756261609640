import React from 'react';
import apiWhiteUrl from 'images/icons/apiWhite.svg';
import api from 'images/icons/api.svg';
import docs from 'images/icons/docs.svg';
import sdk from 'images/icons/sdk.svg';
import started from 'images/icons/started.svg';
import banking from 'images/icons/banking.svg';
import card from 'images/icons/card.svg';
import arrows from 'images/icons/arrows.svg';
import contacts from 'images/icons/contacts.svg';
import products from 'images/icons/products.svg';
import cronica from 'images/icons/check-white.svg';
import schedule from 'images/icons/schedule.svg';
import code from 'images/icons/code.svg';
import MenuCards from './MenuCards';

export const listCards = [
  {
    id: 0,
    title: 'APIs',
    desc: 'Get instant API access in minutes and start building your next project',
    Icon: apiWhiteUrl,
    url: 'https://developer.finto.io/docs/apis/identity',
  },
  {
    id: 1,
    title: 'SDKs',
    desc: 'SDKs for an array of the most popular languages and platforms.',
    Icon: sdk,
    url: 'https://developer.finto.io/docs/sdks/kyc/ios-5-0-0',
  },
  {
    id: 2,
    title: 'Docs',
    desc: 'Leran how to start integrating Finto’s products and services',
    Icon: docs,
    url: 'https://developer.finto.io/docs/guides/getting-started',
  },
  {
    id: 3,
    title: 'Get started',
    desc: 'Get up and running fast, with a quick introduction to Finto’s platform',
    Icon: started,
    url: 'https://developer.finto.io/',
  },
];

export const listProducts = [
  {
    id: 0,
    title: 'Banking as a Service',
    desc: 'Digital banking services, designed for scale',
    tag: 'Baas',
    Icon: schedule,
    url: '/baas',
  },
  {
    id: 1,
    title: 'Payments as a Service',
    desc: 'Create the perfect payment experience',
    tag: 'Paas',
    Icon: contacts,
    url: '/paas',
  },
  {
    id: 2,
    title: 'Banking as a Platform',
    desc: 'Seamlessly integrate digital financial services',
    tag: 'Baap',
    Icon: api,
    url: '/baap',
  },
  {
    id: 3,
    title: 'Cronica',
    desc: 'Securely generate, authenticate, and retrieve programmable documents.',
    tag: 'Cronica',
    Icon: cronica,
    url: '/cronica',
  },
];

export const listContacts = [
  {
    id: 0,
    title: 'Schedule a demo',
    desc: 'Book a demo session and have a member of our team walk you through Finto’s capabilities',
    Icon: banking,
    url: '/get-started/schedule_demo',
  },
  {
    id: 1,
    title: 'Contact sales',
    desc: 'Get in touch with a memebr of our sales team for a solution tailored to your needs',
    Icon: card,
    url: '/contact',
  },
  {
    id: 2,
    title: 'Careers at Finto',
    desc: 'Interested in joining the team at Finto? Check out our open positions and apply.',
    Icon: code,
    url: '/contact#career',
  },
];

export const listMenu = [
  {
    id: 0,
    url: '',
    name: 'Products',
    Icon: products,
    innerList: listProducts,
    innerURLs: listProducts.map((c) => c.url),
    content: (props) => <MenuCards data={listProducts} {...props} />,
  },
  {
    id: 1,
    url: '/developers',
    name: 'Developers',
    Icon: arrows,
    innerList: listCards,
    innerURLs: listCards.map((c) => c.url),
    content: (props) => <MenuCards data={listCards} {...props} />,
  },
  {
    id: 2,
    url: '',
    name: 'Contact',
    Icon: contacts,
    innerList: listContacts,
    innerURLs: listContacts.map((c) => c.url),
    content: (props) => <MenuCards data={listContacts} {...props} />,
  },
];

export const careerMenuList = [
  {
    id: 0,
    url: '/careers/jobs',
    name: 'Jobs',
  },
  {
    id: 1,
    url: '/careers/hiring',
    name: 'Hiring process',
  },
  {
    id: 2,
    url: '/careers/culture',
    name: 'Culture',
  },
];
