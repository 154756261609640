import React from 'react';
import { HelmetWrapper } from 'elements';
import { StartedEpilog, StartedSignUp } from 'components';

const StartedContainer = () => (
  <>
    <HelmetWrapper helmetKey="get-started" />
    <StartedSignUp />
    {/* <StartedPricing /> */}
    <StartedEpilog />
  </>
);

export default StartedContainer;
