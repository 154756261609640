import React, { forwardRef } from 'react';
import { Form } from 'react-final-form';
import cx from 'classnames';
import { Input, Button, Accordion, AccordionItem, FormCheckboxGroup } from 'elements';
import SearchSvg from 'images/icons/search.svg';
import ChevronSvg from 'images/icons/chevron.svg';
import { MOBILE_FORM } from '../jobsData';

// eslint-disable-next-line react/prop-types
export default forwardRef(({ onMobileFormSubmit, onMobileFormReset }, ref) => (
  <div className="job-mobile-form">
    <Form
      onSubmit={onMobileFormSubmit}
      subscription={{ dirty: true }}
      render={({ form, dirty, handleSubmit }) => {
        // eslint-disable-next-line no-param-reassign
        ref.current = form;
        return (
          <>
            <div className="job-mobile-form__mobile-search">
              <Input
                className="job-mobile-form__search-input"
                placeholder="Search"
                name="search"
                variant="secondary"
              />
              <SearchSvg className="job-mobile-form__search-icon" />
            </div>
            <Accordion noAnimateSlide>
              {MOBILE_FORM.map((c, indx) => (
                <AccordionItem
                  key={indx.toString()}
                  DefaultIcon={c.list && ChevronSvg}
                  ActiveIcon={c.list && ChevronSvg}
                  className={cx({ 'accordion__header-icon': !!c.list })}
                  title={<div className="accordion__header-title">{c.title}</div>}
                >
                  <FormCheckboxGroup name={c.name} key={c.name} options={c.list} />
                </AccordionItem>
              ))}
            </Accordion>
            <div className="job-mobile-form__btn-group">
              {dirty && (
                <Button
                  onClick={onMobileFormReset}
                  className="job-mobile-form__reset-btn"
                  size="medium"
                  isCentered
                >
                  Reset
                </Button>
              )}
              <Button
                onClick={handleSubmit}
                className={cx('job-mobile-form__done-btn', { active: dirty })}
                size="medium"
                isCentered
              >
                Done
              </Button>
            </div>
          </>
        );
      }}
    />
  </div>
));
