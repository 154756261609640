import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { oneOf } from 'prop-types';
import { Container, Title, Description, Button, Image } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import Image1 from 'images/contacts/contacts1.webp';
import Image2 from 'images/contacts/contacts2.webp';
import Image3 from 'images/contacts/contacts3.webp';
import MobileImage1 from 'images/mobile/contacts/contacts1.webp';
import MobileImage2 from 'images/mobile/contacts/contacts2.webp';
import MobileImage3 from 'images/mobile/contacts/contacts3.webp';
import './CultureLife.scss';

function CultureLife({ variant }) {
  return (
    <section className={cx('culture-life__section', `culture-life__section--${variant}`)}>
      <Container className="culture-life__container">
        <Title
          tagName="h2"
          className="culture-life__title"
          alignText="text-center"
          sizeText="size-2"
        >
          Life at finto
        </Title>
        <Description
          className="culture-life__description"
          alignText="text-center"
          sizeText="size-2"
        >
          Get a snapshot of what it’s like to work at Finto day to day, hear from some of the
          current team and find out what makes the company tick.
        </Description>
        <div className="culture-life__button-wrap">
          <Button
            tagName={Link}
            className="culture-life__button button--with-icon"
            to="/careers/culture"
            buttontype="gradient-hoverable"
            color="text-white"
            size="medium"
            isCentered
          >
            <div className="button__inner">
              <span>company culture</span>
              <div className="button__icons">
                <ArrowSvg className="button__icon" />
                <ArrowSvg className="button__icon button__icon--next" />
              </div>
            </div>
          </Button>
        </div>
      </Container>
      <div className="culture-life__images">
        <Image className="culture-life__image" src={Image1} srcMobile={MobileImage1} />
        <Image className="culture-life__image" src={Image2} srcMobile={MobileImage2} />
        <Image className="culture-life__image" src={Image3} srcMobile={MobileImage3} />
      </div>
    </section>
  );
}

CultureLife.propTypes = {
  variant: oneOf(['black', 'white']),
};

CultureLife.defaultProps = {
  variant: 'white',
};

export default CultureLife;
