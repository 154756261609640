import React from 'react';
import { string, node, bool } from 'prop-types';
import cx from 'classnames';
import './Title.scss';

const Title = ({
  alignText,
  color,
  className,
  children,
  fullWidth,
  tagName: TagName,
  sizeText,
  ...rest
}) => (
  <TagName
    className={cx('title', {
      [`title--${TagName}`]: !!TagName,
      [`title--${color}`]: !!color,
      [`title--${alignText}`]: !!alignText,
      [`title--${sizeText}`]: !!sizeText,
      'title--full-width': !!fullWidth,
      [className]: !!className,
    })}
    {...rest}
  >
    {children}
  </TagName>
);

Title.defaultProps = {
  alignText: 'text-left',
  className: '',
  color: '',
  children: undefined,
  fullWidth: false,
  sizeText: 'size-1',
  tagName: 'h1',
};

Title.propTypes = {
  alignText: string,
  color: string,
  className: string,
  children: node,
  fullWidth: bool,
  sizeText: string,
  tagName: node,
};

export default Title;
