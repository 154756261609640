export const listMenu = [
  {
    id: 0,
    title: 'Social media',
    desc: 'Get instant API access in minutes and start building your next project',
    list: [
      {
        target: 'blank',
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/finto-io',
      },
      {
        target: 'blank',
        name: 'Twitter',
        url: 'https://twitter.com/finto_io',
      },
      {
        target: 'blank',
        name: 'GitHub',
        url: 'https://github.com/finto-io',
      },
    ],
  },
  {
    id: 1,
    title: 'Solutions',
    list: [
      {
        target: 'self',
        name: 'API Platform',
        url: '/baap',
      },
      {
        target: 'self',
        name: 'Banking',
        url: '/baas',
      },
      {
        target: 'self',
        name: 'Cards',
        url: '/paas',
      },
    ],
  },
  {
    id: 2,
    title: 'Developers',
    list: [
      {
        target: 'blank',
        name: 'API Documentation',
        url: 'https://developer.finto.io/docs/apis/identity',
      },
      {
        target: 'blank',
        name: 'API SDK’s',
        url: 'https://developer.finto.io/docs/sdks/kyc/ios-5-0-0',
      },
    ],
  },
  {
    id: 3,
    title: 'Company',
    list: [
      {
        target: 'self',
        name: 'About',
        url: '/about',
      },
      {
        target: 'self',
        name: 'Careers',
        url: '/careers/jobs',
      },
    ],
  },
];

export const listNav = [
  {
    id: 0,
    url: '/terms-and-conditions',
    name: 'Terms and conditions',
  },
  // {
  //   id: 1,
  //   url: '/legal-statements',
  //   name: 'Legals',
  // },
  {
    id: 2,
    url: '/privacy-policy',
    name: 'Privacy policy',
  },
  {
    id: 3,
    url: '/cookies-policy',
    name: 'Cookies policy',
  },
];
