import React from 'react';
import { HelmetWrapper } from 'elements';
import {
  CultureHero,
  CultureEpilog,
  HiringRole,
  CultureMission,
  CultureValue,
  CultureBenefits,
  CultureFeedback,
  CultureBanner,
} from 'components';

const Culture = () => (
  <>
    <HelmetWrapper helmetKey="culture" />
    <CultureHero />
    <CultureMission withNegativeOffset />
    <CultureValue withTopOffset />
    <CultureBenefits />
    <CultureFeedback />
    <CultureBanner />
    <HiringRole variant="white" />
    <CultureEpilog />
  </>
);

export default Culture;
