import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Title, JobCard, Button } from 'elements';
import { Modal, JobsTable } from 'components';
import { useResponsive } from 'hooks';
import { selectJobs, getInitialData } from 'modules/jobs/jobsSelector';
import { filterData } from 'modules/jobs/jobs';
// import { filterData, increasePage } from 'modules/jobs/jobs';
import FilterSvg from 'images/icons/filter.svg';
import DesktopForm from './Components/DesktopForm';
import MobileForm from './Components/MobileForm';
import MobileTags from './Components/MobileTags';
import './JobsHero.scss';

const JobsHero = () => {
  const mobileFormRef = useRef();
  const desktopFormRef = useRef();
  const [tags, setTags] = useState([]);
  const [isModal, setModalVision] = useState(false);
  const jobsData = useSelector(selectJobs);
  const initialJobsData = useSelector(getInitialData);
  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();

  const handleMatchMedia = () => {
    desktopFormRef.current.reset();
    mobileFormRef.current.reset();
    dispatch(filterData({}));
    setTags([]);
    setModalVision(false);
  };

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 1024px)');
    mql.addEventListener('change', handleMatchMedia);
    return () => {
      mql.removeEventListener('change', handleMatchMedia);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMobileFormSubmit = (values) => {
    const newTags = [];
    const newData = Object.keys(values).reduce((acc, b) => {
      if (b === 'search') {
        newTags.push({ type: b, value: values[b], label: values[b] });
        return { ...acc, [b]: values[b] };
      }
      const newValue = values[b].map((c) => {
        newTags.push({ type: b, value: c, label: c });
        return {
          value: c,
          label: c,
        };
      });
      return { ...acc, [b]: newValue };
    }, {});
    dispatch(filterData(newData));
    setTags(newTags);
    setModalVision(false);
  };

  const onTagPress = (item) => {
    const { values } = mobileFormRef.current.getState();
    Object.keys(values).forEach((key) => {
      if (key === item.type) {
        mobileFormRef.current.change(
          key,
          key === 'search' ? '' : values[key].filter((cc) => cc !== item.value)
        );
      }
      return key;
    });
    onMobileFormSubmit(mobileFormRef.current.getState().values);
  };

  const onMobileFormReset = () => {
    mobileFormRef.current.reset();
    dispatch(filterData({}));
  };

  const shouldRenderTags = !!tags.length && !isDesktop;

  return (
    <section className="jobs-hero__section">
      <Container className="jobs-hero__container">
        <Title
          className="jobs-hero__title"
          alignText="text-center"
          tagName="h1"
          sizeText="size-5"
          color="vulcan"
        >
          Join the Finto team
        </Title>
        {!isDesktop && (
          <div className="jobs-hero__filter-button-wrapper">
            <Button
              onClick={() => setModalVision(true)}
              className="jobs-hero__button jobs-hero__button--filter-button button--with-icon button--with-icon-left"
              size="medium"
              buttontype="hoverable"
              isCentered
            >
              <div className="button__inner">
                <div className="button-icons">
                  <FilterSvg />
                </div>
                <span>Filter</span>
              </div>
            </Button>
          </div>
        )}
        <DesktopForm
          ref={desktopFormRef}
          data={jobsData}
          onDesktopFormChange={(d) => dispatch(filterData(d))}
        />
        {shouldRenderTags && <MobileTags data={tags} onTagPress={onTagPress} />}
        <div className="jobs-hero__table-wrapper">
          {jobsData.length > 0 && (
            <>
              {isDesktop ? (
                <JobsTable data={jobsData} />
              ) : (
                jobsData.map((c, indx) => (
                  <JobCard className="jobs-hero__job-card" key={indx.toString()} {...c} />
                ))
              )}
            </>
          )}
        </div>
        {jobsData.length ? (
          <>
            <p className="jobs-hero__table-info">
              Currently Showing {jobsData.length} out of {initialJobsData.length} Jobs
            </p>
            {/* TODO: this logic is not logical we only have 5 static elements maybe we need to update this logic if we add an API endpoint for this */}
            {/* {jobsData.length !== initialJobsData.length && (
              <div className="jobs-hero__button-wrapper">
                <Button
                  onClick={() => dispatch(increasePage())}
                  className="jobs-hero__button"
                  size="medium"
                  buttontype="gradient-hoverable"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Load more jobs</span>
                  </div>
                </Button>
              </div>
            )} */}
          </>
        ) : (
          !isDesktop && (
            <Title
              className="jobs-hero__sub-title"
              alignText="text-center"
              tagName="h2"
              sizeText="size-7"
              color="vulcan"
            >
              No results
            </Title>
          )
        )}
      </Container>
      <Modal
        isVisible={isModal}
        className="jobs-hero__modal"
        bodyClassName="jobs-hero__modal-body"
        footerClassName="jobs-hero__modal-footer"
        onClose={() => setModalVision(false)}
        title="Filter"
      >
        <MobileForm
          ref={mobileFormRef}
          onMobileFormSubmit={onMobileFormSubmit}
          onMobileFormReset={onMobileFormReset}
        />
      </Modal>
    </section>
  );
};

export default JobsHero;
