import React from 'react';
import { HelmetWrapper } from 'elements';
import { CookiesPolicy as CookiesSection } from 'components';

const Cookies = () => (
  <>
    <HelmetWrapper helmetKey="cookies" />
    <CookiesSection />
  </>
);

export default Cookies;
