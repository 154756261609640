import React from 'react';
import { Link } from 'react-router-dom';
import { Epilog } from 'components';
import { Button } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './CultureEpilog.scss';

const CultureEpilog = () => (
  <Epilog
    className="culture-epilog"
    title="Ready to apply?"
    description="Have a look at our hiring process at Finto before you apply"
    variant="black"
  >
    <div className="culture-epilog__button-wrap">
      <Button
        className="culture-epilog__button button--with-icon"
        to="/careers/hiring"
        tagName={Link}
        buttontype="gradient-hoverable"
        color="text-white"
        size="medium"
        isCentered
      >
        <div className="button__inner">
          <span>hiring process</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </Button>
    </div>
  </Epilog>
);
export default CultureEpilog;
