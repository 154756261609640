import React from 'react';
import { object, string, func, oneOf } from 'prop-types';
import { Field } from 'react-final-form';
import cx from 'classnames';
import './Input.scss';

function FormInput(props) {
  const {
    NodeType,
    variant,
    input,
    meta: { active, valid, error, touched, invalid },
    label,
    type,
    onFocus,
    onBlur,
    onChange,
    className,
    ...rest
  } = props;

  const focusHandler = (e) => {
    input.onFocus(e);
    if (onFocus) {
      onFocus(e);
    }
  };

  const blurHandler = (e) => {
    input.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const changeHandler = (e) => {
    input.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={cx('form-input', `form-input--${variant}`, className)}>
      {label && (
        <div pointerEvents="none" className="form-input__label">
          {label}
        </div>
      )}
      <NodeType
        className={cx('form-input__field', {
          'form-input__field--valid': valid,
          'form-input__field--invalid': invalid && touched && !active,
          'form-input__field--active': active,
          'form-input__field--textarea': NodeType === 'textarea',
        })}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        value={input.value}
        {...rest}
      />
      {touched && error && (
        <div className="form-input__error" pointerEvents="none">
          {error}
        </div>
      )}
    </div>
  );
}

FormInput.propTypes = {
  input: object,
  meta: object,
  label: string,
  type: string,
  onBlur: func,
  onFocus: func,
  onChange: func,
  variant: oneOf(['primary', 'secondary', 'third', 'fourth']),
  className: string,
  NodeType: oneOf(['textarea', 'input']),
};

FormInput.defaultProps = {
  variant: 'primary',
  NodeType: 'input',
};

// eslint-disable-next-line
export default ({ name, ...rest }) => (
  <Field name={name}>
    {({ input, meta }) => <FormInput {...rest} input={input} meta={meta} />}
  </Field>
);
