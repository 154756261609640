import React from 'react';
import { HelmetWrapper } from 'elements';
import { ContactsHero, ContactsCareers } from 'components';

const Contacts = () => (
  <>
    <HelmetWrapper helmetKey="contact" />
    <ContactsHero />
    <ContactsCareers />
  </>
);

export default Contacts;
