/* eslint-disable */
import React, { Component, Children, cloneElement } from 'react';
import cx from 'classnames';
import { bool, number, node, func, string } from 'prop-types';
import './Accordion.scss';

class Accordion extends Component {
  static propTypes = {
    children: node,
    className: string,
    onChange: func,
    noAnimateSlide: bool,
    defaultOpen: number,
    startAnimateSlide: bool
  }

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: '' + props.defaultOpen || -1
    };
  }

  componentDidMount() {
    const { startAnimateSlide } = this.props;

    if (startAnimateSlide !== undefined && startAnimateSlide) {
      this.timeout = setInterval(() => {
        this.animateSlide();
      }, 4000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  animateSlide = () => {
    const { children } = this.props;
    const { currentIndex } = this.state;
    const countEl = React.Children.count(children);
    let index = 0;

    if (countEl === +currentIndex + 1) {
      index = 0;
    } else {
      index = +currentIndex + 1;
    }
    this.setState({
      currentIndex: index
    }, () => (
      this.onChange(index)
    ));
  }

  restartAnimateSlide = () => {
    const { startAnimateSlide } = this.props;

    if (startAnimateSlide !== undefined && startAnimateSlide) {
      clearInterval(this.timeout);
      this.timeout = setInterval(() => {
        this.animateSlide();
      }, 4000);
    } else {
      clearInterval(this.timeout);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.startAnimateSlide !== prevProps.startAnimateSlide) {
      this.restartAnimateSlide();
    }
  }

  handleClick = (index) => {
    this.setState(prev => ({
      currentIndex: prev.currentIndex === index ? -1 : index
    }));
    this.onChange(index, this.props.item);
  }

  handleMouseEnter = () => {
    const { startAnimateSlide } = this.props;

    if (startAnimateSlide) {
      clearInterval(this.timeout);
    }
  }

  handleMouseLeave = () => {
    const { startAnimateSlide } = this.props;

    if (startAnimateSlide) {
      clearInterval(this.timeout);
      this.timeout = setInterval(() => {
        this.animateSlide();
      }, 4000);
    }
  }

  onChange = (index, item) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(index, item);
    }
  }

  render() {
    const { className, children } = this.props;
    const { currentIndex } = this.state;

    return (
      <div
        className={cx('accordion', {
          [className]: !!className,
        })}
      >
        {Children.map(children, (child, i) => (
          cloneElement(child, {
            isOpen: i === +currentIndex,
            handleClick: () => this.handleClick(i),
            handleMouseEnter: () => this.handleMouseEnter(),
            handleMouseLeave: () => this.handleMouseLeave()
          })
        ))}
      </div>
    );
  }
}
export default Accordion;
