import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import './List.scss';

const ListItemText = ({ children, className, id, ...rest }) => (
  <div
    className={cx('list__item_text', {
      [className]: !!className,
    })}
    id={id}
    {...rest}
  >
    {children}
  </div>
);

ListItemText.defaultProps = {
  children: null,
  className: '',
  id: '',
};

ListItemText.propTypes = {
  children: node,
  className: string,
  id: string,
};

export default ListItemText;
