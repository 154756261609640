import React from 'react';
import { Accordion, AccordionItem } from 'elements';
import { useResponsive } from 'hooks';
import { array } from 'prop-types';
import CrossSvg from 'images/icons/cross-rounded.svg';
import CheckSvg from 'images/icons/check-rounded.svg';
import PlusSvg from 'images/icons/plus.svg';
import MinusSvg from 'images/icons/minus.svg';
import './FeaturesList.scss';

const getRandomBool = () => Math.random() >= 0.5;

const FeaturesList = ({ data }) => {
  if (!data) return null;
  return (
    <div className="features-list__container">
      {data.map((c, indx) => (
        <div className="features-list__wrapper" key={indx.toString()}>
          <div className="features-list__header">
            <h3 className="features-list__header-title">{c.title}</h3>
            {indx === 0 && <span className="features-list__header-status">Availibility</span>}
          </div>
          <ItemsList data={c.data} />
        </div>
      ))}
    </div>
  );
};

FeaturesList.propTypes = {
  data: array,
};

const FeaturesAccordion = ({ data }) => {
  if (!data) return null;
  return (
    <Accordion className="features-list__accordion">
      {data &&
        data.map((c, index) => (
          <AccordionItem
            key={index.toString}
            title={<h3 className="features-list__header-title">{c.title}</h3>}
            DefaultIcon={PlusSvg}
            ActiveIcon={MinusSvg}
            className="features-list__accordion__item"
          >
            <ItemsList data={c.data} />
          </AccordionItem>
        ))}
    </Accordion>
  );
};

FeaturesAccordion.propTypes = {
  data: array,
};

const ItemsList = ({ data }) => (
  <ul className="features-list__body">
    {data.map((cc, index) => (
      <li className="features-list__item" key={index.toString()}>
        <span className="features-list__item-name">{cc.name}</span>
        <span className="features-list__item-status">
          {getRandomBool() ? <CheckSvg /> : <CrossSvg />}
        </span>
      </li>
    ))}
  </ul>
);

ItemsList.propTypes = {
  data: array,
};

export default (props) => {
  const { isDesktop } = useResponsive();
  return isDesktop ? <FeaturesList {...props} /> : <FeaturesAccordion {...props} />;
};
