import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Title, Tag, Description, Button, Image, TriggerOnEnter } from 'elements';
import CurvedArrowSvg from 'images/icons/curved-arrow.svg';
import BankCardSvg from 'images/bank_cards/bank-card-orange.svg';
import phone from 'images/phones/phone-analytics.webp';
import code from 'images/dev/code-dev-page.webp';
import phoneMobile from 'images/mobile/phones/phone-analytics.webp';
import codeMobile from 'images/mobile/dev/code-dev-page.webp';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './DevelopersHero.scss';

const DevelopersHero = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="developers-hero">
      <Container className="developers-hero__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked}>
          <div className="developers-hero__top-content hero-animations">
            <div className="developers-hero__declaration">
              <Tag className="developers-hero__tag" color="white">
                Developers
              </Tag>
              <Title
                tagName="h1"
                sizeText="size-3"
                color="white"
                alignText="text-left"
                className="developers-hero__title"
              >
                <div className="developers-hero__title-content">
                  <div className="title__part">Powerful products</div>
                  <div className="title__part">need powerful</div>
                  <div className="title__part">tools</div>
                </div>
                <div className="developers-hero__title-content developers-hero__title-content--mobile">
                  <div className="title__part">Powerful products need</div>
                  <div className="title__part">powerful tools</div>
                </div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                alignText="text-left"
                className="developers-hero__description"
              >
                Create an account to get immediate access to our API and start building the future
                of finance.
              </Description>
              <div className="buttons-holder">
                <Button
                  className="developers-hero__button button--with-icon button--with-icon-left"
                  background="purple"
                  color="text-white"
                  size="medium"
                  buttontype="gradient-hoverable"
                  isCentered
                  tagName={Link}
                  to="/get-started"
                >
                  <div className="button__inner">
                    <div className="button__icons">
                      <CurvedArrowSvg className="button__icon" />
                      <CurvedArrowSvg className="button__icon button__icon--next" />
                    </div>
                    <span>Get Api key</span>
                  </div>
                </Button>
              </div>
            </div>
            <div className="developers-hero__images">
              <div className="developers-hero__image developers-hero__image--phone">
                <Image src={phone} srcMobile={phoneMobile} alt="phone" />
              </div>
              <div className="developers-hero__image developers-hero__image--code">
                <Image src={code} srcMobile={codeMobile} alt="code" />
              </div>
              <BankCardSvg className="developers-hero__card" />
            </div>
          </div>
        </TriggerOnEnter>
      </Container>
    </section>
  );
};

export default DevelopersHero;
