import React from 'react';
import { useSelector } from 'react-redux';
import { Title, Description, Container, TriggerOnEnter, BasicScroll } from 'elements';
import document1 from 'images/cases/cards.webp';
import document2 from 'images/cases/digital.webp';
import document3 from 'images/cases/passport.webp';
import document4 from 'images/cases/application.webp';
import document5 from 'images/cases/documents.webp';
import document6 from 'images/cases/learning.webp';
import document7 from 'images/cases/health.webp';

import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './CronicaDocuments.scss';

const CronicaDocuments = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="cronica-docs" variant="black">
      <div className="cronica-docs__background-wrapper">
        <Container className="cronica-docs__container">
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="5%">
            <div className="cronica-docs__details advantages-animations">
              <Title
                tagName="h2"
                sizeText="size-8"
                color="white"
                alignText="text-center"
                className="cronica-docs__title title__part"
              >
                Use Cases
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                alignText="text-center"
                className="cronica-docs__description"
              >
                Finto Сronica helps organizations and their customers easily and securely
                authenticate critical documents
              </Description>
            </div>
          </TriggerOnEnter>
        </Container>
        <BasicScroll
          props={{
            '--parallax-tx': {
              from: '50%',
              to: '-150%',
            },
            '--parallax-tx-tablet-max': {
              from: '20%',
              to: '-20%',
            },
          }}
        >
          <div className="cronica-docs__images parallax parallax--horizontal">
            <div className="cronica-docs__img">
              <img src={document1} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>Banks</strong>
                <p>
                  Transform your digital document authentication process. Cronica eliminates the
                  need for human intermediaries and makes authentication frictionless for banks and
                  their customers.
                </p>
              </div>
            </div>
            <div className="cronica-docs__img">
              <img src={document2} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>Digital Credentials</strong>
                <p>
                  Our identities are increasingly becoming more digitized. Cronica helps end-users
                  take control of their identities with intuitive, secure, real-time authentication.
                </p>
              </div>
            </div>
            <div className="cronica-docs__img">
              <img src={document3} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>Passports</strong>
                <p>
                  Issue and reissue important documents – such as passports – with significantly
                  less time and resources. Only data necessary for authentication is
                  cryptographically stored.
                </p>
              </div>
            </div>
            <div className="cronica-docs__img">
              <img src={document4} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>Applications</strong>
                <p>
                  Any application issued to the platform may be immediately verified using a digital
                  signifier, with additional conditions placed upon it by permissioned parties.
                </p>
              </div>
            </div>
            <div className="cronica-docs__img">
              <img src={document5} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>SLA Documents</strong>
                <p>
                  Cronica-integrated organizations can quickly upload SLA documents to the
                  blockchain, giving access to relevant users. These can then seamlessly be
                  retrieved, verified, and reformatted .
                </p>
              </div>
            </div>
            <div className="cronica-docs__img">
              <img src={document6} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>Learning Certifications</strong>
                <p>
                  Verify learning certifications, such as degrees and diplomas, from anywhere in the
                  world, instantly, without exposing names or other sensitive information.
                </p>
              </div>
            </div>
            <div className="cronica-docs__img">
              <img src={document7} alt="phone" />
              <div className="cronica-docs__description description description--white">
                <strong>Health Records</strong>
                <p>
                  Cronica only stores data necessary for verification in machine-readable form. Data
                  only becomes human-readable once it is reformed with a locally-stored PDF
                  template.
                </p>
              </div>
            </div>
          </div>
        </BasicScroll>
      </div>
    </section>
  );
};

export default CronicaDocuments;
