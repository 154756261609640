import React from 'react';
import { HelmetWrapper } from 'elements';
import { RequestAccess } from 'components';

const RequestAccessPage = () => (
  <>
    <HelmetWrapper helmetKey="request_access" />
    <RequestAccess />
  </>
);

export default RequestAccessPage;
