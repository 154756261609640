import React from 'react';
import { Container, Title, Tag, LifeCard, ContentSwitcher, ProgressSlider } from 'elements';
import './CultureBenefits.scss';

import sunSvg from 'images/icons/sun.svg';
import trendingSvg from 'images/icons/trending-white.svg';
import smileSvg from 'images/icons/smile.svg';
import houseSvg from 'images/icons/house.svg';
import calendarSvg from 'images/icons/schedule.svg';
import clockSvg from 'images/icons/clock.svg';
import diagramSvg from 'images/icons/diagram.svg';
import activitySvg from 'images/icons/activity-white.svg';
import heartSvg from 'images/icons/heart.svg';
import medalSvg from 'images/icons/medal.svg';
import coffeeSvg from 'images/icons/coffee.svg';
import likeSvg from 'images/icons/like.svg';

const data = [
  {
    icon: sunSvg,
    title: 'Annual leave allowance',
    description: 'Finto offers you 16 days’ holiday every year for you to enjoy and recharge.',
  },
  {
    icon: trendingSvg,
    title: 'Personal development',
    description:
      'Finto offers knowledge-sharing sessions and the opportunity to apply for training courses and conferences that interest you.',
  },
  {
    icon: smileSvg,
    title: 'Health and wellness',
    description:
      'Finto cares about your health and wellbeing, therefore, we offer you paid sick leaves for both physical and mental health. We also have an open door policy, where we will offer you 24/7 assistance.',
  },
  {
    icon: houseSvg,
    title: 'Parental leave',
    description:
      'Finto cares about your family, therefore, we offer you paid maternity and parental leaves as needed.',
  },
  {
    icon: calendarSvg,
    title: '16 Salaries',
    description:
      'Finto believes in the capabilities of its employees, which is why you will get 16 salaries instead of 12.',
  },
  {
    icon: clockSvg,
    title: 'Flexible working hours',
    description:
      'Finto trusts you to work enough hours to do your job well, at the times and conditions that suit you and your team.',
  },
  {
    icon: diagramSvg,
    title: 'Reduced interest loans',
    description:
      'Finto has a reduced interest program for employees’ loans. All you have to do is apply for a loan and leave the rest to us.',
  },
  {
    icon: activitySvg,
    title: 'Medical & life insurance',
    description:
      'Your health is our top priority. Finto offers you a life insurance and a medical insurance for you and your family members.',
  },
  {
    icon: heartSvg,
    title: 'Nursery',
    description:
      'Finto cares just as much about your kids! Finto has a daycare program where you can drop off your kids to play and learn.',
  },
  {
    icon: medalSvg,
    title: 'Annual bonus scheme',
    description:
      'Managers review salaries every 6 months and offer proactive pay increases that reflect growing responsibilities. We also have an annual bonus scheme to reward high achievers.',
  },
  {
    icon: coffeeSvg,
    title: 'Community',
    description:
      'Finto hosts team-building activities where you can socialize, have a good time and meet new people.',
  },
  {
    icon: likeSvg,
    title: 'Social Responsibility',
    description:
      'Finto cares about social issues and always looks for ways to give back to the community, which is why Finto has a wide range of volunteering opportunities.',
  },
];

const CultureBenefits = () => (
  <section className="culture-benefits__section">
    <div className="culture-benefits__patterns">
      <Container className="culture-benefits__container">
        <Tag
          color="purple"
          className="culture-benefits__tag"
          lineClassName="culture-benefits__tag-line"
        >
          BENEFITS
        </Tag>
        <Title
          tagName="h2"
          sizeText="size-2"
          alignText="text-center"
          className="culture-benefits__title"
        >
          Live your best life
        </Title>
        <div className="culture-benefits__wrapper">
          <ContentSwitcher
            mobileContent={
              <ProgressSlider className="culture-benefits__slider">
                {data.map((c) => (
                  <LifeCard className="culture-benefits__card" key={c.title} {...c} />
                ))}
              </ProgressSlider>
            }
            desktopContent={
              <>
                {data.map((c) => (
                  <LifeCard className="culture-benefits__card" key={c.title} {...c} />
                ))}
              </>
            }
          />
        </div>
      </Container>
    </div>
  </section>
);

export default CultureBenefits;
