import React from 'react';
import { useSelector } from 'react-redux';
import { GetStarted, Image, Tag, Title, Description, Container, TriggerOnEnter } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import phone from 'images/phones/phone-regular-black.webp';
import browser from 'images/browsers/browser-overview.webp';
import phoneMobile from 'images/mobile/phones/phone-regular-black.webp';
import browserMobile from 'images/mobile/browsers/browser-overview.webp';
import BankCardSvg from 'images/bank_cards/bank-card-orange.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './BaapHero.scss';

const BaapHero = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="baap-hero__section">
      <Container className="baap-hero__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked}>
          <div className="baap-hero__top-content hero-animations">
            <div className="baap-hero__declaration">
              <Tag color="white">baap</Tag>
              <Title alignText="left" tagName="h1" sizeText="size-2" color="white">
                <div className="title__part">
                  Seamlessly integrate<span className="title__space">&nbsp;</span>
                </div>
                <div className="title__part">financial services</div>
              </Title>
              <Description sizeText="size-2" color="white">
                Use Finto’s API banking platform to seamlessly integrate financial services into
                your digital products, access financial data, and generate new revenue streams.
              </Description>
              <div className="baap-hero__buttons buttons-holder">
                <GetStarted
                  background="white"
                  border="border-white"
                  color="text-black"
                  size="medium"
                  className="baap-hero__button button--with-icon"
                  to="https://developer.finto.io/"
                  target="_blank"
                  buttontype="hoverable"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Get started</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </GetStarted>
              </div>
            </div>
            <div className="baap-hero__images">
              <div className="baap-hero__image baap-hero__image-browser">
                <Image src={browser} srcMobile={browserMobile} alt="browser" />
              </div>
              <div className="baap-hero__image baap-hero__image-phone">
                <Image src={phone} srcMobile={phoneMobile} alt="phone" />
              </div>
              <div className="baap-hero__card">
                <BankCardSvg />
              </div>
            </div>
          </div>
        </TriggerOnEnter>
      </Container>
    </section>
  );
};

export default BaapHero;
