import { createSelector } from 'reselect';
import { STATE_KEY } from './flags';
import { getState, getProperty } from '../common/commonGetters';

export const getFlags = createSelector([getState], (state) => state[STATE_KEY]);

export const getFlagProperty = createSelector(
  [getFlags, getProperty],
  (flags, name) => flags[name]
);

export const getAreAnimationsUnblocked = createSelector(
  [getFlags],
  (flags) => flags.areAnimationsUnblocked
);

export const getIsNotFoundPage = createSelector([getFlags], (flags) => flags.isNotFound);
