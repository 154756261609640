import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="62.6065" cy="62.8714" r="62.1287" fill="#1E2132" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.999 63.3504C107.999 56.9504 104.899 51.9004 98.9741 51.9004C93.0241 51.9004 89.4241 56.9504 89.4241 63.3004C89.4241 70.8254 93.6741 74.6254 99.7741 74.6254C102.749 74.6254 104.999 73.9504 106.699 73.0004V68.0004C104.999 68.8504 103.049 69.3754 100.574 69.3754C98.1491 69.3754 95.9991 68.5254 95.7241 65.5754H107.949C107.949 65.2504 107.999 63.9504 107.999 63.3504ZM95.6491 60.9754C95.6491 58.1504 97.3741 56.9754 98.9491 56.9754C100.474 56.9754 102.099 58.1504 102.099 60.9754H95.6491Z"
      fill="#635BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.7741 51.9004C77.3241 51.9004 75.7491 53.0504 74.8741 53.8504L74.5491 52.3004H69.0491V81.4504L75.2991 80.1254L75.3241 73.0504C76.2241 73.7004 77.5491 74.6254 79.7491 74.6254C84.2241 74.6254 88.2991 71.0254 88.2991 63.1004C88.2741 55.8504 84.1491 51.9004 79.7741 51.9004ZM78.2741 69.1254C76.7991 69.1254 75.9241 68.6004 75.3241 67.9504L75.2991 58.6754C75.9491 57.9504 76.8491 57.4504 78.2741 57.4504C80.5491 57.4504 82.1241 60.0004 82.1241 63.2754C82.1241 66.6254 80.5741 69.1254 78.2741 69.1254Z"
      fill="#635BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4495 50.4255L66.7245 49.0755V44.0005L60.4495 45.3255V50.4255Z"
      fill="#635BFF"
    />
    <path d="M66.7245 52.3257H60.4495V74.2007H66.7245V52.3257Z" fill="#635BFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.7241 54.1753L53.3241 52.3253H47.9241V74.2003H54.1741V59.3753C55.6491 57.4503 58.1491 57.8003 58.9241 58.0753V52.3253C58.1241 52.0253 55.1991 51.4753 53.7241 54.1753Z"
      fill="#635BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.2244 46.9004L35.1244 48.2004L35.0994 68.2254C35.0994 71.9254 37.8744 74.6504 41.5744 74.6504C43.6244 74.6504 45.1244 74.2754 45.9494 73.8254V68.7504C45.1494 69.0754 41.1994 70.2254 41.1994 66.5254V57.6504H45.9494V52.3254H41.1994L41.2244 46.9004Z"
      fill="#635BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3243 58.6754C24.3243 57.7004 25.1243 57.3254 26.4493 57.3254C28.3493 57.3254 30.7493 57.9004 32.6493 58.9254V53.0504C30.5743 52.2254 28.5243 51.9004 26.4493 51.9004C21.3743 51.9004 17.9993 54.5504 17.9993 58.9754C17.9993 65.8754 27.4993 64.7754 27.4993 67.7504C27.4993 68.9004 26.4993 69.2754 25.0993 69.2754C23.0243 69.2754 20.3743 68.4254 18.2743 67.2754V73.2254C20.5993 74.2254 22.9493 74.6504 25.0993 74.6504C30.2993 74.6504 33.8743 72.0754 33.8743 67.6004C33.8493 60.1504 24.3243 61.4754 24.3243 58.6754Z"
      fill="#635BFF"
    />
  </svg>
);

export default SVG;
