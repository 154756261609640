import { Subscription, FeaturesByCountry, FeaturesForm } from 'components';
import { Container, Description, Image, Title } from 'elements';
import earth from 'images/coverage-earth.webp';
import React from 'react';
import './CoveragFeaturesHero.scss';

const CoverageHero = () => (
  <section className="coverage-hero__section">
    <div className="coverage-hero__patterns-wrapper">
      <div className="coverage-hero__patterns-wrapper-second">
        <Container className="coverage-hero__container coverage-hero__container--declaration">
          <div className="coverage-hero__content-wrapper coverage-hero__content-wrapper--declaration">
            <Title
              className="coverage-hero__title coverage-hero__title--declaration"
              alignText="text-center"
              sizeText="size-4"
            >
              Global coverage
            </Title>
            <Description
              className="coverage-hero__description"
              alignText="text-center"
              sizeText="size-2"
            >
              We are constantly expanding our coverage and capabilities across the globe, sign up
              below to get notified when we launch new features and in new regions
            </Description>
            <Subscription className="coverage-hero__subscription" />
          </div>
        </Container>
        <div className="coverage-hero__earth-wrapper">
          <Image className="coverage-hero__earth-img" src={earth} />
        </div>
        <Container className="coverage-hero__container coverage-hero__container--form">
          <div className="coverage-hero__content-wrapper coverage-hero__content-wrapper--form">
            <Title
              tagName="h2"
              className="coverage-hero__title coverage-hero__title--form"
              alignText="text-center"
              sizeText="size-2"
            >
              Finto’s regional capabilities
            </Title>
            <Description
              className="coverage-hero__description coverage-hero__description--form"
              alignText="text-center"
              sizeText="size-2"
            >
              See what digital banking services are currently availible in your country or region.
            </Description>
          </div>
          <FeaturesByCountry />
          <FeaturesForm />
        </Container>
      </div>
    </div>
  </section>
);
export default CoverageHero;
