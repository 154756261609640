import React from 'react';
import { HelmetWrapper } from 'elements';
import { ScheduleDemo } from 'components';

const ScheduleDemoPage = () => (
  <>
    <HelmetWrapper helmetKey="schedule_demo" />
    <ScheduleDemo />
  </>
);

export default ScheduleDemoPage;
