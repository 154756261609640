/* eslint-disable no-param-reassign, consistent-return, no-unused-expressions */
import React from 'react';
import { array, oneOf, string } from 'prop-types';
import { useResponsive } from 'hooks';
import cx from 'classnames';
import gsap from 'gsap';
import './PresentationView.scss';
import './presentation_view_variables.scss';
import PresentationItem from './PresentationItem';

const PresentationView = ({ data, className, variant, align = 'center' }) => {
  const contentColor = variant === 'black' ? 'white' : 'black';
  const { isDesktop } = useResponsive();
  const dataLength = data?.length;
  return (
    <div
      className={cx('presentation-view', className, {
        [`presentation-view--${align}`]: !!align,
        [`presentation-view--${variant}`]: !!variant,
        'presentation-view--one': dataLength === 1,
        'presentation-view--two': dataLength === 2,
        'presentation-view--three': dataLength === 3,
      })}
    >
      {data.map((c, i) => {
        const computedProps = {};
        computedProps.onMouseEnter = (event, { video }) => {
          if (isDesktop && video) {
            gsap.fromTo(
              video,
              { currentTime: video.currentTime },
              {
                duration: 0.3,
                currentTime: 0.55,
                ease: 'none',
              }
            );
          }
        };
        computedProps.onMouseLeave = (event, { video }) => {
          if (isDesktop && video) {
            gsap.fromTo(
              video,
              { currentTime: video.currentTime },
              {
                duration: 0.3,
                currentTime: 0,
                ease: 'none',
              }
            );
          }
        };
        return (
          <PresentationItem
            contentColor={contentColor}
            contentAlign={align}
            key={i.toString()}
            item={c}
            {...computedProps}
          />
        );
      })}
    </div>
  );
};

PresentationView.propTypes = {
  data: array,
  className: string,
  align: string,
  variant: oneOf(['white', 'black']),
};

PresentationView.defaultProps = {
  variant: 'black',
};

export default PresentationView;
