/* eslint-disable no-shadow, react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { Form } from 'react-final-form';
import memoize from 'lru-memoize';
import { useForm } from '@formspree/react';
import { Button, Input, Title, Description, FormSelect } from 'elements';
import ReCAPTCHA from 'react-google-recaptcha';
import { createValidator, email as emailValidation, required, phone } from 'utils/validation';
import FormWrapper from '../FormWrapper/FormWrapper';
import { COUNTRIES } from '../../../data/selects_data';
import './ContactsForm.scss';

const validate = memoize(10)(
  createValidator({
    firstName: [required],
    lastName: [required],
    message: [required],
    phone: [required, phone],
    email: [required, emailValidation],
    country: [required],
  })
);

const SITE_KEY = '6LfVV30cAAAAAFgIeM443bBPErI8SHp7kbayq4Ea'; // google test key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const ContactsForm = () => {
  const recaptchaRef = useRef();

  const [submittingState, submit] = useForm('meqvagjn');

  const submitForm = (formData) => {
    if (!recaptchaRef.current) return;
    recaptchaRef.current.reset();
    recaptchaRef.current
      .executeAsync()
      .then((t) => {
        const data = {
          ...formData,
          'g-recaptcha-response': t,
          country: formData?.country?.label || '',
        };
        submit(data);
      })
      .catch((e) => console.log('error', e));
  };

  return (
    <FormWrapper className="contacts-form__wrapper" scrollXPos={0} {...submittingState}>
      <div className="contacts-form">
        <Title
          tagName="h1"
          className="contacts-form__title"
          alignText="text-center"
          sizeText="size-3"
        >
          Contact us
        </Title>
        <Description
          className="contacts-form__description"
          alignText="text-center"
          sizeText="size-2"
        >
          Our sales team are always on hand to answer any questions you might have, simply fill out
          the form and we will be in touch as soon as possible.
        </Description>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={SITE_KEY}
          onErrored={(error) => console.log('onErrored', error)}
        />
        <Form
          onSubmit={submitForm}
          validate={validate}
          initialValues={{
            country: 'jordan',
          }}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <div className="contacts-form__row contacts-form__row--double-col">
                <Input variant="fourth" name="firstName" label="First name" />
                <Input variant="fourth" name="lastName" label="Last name" />
              </div>
              <div className="contacts-form__row">
                <Input variant="fourth" name="email" label="work email" />
              </div>
              <div className="contacts-form__row">
                <Input variant="fourth" name="phone" label="work phone" />
              </div>
              <div className="contacts-form__row">
                <FormSelect
                  options={COUNTRIES}
                  variant="secondary"
                  name="country"
                  label="Country"
                />
              </div>
              <div className="contacts-form__row">
                <Input variant="fourth" name="message" NodeType="textarea" label="message" />
              </div>
              <Button
                disabled={submitting || pristine}
                className="contacts-form__submit-button button button-with-icon"
                type="submit"
                size="medium"
                display="block"
                onClick={handleSubmit}
                buttontype="gradient-hoverable"
                isCentered
              >
                <div className="button__inner">
                  <span>submit</span>
                </div>
              </Button>
            </>
          )}
        />
      </div>
    </FormWrapper>
  );
};

export default ContactsForm;
