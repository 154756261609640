import {
  Scene,
  PerspectiveCamera,
  PointLight,
  WebGLRenderer,
  BasicShadowMap,
  TextureLoader,
  MeshLambertMaterial,
  MeshPhongMaterial,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { gsap, Expo, Power1 } from 'gsap';
import './Card.scss';

export default class Card {
  constructor(options) {
    this.canvas = options.canvas;
    this.buttons = options.buttons; // set stuffs
    this.cardNameColors = options.cardNameColors;
    this.container = options.container;
    this.mqlDevice = window.matchMedia('(pointer: coarse)');

    this.activeButton = 0;
    this.setTextureCallback = null;

    const { width, height } = this.container.getBoundingClientRect();

    this.scene = new Scene();

    this.camera = new PerspectiveCamera(45, width / height, 0.1, 1000);
    this.camera.position.set(0, 0, 6);

    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
    });

    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = BasicShadowMap;
    this.renderer.setAnimationLoop(this.render);

    this.light = new PointLight(0xffffff, 2, 1000);
    this.light.position.set(2, 6, 4);
    this.light.castShadow = true;
    this.light.shadow.mapSize.width = 1280;
    this.light.shadow.mapSize.height = 1280;
    this.light.shadow.camera.near = 0.5;
    this.light.shadow.camera.far = 20;
    this.scene.add(this.light);

    // create loaders
    const textureLoader = new TextureLoader();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('three/card/draco/');

    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

    // init everything
    this.loadScene(gltfLoader, textureLoader);
    this.loadCardTextures(textureLoader);
    this.loadCard(gltfLoader);

    this.render();

    this.buttons.forEach(($button, i) => {
      $button.addEventListener('click', this.onButtonClick(i));
    });

    window.addEventListener('resize', this.onResize);
    if (!this.mqlDevice.matches) {
      this.container.addEventListener('mousemove', this.onMouseMove);
    }
  }

  loadScene(gltfLoader, textureLoader) {
    const bakedBgTexture = textureLoader.load('three/card/bg_dark_2.jpg');
    bakedBgTexture.flipY = false;

    const bakedBgMaterial = new MeshLambertMaterial({
      map: bakedBgTexture,
    });

    gltfLoader.load('three/card/finto_bg_dark_v1.4.glb', (gltf) => {
      // Create variables
      // const backgroundMesh = gltf.scene.children.find((child) => child.name === 'Background_Dark');
      const plinthMesh = gltf.scene.children.find((child) => child.name === 'Plinth_Dark');

      // Add textures
      gltf.scene.traverse((child) => {
        child.material = bakedBgMaterial; // eslint-disable-line
      });

      plinthMesh.castShadow = false;
      plinthMesh.receiveShadow = true;

      this.scene.add(gltf.scene);
    });
  }

  loadCardTextures(textureLoader) {
    this.cardsTextures = [
      'three/card/tex_test_grad_v1+chip.jpg',
      'three/card/tex_test_grad_v2+chip.jpg',
      'three/card/tex_test_grad_v3+chip.jpg',
      'three/card/tex_test_grad_v4+chip.jpg',
    ].map((name) => {
      const texture = textureLoader.load(name);
      texture.flipY = false;

      return texture;
    });
  }

  loadCard(gltfLoader) {
    this.bakedCardMaterial = new MeshLambertMaterial({
      map: this.cardsTextures[0],
      reflectivity: 1,
    });

    const white = 0xffffff;

    this.cardColorMaterial = new MeshPhongMaterial({
      color: this.cardNameColors[1],
    });

    const whiteMaterial = new MeshPhongMaterial({ color: white });

    gltfLoader.load('three/card/finto_three_v1.5.2.glb', (card) => {
      const cardRHSMesh = card.scene.children.find((child) => child.name === 'RHS');
      const cardLHSMesh = card.scene.children.find((child) => child.name === 'LHS');
      const cardTypeMesh = card.scene.children.find((child) => child.name === 'Card_Type');
      const nameMesh = card.scene.children.find((child) => child.name === 'Carholder_Name');
      const logoMesh = card.scene.children.find((child) => child.name === 'Finto_Logo');
      const visaLogoMesh = card.scene.children.find((child) => child.name === 'Visa_Logo');
      const chipMesh = card.scene.children.find((child) => child.name === 'Chip');

      card.scene.traverse((child) => {
        child.material = this.bakedCardMaterial; // eslint-disable-line
      });

      cardTypeMesh.material = this.cardColorMaterial;
      nameMesh.material = whiteMaterial;
      logoMesh.material = whiteMaterial;
      visaLogoMesh.material = this.bakedCardMaterial;
      chipMesh.material = this.bakedCardMaterial;

      cardRHSMesh.castShadow = true;
      cardRHSMesh.recieveShadow = false;
      cardLHSMesh.castShadow = true;
      cardLHSMesh.receiveShadow = false;

      this.scene.add(card.scene);
      this.card = card.scene;
    });
  }

  onMouseMove = (e) => {
    gsap.to(this.camera.position, {
      x: e.clientX / window.innerWidth - 0.5,
      y: -(e.clientY / window.innerWidth - 0.5),
      duration: 0.2,
      ease: Power1.easeInOut,
    });
  };

  onResize = () => {
    const { width, height } = this.container.getBoundingClientRect();

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  };

  onButtonClick = (i) => () => {
    this.updateCard(i);
    this.updateButtons();
  };

  updateCard(i) {
    // is the card ready?
    if (!this.card || this.activeButton === i) {
      return;
    }

    this.activeButton = i;

    this.cardColorMaterial.color.set(this.cardNameColors[i]); // here you can update the color on the material

    // rotate the card
    gsap.fromTo(
      this.card.rotation,
      { y: 0 },
      {
        duration: 1,
        ease: Expo.easeOut,
        y: Math.PI * 2,
      }
    );

    // we need to keep track of the delayed call to update the texture
    // if the user clicks on two buttons fast, only to execute the latest
    if (this.setTextureCallback) {
      this.setTextureCallback.kill();
    }

    this.setTextureCallback = gsap.delayedCall(0.2, () => {
      this.bakedCardMaterial.map = this.cardsTextures[i];
      this.setTextureCallback = null;
    });
  }

  updateButtons() {
    this.buttons.forEach(($button, i) => {
      if (i === this.activeButton) {
        $button.classList.add('selected');
      } else {
        $button.classList.remove('selected');
      }
    });
  }

  destroy() {
    this.renderer.setAnimationLoop(null);
    window.removeEventListener('resize', this.onResize);

    if (!this.mqlDevice.matches) {
      this.container?.removeEventListener('mousemove', this.onMouseMove);
    }
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.light = null;
  }

  render = () => {
    this.camera.lookAt(0, 0, 0);
    this.renderer.render(this.scene, this.camera);
  };
}
