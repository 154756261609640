import React from 'react';
import { func, bool, node, string, oneOfType, object, elementType } from 'prop-types';
import cx from 'classnames';
import './Button.scss';

const Button = ({
  active,
  alignText,
  alignIcon,
  background,
  border,
  children,
  className,
  color,
  display,
  disabled,
  icon,
  kind,
  onClick,
  size,
  style,
  type,
  textTransform,
  tagName: TagName,
  isCentered,
  buttontype,
  ...rest
}) => (
  <TagName
    type={type}
    className={cx('button', className, {
      'is-active': !!active,
      [`button--${kind}`]: !!kind,
      [`button--${display}`]: !!display,
      [`button--${size}`]: !!size,
      [`button--${alignIcon}`]: !!alignIcon,
      [`button--${alignText}`]: !!alignText,
      [`button--${textTransform}`]: !!textTransform,
      [`button--${color}`]: !!color,
      [`button--${background}`]: !!background,
      [`button--${border}`]: !!border,
      [`button--type-${buttontype}`]: !!buttontype,
      [`button--is-centered`]: isCentered,
    })}
    onClick={onClick}
    disabled={disabled}
    style={style}
    {...rest}
  >
    {icon && <span className={icon} />}
    {children}
  </TagName>
);

Button.defaultProps = {
  active: '',
  alignText: 'text-center',
  alignIcon: null,
  background: null,
  border: null,
  children: null,
  color: '',
  className: null,
  display: null,
  disabled: false,
  icon: null,
  kind: null,
  onClick: null,
  size: null,
  style: null,
  type: 'button',
  textTransform: 'uppercase',
  tagName: 'button',
};

Button.propTypes = {
  active: string,
  alignText: string,
  alignIcon: string,
  background: string,
  border: string,
  children: oneOfType([node, string]),
  className: string,
  color: string,
  display: string,
  disabled: bool,
  icon: string,
  kind: string,
  onClick: func,
  size: string,
  style: object,
  type: string,
  textTransform: string,
  tagName: oneOfType([node, elementType]),
  buttontype: string,
  isCentered: bool,
};

export default Button;
