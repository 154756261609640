/* eslint-disable react-hooks/rules-of-hooks */
import { useLayoutEffect, useRef } from 'react';
import anime from 'animejs';
import { getSvgLength } from '../utils/animationHelpers';
import { checkIsServer } from '../utils/helpers';
import { ANIMATION_EASING_IN, ANIMATION_MEDIUM_DURATION } from '../constants/animations';

function getLength(el) {
  const offsetError = 2;
  return (el?.getTotalLength() || getSvgLength.rect(el)) + offsetError;
}

export default function useAnimatableBorder({
  ref,
  easing = ANIMATION_EASING_IN,
  duration = ANIMATION_MEDIUM_DURATION,
}) {
  const mutableObj = useRef({ instance: null }).current;

  const handleMouseEnter = () => {
    const { instance } = mutableObj;
    if (!instance) {
      mutableObj.instance = anime({
        easing,
        duration,
        targets: ref.current,
        strokeDashoffset: [getLength(ref.current), 0],
      });
    } else {
      if (instance.direction === 'reverse') {
        instance.reverse();
      }
      if (instance.changeCompleted) {
        instance.play();
      }
    }
  };
  const handleMouseLeave = () => {
    const { instance } = mutableObj;

    // reset animation and instance if mouse was removed too quickly
    // before the animation has had a chance to run
    if (!instance.changeBegan && !instance.changeCompleted) {
      instance.reset();
      mutableObj.instance = null;
      return;
    }
    if (instance.direction === 'normal') {
      instance.reverse();
    }
    if (instance.changeCompleted) {
      instance.play();
    }
  };

  if (!checkIsServer()) {
    useLayoutEffect(() => {
      const elementLength = getLength(ref.current);
      ref.current.setAttribute('stroke-dasharray', elementLength);
      ref.current.setAttribute('stroke-dashoffset', elementLength);
    }, []); // eslint-disable-line
  }

  return [handleMouseEnter, handleMouseLeave];
}
