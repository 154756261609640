import React from 'react';
import { PresentationView } from 'components';

const data = [
  {
    tag: 'Baap',
    title: 'API Platform',
    description:
      'Use Finto’s API platform to seamlessly integrate financial services into your digital products, access financial data, and generate new revenue streams.',
    itemType: 'browser',
    video: {
      mp4: '/videos/slider_2_browser.mp4',
      poster: '/videos/slider_2_browser.webp',
    },
    link: '/baap',
  },
  {
    tag: 'PAAS',
    title: 'Personalised payments',
    description:
      'Use Finto’s API platform to seamlessly integrate financial services into your digital products, access financial data, and generate new revenue streams.',
    itemType: 'card',
    video: {
      mp4: '/videos/slider_2_card.mp4',
      poster: '/videos/slider_2_card.webp',
    },
    link: '/paas',
  },
];

export default () => <PresentationView className="baas-presentation" data={data} />;
