/* eslint-disable no-restricted-syntax */
import JOBS from '../../../data/data';

export const STATE_KEY = 'jobs';

const FILTER_DATA = `${STATE_KEY}/FILTER_DATA`;
const INCREASE_PAGE = `${STATE_KEY}/INCREASE_PAGE`;

const initialState = {
  initialData: JOBS,
  data: JOBS,
  page: 1,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FILTER_DATA: {
      let { initialData } = state;
      let result = [];
      const query = action.data.search?.trim();
      for (const [key, values] of Object.entries(action.data)) {
        // eslint-disable-next-line no-continue
        if (!values.length || key === 'search') continue;
        initialData = initialData.filter((c) => values.some((cc) => cc.value === c[key]));
      }
      if (query) {
        result = initialData.filter((obj) =>
          Object.keys(obj).some((key) => {
            const objKey = obj[key].toLowerCase();
            return objKey.includes(query.toLowerCase());
          })
        );
      } else {
        result = initialData;
      }
      return {
        ...state,
        data: result,
        page: 1,
      };
    }
    case INCREASE_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    default:
      return state;
  }
}

export const filterData = (formData) => ({
  type: FILTER_DATA,
  data: formData,
});

export const increasePage = () => ({
  type: INCREASE_PAGE,
});
