import React from 'react';
import { string, node, oneOf } from 'prop-types';
import cx from 'classnames';
import './Tag.scss';

const Tag = ({
  color,
  lineColor,
  children,
  sizeText,
  className,
  textClassName,
  lineClassName,
  align,
}) => (
  <div className={cx('tag', className, { [`tag--${align}`]: !!align })}>
    <span
      className={cx(
        'tag__line',
        {
          [`tag__line--${lineColor}`]: !!lineColor,
        },
        lineClassName
      )}
    />
    <span
      className={cx(
        'tag__text',
        {
          [`tag__text--${color}`]: !!color,
          [`tag__text--${sizeText}`]: !!sizeText,
        },
        textClassName
      )}
    >
      {children}
    </span>
  </div>
);

Tag.defaultProps = {
  color: 'vulcan',
  children: undefined,
  sizeText: 'size-1',
  lineColor: 'purple',
};

Tag.propTypes = {
  children: node,
  sizeText: string,
  color: string,
  lineColor: string,
  className: string,
  textClassName: string,
  lineClassName: string,
  align: oneOf(['center', 'left', 'right']),
};

export default Tag;
