import React from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';
import { Container, Title, Description, Tag, Image } from 'elements';
import Image1 from 'images/culture/mission-1.webp';
import Image2 from 'images/culture/mission-2.webp';
import Image3 from 'images/culture/mission-3.webp';
import ImageMobile1 from 'images/mobile/culture/mission-1.webp';
import ImageMobile2 from 'images/mobile/culture/mission-2.webp';
import ImageMobile3 from 'images/mobile/culture/mission-3.webp';
import './CultureMission.scss';

const CultureMission = ({ withNegativeOffset }) => (
  <section
    className={cx('culture-mission__section', {
      'culture-mission__section--bottom-negative-offset': !!withNegativeOffset,
    })}
  >
    <Container className="culture-mission__container">
      <Tag
        color="purple"
        className="culture-mission__tag"
        lineClassName="culture-mission__tag-line"
      >
        Our mission
      </Tag>
      <Title
        tagName="h2"
        sizeText="size-2"
        alignText="text-center"
        className="culture-mission__title"
      >
        Powering financial innovation and inclusion
      </Title>
      <Description
        sizeText="size-2"
        alignText="text-center"
        className="culture-mission__description"
      >
        Our mission is to create the most intuitive and comprehensive financial framework in the
        world. Designed meticulously from the ground up to enrich the lives of our customers and
        their users.
      </Description>
      <div className="culture-mission__image-wrapper">
        <Image className="culture-mission__image" src={Image3} srcMobile={ImageMobile3} />
        <Image className="culture-mission__image" src={Image2} srcMobile={ImageMobile2} />
        <Image className="culture-mission__image" src={Image1} srcMobile={ImageMobile1} />
      </div>
    </Container>
  </section>
);

CultureMission.propTypes = {
  withNegativeOffset: bool,
};

CultureMission.defaultProps = {
  withNegativeOffset: false,
};

export default CultureMission;
