import React from 'react';
import { GetStarted } from 'elements';
import { Epilog } from 'components';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './HomeEpilog.scss';

const HomeEpilog = () => (
  <Epilog
    title={
      <>
        <div className="title__part">Start building the future </div>
        <div className="title__part">of finance now</div>
      </>
    }
    description="Create an account, get instant access to the Finto API and start building in minutes."
    variant="black"
    hasAnimation
  >
    <div className="buttons-holder">
      <GetStarted
        className="button--with-icon"
        to="https://developer.finto.io/"
        target="_blank"
        buttontype="gradient-hoverable"
        color="text-white"
        size="medium"
        isCentered
      >
        <div className="button__inner">
          <span>Get started</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </GetStarted>
    </div>
  </Epilog>
);

export default HomeEpilog;
