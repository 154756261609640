import React from 'react';
import { HelmetWrapper } from 'elements';
import { DevelopersHero, DevelopersSdks, DevelopersResources, DevelopersEpilog } from 'components';

const Developers = () => (
  <>
    <HelmetWrapper helmetKey="developers" />
    <DevelopersHero />
    <DevelopersSdks />
    <DevelopersResources />
    <DevelopersEpilog />
  </>
);

export default Developers;
