import React from 'react';
import { RequestAccessForm } from 'components';
import { Container } from 'elements';
import './RequestAccess.scss';

const RequestAccess = () => (
  <section className="request-access__section">
    <Container className="request-access__container">
      <RequestAccessForm />
    </Container>
  </section>
);
export default RequestAccess;
