import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { string, oneOfType, node, arrayOf, array } from 'prop-types';
import cx from 'classnames';
import { Image } from 'elements';
import QuotesSvg from 'images/icons/quotes.svg';
import './CultureSlider.scss';

SwiperCore.use([Pagination, Navigation]);

const Slider = ({ className, children, data }) => {
  if (!children && !data) return null;
  const dataToRender = children || data;
  const isData = data && data.length > 1;
  return (
    <div className={cx('culture-slider', { [className]: !!className })}>
      <Swiper
        pagination={isData ? { type: 'progressbar' } : false}
        navigation={isData}
        slidesPerView="auto"
        className="mySwiper"
        noSwiping={!isData}
        noSwipingClass="swiper-slide"
      >
        {dataToRender.map((c, index) => (
          <SwiperSlide key={index.toString()}>
            <Slide {...c} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  className: string,
  data: array,
  children: oneOfType([arrayOf(node), node]),
};

export default Slider;

const Slide = ({ image, text, name, position }) => (
  <div className="culture-slide">
    <div className="culture-slide__image-wrapper">
      <Image className="culture-slide__image" src={image} />
    </div>
    <div className="culture-slide__description">
      <div className="culture-slide__icon-wrap">
        <QuotesSvg className="culture-slide__icon" />
      </div>
      <p className="culture-slide__text">{text}</p>
      <span className="culture-slide__name">{name}</span>
      <span className="culture-slide__position">{position}</span>
    </div>
  </div>
);

Slide.propTypes = {
  image: string,
  text: string,
  name: string,
  position: string,
};
