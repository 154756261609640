import React, { useRef } from 'react';
import { string, func } from 'prop-types';
import cx from 'classnames';
import './MenuCard.scss';
import useAnimatableBorder from '../../hooks/useAnimatableBorder';

const MenuCard = ({ desc, Icon, title, tag, handleCardClick }) => {
  const rectRef = useRef();
  const [handleMouseEnter, handleMouseLeave] = useAnimatableBorder({ ref: rectRef });

  return (
    <div
      className="menu_card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleCardClick}
    >
      <svg
        className="menu_card__border"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#8d93ff" />
          <stop offset="100%" stopColor="#823cd7" />
        </linearGradient>
        <rect
          ref={rectRef}
          width="100"
          height="100"
          x="0"
          y="0"
          fill="none"
          strokeWidth="10"
          rx="3"
          stroke="url(#gradient)"
        />
      </svg>
      <div className="menu_card__container">
        <div className="menu_card__icon">
          <Icon />
        </div>
        <div className="menu_card__title">{title}</div>
        <div className={cx('menu_card__desc', { 'menu_card__desc--bottom-offset': !!tag })}>
          {desc}
        </div>
        <div className="menu_card__tag">{tag}</div>
      </div>
    </div>
  );
};

MenuCard.defaultProps = {
  desc: '',
  title: '',
  tag: '',
};

MenuCard.propTypes = {
  desc: string,
  title: string,
  tag: string,
  Icon: func,
  handleCardClick: func,
};

export default MenuCard;
