import React from 'react';
import cx from 'classnames';
import { node, string } from 'prop-types';
import './Form.scss';

const FormRow = ({ className, children, type, ...rest }) => (
  <div
    className={cx('form__row', {
      [`form__row--${type}`]: !!type,
      [`${className}`]: !!className,
    })}
    {...rest}
  >
    {children}
  </div>
);

FormRow.defaultProps = {
  className: '',
  children: null,
  type: 'type-1',
};

FormRow.propTypes = {
  className: node,
  children: node,
  type: string,
};

export default FormRow;
