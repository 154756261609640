/* eslint-disable */
import React from 'react';
import { Title, TriggerOnEnter } from 'elements';
import { Link } from 'react-router-dom';
import { func, object } from 'prop-types';
import cx from 'classnames';
import TermsPrivacy from '../../TermsPrivacy/TermsPrivacy';
import { TERMS_AND_CONDITIONS_LAST_CHANGED_DATE } from '../../../constants/const';

function Terms() {
  return <TermsPrivacy title="Terms & Conditions" Content={Content} />;
}
export default Terms;

const Content = ({ wayPointCallback, className, offset }) => {
  return (
    <div className={cx({ [className]: !!className })}>
      <div className="content-box">
        <Title className="content__title content__title--2" tagName="h2">
          Please read these terms and conditions carefully before using this site
        </Title>
        <Title className="content__title content__title--3" tagName="h3">
          What's in these terms?
        </Title>
        <p>
          These terms tell you the rules for using our website <Link to="/">[www.finto.io]</Link>
          &nbsp;
          <b>(our Site)</b>.
        </p>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('how-to-contact-us')}>
          <>
            <Title id="how-to-contact-us" className="content__title content__title--3" tagName="h3">
              Who we are and how to contact us
            </Title>
            <p>
              <b>[Finto]</b> is an online Site operated by [Etihad Financial Technology] (
              <b>"We"</b>). We are registered in [the Hashemite Kingdom of Jordan] under company
              number [1322] and have our registered office at [building 13, king Hussein Business
              Park, Amman - Jordan]. To contact us, please email{' '}
              <a href="mailto:hello@finto.io">hello@finto.io</a>. By using our Site you accept these
              terms.
            </p>
            <p>
              To contact us, please email <a href="mailto:hello@finto.io">hello@finto.io</a>.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('you-accept-these-terms')}>
          <>
            <Title
              id="you-accept-these-terms"
              className="content__title content__title--3"
              tagName="h3"
            >
              By using our Site you accept these terms
            </Title>
            <p>If you do not agree to these terms, you must not use our Site.</p>
            <p>We recommend that you print a copy of these terms for future reference.</p>
            <p>There are other terms that may apply to you.</p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('terms-that-may-apply-to-you')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="terms-that-may-apply-to-you"
            >
              These terms of use refer to the following additional terms, which also apply to your
              use of our Site:
            </Title>
            <ul className="content__list">
              <li>
                Our <Link to="/privacy-policy">privacy policy</Link>, which sets out how we may use
                your personal information; and
              </li>
              <li>
                Our <Link to="/cookies-policy">cookie policy</Link>, which sets out information about
                the cookies on our Site.
              </li>
            </ul>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('may-make-change-terms')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="may-make-change-terms"
            >
              We may make changes to these terms
            </Title>
            <p>
              {`We amend these terms from time to time. Every time you wish to use our Site, please
              check these terms to ensure you understand the terms that apply at that time. These
              terms were most recently updated on ${TERMS_AND_CONDITIONS_LAST_CHANGED_DATE}.`}
            </p>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('may-make-change-site')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="may-make-change-site"
            >
              We may make changes to our Site
            </Title>
            <p>
              We may update and change our Site from time to time to reflect changes to our
              products, our users’ needs and our business priorities or for any other reason. We
              will try to give you reasonable notice of any major changes.
            </p>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('suspend')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="suspend">
              We may suspend or withdraw our Site
            </Title>
            <p>
              We do not guarantee that our Site, or any content on it, will always be available or
              be uninterrupted. We may suspend or withdraw or restrict the availability of all or
              any part of our Site for business and operational reasons. We will try to give you
              reasonable notice of any suspension or withdrawal, and under no circumstances will we
              be liable to you or to any person for any indirect, incidental, special, punitive,
              cover or consequential damages (including, without limitation, damages for lost
              profits, revenue, sales, goodwill, use of content, impact on business, business
              interruption, loss of anticipated savings, loss of business opportunity) if we do not
              notify you of such suspension or withdrawal.
            </p>
            <p>
              We reserve the right to refuse access to anyone for any reason at any time without the
              need to provide any justification or reason for such refusal.
            </p>
            <p>
              You are also responsible for ensuring that all persons who access our Site through
              your internet connection are aware of these terms of use and other applicable terms
              and conditions, and that they comply with them.
            </p>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('compensation')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="compensation">
              Compensation, fees
            </Title>
            <p>
              Our Site is made available free of charge. We shall notify you of any changes in this
              regard at least one week in advance.
            </p>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('transfer')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="transfer">
              We may transfer this agreement to someone else
            </Title>
            <p>
              We may transfer our rights and obligations under these terms to another organization.
              We will always tell you in writing if this happens and we will ensure that the
              transfer will not affect your rights under the contract.
            </p>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('account-details-safe')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="account-details-safe"
            >
              You must keep your account details safe
            </Title>
            <p>
              You must be at least [16] years of age to use our Site. By using our Site and by
              agreeing to these terms and conditions you warrant and represent that you are at least
              [16] years of age. If you create an account in our Site, you are responsible for
              maintaining the security of your account and you are fully responsible for all
              activities that occur under the account and any other actions taken in connection with
              it. We may monitor and review new accounts before you may sign in and use our Site.
              Providing false contact information of any kind may result in the termination of your
              account. You must immediately notify us of any unauthorized uses of your account or
              any other breaches of security. We will not be liable for any acts or omissions by
              you, including any damages of any kind incurred as a result of such acts or omissions.
              We may suspend, disable, or delete your account (or any part thereof) if we determine
              that you have violated any provision of these terms and conditions or that your
              conduct or content would tend to damage our reputation and goodwill. If we delete your
              account for the foregoing reasons, you may not re-register for our Site. We may block
              your email address and Internet protocol address to prevent further registration.
            </p>
            <p>
              If you choose, or you are provided with, a user identification code, password or any
              other piece of information as part of our security procedures, you must treat such
              information as confidential. You must not disclose it to any third party.
            </p>
            <p>
              We have the right to disable any user identification code or password, whether chosen
              by you or allocated by us, at any time, if in our reasonable opinion you have failed
              to comply with any of the provisions of these terms of use.
            </p>
            <p>
              If you know or suspect that anyone other than you knows your user identification code
              or password, you must promptly notify us at{' '}
              <a href="mailto:hello@finto.io">hello@finto.io</a>
            </p>
          </>
        </TriggerOnEnter>

        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('may-use-material')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="may-use-material">
              How you may use material on our Site
            </Title>
            <p>
              We are the owner or the licensee of all intellectual property rights in our Site, and
              in the material published on it. Those works are protected by copyright laws and
              treaties around the world. All such rights are reserved.
            </p>
            <p>
              You may print off one copy, and may download extracts, of any page(s) from our Site
              for your personal use and you may draw the attention of others within your
              organisation to content posted on our Site.
            </p>
            <p>
              You must not modify the paper or digital copies of any materials you have printed off
              or downloaded in any way, and you must not use any illustrations, photographs, video
              or audio sequences or any graphics separately from any accompanying text.
            </p>
            <p>
              Our status (and that of any identified contributors) as the authors of content on our
              Site must always be acknowledged.
            </p>
            <p>
              You must not use any part of the content on our Site for commercial purposes without
              obtaining a licence to do so from us or our licensors.
            </p>
            <p>
              If you print off, copy or download any part of our Site in breach of these terms of
              use, your right to use our Site will cease immediately and you must, at our option,
              return or destroy any copies of the materials you have made.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('disclaimer-of-warranty')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="disclaimer-of-warranty"
            >
              Disclaimer of warranty
            </Title>
            <p>
              You expressly agree that your use of our Site and its services is solely at your own
              risk. You agree that our Site’s services are provided on an "as is" and "as available"
              basis. We expressly disclaim all warranties of any kind, whether express or implied,
              including but not limited to the implied warranties of merchantability, fitness for a
              particular purpose and non-infringement. We make no warranty that the services will
              meet your requirements, or that the services will be uninterrupted, timely, secure, or
              error-free; nor do we make any warranty as to the results that may be obtained from
              the use of the services or as to the accuracy or reliability of any information
              obtained through the services or that defects in the Service will be corrected. You
              understand and agree that any material and/or data downloaded or otherwise obtained
              through the use of the services is done at your own discretion and risk and that you
              will be solely responsible for any damage to your system or loss of data that results
              from the download of such material and/or data. We make no warranty regarding any
              goods or services purchased or obtained through the Site’s services or any
              transactions entered into through our Site. No advice or information, whether oral or
              written, obtained by you from us or through the services shall create any warranty not
              expressly made herein.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('limitation-of-liability')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="limitation-of-liability"
            >
              Limitation of liability
            </Title>
            <p>
              To the fullest extent permitted by applicable law, we, our affiliates, officers,
              directors, employees, agents, suppliers or licensors shall under no circumstances
              whatever be liable to you or to any person, whether in contract, tort (including
              negligence), breach of statutory duty, warranty or otherwise, for any indirect,
              incidental, special, punitive, cover or consequential damages (including, without
              limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact
              on business, business interruption, loss of anticipated savings, loss of business
              opportunity), even if we have been advised as to the possibility of such damages or
              could have foreseen such damages.
            </p>
            <p>
              To the maximum extent permitted by applicable law, our aggregate liability and that of
              our affiliates, officers, employees, agents, suppliers and licensors, relating to the
              services will be limited to an amount greater of [one JOD] or any amounts actually
              paid in cash by you to us for the prior one month period prior to the first event or
              occurrence giving rise to such liability. The limitations and exclusions also apply if
              this remedy does not fully compensate you for any losses or fails of its essential
              purpose.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('indemnification')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="indemnification">
              Indemnification
            </Title>
            <p>
              You agree to indemnify and hold us and our affiliates, officers, directors, employees,
              agents, suppliers or licensors harmless from and against any liabilities, losses,
              damages or costs, including reasonable attorneys' fees, incurred in connection with or
              arising from any third-party claims, allegations, disputes, actions or demands
              asserted against any of them as a result of or relating to your content, data, your
              use of our Site or services or any wilful misconduct on your part.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('service-providers')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="service-providers">
              Service Providers
            </Title>
            <p>
              We may employ third party companies and individuals to facilitate and implement our
              services ("Service Providers"), provide the services on our behalf, perform
              services-related services or assist us in analysing how our services are used or to
              process, analyse, store and save any relevant information including your personal
              data. These third parties have access to your personal data only to perform these
              tasks on our behalf and will be obligated not to disclose or use it for any other
              purpose.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('rely-on-information')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="rely-on-information"
            >
              Do not rely on information on this Site
            </Title>
            <p>
              The content on our Site is provided for general information only. It is not intended
              to amount to advice on which you should rely. You must obtain professional or
              specialist advice before taking, or refraining from, any action on the basis of the
              content on our Site.
            </p>
            <p>
              Although we make reasonable efforts to update the information on our Site, we make no
              representations, warranties or guarantees, whether express or implied, that the
              content on our Site is accurate, complete or up to date.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('not-responsible')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="not-responsible">
              We are not responsible for applications we link to
            </Title>
            <p>
              Where our Site contains links to other Sites and resources provided by third parties,
              these links are provided for your information only. Such links should not be
              interpreted as approval by us of those linked applications or information you may
              obtain from them.
            </p>
            <p>We have no control over the contents of those sites or resources.</p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('personal-information')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="personal-information"
            >
              How we may use your personal information
            </Title>
            <p>
              We will only use your personal information as set out in our&nbsp;
              <Link to="/privacy-policy">privacy policy</Link>.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('prohibited-uses')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="prohibited-uses">
              Prohibited uses
            </Title>
            <p>
              In addition to other terms as set forth in these terms and conditions, you are
              prohibited from using our Site or its content: (a) for any unlawful purpose; (b) to
              solicit others to perform or participate in any unlawful acts; (c) to violate any
              international or local regulations, rules, laws, or ordinances; (d) to infringe upon
              or violate our intellectual property rights or the intellectual property rights of
              others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
              discriminate based on gender, sexual orientation, religion, ethnicity, race, age,
              national origin, or disability; (f) to submit false or misleading information; (g) to
              upload or transmit viruses or any other type of malicious code that will or may be
              used in any way that will affect the functionality or operation of our Site or the
              services or of any other related mobile applications, or the Internet; (h) to collect
              or track the personal information of others; (i) to spam, phish, pharm, pretext,
              spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere
              with or circumvent the security features of our Site or the service or any other
              related mobile applications, or the Internet. We reserve the right to terminate your
              use of our Site or any related service for violating any of the prohibited uses.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('not-responsible-for-viruses')}>
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="not-responsible-for-viruses"
            >
              We are not responsible for viruses and you must not introduce them
            </Title>
            <p>We do not guarantee that our Site will be secure or free from bugs or viruses.</p>
            <p>
              You are responsible for configuring your information technology, computer programmes
              and Site to access our Site You should use your own virus protection software.
            </p>
            <p>
              You must not misuse our Site by knowingly introducing viruses, trojans, worms, logic
              bombs or other material that is malicious or technologically harmful. You must not
              attempt to gain unauthorised access to our Site the server on which our Site is stored
              or any server, computer or database connected to our Site You must not attack our Site
              via a denial-of-service attack or a distributed denial-of service attack. By breaching
              this provision, you would commit a criminal offence under the applicable laws. We will
              report any such breach to the relevant law enforcement authorities and we will
              cooperate with those authorities by disclosing your identity to them. In the event of
              such a breach, your right to use our Site will cease immediately.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('linking-rules')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="linking-rules">
              Rules about linking to our Site
            </Title>
            <p>
              You may link to our Site provided you do so in a way that is fair and legal and does
              not damage our reputation or take advantage of it.
            </p>
            <p>
              You must not establish a link in such a way as to suggest any form of association,
              approval or endorsement on our part where none exists.
            </p>
            <p>
              You must not establish a link to our Site in any website or application that is not
              owned by you.
            </p>
            <p>
              Our Site must not be framed on any other site, nor may you create a link to any part
              of our Site other than the homepage.
            </p>
            <p>We reserve the right to withdraw linking permission without notice.</p>
            <p>
              The website or application in which you are linking must comply in all respects with
              our content standards.
            </p>
            <p>
              If you wish to link to or make any use of content on our Site other than that set out
              above, please contact <a href="mailto:hello@finto.io">hello@finto.io</a>.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('countrys-laws')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="countrys-laws">
              Which country's laws apply to any disputes?
            </Title>
            <p>
              These terms of use, their subject matter and their formation, are governed by the laws
              of the Hashemite Kingdom of Jordan. You and we both agree that the courts of
              Amman-Jordan will have exclusive jurisdiction.
            </p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('trademarks')}>
          <>
            <Title className="content__title content__title--3" tagName="h3" id="trademarks">
              Our trademarks are registered
            </Title>
            <p>Finto is a registered trademark in Jordan. Finto’s logo, slogan, or any other
              source-identifying information (the "Marks") are trademarks of Finto. No third party
              shall use these Marks, or any other trademarks, slogans, or logos which are likely to
              cause confusion with Finto’s Marks, to mislead consumers about the origin of the third
              party’s goods or services, or to signal Finto‘s affiliation with, or sponsorship of,
              such third party’s products, services, websites, or companies. Additionally, Finto
              strictly forbids the use of "frames", framing techniques, meta tags, or any other
              "hidden text" utilizing the Marks. You agree you will not delete or in any manner
              alter the trademark notices or markings appearing on the Marks.</p>
          </>
        </TriggerOnEnter>
        <TriggerOnEnter
          {...offset}
          onEnter={() => wayPointCallback('acceptance-of-terms-and-conditions')}
        >
          <>
            <Title
              className="content__title content__title--3"
              tagName="h3"
              id="acceptance-of-terms-and-conditions"
            >
              Acceptance of these terms and conditions
            </Title>
            <p>
              You acknowledge that you have read these terms and conditions and agree to all its
              provisions. By using our Site or its services you agree to be bound by these terms and
              conditions. If you do not agree to abide by these terms and conditions, you are not
              authorized to use or access our Site and its services.
            </p>
          </>
        </TriggerOnEnter>
      </div>
    </div>
  );
};

Content.propTypes = {
  wayPointCallback: func,
  offset: object,
};

Content.defaultProps = {
  wayPointCallback: () => {},
  offset: {
    topOffset: '0',
    bottomOffset: '0',
  },
};
