import React from 'react';
import { string, func } from 'prop-types';
import cx from 'classnames';
import './LifeCard.scss';

const LifeCard = ({ className, icon, title, description }) => {
  const Icon = icon;
  return (
    <div className={cx('life-card', { [className]: !!className })}>
      <div className="life-card__icon-wrap">
        <Icon className="life-card__icon" />
      </div>
      <h3 className="life-card__title">{title}</h3>
      <p className="life-card__description">{description}</p>
    </div>
  );
};
LifeCard.propTypes = {
  className: string,
  icon: func,
  title: string,
  description: string,
};

export default LifeCard;
