export default [
  {
    role: 'Backend Engineer',
    location: 'Jordan',
    team: 'Digital Services',
    contract_type: 'Full Time',
    date: '30 Dec 2021',
  },
  {
    role: 'Frontend Engineer',
    location: 'Jordan',
    team: 'Digital Products',
    contract_type: 'Full Time',
    date: '30 Dec 2021',
  },
  {
    role: 'Data Analyst',
    location: 'Jordan',
    team: 'Digital Products',
    contract_type: 'Full Time',
    date: '30 Dec 2021',
  },
  {
    role: 'Business Analyst',
    location: 'Jordan',
    team: 'Digital Services',
    contract_type: 'Full Time',
    date: '30 Dec 2021',
  },
  {
    role: 'Digital Product Owner',
    location: 'Jordan',
    team: 'Digital Products',
    contract_type: 'Full Time',
    date: '30 Dec 2021',
  },
];
