import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.3302 53.2872C42.0273 53.4241 41.7077 53.4925 41.3712 53.4925C40.9842 53.5268 40.6141 53.3556 40.3617 53.0647C40.143 52.7225 40.0421 52.3118 40.0757 51.9183C40.0757 51.3536 40.2103 50.9258 40.4627 50.652C40.7319 50.4124 41.0852 50.2927 41.4385 50.3269C41.7077 50.3269 41.96 50.3611 42.2124 50.4124L42.1788 50.9087C41.96 50.8745 41.7245 50.8573 41.5058 50.8573C41.2871 50.8231 41.0684 50.9087 40.9001 51.0627C40.7319 51.3194 40.6646 51.6103 40.6982 51.9183C40.6814 52.1749 40.7319 52.4316 40.8328 52.6712C40.9674 52.8765 41.2029 52.9792 41.4385 52.9621C41.6909 52.945 41.9432 52.9107 42.1788 52.8252L42.3302 53.2872Z"
      fill="#D2D3D6"
    />
    <path
      d="M42.6162 53.4412V50.3611H44.4837V50.8916H43.205V51.6103H44.265V52.1236H43.205V52.9107H44.4837V53.4412H42.6162Z"
      fill="#D2D3D6"
    />
    <path
      d="M46.4017 53.4412C46.183 53.0305 45.9979 52.6541 45.8128 52.2605C45.6782 52.2605 45.51 52.2605 45.3922 52.2605V53.4583H44.8034V50.3953C45.123 50.3611 45.4427 50.344 45.7455 50.3269C46.368 50.3269 46.9401 50.4809 46.9401 51.268C46.9737 51.6616 46.7382 52.038 46.368 52.1578C46.5363 52.5172 46.8055 52.9792 47.0747 53.4241L46.4017 53.4412ZM45.409 51.7985C45.6278 51.8156 45.8297 51.7985 46.0484 51.7471C46.2671 51.6958 46.4017 51.4905 46.368 51.268C46.368 50.8916 46.1157 50.8402 45.8128 50.8402C45.6782 50.8402 45.5268 50.8402 45.409 50.8402V51.7985Z"
      fill="#D2D3D6"
    />
    <path
      d="M47.8654 53.4412V50.8916H47.0578V50.3611H49.2787V50.8916H48.4711V53.4412H47.8654Z"
      fill="#D2D3D6"
    />
    <path d="M49.4974 53.4412V50.3611H50.0862V53.4412H49.4974Z" fill="#D2D3D6" />
    <path
      d="M50.5741 53.4412V50.3611H52.4921V50.8916H51.163V51.6616H52.2566V52.1749H51.163V53.4412H50.5741Z"
      fill="#D2D3D6"
    />
    <path d="M52.7781 53.4412V50.3611H53.367V53.4412H52.7781Z" fill="#D2D3D6" />
    <path
      d="M53.8549 53.4412V50.3611H55.7392V50.8916H54.4437V51.6103H55.5037V52.1236H54.4437V52.9107H55.7392V53.4412H53.8549Z"
      fill="#D2D3D6"
    />
    <path
      d="M56.0421 50.3953C56.3617 50.344 56.6646 50.3098 56.9842 50.3098C58.0105 50.3098 58.3638 50.8402 58.3638 51.884C58.3638 52.9278 58.0105 53.4754 56.9842 53.4754C56.6646 53.4754 56.3617 53.4583 56.0421 53.4241V50.3953ZM56.6309 52.945C56.7655 52.945 56.816 52.945 56.9338 52.945C57.6067 52.945 57.7581 52.6541 57.7581 51.884C57.7581 51.1653 57.5899 50.8231 56.9338 50.8231C56.8328 50.8231 56.7319 50.8231 56.6309 50.8231V52.945Z"
      fill="#D2D3D6"
    />
    <path d="M40.3617 30.9736V27.0551H40.9338V30.9736H40.3617Z" fill="#D2D3D6" />
    <path
      d="M41.5563 30.4261C41.8591 30.4774 42.1619 30.5116 42.4648 30.5116C42.7508 30.5459 43.0536 30.4603 43.2892 30.2892C43.3901 30.1865 43.4574 30.0325 43.4574 29.8785C43.4574 29.502 43.2219 29.3652 42.5657 29.1598C41.9096 28.9545 41.4721 28.6807 41.4721 28.0133C41.4721 27.7053 41.6067 27.4144 41.8423 27.2262C42.1283 27.038 42.4648 26.9353 42.8013 26.9695C43.1546 26.9695 43.5079 27.0209 43.8612 27.0893L43.7939 27.5684C43.4911 27.5171 43.205 27.4829 42.9022 27.4829C42.6667 27.4658 42.4479 27.5171 42.2461 27.6198C42.1283 27.7053 42.061 27.8422 42.061 27.9791C42.061 28.3898 42.3638 28.5096 43.0032 28.6978C43.6425 28.886 44.0463 29.1598 44.0463 29.8443C44.0631 30.1694 43.9117 30.4945 43.6593 30.6999C43.3396 30.9052 42.9695 31.0079 42.5994 30.9908C42.2292 30.9908 41.8591 30.9394 41.5058 30.871L41.5563 30.4261Z"
      fill="#D2D3D6"
    />
    <path
      d="M44.2986 29.0229C44.2986 27.7053 44.8034 27.0037 45.9642 27.0037C47.1251 27.0037 47.6299 27.7053 47.6299 29.0229C47.6299 30.3576 47.142 31.0421 45.9642 31.0421C44.7865 31.0421 44.2986 30.3405 44.2986 29.0229ZM47.041 29.0229C47.041 27.9791 46.7382 27.5171 45.9642 27.5171C45.1903 27.5171 44.8875 27.9791 44.8875 29.0229C44.8875 30.0667 45.1903 30.5287 45.9642 30.5287C46.7382 30.5287 47.041 30.0496 47.041 29.0229Z"
      fill="#D2D3D6"
    />
    <path d="M47.7645 30.9736L49.2618 27.0551H49.7329L48.2524 30.9736H47.7645Z" fill="#D2D3D6" />
    <path d="M50.0694 30.9736V27.0551H50.6414V30.9736H50.0694Z" fill="#D2D3D6" />
    <path
      d="M51.2976 30.9736V27.0551H53.6025V27.5855H51.8864V28.7149H53.2997V29.2283H51.8864V30.4603H53.6025V30.9908L51.2976 30.9736Z"
      fill="#D2D3D6"
    />
    <path
      d="M56.6477 30.7512C56.2608 30.9394 55.8402 31.0421 55.4196 31.0421C54.9316 31.0763 54.4774 30.871 54.1745 30.4774C53.9054 30.0325 53.7876 29.5363 53.8212 29.0229C53.7876 28.4753 53.939 27.9449 54.2418 27.5C54.5783 27.1406 55.0494 26.9524 55.5373 27.0037C55.8738 27.0037 56.2103 27.038 56.53 27.1235L56.4795 27.6198C56.1767 27.5684 55.8738 27.5342 55.571 27.5342C55.2345 27.5 54.898 27.6369 54.6625 27.8936C54.4606 28.2358 54.3764 28.6465 54.4101 29.04C54.3764 29.4165 54.4606 29.7929 54.6288 30.1352C54.8139 30.409 55.1335 30.563 55.47 30.5459C55.8233 30.5287 56.1767 30.4603 56.5131 30.3063L56.6477 30.7512Z"
      fill="#D2D3D6"
    />
    <path
      d="M40.2944 37.3392V36.8772C41.3544 35.936 42.1115 35.2002 42.1115 34.55C42.1115 34.0708 41.7582 33.8826 41.3544 33.8826C41.0179 33.8997 40.6814 33.9682 40.3785 34.088L40.2103 33.6259C40.5804 33.4548 40.9842 33.3693 41.388 33.3693C42.1619 33.3693 42.6835 33.7628 42.6835 34.5329C42.6835 35.3884 41.8423 36.1242 41.0179 36.8258H42.734V37.3563H40.2608L40.2944 37.3392Z"
      fill="#D2D3D6"
    />
    <path
      d="M43.6257 37.3392C43.9285 36.1414 44.3828 34.9949 44.9884 33.934H43.0536V33.4206H45.5773V33.8997C44.9716 34.9778 44.5173 36.1414 44.2482 37.3392H43.6257Z"
      fill="#D2D3D6"
    />
    <path
      d="M45.8801 35.3713C45.8801 34.0537 46.1325 33.3522 47.2766 33.3522C48.4206 33.3522 48.6562 34.0537 48.6562 35.3713C48.6562 36.6889 48.4038 37.3905 47.2766 37.3905C46.1493 37.3905 45.8801 36.6889 45.8801 35.3713ZM48.1009 35.3713C48.1009 34.4473 48.0505 33.8655 47.2934 33.8655C46.5363 33.8655 46.469 34.4473 46.469 35.3713C46.469 36.2954 46.5195 36.8943 47.2934 36.8943C48.0673 36.8943 48.1009 36.3125 48.1009 35.3713Z"
      fill="#D2D3D6"
    />
    <path
      d="M49.0768 35.3713C49.0768 34.0537 49.3291 33.3522 50.4732 33.3522C51.6172 33.3522 51.8528 34.0537 51.8528 35.3713C51.8528 36.6889 51.6004 37.3905 50.4732 37.3905C49.3459 37.3905 49.0768 36.6889 49.0768 35.3713ZM51.2976 35.3713C51.2976 34.4473 51.2471 33.8655 50.49 33.8655C49.7329 33.8655 49.6656 34.4473 49.6656 35.3713C49.6656 36.2954 49.7161 36.8943 50.49 36.8943C51.2639 36.8943 51.2976 36.3125 51.2976 35.3713Z"
      fill="#D2D3D6"
    />
    <path
      d="M52.8623 37.3392V34.0024L52.0883 34.3104L51.9201 33.9168L52.9632 33.4035H53.4847V37.3221L52.8623 37.3392Z"
      fill="#D2D3D6"
    />
    <path d="M40.2608 42.37V39.2899H40.715V42.37H40.2608Z" fill="#D2D3D6" />
    <path
      d="M42.4984 42.37V40.9155C42.4984 40.6417 42.3638 40.5048 42.1283 40.5048C41.9432 40.5048 41.775 40.5561 41.6067 40.6417V42.37H41.1693V40.1626H41.489L41.5226 40.3679C41.7077 40.2139 41.9432 40.1284 42.1788 40.1112C42.5489 40.077 42.8854 40.3508 42.919 40.7273C42.919 40.7786 42.919 40.8299 42.919 40.8813V42.37H42.4984Z"
      fill="#D2D3D6"
    />
    <path
      d="M43.4406 42.37V40.5219H43.1209V40.2139L43.4406 40.1968V39.9401C43.4238 39.7519 43.4911 39.5637 43.6088 39.4097C43.7603 39.2899 43.9621 39.2214 44.164 39.2386C44.265 39.2386 44.3828 39.2386 44.4837 39.2557L44.4669 39.615C44.3491 39.615 44.265 39.615 44.1809 39.615C44.0967 39.615 43.9958 39.615 43.9453 39.6835C43.8948 39.769 43.8612 39.8717 43.878 39.9743V40.1626H44.3491V40.5048H43.878V42.3529L43.4406 42.37Z"
      fill="#D2D3D6"
    />
    <path
      d="M45.3922 42.4213C45.1399 42.4384 44.8875 42.3529 44.7024 42.1818C44.3155 41.6342 44.3155 40.9155 44.7024 40.3679C44.8875 40.1968 45.1399 40.1112 45.3922 40.1284C45.6446 40.1112 45.8969 40.1968 46.082 40.3679C46.469 40.9155 46.469 41.6342 46.082 42.1818C45.8969 42.3358 45.6446 42.4384 45.3922 42.4213ZM45.9306 41.2577C45.9474 41.0353 45.8969 40.8299 45.7792 40.6417C45.5605 40.4364 45.224 40.4364 45.0053 40.6417C44.8875 40.8299 44.837 41.0353 44.8538 41.2577C44.837 41.4802 44.8875 41.6855 45.0053 41.8737C45.1062 41.9764 45.2408 42.0449 45.3922 42.0277C45.5436 42.0277 45.6782 41.9764 45.7792 41.8737C45.8969 41.7026 45.9474 41.4802 45.9306 41.2577Z"
      fill="#D2D3D6"
    />
    <path
      d="M46.654 42.37V40.1626H46.9737L47.0242 40.4193H47.041C47.1756 40.1968 47.428 40.077 47.6803 40.1112C47.7476 40.1112 47.7981 40.1112 47.8654 40.1112L47.8318 40.5048C47.7476 40.4877 47.6635 40.4877 47.5794 40.4877C47.3943 40.4706 47.2093 40.539 47.0578 40.6588V42.3529L46.654 42.37Z"
      fill="#D2D3D6"
    />
    <path
      d="M50.591 42.37V40.9155C50.591 40.6588 50.4564 40.5219 50.2208 40.5219C50.0357 40.5219 49.8675 40.5733 49.7161 40.6759C49.7329 40.7615 49.7329 40.847 49.7329 40.9326V42.37H49.3123V40.9155C49.3123 40.6588 49.1777 40.5219 48.9422 40.5219C48.7739 40.5219 48.6057 40.5733 48.4543 40.6588V42.3871H48.0336V40.1797H48.3533L48.387 40.385C48.572 40.231 48.8076 40.1455 49.0431 40.1284C49.2618 40.1112 49.4805 40.2139 49.5983 40.4021C49.7834 40.231 50.0189 40.1284 50.2545 40.1284C50.7424 40.1284 50.9947 40.385 50.9947 40.9326V42.37H50.591Z"
      fill="#D2D3D6"
    />
    <path
      d="M52.6772 42.37L52.6267 42.1818C52.4585 42.3358 52.2229 42.4213 51.9874 42.4213C51.6172 42.4555 51.2976 42.1646 51.2808 41.8053C51.2808 41.7882 51.2808 41.754 51.2808 41.7368C51.2808 41.5315 51.3649 41.3433 51.5163 41.2235C51.6845 41.1037 51.8864 41.0524 52.1052 41.0524C52.2566 41.0524 52.4248 41.0524 52.5762 41.0695V40.8813C52.5762 40.7786 52.5426 40.693 52.4921 40.6246C52.3743 40.539 52.2397 40.5048 52.1052 40.5219C51.9033 40.5219 51.7014 40.5561 51.4995 40.5904L51.4322 40.231C51.6677 40.1626 51.9033 40.1284 52.1388 40.1284C52.3743 40.1112 52.5931 40.1626 52.795 40.2995C52.9464 40.4364 53.0305 40.6588 53.0137 40.8642V42.3871L52.6772 42.37ZM52.5594 41.3775C52.4585 41.3775 52.307 41.3604 52.1724 41.3604C51.8528 41.3604 51.7014 41.4802 51.7014 41.7197C51.7014 41.908 51.836 42.062 52.021 42.062C52.0379 42.062 52.0547 42.062 52.0883 42.062C52.2566 42.062 52.4248 42.0106 52.5594 41.908V41.3775Z"
      fill="#D2D3D6"
    />
    <path
      d="M54.5279 42.2673C54.3596 42.3529 54.1914 42.4042 54.0063 42.4042C53.653 42.4042 53.4511 42.1989 53.4511 41.8053V40.5219H53.1146V40.1968L53.4511 40.1797L53.4847 39.5295H53.8717V40.1626H54.4774V40.5219H53.8717V41.7368C53.8717 41.8909 53.9054 41.9935 54.0568 41.9935C54.1746 41.9935 54.2755 41.9593 54.3764 41.908L54.5279 42.2673Z"
      fill="#D2D3D6"
    />
    <path
      d="M54.9653 39.2728C55.0999 39.2728 55.2008 39.3754 55.2008 39.5123C55.2008 39.6492 55.0999 39.7519 54.9653 39.7519C54.8307 39.7519 54.7298 39.6492 54.7298 39.5123C54.7298 39.3926 54.8307 39.2899 54.9653 39.2728ZM54.7466 42.37V40.1626H55.1672V42.37H54.7466Z"
      fill="#D2D3D6"
    />
    <path
      d="M56.429 42.4213C56.1767 42.4384 55.9243 42.3529 55.7392 42.1818C55.3523 41.6342 55.3523 40.9155 55.7392 40.3679C55.9243 40.1968 56.1767 40.1112 56.429 40.1284C56.6814 40.1112 56.9338 40.1968 57.1188 40.3679C57.5058 40.9155 57.5058 41.6342 57.1188 42.1818C56.9338 42.3358 56.6814 42.4384 56.429 42.4213ZM56.9842 41.2577C57.001 41.0353 56.9506 40.8299 56.8328 40.6417C56.6141 40.4364 56.2776 40.4364 56.0589 40.6417C55.9411 40.8299 55.8906 41.0353 55.9075 41.2577C55.8906 41.4802 55.9411 41.6855 56.0589 41.8737C56.1598 41.9764 56.2944 42.0449 56.4458 42.0277C56.5973 42.0277 56.7319 41.9764 56.8328 41.8737C56.9338 41.7026 57.001 41.4802 56.9842 41.2577Z"
      fill="#D2D3D6"
    />
    <path
      d="M59.0032 42.37V40.9155C59.0032 40.6417 58.8686 40.5048 58.633 40.5048C58.4479 40.5048 58.2797 40.5561 58.1115 40.6417V42.37H57.6908V40.1626H58.0105L58.0442 40.3679C58.2292 40.2139 58.4648 40.1284 58.7003 40.1112C59.0704 40.077 59.4069 40.3508 59.4406 40.7273C59.4406 40.7786 59.4406 40.8299 59.4406 40.8813V42.37H59.0032Z"
      fill="#D2D3D6"
    />
    <path
      d="M60.7361 41.9422C60.9716 41.9764 61.2071 42.0106 61.4427 42.0106C61.6782 42.0277 61.8969 41.9764 62.082 41.8395C62.1661 41.754 62.2166 41.6513 62.2166 41.5315C62.2166 41.2406 62.0315 41.1379 61.51 40.9668C60.9884 40.8128 60.6519 40.5904 60.6519 40.077C60.6519 39.8375 60.7529 39.615 60.938 39.461C61.1567 39.307 61.4259 39.2386 61.6782 39.2557C61.9642 39.2557 62.2334 39.2899 62.5195 39.3583L62.469 39.7348C62.2334 39.7006 61.9979 39.6663 61.7792 39.6663C61.5941 39.6492 61.4259 39.6835 61.2576 39.769C61.1735 39.8375 61.1062 39.9401 61.123 40.0599C61.123 40.3679 61.3586 40.4706 61.8633 40.6246C62.368 40.7786 62.6877 40.9839 62.6877 41.5315C62.7045 41.7882 62.5868 42.0449 62.3849 42.216C62.1325 42.3871 61.8465 42.4555 61.5605 42.4384C61.2744 42.4384 60.9884 42.4042 60.7024 42.3529L60.7361 41.9422Z"
      fill="#D2D3D6"
    />
    <path
      d="M64.572 42.2331C64.3365 42.3529 64.0841 42.4042 63.8149 42.4042C63.1756 42.4042 62.8391 41.9935 62.8391 41.2748C62.8391 40.6759 63.0915 40.1112 63.7645 40.1112C64.286 40.1112 64.5889 40.4877 64.5889 41.0866C64.5889 41.2064 64.572 41.3091 64.5552 41.4288H63.2597C63.2597 41.6 63.3102 41.754 63.4111 41.8737C63.5289 41.9764 63.6803 42.0449 63.8486 42.0277C64.0673 42.0277 64.2692 41.9764 64.4711 41.8909L64.572 42.2331ZM64.2019 41.0695C64.2019 40.7444 64.1009 40.4706 63.7645 40.4706C63.428 40.4706 63.2934 40.7615 63.2934 41.0695H64.2019Z"
      fill="#D2D3D6"
    />
    <path
      d="M66.4227 42.2331C66.1872 42.3529 65.9348 42.4042 65.6824 42.4042C65.1104 42.4042 64.7571 42.0791 64.7571 41.2577C64.7571 40.3166 65.2282 40.0941 65.7497 40.0941C65.9516 40.0941 66.1535 40.1112 66.3554 40.1626L66.3218 40.5219C66.1535 40.4877 66.0021 40.4877 65.8339 40.4877C65.4132 40.4877 65.1945 40.6588 65.1945 41.2577C65.1945 41.7882 65.3796 42.0277 65.7329 42.0277C65.9348 42.0277 66.1367 41.9764 66.3218 41.8909L66.4227 42.2331Z"
      fill="#D2D3D6"
    />
    <path
      d="M68.0547 42.37L68.021 42.1818C67.836 42.3358 67.6004 42.4213 67.3649 42.4213C66.9947 42.4555 66.6582 42.1817 66.6246 41.8053C66.6246 41.754 66.6246 41.7026 66.6246 41.6513V40.1626H67.0452V41.6C67.0452 41.8909 67.1798 42.0106 67.4322 42.0106C67.6004 42.0106 67.7855 41.9593 67.9369 41.8909V40.1626H68.3575V42.37H68.0547Z"
      fill="#D2D3D6"
    />
    <path
      d="M68.7949 42.37V40.1626H69.1146L69.1651 40.4193H69.1819C69.3165 40.1968 69.5689 40.077 69.8212 40.1112C69.8885 40.1112 69.939 40.1112 70.0063 40.1112L69.9727 40.5048C69.8885 40.4877 69.8044 40.4877 69.7203 40.4877C69.5352 40.4706 69.3502 40.539 69.1987 40.6588V42.3529L68.7949 42.37Z"
      fill="#D2D3D6"
    />
    <path
      d="M70.3933 39.2728C70.5279 39.2728 70.6288 39.3754 70.6288 39.5123C70.6288 39.6492 70.5279 39.7519 70.3933 39.7519C70.2587 39.7519 70.1577 39.6492 70.1577 39.5123C70.1577 39.3926 70.2587 39.2899 70.3933 39.2728ZM70.1914 42.37V40.1626H70.612V42.37H70.1914Z"
      fill="#D2D3D6"
    />
    <path
      d="M72.1767 42.2673C72.0084 42.3529 71.8402 42.4042 71.6551 42.4042C71.3018 42.4042 71.0999 42.1989 71.0999 41.8053V40.5219H70.7802V40.1968L71.1167 40.1797L71.1504 39.5295H71.5373V40.1626H72.143V40.5219H71.5373V41.7368C71.5373 41.8909 71.571 41.9935 71.7224 41.9935C71.8402 41.9935 71.9411 41.9593 72.0421 41.908L72.1767 42.2673Z"
      fill="#D2D3D6"
    />
    <path
      d="M72.7655 43.2427C72.9001 42.9004 73.0011 42.6095 73.0684 42.37H72.8328C72.6141 41.754 72.3954 40.9668 72.1935 40.1626H72.6477C72.7992 40.8128 72.9506 41.4288 73.1356 42.0106H73.1693C73.3375 41.4802 73.5226 40.7786 73.674 40.1626H74.1115C73.8591 41.2064 73.5563 42.2331 73.1861 43.2427H72.7655Z"
      fill="#D2D3D6"
    />
    <path
      d="M42.7508 47.4008C42.734 46.5794 42.6835 45.741 42.6498 44.9196H42.633L41.9432 46.8703H41.4553L40.7655 44.9196H40.7487C40.715 45.7239 40.6814 46.5794 40.6477 47.4008H40.1935C40.2271 46.3741 40.2776 45.3645 40.3281 44.3207H40.9842L41.674 46.357H41.6909L42.3806 44.3207H43.0368C43.0873 45.3474 43.1377 46.3741 43.1714 47.4008H42.7508Z"
      fill="#D2D3D6"
    />
    <path
      d="M44.8707 47.4008L44.8202 47.2126C44.6519 47.3666 44.4164 47.4521 44.1809 47.4521C43.8107 47.4863 43.4911 47.1955 43.4742 46.8361C43.4742 46.819 43.4742 46.7848 43.4742 46.7677C43.4742 46.5623 43.5584 46.3741 43.7098 46.2543C43.878 46.1345 44.0799 46.0832 44.2986 46.0832C44.4501 46.0832 44.6183 46.0832 44.7697 46.1003V45.9121C44.7697 45.8094 44.7361 45.7239 44.6856 45.6554C44.5678 45.5698 44.4332 45.5356 44.2986 45.5527C44.0967 45.5527 43.8948 45.587 43.693 45.6212L43.6257 45.2618C43.8612 45.1934 44.0967 45.1592 44.3323 45.1592C44.5678 45.1421 44.7865 45.1934 44.9884 45.3303C45.1399 45.4672 45.224 45.6896 45.2071 45.895V47.4179L44.8707 47.4008ZM44.7697 46.4083C44.6688 46.4083 44.5173 46.4083 44.3828 46.4083C44.0631 46.4083 43.9117 46.5281 43.9117 46.7677C43.9117 46.9559 44.0463 47.1099 44.2313 47.1099C44.2482 47.1099 44.265 47.1099 44.2818 47.1099C44.4501 47.1099 44.6183 47.0586 44.7529 46.9559V46.4083H44.7697Z"
      fill="#D2D3D6"
    />
    <path
      d="M46.9232 47.4008V45.9463C46.9232 45.6725 46.7886 45.5356 46.5531 45.5356C46.368 45.5356 46.1998 45.587 46.0315 45.6725V47.4008H45.6109V45.1934H45.9306L45.9642 45.3987C46.1493 45.2447 46.3849 45.1592 46.6204 45.1421C46.9905 45.1078 47.327 45.3816 47.3607 45.7581C47.3607 45.8094 47.3607 45.8607 47.3607 45.9121V47.4008H46.9232Z"
      fill="#D2D3D6"
    />
    <path
      d="M49.0095 47.4008L48.959 47.2126C48.7907 47.3666 48.5552 47.4521 48.3197 47.4521C47.9495 47.4863 47.6299 47.1955 47.613 46.8361C47.613 46.819 47.613 46.7848 47.613 46.7677C47.613 46.5623 47.6972 46.3741 47.8486 46.2543C48.0168 46.1345 48.2187 46.0832 48.4374 46.0832C48.5889 46.0832 48.7571 46.0832 48.9085 46.1003V45.9121C48.9085 45.8094 48.8749 45.7239 48.8244 45.6554C48.7066 45.5698 48.572 45.5356 48.4374 45.5527C48.2355 45.5527 48.0336 45.587 47.8318 45.6212L47.7645 45.2618C48 45.1934 48.2355 45.1592 48.4711 45.1592C48.7066 45.1421 48.9253 45.1934 49.1272 45.3303C49.2787 45.4672 49.3628 45.6896 49.346 45.895V47.4179L49.0095 47.4008ZM48.8917 46.4083C48.7907 46.4083 48.6393 46.4083 48.5047 46.4083C48.1851 46.4083 48.0336 46.5281 48.0336 46.7677C48.0336 46.9559 48.1682 47.1099 48.3533 47.1099C48.3701 47.1099 48.387 47.1099 48.4038 47.1099C48.572 47.1099 48.7403 47.0586 48.8749 46.9559V46.4083H48.8917Z"
      fill="#D2D3D6"
    />
    <path
      d="M50.9779 47.281C50.8265 47.3837 50.6414 47.4521 50.4564 47.4521C49.8339 47.4521 49.6151 47.0414 49.6151 46.3228C49.6151 45.5014 49.8675 45.1592 50.4732 45.1592C50.7928 45.1592 51.0957 45.2105 51.3985 45.3132V47.6061C51.4153 47.8115 51.3312 47.9997 51.1966 48.1366C51.0116 48.2735 50.7592 48.3419 50.5237 48.3248C50.2881 48.3248 50.0526 48.2906 49.8339 48.2564L49.8843 47.8799C50.0694 47.9141 50.2713 47.9313 50.4732 47.9313C50.6246 47.9484 50.7592 47.9141 50.877 47.8286C50.9443 47.7601 50.9611 47.6575 50.9611 47.5719V47.2981L50.9779 47.281ZM50.9779 45.5698C50.8265 45.5356 50.6751 45.5356 50.5237 45.5356C50.1535 45.5356 50.0526 45.7923 50.0526 46.3228C50.0526 46.8532 50.1535 47.0928 50.5068 47.0928C50.6751 47.0928 50.8265 47.0586 50.9779 46.9901V45.5698Z"
      fill="#D2D3D6"
    />
    <path
      d="M53.3838 47.2639C53.1483 47.3837 52.8959 47.435 52.6267 47.435C51.9874 47.435 51.6509 47.0243 51.6509 46.3057C51.6509 45.7067 51.9033 45.1421 52.5762 45.1421C53.0978 45.1421 53.4006 45.5185 53.4006 46.1174C53.4006 46.2372 53.3838 46.3399 53.367 46.4597H52.0883C52.0883 46.6308 52.1388 46.7848 52.2397 46.9046C52.3575 47.0072 52.5089 47.0757 52.6772 47.0586C52.8959 47.0586 53.0978 47.0072 53.2997 46.9217L53.3838 47.2639ZM52.9968 46.1174C52.9968 45.7923 52.8959 45.5185 52.5594 45.5185C52.2229 45.5185 52.0883 45.8094 52.0883 46.1174H52.9968Z"
      fill="#D2D3D6"
    />
    <path
      d="M56.244 47.4008V45.9463C56.244 45.6896 56.1094 45.5527 55.8738 45.5527C55.6887 45.5527 55.5205 45.6041 55.3691 45.7067C55.3859 45.7923 55.3859 45.8779 55.3859 45.9634V47.4008H54.9653V45.9292C54.9653 45.6725 54.8307 45.5356 54.5952 45.5356C54.4269 45.5356 54.2587 45.587 54.1073 45.6725V47.4008H53.6866V45.2105H54.0063L54.04 45.4158C54.225 45.2618 54.4606 45.1763 54.6961 45.1592C54.9148 45.1421 55.1335 45.2447 55.2513 45.433C55.4364 45.2618 55.6719 45.1592 55.9243 45.1592C56.4122 45.1592 56.6646 45.4158 56.6646 45.9634V47.4008H56.244Z"
      fill="#D2D3D6"
    />
    <path
      d="M58.633 47.2639C58.3975 47.3837 58.1451 47.435 57.8759 47.435C57.2366 47.435 56.9001 47.0243 56.9001 46.3057C56.9001 45.7067 57.1525 45.1421 57.8254 45.1421C58.347 45.1421 58.6498 45.5185 58.6498 46.1174C58.6498 46.2372 58.633 46.3399 58.6162 46.4597H57.3207C57.3207 46.6308 57.3712 46.7848 57.4721 46.9046C57.5899 47.0072 57.7413 47.0757 57.9096 47.0586C58.1283 47.0586 58.3302 47.0072 58.5321 46.9217L58.633 47.2639ZM58.2629 46.1174C58.2629 45.7923 58.1619 45.5185 57.8254 45.5185C57.489 45.5185 57.3544 45.8094 57.3375 46.1174H58.2629Z"
      fill="#D2D3D6"
    />
    <path
      d="M60.265 47.4008V45.9463C60.265 45.6725 60.1304 45.5356 59.8948 45.5356C59.7098 45.5356 59.5415 45.587 59.3733 45.6725V47.4008H58.9527V45.1934H59.2723L59.306 45.3987C59.4911 45.2447 59.7266 45.1592 59.9621 45.1421C60.3323 45.1078 60.6688 45.3816 60.7024 45.7581C60.7024 45.8094 60.7024 45.8607 60.7024 45.9121V47.4008H60.265Z"
      fill="#D2D3D6"
    />
    <path
      d="M62.2166 47.2981C62.0484 47.3837 61.8801 47.435 61.6951 47.435C61.3417 47.435 61.1398 47.2297 61.1398 46.8361V45.5527H60.8202V45.2276L61.1567 45.2105L61.1903 44.5603H61.5773V45.1934H62.183V45.5527H61.5773V46.7677C61.5773 46.9217 61.6109 47.0243 61.7624 47.0243C61.8801 47.0243 61.9811 46.9901 62.082 46.9388L62.2166 47.2981Z"
      fill="#D2D3D6"
    />
    <path
      d="M61.5436 60.6452H18.4564C18.204 60.6452 18.0021 60.4398 18.0021 60.1832C18.0021 59.9265 18.204 59.7212 18.4564 59.7212H61.5436C71.2177 59.7212 79.0747 51.73 79.0747 41.8909C79.0747 32.0517 71.2345 24.0606 61.5436 24.0606H27.693C27.4406 24.0606 27.2387 23.8552 27.2387 23.5985C27.2387 23.3419 27.4406 23.1365 27.693 23.1365H61.5436C71.7392 23.1365 80 31.5383 80 41.908C80 52.2776 71.7224 60.6452 61.5436 60.6452Z"
      fill="#D2D3D6"
    />
    <path
      d="M19.5331 37.4247C19.6509 35.2173 21.4848 33.5404 23.6551 33.6431C25.6572 33.7457 27.2723 35.3713 27.3733 37.4247H19.5331ZM22.8307 46.8874C22.2755 45.2276 20.9632 44.3549 19.8528 43.6191C18.6246 42.7978 18.103 42.3871 18.0862 41.6C18.0862 40.539 18.9106 39.6663 19.9706 39.6492H26.8013L22.8307 46.8874ZM17.5478 53.1845C17.8507 53.2359 18.1535 53.2701 18.4732 53.2701C18.776 53.2701 19.0957 53.2359 19.3985 53.1845L18.4732 54.8615L17.5478 53.1845ZM16.4206 39.6321C16.1178 40.2139 15.9495 40.8642 15.9495 41.5315C15.9495 41.5486 15.9495 41.5828 15.9495 41.6C15.9832 43.6705 17.4974 44.68 18.7087 45.4843C20.1556 46.4425 20.98 47.0757 20.98 48.6328C20.98 50.19 19.7687 51.0798 18.2881 51.0798C17.3291 51.0798 16.1346 50.5493 15.6467 49.6937L15.2261 48.9408L10.0946 39.615L16.4206 39.6321ZM13.5605 33.6259C15.6467 33.6431 17.3459 35.3029 17.4637 37.4247H9.6572C9.77497 35.32 11.4742 33.6602 13.5605 33.6259ZM23.4532 31.5041C21.4511 31.5041 19.5836 32.5308 18.5068 34.2591C17.4301 32.5479 15.5626 31.5041 13.5605 31.5041C10.2124 31.5212 7.50368 34.2762 7.5205 37.6814V38.3145C7.50368 38.999 7.68875 39.6835 8.04206 40.2481L18.4564 58.5233C18.4564 58.5233 28.3491 41.1551 28.837 40.2481C29.3249 39.3412 29.409 38.9305 29.409 38.3145V37.6814C29.4259 34.3275 26.7508 31.5554 23.4532 31.5041Z"
      fill="#D2D3D6"
    />
    <path
      d="M14.7045 22.6403C14.082 22.6403 13.4595 22.8285 12.938 23.1707V19.3549H11.4574V29.7074H12.3323L12.5678 29.1427C13.1735 29.6218 13.9306 29.8785 14.7045 29.8614C16.6393 29.8614 17.346 28.5096 17.346 26.2508C17.3628 23.9921 16.6393 22.6403 14.7045 22.6403ZM14.368 23.975C15.6635 23.975 15.8654 25.0017 15.8654 26.2851C15.8654 27.5513 15.6635 28.5951 14.368 28.5951C13.8633 28.5951 13.3754 28.4582 12.938 28.1845V24.3686C13.3754 24.0948 13.8633 23.9579 14.368 23.975ZM19.2303 24.7964C19.2303 24.1803 19.7182 23.9408 20.5089 23.9408C21.1987 23.9408 21.8885 24.0606 22.5279 24.3343V22.9996C21.8717 22.743 21.1819 22.6232 20.4753 22.6574C18.5405 22.6574 17.8002 23.667 17.8002 24.8477C17.8002 26.0284 18.2881 26.6786 20.2902 26.8669C21.1819 26.9524 21.3838 27.2091 21.3838 27.7224C21.3838 28.3213 20.8622 28.578 19.8191 28.578C19.1462 28.5609 18.4732 28.4411 17.8507 28.1845V29.502C18.5573 29.7587 19.2976 29.8785 20.0379 29.8785C22.1577 29.8785 22.8475 28.8518 22.8475 27.654C22.8475 26.3706 22.326 25.7546 20.3575 25.5664C19.4658 25.4808 19.2303 25.2755 19.2303 24.7964ZM25.1693 29.8101C25.2702 29.8101 25.3712 29.7929 25.4553 29.7758V28.5267C25.0347 28.5267 24.9001 28.3898 24.9001 27.9962V22.8114H23.4196V28.0133C23.4196 29.194 23.9748 29.8101 25.1693 29.8101ZM23.2008 20.758C23.1672 21.3056 23.5878 21.7676 24.1094 21.7847C24.6477 21.8189 25.102 21.3911 25.1188 20.8607C25.1188 20.8265 25.1188 20.7922 25.1188 20.758C25.1525 20.2104 24.7319 19.7484 24.2103 19.7313C23.6719 19.6971 23.2177 20.1249 23.2008 20.6553C23.2008 20.6896 23.2008 20.7238 23.2008 20.758Z"
      fill="#D2D3D6"
    />
    <path
      d="M18.4564 60.6452C8.26078 60.6452 0 52.2434 0 41.8737C0 35.2687 3.41535 29.1427 8.98423 25.7546C9.18612 25.6006 9.47213 25.6177 9.64038 25.8231C9.7918 26.0284 9.77497 26.3193 9.57308 26.4904C9.53943 26.5246 9.50578 26.5417 9.47213 26.5588C1.16088 31.6068 -1.56467 42.5582 3.39853 51.0113C8.36172 59.4645 19.1293 62.2366 27.4406 57.1886C32.7403 53.9716 35.9874 48.1537 35.9874 41.8909C36.021 35.6109 32.7571 29.7758 27.4406 26.576C27.205 26.4733 27.1041 26.1995 27.205 25.9599C27.306 25.7204 27.5752 25.6177 27.8107 25.7204C27.8444 25.7375 27.878 25.7546 27.9117 25.7888C36.6604 31.1105 39.5037 42.6438 34.2545 51.5418C30.9401 57.1886 24.9338 60.6452 18.4564 60.6452Z"
      fill="#D2D3D6"
    />
  </svg>
);

export default SVG;
