import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Title,
  Description,
  Button,
  Container,
  Image,
  TriggerOnEnter,
  BasicScroll,
  GetStarted,
  Tag,
} from 'elements';
import { Standarts } from 'components';
import bannerTOP from 'images/browsers/banner-top.webp';
import bannerBottom from 'images/browsers/banner-bottom.webp';
import phone from 'images/phones/mob-upload.webp';
import ArrowSvg from 'images/icons/arrowTop.svg';
import VideoIcon from 'images/icons/video.svg';
import phoneDoc from 'images/phones/mob-document.webp';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './CronicaHero.scss';
import './Popup.scss';

const CronicaHero = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);
  const [isImagesAnimationComplete, setImagesAnimationComplete] = useState(false);
  const [isIframe, tooglePopup] = useState(false);
  const parallaxAnchorRef = useRef();
  const phoneImageRef = useRef();
  const cardImageRef = useRef();
  const iFrameRef = useRef();

  const closeIframe = (event) => {
    if (iFrameRef.current && iFrameRef.current.contains(event.target)) {
      tooglePopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeIframe);
    return () => {
      document.removeEventListener('click', closeIframe);
    };
  }, []);

  return (
    <section className="cronica-hero">
      {isIframe && (
        <div ref={iFrameRef} className="popup popup-video">
          <div className="popup__wrap">
            <div className="popup__close" />
            <div className="popup__box">
              <div className="popup__response">
                <iframe
                  id="video-iframe"
                  title="video cronica"
                  src="https://player.vimeo.com/video/355279736"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        ref={parallaxAnchorRef}
        className="cronica-hero__parallax-anchor"
        style={{
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '-1',
          pointerEvents: 'none',
        }}
      />
      <TriggerOnEnter bypass={!areAnimationsUnblocked}>
        {({ enteredClassName }) => (
          <Container className={cx('cronica-hero__container', enteredClassName)}>
            <div className="cronica-hero__details">
              <Tag align="center" color="red" lineClassName="paas-support__tag-line">
                Cronica
              </Tag>
              <Title
                tagName="h1"
                sizeText="size-2"
                color="white"
                alignText="text-center"
                className="cronica-hero__title"
              >
                <div className="cronica-hero__title-part">Digital Authentication Made Simple</div>
              </Title>
              <Description
                sizeText="size-1"
                color="white"
                alignText="text-center"
                className="cronica-hero__description"
              >
                Finto Cronica is a blockchain-based platform that enables businesses to securely
                generate, authenticate, and retrieve programmable documents.
              </Description>
              <div className="cronica-hero__buttons">
                <GetStarted
                  className="cronica-hero__button button--with-icon button--with-icon-get"
                  color="text-white"
                  background="red"
                  size="small-text"
                  isCentered
                  to="/contact"
                  tagName={Link}
                >
                  <div className="button__inner">
                    <span>Get Cronica</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </GetStarted>
                <Button
                  background="black"
                  border="border-bold-white"
                  color="text-white"
                  size="small-text"
                  className="button--with-icon button--with-icon-video"
                  buttontype="hoverable"
                  isCentered
                  onClick={() => tooglePopup(true)}
                >
                  <div className="button__inner">
                    <span>Watch the video</span>
                    <div className="button__icons">
                      <VideoIcon className="button__icon" />
                      <VideoIcon className="button__icon button__icon--next" />
                    </div>
                  </div>
                </Button>
              </div>
            </div>
            <div
              className="cronica-hero__media"
              onAnimationEnd={() => setImagesAnimationComplete(true)}
            >
              <div className="cronica-hero__box">
                <div
                  ref={phoneImageRef}
                  className="cronica-hero__img_left parallax parallax--vertical"
                >
                  <Image src={phone} srcMobile={phone} alt="phone" bypass />
                </div>
                <div className="cronica-hero__img_center">
                  <Image src={bannerTOP} alt="browser" bypass />
                  <Image src={bannerBottom} alt="browser" bypass />
                </div>
                <div
                  className="cronica-hero__img_right parallax parallax--vertical"
                  ref={cardImageRef}
                >
                  <Image src={phoneDoc} srcMobile={phoneDoc} alt="phone" bypass />
                </div>
                {isImagesAnimationComplete && (
                  <>
                    <BasicScroll
                      reference={parallaxAnchorRef}
                      direct={phoneImageRef.current}
                      from="bottom-bottom"
                      props={{
                        '--parallax-ty': {
                          from: '0%',
                          to: '-50%',
                        },
                        '--parallax-ty-tablet-max': {
                          from: '0%',
                          to: '-50%',
                        },
                      }}
                    />
                    <BasicScroll
                      reference={parallaxAnchorRef}
                      direct={cardImageRef.current}
                      from="bottom-bottom"
                      props={{
                        '--parallax-ty': {
                          from: '0%',
                          to: '-50%',
                        },
                        '--parallax-ty-tablet-max': {
                          from: '0%',
                          to: '-50%',
                        },
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </Container>
        )}
      </TriggerOnEnter>
      <div className="cronica-hero__partners">
        <Standarts />
      </div>
    </section>
  );
};

export default CronicaHero;
