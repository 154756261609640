import React, { useState } from 'react';
import { elementType, string, node, oneOfType, func, array, bool } from 'prop-types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ChevronArrowSvg from 'images/icons/chevron-white.svg';
import useHover from '../../hooks/useHover';
import { ANIMATION_DEFAULT_DURATION } from '../../constants/animations';

function MenuItem({ name, Icon, url, content, innerURLs, isHeaderWhite }) {
  const location = useLocation();
  const [isContentActive, setContentActive] = useState(false);
  const currentPathName = location.pathname;
  const openMenu = () => {
    setContentActive(true);
  };
  const closeMenu = () => {
    setContentActive(false);
  };
  const [handleMouseEnter, handleMouseLeave] = useHover({
    onShow: openMenu,
    onHide: closeMenu,
  });
  const Node = url ? Link : 'div';
  const computedProps = {
    ...(content && {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    }),
    ...(url && { to: url }),
  };
  const isLinkClickable = !!(url && url !== currentPathName);

  return (
    <Node
      className={cx('menu__item', {
        'menu__item--has-content': !!content,
        'menu__item--is-active': isContentActive,
        'menu__item--is-router-active':
          url === currentPathName ||
          (innerURLs && innerURLs.indexOf(currentPathName) >= 0) ||
          (url && currentPathName.includes(url)),
        'menu__item--is-home-page': currentPathName === '/',
        'menu__item--is-clickable': isLinkClickable,
      })}
      onClick={isLinkClickable ? closeMenu : undefined}
      {...computedProps}
    >
      {Icon && <Icon className="menu__icon" />}
      {Icon && <Icon className="menu__icon menu__icon--disabled" />}
      <span className="menu__name">{name}</span>
      {!!content && (
        <ChevronArrowSvg
          className={cx('menu__icon menu__icon--arrow', {
            'menu__icon--arrow-grey': isHeaderWhite,
          })}
        />
      )}
      <CSSTransition
        in={isContentActive}
        timeout={ANIMATION_DEFAULT_DURATION}
        classNames="vertical-reveal"
        unmountOnExit
      >
        <div
          className="menu__content-holder"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {typeof content === 'function' ? content({ closeMenu: handleMouseLeave }) : content}
        </div>
      </CSSTransition>
    </Node>
  );
}

MenuItem.propTypes = {
  name: string,
  Icon: elementType,
  url: string,
  isHeaderWhite: bool,
  innerURLs: array,
  content: oneOfType([func, node]),
};

export default MenuItem;
