import React from 'react';
import { useSelector } from 'react-redux';
import {
  Title,
  Description,
  GetStarted,
  Container,
  Image,
  TriggerOnEnter,
  BasicScroll,
} from 'elements';
import BankCardSvg from 'images/bank_cards/bank-card-orange.svg';
import ThunderPurpleIcon from 'images/icons/thunder-purple.svg';
import code from 'images/dev/code.webp';
import codeMobile from 'images/mobile/dev/code.webp';
import ArrowSvg from 'images/icons/arrowTop.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './HomeApi.scss';

const HomeApi = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="home_api">
      <Container className="home_api__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="15%">
          <div className="home_api__details advantages-animations">
            <div className="home_api__icon title-icon">
              <ThunderPurpleIcon />
            </div>
            <Title
              tagName="h2"
              sizeText="size-2"
              color="white"
              className="home_api__title title__part"
            >
              Deploy, fast
            </Title>
            <Description sizeText="size-2" color="white" className="home_api__description">
              Dramatically accelerate your speed to market with Finto’s fully licensed white label
              products and regulatory expertise.
            </Description>
            <div className="buttons-holder">
              <GetStarted
                className="button--with-icon"
                to="https://developer.finto.io/"
                target="_blank"
                buttontype="gradient-hoverable"
                color="text-white"
                size="medium"
                isCentered
              >
                <div className="button__inner">
                  <span>Get started</span>
                  <div className="button__icons">
                    <ArrowSvg className="button__icon" />
                    <ArrowSvg className="button__icon button__icon--next" />
                  </div>
                </div>
              </GetStarted>
            </div>
          </div>
        </TriggerOnEnter>
        <div className="home_api__media">
          <div className="home_api__img_big">
            <Image src={code} srcMobile={codeMobile} alt="code" />
          </div>
          <BasicScroll
            props={{
              '--parallax-tx': {
                from: '30%',
                to: '-30%',
              },
            }}
          >
            <div className="home_api__img_small parallax parallax--horizontal">
              <BankCardSvg />
            </div>
          </BasicScroll>
        </div>
      </Container>
    </section>
  );
};

export default HomeApi;
