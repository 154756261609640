import React from 'react';
import { Container, Title } from 'elements';
import './CultureBanner.scss';

function CultureBanner() {
  return (
    <section className="culture-banner__section">
      <Container className="culture-banner__container">
        <Title
          tagName="h2"
          sizeText="size-2"
          alignText="text-center"
          className="culture-banner__title"
        >
          We are going places. Come with us!
        </Title>
      </Container>
    </section>
  );
}

export default CultureBanner;
