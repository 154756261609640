import React from 'react';
import { ContentSwitcher, ProgressSlider, FeatureCard, Tag, Title, Container } from 'elements';
import checkIcon from 'images/icons/check-white.svg';
import shieldIcon from 'images/icons/shield.svg';
import medalIcon from 'images/icons/medal.svg';
import './BaasSecure.scss';

const sliderData = [
  {
    title: 'Full banking license',
    description:
      'All products and services are covered by Finto’s full banking license, so you can focus on growing your business.',
    Icon: medalIcon,
  },
  {
    title: 'Bank grade security',
    description:
      'Finto uses advanced fraud detection algorithms and bank level encryption to keep all customers’ financial data safe.',
    Icon: shieldIcon,
  },
  {
    title: 'Regulatory certifications',
    description:
      'Finto rigorously adheres to the highest levels of regulatory compliance, with ISO, NIST, PCI and COBIT certification.',
    Icon: checkIcon,
  },
];

const BaasSecure = () => (
  <section className="baas-secure__section">
    <Container className="baas-secure__container">
      <div className="baas-secure__declaration">
        <Tag align="center" color="purple">
          FULLY LICENSED AND SECURE
        </Tag>
        <Title tagName="h2" alignText="text-center" sizeText="size-2" color="vulcan">
          Fully licensed, secure and compliant
        </Title>
      </div>
      <ContentSwitcher
        mobileContent={
          <ProgressSlider className="baas-secure__slider">
            {sliderData.map((c) => (
              <FeatureCard shadow key={c.title} {...c} />
            ))}
          </ProgressSlider>
        }
        desktopContent={
          <div className="baas-secure__cards">
            {sliderData.map((c) => (
              <FeatureCard shadow key={c.title} {...c} />
            ))}
          </div>
        }
      />
    </Container>
  </section>
);

export default BaasSecure;
