import React, { useEffect } from 'react';
import { Container, Title, Description } from 'elements';
import './ScheduleDemo.scss';

function ScheduleDemo() {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }, []);
  return (
    <section className="schedule-demo__section">
      <Container className="schedule-demo__container">
        <Title
          alignText="text-center"
          tagName="h1"
          sizeText="size-3"
          className="schedule-demo__title"
        >
          Schedule a demo
        </Title>
        <Description
          sizeText="size-2"
          alignText="text-center"
          className="schedule-demo__description"
        >
          Schedule a demo to have a member of the Finto team give you an in depth walkthrough of our
          products and services.
        </Description>
        <div className="calendly-inline-widget" data-url="https://calendly.com/finto" />
      </Container>
    </section>
  );
}
export default ScheduleDemo;
