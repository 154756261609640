import React from 'react';
import { useSelector } from 'react-redux';
import { Bsi, Cobit, Pci, Iso, Gdpr, Nist } from 'icons';
import { TriggerOnEnter } from '../../elements';
import { getAreAnimationsUnblocked } from '../../redux/modules/flags/flagsSelectors';
import './Standarts.scss';

const Standarts = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);
  return (
    <TriggerOnEnter bypass={!areAnimationsUnblocked}>
      <nav className="standarts">
        <div className="standarts__list">
          <div className="standarts__list_item">
            <Cobit className="standarts__list_logo" />
          </div>
          <div className="standarts__list_item">
            <Pci className="standarts__list_logo" />
          </div>
          <div className="standarts__list_item">
            <Gdpr className="standarts__list_logo" />
          </div>
          <div className="standarts__list_item">
            <Bsi className="standarts__list_logo" />
          </div>
          <div className="standarts__list_item">
            <Nist className="standarts__list_logo" />
          </div>
          <div className="standarts__list_item">
            <Iso className="standarts__list_logo" />
          </div>
        </div>
      </nav>
    </TriggerOnEnter>
  );
};
export default Standarts;
