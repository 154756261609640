import React from 'react';
import { HelmetWrapper } from 'elements';
import { CoverageFeaturesHero, CoverageFeaturesEpilog } from 'components';

const CoverageFeatures = () => (
  <>
    <HelmetWrapper helmetKey="coverage" />
    <CoverageFeaturesHero />
    <CoverageFeaturesEpilog />
  </>
);

export default CoverageFeatures;
