import React from 'react';
import { Link } from 'react-router-dom';
import { listNav } from './constants';
import './FooterNav.scss';

const FooterNav = () => (
  <div className="footer_nav">
    {listNav &&
      listNav.map((c) => (
        <Link to={c.url} key={c.id} className="footer_nav__link">
          {c.name}
        </Link>
      ))}
  </div>
);

export default FooterNav;
