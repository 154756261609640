import React from 'react';
import { string, func } from 'prop-types';
import './HelperText.scss';

const HelperTextItem = ({ Icon, text, ...rest }) => (
  <div className="helper_text__item" {...rest}>
    {!!Icon && <Icon className="helper_text__icon" />}
    <span className="helper_text__text">{text}</span>
  </div>
);

HelperTextItem.defaultProps = {
  text: '',
};

HelperTextItem.propTypes = {
  Icon: func,
  text: string,
};

export default HelperTextItem;
