import React from 'react';
import { object, array } from 'prop-types';
import './styles.scss';
import FormResultElements from './FormResultElements';

function FormResult({ form, data }) {
  if (!form) return null;
  const { values } = form;
  if (
    !values?.contract_type?.length &&
    !values?.location?.length &&
    !values?.team?.length &&
    !values?.search?.length
  )
    return null;

  const changeForm = (fieldName, value) => {
    const newValue = values[fieldName].filter((c) => c.value !== value);
    form.form.change(fieldName, newValue);
  };
  const teamSpelling = values.team.length > 1 ? ' teams.' : ' team.';
  return (
    <p className="form-result">
      {`Showing ${data?.length || 0} jobs `}
      <FormResultElements
        data={values?.contract_type}
        typeLabel="positions"
        typeAllLabel="all contract"
        onItemPress={(value) => changeForm('contract_type', value)}
      />
      <FormResultElements
        data={values?.location}
        typeLabel=""
        typeAllLabel="all location"
        onItemPress={(value) => changeForm('location', value)}
      />
      <FormResultElements
        data={values?.team}
        typeLabel=""
        typeAllLabel="all teams"
        onItemPress={(value) => changeForm('team', value)}
        PostElementComponent={(currenElementindex) => (
          <>{currenElementindex + 1 === values.team.length ? teamSpelling : ',\u00A0'}</>
        )}
      />
      <span className="form-result form-result__clear" onClick={() => form.form.reset()}>
        Clear all filters
      </span>
    </p>
  );
}

FormResult.propTypes = {
  form: object,
  data: array,
};

export default FormResult;
