import React from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Header, Footer, InitialLoader } from 'components';
import { TriggerOnEnter } from '../../elements';

import { getAreAnimationsUnblocked } from '../../redux/modules/flags/flagsSelectors';
import './App.scss';

export const App = (props) => {
  const {
    location: { pathname },
    route: { routes },
  } = props;
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);
  return (
    <div className="app">
      <TriggerOnEnter bypass={!areAnimationsUnblocked}>
        <Header currentPathName={pathname} />
      </TriggerOnEnter>
      <div className="app__content">{renderRoutes(routes)}</div>
      <Footer />
      <InitialLoader />
    </div>
  );
};

App.propTypes = {
  route: object,
  location: object,
};

export default App;
