import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = ({ fill = 'none', id = '' }) => (
  <svg viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62732 0.92129C9.86193 0.803987 10.1381 0.803987 10.3727 0.92129L18.706 5.08796C18.9883 5.22912 19.1667 5.51767 19.1667 5.83331C19.1667 6.14896 18.9883 6.43751 18.706 6.57867L10.3727 10.7453C10.1381 10.8626 9.86193 10.8626 9.62732 10.7453L1.29399 6.57867C1.01167 6.43751 0.833333 6.14896 0.833333 5.83331C0.833333 5.51767 1.01167 5.22912 1.29399 5.08796L9.62732 0.92129ZM3.53006 5.83331L10 9.06828L16.4699 5.83331L10 2.59834L3.53006 5.83331Z"
      fill={`url(#paint0_linear_${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.92129 13.7939C1.12711 13.3823 1.62767 13.2154 2.03932 13.4213L9.99998 17.4016L17.9606 13.4213C18.3723 13.2154 18.8728 13.3823 19.0787 13.7939C19.2845 14.2056 19.1176 14.7062 18.706 14.912L10.3727 19.0786C10.1381 19.1959 9.86191 19.1959 9.6273 19.0786L1.29397 14.912C0.882319 14.7062 0.715465 14.2056 0.92129 13.7939Z"
      fill={`url(#paint1_linear_${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.92129 9.62728C1.12711 9.21563 1.62767 9.04878 2.03932 9.2546L9.99998 13.2349L17.9606 9.2546C18.3723 9.04878 18.8728 9.21563 19.0787 9.62728C19.2845 10.0389 19.1176 10.5395 18.706 10.7453L10.3727 14.912C10.1381 15.0293 9.86191 15.0293 9.6273 14.912L1.29397 10.7453C0.882319 10.5395 0.715465 10.0389 0.92129 9.62728Z"
      fill={`url(#paint2_linear_${id})`}
    />
    <defs>
      <linearGradient
        id={`paint0_linear_${id}`}
        x1="9.99998"
        y1="-19.7917"
        x2="9.99998"
        y2="40.7905"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${id}`}
        x1="9.99998"
        y1="-19.7917"
        x2="9.99998"
        y2="40.7905"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_${id}`}
        x1="9.99998"
        y1="-19.7917"
        x2="9.99998"
        y2="40.7905"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;
