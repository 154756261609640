import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { number, shape, string, arrayOf, node, oneOfType } from 'prop-types';
import cx from 'classnames';
import './ProgressSlider.scss';

SwiperCore.use([Pagination]);

const ProgressSlider = ({ children, className, ...rest }) => {
  if (!children) return null;
  return (
    <div className={cx('progress-slider', className)}>
      <Swiper {...rest}>
        {children.map((c, index) => (
          <SwiperSlide key={index.toString()}>{c}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

ProgressSlider.propTypes = {
  spaceBetween: number,
  className: string,
  slidesPerView: string,
  children: oneOfType([arrayOf(node), node]),
  pagination: shape({
    type: string,
  }),
};
ProgressSlider.defaultProps = {
  // spaceBetween: 8,
  slidesPerView: 'auto',
  pagination: {
    type: 'progressbar',
  },
};

export default ProgressSlider;
