import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6544 30.8979C17.8122 31.1509 14.3381 34.1304 13.9413 38.3982C13.7514 40.4056 14.2231 42.2864 15.431 43.9314C17.2945 46.4622 19.8483 47.5895 22.9715 47.4285C23.1729 48.0209 23.3569 48.5673 23.5467 49.1195C23.5755 49.1195 23.5985 49.1138 23.6273 49.108C24.3635 47.4515 25.1055 45.7892 25.8532 44.1097C24.2369 43.1837 22.6552 42.2749 20.9814 41.3143C21.2288 42.0966 21.4531 42.7868 21.6774 43.4943C20.3545 43.4483 18.4219 42.3152 17.9157 40.164C17.4556 38.2314 18.3701 36.224 20.1417 35.2692C21.85 34.3489 24.0471 34.6595 25.3758 35.9997C26.1005 36.7302 26.5664 37.5929 26.6814 38.6053C26.8598 40.1582 26.3191 41.4696 25.1515 42.5395C25.8417 42.9421 26.4744 43.3102 27.1186 43.6898C26.6757 44.6849 26.2558 45.6339 25.8187 46.6175C28.5335 45.4729 31.2541 42.1311 30.4834 37.6907C29.8277 33.9118 26.4916 30.8806 22.2526 30.8806C22.057 30.8806 21.8557 30.8864 21.6544 30.8979Z"
      fill="#D2D3D6"
    />
    <path
      d="M6.96435 31.243C4.59462 31.6629 2.65627 32.8017 1.33336 34.8436C-0.0183109 36.92 -0.259886 39.1977 0.240519 41.5674C0.861711 44.4778 2.65627 46.3529 5.53791 47.1524C7.45325 47.6816 9.4031 47.6413 11.3357 47.2099C12.2847 46.9971 13.1878 46.6577 14.062 46.1631C13.7227 45.2025 13.3833 44.2593 13.044 43.293C12.9174 43.3562 12.8427 43.385 12.7736 43.4252C11.4162 44.219 9.94952 44.5814 8.37929 44.4318C6.64225 44.2708 5.30784 43.4713 4.51984 41.8838C3.93316 40.7047 3.84113 39.4508 4.0712 38.1796C4.40481 36.3103 5.39411 34.9471 7.28645 34.3604C8.47131 33.9923 9.68494 34.0383 10.8698 34.3259C11.6866 34.5215 12.4803 34.8263 13.3028 35.0852V31.8527C13.2683 31.8182 13.251 31.7951 13.228 31.7894C13.1303 31.7491 13.0325 31.7089 12.9289 31.6744C11.6693 31.2602 10.3924 31.0532 9.10401 31.0532C8.39079 31.0532 7.67182 31.1164 6.94709 31.243"
      fill="#D2D3D6"
    />
    <path
      d="M49.5678 31.243V34.1994H54.3015V47.2329H58.0229V34.1936H62.2274C62.5265 33.1986 62.8199 32.2323 63.119 31.243H49.5678Z"
      fill="#D2D3D6"
    />
    <path d="M48.2449 31.2487H44.5695V47.2387H48.2449V31.2487Z" fill="#D2D3D6" />
    <path
      d="M61.0598 40.118C60.2776 40.4918 59.8577 41.2281 59.9497 42.0218H61.5487C61.5775 41.9413 61.6005 41.878 61.6235 41.8147C61.7673 41.4409 62.0951 41.2741 62.4805 41.3719C62.8486 41.4639 63.0384 41.7055 62.9579 42.1081C62.9119 42.3554 62.7796 42.6027 62.6243 42.8041C62.3655 43.1377 62.0606 43.431 61.7673 43.7416C61.1403 44.4203 60.5134 45.099 59.921 45.7375V47.2732H64.6547V45.7835H62.0664C62.0549 45.7547 62.0491 45.7202 62.0376 45.6914C62.1814 45.5361 62.3252 45.3866 62.469 45.2313C63.0787 44.5698 63.7287 43.9429 64.2808 43.2354C65.0516 42.2576 64.7122 40.7392 63.6194 40.1582C63.188 39.9282 62.7451 39.8131 62.3022 39.8131C61.8881 39.8131 61.4682 39.9109 61.0541 40.1122"
      fill="#D2D3D6"
    />
    <path
      d="M72.4138 39.9224C71.8904 40.3826 71.3728 40.8369 70.8839 41.2626V43.0859C71.4073 42.6142 71.8847 42.1944 72.3621 41.7687L72.4426 41.8147V47.2847H74.1221V39.9224H72.4138Z"
      fill="#D2D3D6"
    />
    <path
      d="M35.5564 44.2765V40.6299C36.4825 40.6701 37.3855 40.6529 38.2713 40.7679C39.1628 40.883 39.6632 41.4926 39.7035 42.3094C39.7495 43.2354 39.2951 43.8796 38.3863 44.1212C38.07 44.2075 37.7364 44.2535 37.4085 44.2708C37.1554 44.2823 36.9081 44.2823 36.655 44.2823C36.2927 44.2823 35.9303 44.2765 35.5564 44.2765ZM35.5564 34.2051C36.2179 34.2627 36.8563 34.2454 37.4488 34.3892C38.2425 34.5733 38.6624 35.2117 38.6509 35.9939C38.6394 36.7762 38.1965 37.3916 37.397 37.5584C36.8103 37.685 36.2006 37.6735 35.5564 37.731V34.2051ZM31.8293 31.2142V47.2329C31.9731 47.2444 32.0479 47.2559 32.1284 47.2559C34.0437 47.2559 35.9591 47.2674 37.8744 47.2559C38.3748 47.2559 38.881 47.1869 39.3699 47.0834C41.6878 46.5887 43.3271 44.7424 43.4191 42.5625C43.4881 40.8312 42.5218 39.284 40.8653 38.6743C40.7388 38.6283 40.6123 38.5765 40.4454 38.5132C40.549 38.4327 40.6007 38.3924 40.6525 38.3579C42.913 36.7647 42.8324 33.5609 40.4972 32.0655C39.6057 31.4961 38.6164 31.266 37.5868 31.2487C35.8728 31.2142 34.1588 31.2257 32.4447 31.22H31.8293"
      fill="#D2D3D6"
    />
    <path
      d="M67.0762 45.1048C67.0647 44.104 67.0647 43.1031 67.0762 42.1023C67.0762 41.6422 67.3753 41.3431 67.7722 41.3489C68.169 41.3546 68.4509 41.6594 68.4624 42.1138C68.4739 42.6142 68.4624 43.1146 68.4624 43.6151C68.4624 44.104 68.4681 44.5929 68.4624 45.076C68.4566 45.5592 68.2035 45.8352 67.7894 45.841H67.7779C67.3753 45.841 67.0819 45.5592 67.0762 45.1048ZM67.404 39.8649C66.294 39.9569 65.4772 40.7219 65.4139 41.8263C65.3449 43.0341 65.3794 44.2477 65.4197 45.4614C65.4427 46.1573 65.8453 46.6635 66.4435 47.0144C66.9842 47.3365 67.5708 47.3997 68.1748 47.3307C69.2504 47.2099 70.0211 46.4622 70.1189 45.4039C70.1764 44.8057 70.1304 44.1902 70.1304 43.5863H70.1649C70.1649 43.0974 70.1649 42.6085 70.1649 42.1196C70.1534 41.0498 69.6127 40.2503 68.6234 39.9742C68.3358 39.8937 68.0195 39.8534 67.7146 39.8534C67.6111 39.8534 67.5076 39.8534 67.4098 39.8649"
      fill="#D2D3D6"
    />
    <path
      d="M77.4697 43.0744C77.0153 43.0686 76.7047 42.7063 76.7104 42.1944C76.7104 41.6882 77.044 41.3316 77.4927 41.3431C77.947 41.3546 78.2749 41.7227 78.2692 42.2174C78.2692 42.6948 77.9125 43.0744 77.4812 43.0744H77.4697ZM76.3308 40.0605C75.3875 40.4631 74.9216 41.3143 75.0136 42.482C75.1057 43.6553 75.9512 44.4203 77.1476 44.4146C77.2051 44.4146 77.2626 44.4318 77.3719 44.4491C76.8945 45.4039 76.4401 46.3299 75.9627 47.2962H77.8378C78.505 45.8813 79.2355 44.4778 79.8221 43.0169C80.15 42.2001 80.0349 41.3143 79.3908 40.6069C78.9191 40.095 78.2174 39.8246 77.4869 39.8246C77.0958 39.8246 76.7047 39.8994 76.3308 40.0605Z"
      fill="#D2D3D6"
    />
    <path
      d="M63.7344 32.2035C63.7344 31.7606 64.1083 31.3983 64.5569 31.3983C65.0171 31.3983 65.3794 31.7664 65.3794 32.2208C65.3794 32.6752 65.0055 33.0318 64.5569 33.0318C64.0968 33.0318 63.7286 32.6637 63.7344 32.2035ZM63.6021 32.215C63.6021 32.7384 64.0277 33.1641 64.5569 33.1641C65.0918 33.1641 65.5175 32.7384 65.5175 32.2093C65.5175 31.6916 65.0861 31.266 64.5627 31.266C64.0277 31.266 63.6021 31.6859 63.6021 32.215Z"
      fill="#D2D3D6"
    />
    <path
      d="M64.3326 32.146V31.8297H64.3786C64.4591 31.8297 64.5397 31.8297 64.6202 31.8297C64.7122 31.8297 64.7812 31.8987 64.7812 31.9907C64.7812 32.0827 64.7122 32.146 64.6259 32.146C64.5857 32.146 64.5454 32.146 64.4994 32.146C64.4419 32.146 64.3901 32.146 64.3326 32.146ZM64.3096 31.6974H64.2003V32.6637H64.3326V32.2898C64.5512 32.2725 64.718 32.2725 64.7927 32.6694H64.925C64.9135 32.5084 64.8618 32.3761 64.7467 32.261C64.7525 32.261 64.7582 32.2553 64.7582 32.2553C64.8848 32.1805 64.9423 32.054 64.902 31.9217C64.8617 31.7894 64.7525 31.7089 64.6029 31.7089H64.3096"
      fill="#D2D3D6"
    />
  </svg>
);

export default SVG;
