import React from 'react';
import { components } from 'react-select';
import { oneOfType, node, arrayOf } from 'prop-types';
import CustomMultiValue from './CustomMultiValue';

function GroupedValueContainer({ children, ...restProps }) {
  const { selectProps, getValue } = restProps;
  const { showSelectedCountAfter, selectedCountFormatter, placeholder } = selectProps;
  const selectedValues = getValue();

  const shouldShowSelectedCount =
    typeof showSelectedCountAfter === 'number' && selectedValues.length > showSelectedCountAfter;
  let _children = children;

  if (shouldShowSelectedCount) {
    const [, ...restChildren] = children;
    const content = selectedCountFormatter(selectedValues.length, placeholder);

    _children = [<CustomMultiValue key="customChild" content={content} />, ...restChildren];
  }

  return <components.ValueContainer {...restProps}>{_children}</components.ValueContainer>;
}

GroupedValueContainer.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
};

export default GroupedValueContainer;
