import React from 'react';
import { string } from 'prop-types';
import {
  GetStarted,
  Tag,
  Title,
  Description,
  Container,
  ContentSwitcher,
  BankCard,
} from 'elements';
import cx from 'classnames';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './CommonCards.scss';

const cards = [
  {
    textVariant: 'virtual',
    colorVariant: 'coral',
  },
  {
    textVariant: 'credit',
    colorVariant: 'orange',
  },
  {
    textVariant: 'prepaid',
    colorVariant: 'red',
  },
  {
    textVariant: 'debit',
    colorVariant: 'purple',
  },
];

const cardsReversed = [...cards].reverse();

const CommonCards = ({ className, title, tag, description }) => (
  <section className={cx(className, 'common-cards__section')}>
    <Container className="common-cards__container">
      <ContentSwitcher
        desktopContent={
          <div className="common-cards__images">
            {cards.map((c) => (
              <BankCard key={c.textVariant} {...c} />
            ))}
          </div>
        }
      />
      <div className="common-cards__declaration">
        <Tag color="purple">{tag}</Tag>
        <Title tagName="h2" color="vulcan">
          {title}
        </Title>
        <Description sizeText="size-2">{description}</Description>
        <ContentSwitcher
          mobileContent={
            <div className="common-cards__mobile-images">
              {cardsReversed.map((c) => (
                <BankCard key={c.textVariant} {...c} />
              ))}
            </div>
          }
        />
        <GetStarted
          to="https://developer.finto.io/"
          target="_blank"
          background="black"
          border="border-black"
          color="text-white"
          size="medium"
          className="baap-accelerate__button button--with-icon"
          buttontype="hoverable"
          isCentered
        >
          <div className="button__inner">
            <span>Get started</span>
            <div className="button__icons">
              <ArrowSvg className="button__icon" />
              <ArrowSvg className="button__icon button__icon--next" />
            </div>
          </div>
        </GetStarted>
      </div>
    </Container>
  </section>
);

CommonCards.propTypes = {
  tag: string,
  title: string,
  description: string,
  className: string,
};

export default CommonCards;
