import React from 'react';
import { HelmetWrapper } from 'elements';
import {
  BaasHero,
  BaasTailored,
  BaasInnovation,
  BaasSecure,
  CommonCards,
  BaasEpilog,
  BaasPresentationView,
} from 'components';

const Baas = () => (
  <>
    <HelmetWrapper helmetKey="baas" />
    <BaasHero />
    <BaasTailored />
    <BaasInnovation />
    <BaasSecure />
    <CommonCards
      tag="paas"
      title="Instantly issue custom cards"
      description="Take your product to the another level with branded and сustomizable debit, prepaid, virtual and loyalty
          cards."
      buttonText="Find out more"
      buttonLink="/paas"
    />
    <BaasEpilog />
    <BaasPresentationView />
  </>
);

export default Baas;
