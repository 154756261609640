import React from 'react';
import { App } from 'components';
import { Redirect } from 'react-router';
import {
  Baap,
  Baas,
  Developers,
  Home,
  NotFound,
  Paas,
  Started,
  About,
  CoverageFeatures,
  Contacts,
  Jobs,
  Hiring,
  Culture,
  Enterprise,
  RequestAccess,
  ScheduleDemo,
  Terms,
  // LegalStatement,
  CookiesPolicy,
  PrivacyPolicy,
  Cronica,
} from 'routes';

export default () => [
  {
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/baas',
        exact: true,
        component: Baas,
      },
      {
        path: '/baap',
        exact: true,
        component: Baap,
      },
      {
        path: '/paas',
        exact: true,
        component: Paas,
      },
      {
        path: '/cronica',
        exact: true,
        component: Cronica,
      },
      {
        path: '/get-started',
        exact: true,
        component: Started,
      },
      {
        path: '/get-started/enterprise',
        exact: true,
        component: Enterprise,
      },
      {
        path: '/get-started/request_access',
        exact: true,
        component: RequestAccess,
      },
      {
        path: '/get-started/schedule_demo',
        exact: true,
        component: ScheduleDemo,
      },
      {
        path: '/developers',
        exact: true,
        component: Developers,
      },
      {
        path: '/about',
        exact: true,
        component: About,
      },
      {
        path: '/coverage',
        exact: true,
        component: CoverageFeatures,
      },
      {
        path: '/contact',
        component: Contacts,
        exact: true,
      },
      {
        path: '/careers',
        exact: true,
        component: () => <Redirect to="/careers/jobs" />,
      },
      {
        path: '/careers/jobs',
        exact: true,
        component: Jobs,
      },
      {
        path: '/careers/hiring',
        exact: true,
        component: Hiring,
      },
      {
        path: '/careers/culture',
        exact: true,
        component: Culture,
      },
      {
        path: '/terms-and-conditions',
        exact: true,
        component: Terms,
      },
      {
        path: '/privacy-policy',
        exact: true,
        component: PrivacyPolicy,
      },
      {
        path: '/cookies-policy',
        extract: true,
        component: CookiesPolicy,
      },
      {
        path: '*',
        component: NotFound,
        isNotFound: true,
      },
    ],
  },
];
