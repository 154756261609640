import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = ({ fill = 'none', id = '' }) => (
  <svg viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.80409 4.47145C6.06444 4.2111 6.06444 3.78899 5.80409 3.52864C5.54374 3.26829 5.12163 3.26829 4.86128 3.52864L0.861278 7.52864C0.600928 7.78899 0.600928 8.2111 0.861278 8.47145L4.86128 12.4714C5.12163 12.7318 5.54374 12.7318 5.80409 12.4714C6.06444 12.2111 6.06444 11.789 5.80409 11.5286L2.27549 8.00004L5.80409 4.47145Z"
      fill={`url(#paint0_linear_${id})`}
    />
    <path
      d="M11.1374 3.52864C10.8771 3.26829 10.455 3.26829 10.1946 3.52864C9.93426 3.78899 9.93426 4.2111 10.1946 4.47145L13.7232 8.00004L10.1946 11.5286C9.93426 11.789 9.93426 12.2111 10.1946 12.4714C10.455 12.7318 10.8771 12.7318 11.1374 12.4714L15.1374 8.47145C15.3978 8.2111 15.3978 7.78899 15.1374 7.52864L11.1374 3.52864Z"
      fill={`url(#paint1_linear_${id})`}
    />
    <defs>
      <linearGradient
        id={`paint0_linear_${id}`}
        x1="7.99935"
        y1="-7.16663"
        x2="7.99935"
        y2="23.6753"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${id}`}
        x1="7.99935"
        y1="-7.16663"
        x2="7.99935"
        y2="23.6753"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;
