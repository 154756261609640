import React, { useState, useEffect, useRef } from 'react';
import { Container, Title } from 'elements';
import { string, array, elementType } from 'prop-types';
import cx from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';
import DotsSvg from 'images/icons/dots.svg';
import SideMenu from './SideMenu/SideMenu';
import './TermsPrivacy.scss';

const TermsPrivacy = ({ title, menu, Content }) => {
  const IS_SIDE_MENU = menu && menu.length;
  const [isOffset, setIsOffset] = useState(true);
  const [isMobileMenuOpen, setMobileMenu] = useState(false);
  const [waypoint, setCurrentWaypoint] = useState(undefined);
  const [waypointText, setWaypointText] = useState('');
  const waypointDisabled = useRef(false);

  const handleHeaderStick = (event) => {
    setIsOffset(event.detail.visible);
  };

  useEffect(() => {
    smoothscroll.polyfill();
    if (IS_SIDE_MENU) {
      document.addEventListener('header_stick', handleHeaderStick);
    }
    return () => {
      if (IS_SIDE_MENU) {
        document.removeEventListener('header_stick', handleHeaderStick);
      }
    };
  }, [IS_SIDE_MENU]);

  useEffect(() => {
    if (waypoint && IS_SIDE_MENU) {
      // eslint-disable-next-line no-restricted-syntax
      for (const value of menu) {
        const waypointObject = value.list.find((c) => c.id === waypoint);
        if (waypointObject) {
          setWaypointText(waypointObject.text || '');
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waypoint, IS_SIDE_MENU]);

  const toggleMenu = () => {
    if (isMobileMenuOpen) {
      setMobileMenu(false);
      document.body.classList.remove('terms-menu-mobile-open');
    } else {
      setMobileMenu(true);
      document.body.classList.add('terms-menu-mobile-open');
    }
  };

  const setWaypoint = (id, blockTime) => {
    if (waypointDisabled.current && !blockTime) {
      return;
    }
    if (blockTime) {
      if (waypointDisabled.current) {
        clearTimeout(waypointDisabled.current);
      }
      waypointDisabled.current = setTimeout(() => {
        waypointDisabled.current = false;
      }, blockTime);
    }
    setCurrentWaypoint(id);
  };

  return (
    <section className="terms-privacy__section">
      <Container className="terms-privacy__container">
        <Title tagName="h1" sizeText="size-3" alignText="text-center" className="content__title--1">
          {title}
        </Title>
        <div
          className={cx('transformed-container', {
            'transformed-container--is-side-menu': IS_SIDE_MENU,
            'transformed-container--open': isMobileMenuOpen,
          })}
        >
          {IS_SIDE_MENU && (
            <SideMenu
              setWaypoint={setWaypoint}
              isOffset={isOffset}
              toggleMenu={toggleMenu}
              waypointText={waypointText}
              data={menu}
            />
          )}
          <div className="content-container">
            {IS_SIDE_MENU && (
              <button
                className={cx('toggle-button', {
                  'toggle-button--with-offset': isOffset,
                })}
                type="button"
                onClick={toggleMenu}
              >
                <DotsSvg className="dots-svg" />
                <span>{waypointText}</span>
              </button>
            )}
            <Title
              tagName="h2"
              sizeText="size-3"
              alignText="text-center"
              className="content__title--1"
            >
              {title}
            </Title>
            <Content
              offset={{
                topOffset: '75%',
                bottomOffset: '75%',
              }}
              wayPointCallback={setWaypoint}
            />
            <div
              onClick={toggleMenu}
              className={cx('black-overlay', { 'is-active': isMobileMenuOpen })}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

TermsPrivacy.propTypes = {
  title: string,
  menu: array,
  Content: elementType,
};

export default TermsPrivacy;
