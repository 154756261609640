import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useResponsive } from 'hooks';
import { array } from 'prop-types';
import './TeamSlider.scss';

export default function TeamSlider({ data }) {
  const { isDesktop } = useResponsive();
  if (!data?.length) return null;
  return (
    <Swiper
      className="team-slider"
      slidesPerView={3}
      centeredSlides
      loop
      preloadImages
      spaceBetween={40}
    >
      {data.map((c, index) => (
        <SwiperSlide className="team-slider__swiper-slide" key={index.toString()}>
          <img src={isDesktop ? c.desktop : c.mobile || c.desktop} alt="slide" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

TeamSlider.propTypes = {
  data: array,
};
