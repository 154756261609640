import React from 'react';
import { SignUpForm } from 'components';
import './StartedSignUp.scss';

const StartedSignUp = () => (
  <div className="started_sing_up">
    <div className="started_sing_up__container">
      <div className="started_sing_up__form">
        <SignUpForm />
      </div>
    </div>
  </div>
);
export default StartedSignUp;
