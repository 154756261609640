import React from 'react';
import { func, array } from 'prop-types';
import { useHistory } from 'react-router';
import { isHttps } from '../../utils/helpers';
import MenuCard from './MenuCard';
import './MenuCards.scss';

const MenuCards = (props) => {
  const { closeMenu, data } = props;
  const history = useHistory();

  return (
    <nav className="menu_cards">
      {data &&
        data.map((c) => (
          <div key={c.id} className="menu_cards__item">
            <MenuCard
              url={c.url}
              Icon={c.Icon}
              title={c.title}
              desc={c.desc}
              tag={c.tag}
              handleCardClick={() => {
                closeMenu();
                if (isHttps(c.url)) {
                  window.open(c.url, '_blank').focus();
                } else {
                  history.push(c.url);
                }
              }}
            />
          </div>
        ))}
    </nav>
  );
};

MenuCards.propTypes = {
  closeMenu: func,
  data: array,
};

export default MenuCards;
