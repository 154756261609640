import React from 'react';
import { useSelector } from 'react-redux';
import BankaletihadSvg from 'images/partners/bankaletihad-logo-white.svg';
import SheeldSvg from 'images/partners/sheeld-logo-white.svg';
import WaselSvg from 'images/partners/wasel-logo-white.svg';
import CronicaSvg from 'images/partners/cronica-logo-white.svg';
import { TriggerOnEnter } from '../../elements';
import { getAreAnimationsUnblocked } from '../../redux/modules/flags/flagsSelectors';
import './Partners.scss';

const Partners = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <TriggerOnEnter bypass={!areAnimationsUnblocked}>
      <nav className="partners">
        <div className="partners__title">Powering our partners</div>
        <div className="partners__list">
          <div className="partners__list_item sheeld">
            <SheeldSvg className="partners__list_logo" />
          </div>
          <div className="partners__list_item bankaletigad">
            <BankaletihadSvg className="partners__list_logo" />
          </div>
          <div className="partners__list_item wasel">
            <WaselSvg className="partners__list_logo" />
          </div>
          <div className="partners__list_item cronica">
            <CronicaSvg className="partners__list_logo" />
          </div>
        </div>
      </nav>
    </TriggerOnEnter>
  );
};
export default Partners;
