import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tag, Title, Description, Container } from 'elements';
import './BaapApi.scss';
import data from './data';

const BaapApi = () => (
  <section className="baap-api__section">
    <Container className="baap-api__container">
      <div className="baap-api__declaration">
        <Tag align="center" color="purple" lineClassName="baap-api__tag-line">
          INTUITIVE API
        </Tag>
        <Title tagName="h2" alignText="text-center" sizeText="size-2" color="white">
          Designed for developers
        </Title>
        <Description alignText="text-center" sizeText="size-2" color="white">
          Finto’s API has been meticulously designed by developers for developers. Get instant API
          access, and start implementing powerful new features.
        </Description>
      </div>
    </Container>
    <Tabs className="react-tabs baap-api__tabs">
      <TabList>
        {data.map((c, indx) => {
          const Icon = c.head.icon;
          return (
            <Tab className="react-tabs__tab baap-api__tab" key={indx.toString()}>
              {Icon && <Icon className="baap-api__tab-icon" />}
              <span className="baap-api__tab-head">{c.head.title}</span>
            </Tab>
          );
        })}
      </TabList>
      {data.map((c, indx) => (
        <TabPanel className="baap-api__tab-content tab-content" key={indx.toString()}>
          <div className="tab-content__description">
            <Title
              tagName="h2"
              className="baap-api__tab-title"
              alignText="text-left"
              sizeText="size-7"
              color="white"
            >
              {c.body.title}
            </Title>
            <Description
              className="baap-api__tab-description"
              alignText="text-left"
              sizeText="size-2"
              color="white"
            >
              {c.body.description}
            </Description>
          </div>
          <div className="tab-content__code">
            <ul className="code-view">
              {c.body.data.map((cc, index) => (
                <li className="code-item" key={index.toString()}>
                  <code className="code-item__number">{index + 1}</code>
                  <code className="code-item__text">{cc}</code>
                </li>
              ))}
            </ul>
          </div>
        </TabPanel>
      ))}
    </Tabs>
  </section>
);

export default BaapApi;
