import React from 'react';
import { string, node, func, oneOf } from 'prop-types';
import cx from 'classnames';
import { Description, Title, Button } from 'elements';
import './PricingCard.scss';

const PricingCard = ({
  btnName,
  children,
  colorTag,
  desc,
  Icon,
  title,
  tagName,
  btnBackground,
  onButtonClick,
}) => (
  <div className="pricing_card">
    <div className="pricing_card__details">
      <div
        className={cx('pricing_card__tag', {
          [`pricing_card__tag--${colorTag}`]: !!colorTag,
        })}
      >
        <span>{tagName}</span>
        <Icon />
      </div>
      <Title tagName="h3" sizeText="size-7" color="black" className="pricing_card__title">
        {title}
      </Title>
      <Description sizeText="size-2" color="black" className="pricing_card__description">
        {desc}
      </Description>
      <div className="pricing_card__button-wrapper">
        <Button
          className={`pricing_card__button pricing_card__button--${btnBackground} button--with-icon`}
          size="medium"
          buttontype="gradient-hoverable"
          isCentered
          onClick={onButtonClick}
        >
          <div className="button__inner">
            <span>{btnName}</span>
          </div>
        </Button>
      </div>
    </div>
    <div className="pricing_card__list">{children}</div>
  </div>
);

PricingCard.defaultProps = {
  children: null,
  colorTag: '',
  btnName: '',
  desc: '',
  title: '',
  tagName: '',
  btnBackground: 'purple',
};

PricingCard.propTypes = {
  onButtonClick: func,
  children: node,
  colorTag: string,
  btnName: string,
  desc: string,
  title: string,
  tagName: string,
  Icon: func,
  btnBackground: oneOf(['purple', 'red', 'orange']),
};

export default PricingCard;
