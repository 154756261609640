import React from 'react';
import { Title, TriggerOnEnter } from 'elements';
import { Link } from 'react-router-dom';
import { func, object, string } from 'prop-types';
import cx from 'classnames';
import TermsPrivacy from '../../TermsPrivacy/TermsPrivacy';
import PrivacyPolicyTable from '../../NestedTableSlider/PrivacyPolicyTable';
import { PRIVACY_POLICY_LAST_CHANGED_DATE } from '../../../constants/const';

const Content = ({ wayPointCallback, className, offset }) => (
  <div className={cx({ [className]: !!className })}>
    <div className="content-box">
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('introduction')}>
        <>
          <Title id="introduction" className="content__title content__title--3" tagName="h3">
            Introduction
          </Title>
          <p>Our Privacy Policy applies to your use of:</p>
          <ul className="content__list">
            <li>
              The Finto site hosted on [www.finto.io] which can be accessed via desktop or mobile
              web <b>(Site)</b>, once you have streamed the Site onto your mobile telephone or
              handheld device <b>(Device)</b>.
            </li>
            <li>
              Any of the services accessible through the Site <b />.
            </li>
          </ul>
          <p>
            Our Privacy Policy sets out the basis on which any personal data we collect from you, or
            that you provide to us, will be processed by us. This Site is not intended for children
            and we do not knowingly collect data relating to children without proper parental
            consent. Please read the following carefully to understand our practices regarding your
            personal data and how we will treat it.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter
        {...offset}
        onEnter={() => wayPointCallback('important-information-and-who-we-are')}
      >
        <>
          <Title
            id="important-information-and-who-we-are"
            className="content__title content__title--3"
            tagName="h3"
          >
            Important information and who we are
          </Title>
          <p>
            <b>[Etihad Financial Technology (Finto)]</b> is the controller and is responsible for
            your personal data (collectively referred to as “<b>Company</b>”, “<b>we</b>”, “
            <b>us</b>” or “<b>our</b>” in this Privacy Policy).
          </p>
          <p>
            If you have any questions about this Privacy Policy, please contact them using the
            details set out below.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('contact-details')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="contact-details">
            Contact details
          </Title>
          <p>Our contact details are as follows:</p>
          <ul className="content__list">
            <li>Full name of legal entity: Etihad Financial Technology (Finto)</li>
            <li>
              Email address: [<a href="mailto:hello@finto.io">hello@finto.io</a>]
            </li>
          </ul>
          <p>
            You have the right to make a complaint at any time to the competent supervisory
            authority.
          </p>
        </>
      </TriggerOnEnter>

      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('privacy-policy-changes')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="privacy-policy-changes"
          >
            Changes to the Privacy Policy and your duty to inform us of changes
          </Title>
          <p>We may update our Privacy Policy.</p>
          <p>
            {`This version was last updated on [${PRIVACY_POLICY_LAST_CHANGED_DATE}]. It may be changed and if it does, these
              changes will be posted on this page and, where appropriate, notified to you [by email
              or when you next open the Site]. The new Privacy Policy may be displayed on-screen and
              you may be required to read and accept the changes to continue with your use of the
              Site or the Services.`}
          </p>
          <p>
            It is important that the personal data we hold about you is accurate and current. Please
            keep us informed on any changes to your personal data during our relationship with you.
          </p>
        </>
      </TriggerOnEnter>

      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('third-party-links')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="third-party-links">
            Third party links
          </Title>
          <p>
            Our Site may, from time to time, contain links to and from the websites of our partner
            networks, advertisers and affiliates. Please note that these websites and any services
            that may be accessible through them have their own privacy policies and that we do not
            accept any responsibility or liability for these policies, or for any personal data that
            may be collected through these websites or services, such as your contact or location
            information.
          </p>
          <p>
            Please review the policies of these other websites before you submit any personal data
            to them or use their services.
          </p>
        </>
      </TriggerOnEnter>

      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('the-data-we-collect-about-you')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="the-data-we-collect-about-you"
          >
            The data we collect about you
          </Title>
          <p>
            We may collect, use, store and transfer different kinds of personal data about you as
            follows:
          </p>
          <ul className="content__list">
            <li>Identity Data</li>
            <li>Contact Data</li>
            <li>Financial Data</li>
            <li>Transaction Data</li>
            <li>Device Data</li>
            <li>Content Data</li>
            <li>Profile Data</li>
            <li>Usage Data</li>
            <li>Marketing and Communications Data</li>
            <li>Location Data </li>
          </ul>
          <p>
            We also collect, use and share aggregated data such as statistical or demographic data
            for any purpose. Aggregated data could be derived from your personal data however it is
            not considered personal data in law, as this data will not directly or indirectly reveal
            your identity. For example, we may aggregate your Usage Data to calculate the percentage
            of users accessing a specific Site feature. However, if we combine or connect aggregated
            data with your personal data so that it can directly or indirectly identify you, we
            treat the combined data as personal data, which will be used in accordance with this
            Privacy Policy.
          </p>
          <p>
            We do not collect any special categories of personal data about you (this includes
            details about your race or ethnicity, religious or philosophical beliefs, sexual
            orientation, political opinions, trade union membership, information about your health,
            and genetic and biometric data). We also do not collect any information about criminal
            convictions and offences.
          </p>
        </>
      </TriggerOnEnter>

      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('personal-data-collection')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="personal-data-collection"
          >
            How is your personal data collected?
          </Title>
          <p>We will collect and process the following data about you:</p>
          <p>
            <u>Information you give us.</u> This is information (including Identity, Contact,
            Financial, and Marketing and Communications Data) you consent to giving us about
            yourself by filling in forms on the Site, or by corresponding with us (for example, by
            email or chat). It includes information you provide when you register to use the Site,
            subscribe to any of our Services, site share data via social media functions, enter a
            competition, promotion or survey, and when you report a problem with the Site or any of
            our Services. If you contact us, we will keep a record of that correspondence.
          </p>
          <p>
            <u>Information we collect about you and your device.</u> Each time you visit our Site we
            will automatically collect personal data including Device, Content and Usage Data.We
            collect this data using cookies and other similar technologies.
          </p>
          <p>
            <u>Location Data.</u> We also use GPS technology and other location technologies to
            determine your current location. Some of our location-enabled Services require your
            personal data for the feature to work. If you wish to use this particular feature, you
            will be asked to consent to your data being used for this purpose. You can withdraw your
            consent at any time by disabling Location Data in yourDevice’s settings.
          </p>
          <p>
            <u>
              Information we receive from other sources including third parties and publicly
              available sources.
            </u>
            We will receive personal data about you from various third parties and public sources as
            set out below:
          </p>
          <ul className="content__list">
            <li>
              Device Data from the following parties:
              <ul>
                <li>analytics providers;</li>
                <li>advertising networks; and</li>
                <li>search information providers.</li>
              </ul>
            </li>
            <li>
              Contact, Financial and Transaction Data from providers of technical, payment and
              delivery services;
            </li>
            <li>Identity and Contact Data from data brokers or aggregators;</li>
            <li>Identity and Contact Data from publicly available sources; and</li>
            <li>Cookies</li>
          </ul>
          <p>
            We use/may use cookies and/or other tracking technologies to distinguish you from other
            users of the Site and to remember your preferences. This assists us in providing you
            with a good experience when you use the Site and also allows us to improve the Site. For
            detailed information on the cookies we use, the purposes for which we use them and how
            you can exercise your choices regarding our use of your cookies, see our&nbsp;
            <Link to="/cookie-policy">cookie policy</Link>.
          </p>
        </>
      </TriggerOnEnter>

      <TriggerOnEnter
        {...offset}
        onEnter={() => wayPointCallback('how-we-user-your-personal-data')}
      >
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="how-we-user-your-personal-data"
          >
            How we use your personal data
          </Title>
          <p>
            We will only use your personal data when the law allows us to do so. Most commonly we
            will use your personal data in the following circumstances:
          </p>
          <ul className="content__list">
            <li>Where you have consented before the processing of the data.</li>
            <li>
              Where we need to perform a contract we are about to enter or have entered with you
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party)and your
              interests and fundamental rights do not override those interests.
            </li>
            <li>Where we need to comply with a legal or regulatory obligation. </li>
          </ul>
          <p>
            Please see <b>Glossary: Lawful Basis</b> below to find out more about the types of
            lawful basis that we will rely on to process your personal data.
          </p>
          <p>
            We will only send you direct marketing communications by email or text if w e ave your
            consent. You have the right to withdraw that consent at any time by contacting us.
          </p>
        </>
      </TriggerOnEnter>
    </div>
    <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('your-personal-data')}>
      <>
        <div className="content-box">
          <Title className="content__title content__title--3" tagName="h3" id="your-personal-data">
            Purposes for which we will use your personal data
          </Title>
        </div>
        <PrivacyPolicyTable />
      </>
    </TriggerOnEnter>
    <div className="content-box">
      <TriggerOnEnter
        {...offset}
        onEnter={() => wayPointCallback('disclosures-of-your-personal-data')}
      >
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="disclosures-of-your-personal-data"
          >
            Disclosures of your personal data
          </Title>
          <p>
            When you consent to providing us with your personal data, we will also ask you to
            provide your consent to share your personal data with the third parties set out below
            for the purposes set out in the above data.
          </p>
          <ul className="content__list">
            <li>
              Internal Third Parties as set out in the <b>Third parties</b> section below.
            </li>
            <li>
              External Third Parties as set out in the <b>Third parties</b> section below.
            </li>
            <li>
              Third parties to whom we may choose to sell, transfer or merge parts of our business
              or our assets with. Alternatively, we may seek to acquire other businesses or merge
              with them. If a change happens to our business, then the new owners may use your
              personal data in the same way as set out in this Privacy Policy.
            </li>
          </ul>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('international-transfers')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="international-transfers"
          >
            International transfers
          </Title>
          <p>
            Whenever we transfer your personal data out of Jordan, we ensure a similar degree of
            protection is afforded to it by ensuring at least one of the following safeguards is
            implemented:
          </p>
          <ul className="content__list">
            <li>
              We will only transfer your personal data to countries that have been deemed to provide
              an adequate level of protection for personal data.
            </li>
            <li>
              Where we use certain service providers, we may use specific contracts which provide an
              adequate level of protection for personal data.
            </li>
            <li>
              Please contact us if you want further information regarding transferring your personal
              data out of Jordan.
            </li>
          </ul>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('indemnification')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="indemnification">
            Indemnification
          </Title>
          <p>
            You agree to indemnify and hold us and our affiliates, officers, directors, employees,
            agents, suppliers or licensors harmless from and against any liabilities, losses,
            damages or costs, including reasonable attorneys&apos; fees, incurred in connection with
            or arising from any third-party claims, allegations, disputes, actions or demands
            asserted against any of them as a result of or relating to your content, data, your use
            of our Site or services or any wilful misconduct on your part.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('data-security')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="data-security">
            Data security
          </Title>
          <p>
            All information you provide to us is stored on our secure servers. Any payment
            transactions carried out by us or our chosen third-party provider of payment processing
            services will be encrypted using [Secured Sockets Layer technology OR ALTERNATIVE
            ENCRYPTION TECHNOLOGY]. Where we have given you (or where you have chosen) a password
            that enables you to access certain parts of Site, you are responsible for keeping this
            password confidential. We ask you not to share your password with anyone.
          </p>
          <p>
            Once we have received your information, we will use strict procedures and security
            features to try to prevent your personal data from being accidentally lost, used or
            accessed in an unauthorized way.
          </p>
          <p>
            We will collect and store personal data on your Device using [application data caches
            and browser web storage (including HTML5)].
          </p>
          <p>
            Certain Services include social networking, chat room or forum features. Ensure when
            using these features that you do not submit any personal data that you do not want to be
            seen, collected or used by other users.
          </p>
        </>
      </TriggerOnEnter>

      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('data-retention')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="data-retention">
            Data retention
          </Title>
          <p>
            We will only retain your personal data for as long as reasonably necessary to fulfil the
            purposes we collected it for, including for the purposes of satisfying any legal,
            regulatory, tax, accounting or reporting requirements. We may retain your personal data
            for a longer period in the event of a complaint or if we reasonably believe there is a
            prospect of litigation in respect of our relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we consider the amount,
            nature and sensitivity of the personal data, the potential risk of harm from
            unauthorized use or disclosure of your personal data, the purposes for which we process
            your personal data and whether we can achieve those purposes through other means, and
            the applicable legal, regulatory, tax, accounting or other requirements.
          </p>
          <p>
            In some circumstances, you can ask us to delete your data, see the&nbsp;
            <b>Your legal rights</b>&nbsp;section below for further information.
          </p>
          <p>
            In some circumstances, we will anonymize your personal data (so that it can no longer be
            associated with you) for research or statistical purposes, in which case we may use this
            information indefinitely without further notice to you.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('your-legal-rights')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="your-legal-rights">
            Your legal rights
          </Title>
          <p>
            Under certain circumstances you have the following rights under data protection laws in
            relation to your personal data:
          </p>
          <p>
            <u>
              Request access to your personal data (commonly known as a “data subject access
              request”).
            </u>
            &nbsp; This enables you to receive a copy of the personal data we hold about you and to
            check that we are lawfully processing it.
          </p>
          <p>
            <u>Request correction of the personal data that we hold about you.</u> This enables you
            to have any incomplete or inaccurate data we hold about you corrected, though we may
            need to verify the accuracy of the new data you provide to us.
          </p>
          <p>
            <u>Request erasure of your personal data.</u> This enables you to ask us to delete or
            remove personal data where there is no good reason for us continuing to process it. You
            also have the right to ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see below), where we may have
            processed your information unlawfully or where we are required to erase your personal
            data to comply with local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will be notified to you,
            if applicable, at the time of your request.
          </p>
          <p>
            <u>Object to processing of your personal data</u>, where we are relying on a legitimate
            interest (or those of a third party) and there is something about your particular
            situation which makes you want to object to processing on this ground as you feel it
            impacts on your fundamental rights and freedoms. You also have the right to object where
            we are processing your personal data for direct marketing purposes. In some cases, we
            may demonstrate that we have compelling legitimate grounds to process your information
            which override your rights and freedoms.
          </p>
          <p>
            <u>Request restriction of processing of your personal data.</u> This enables you to ask
            us to suspend the processing of your personal data in the following scenarios:
          </p>
          <ul className="content__list">
            <li>if you want us to establish the data&apos;s accuracy;</li>
            <li>where our use of the data is unlawful but you do not want us to erase it;</li>
            <li>
              where you need us to hold the data even if we no longer require it as you need it to
              establish, exercise or defend legal claims; or
            </li>
            <li>
              you have objected to our use of your data but we need to verify whether we have
              overriding legitimate grounds to use it.
            </li>
          </ul>
          <p>
            <u>Request the transfer of your personal data to you or to a third party.</u> We will
            provide to you, or a third party you have chosen, your personal data in a structured,
            commonly used, machine-readable format. Note that this right only applies to automated
            information which you initially provided consent for us to use or where we used the
            information to perform a contract with you.
          </p>
          <p>
            <u>
              Withdraw consent at any time where we are relying on consent to process your personal
              data.
            </u>
            &nbsp; However, this will not affect the lawfulness of any processing carried out before
            you withdraw your consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is the case at the time
            you withdraw your consent.
          </p>
          <p>
            You also have the right to ask us not to continue to process your personal data for
            marketing purposes.
          </p>
          <p>
            You can exercise any of these rights at any time by contacting us at [
            <a href="mailto:hello@finto.io">hello@finto.io</a>].
          </p>
          <p>
            <b>Glossary: Lawful Basis</b>
          </p>
          <p>
            <b>“Consent”</b> means processing your personal data where you have signified your
            agreement by a statement or clear opt-in to processing for a specific purpose. Consent
            will only be valid if it is a freely given, specific, informed and unambiguous
            indication of what you want. You can withdraw your consent at any time by contacting us.
          </p>
          <p>
            <b>“Legitimate Interest”</b> means the interest of our business in conducting and
            managing our business to enable us to give you the best service/product and the best and
            most secure experience. We make sure we consider and balance any potential impact on you
            (both positive and negative) and your rights before we process your personal data for
            our legitimate interests. We do not use your personal data for activities where our
            interests are overridden by the impact on you (unless we have your consent or are
            otherwise required or permitted to by law). You can obtain further information about how
            we assess our legitimate interests against any potential impact on you in respect of
            specific activities by contacting us.
          </p>
          <p>
            <b>“Performance of Contract”</b> means processing your data where it is necessary for
            the performance of a contract to which you are a party or to take steps at your request
            before entering into such a contract.
          </p>
          <p>
            <b>“Comply”</b> with a legal obligation means processing your personal data where it is
            necessary for compliance with a legal obligation that we are subject to.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('personal-information')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="personal-information"
          >
            How we may use your personal information
          </Title>
          <p>
            We will only use your personal information as set out in our&nbsp;
            <Link to="/privacy-policy">privacy policy</Link>.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('third-parties')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="third-parties">
            Third parties
          </Title>
          <p>
            <u>Internal third partiess</u>
          </p>
          <p>
            Other companies in the Finto group acting as joint controllers or processors, providing
            IT and system administration services and undertaking leadership reporting.
          </p>
          <p>
            <u>External third parties</u>
          </p>
          <p>
            Service providers acting as processors who provide IT and system administration
            services.
          </p>
          <p>
            Professional advisers acting as processors or joint controllers including lawyers,
            bankers, auditors and insurers who provide consultancy, banking, legal, insurance and
            accounting services.
          </p>
          <p>
            <u>Regulators and other authorities </u>
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('not-responsible-for-viruses')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="not-responsible-for-viruses"
          >
            Description of categories of personal data
          </Title>
          <p>
            Identity Data: first name, last name, maiden name, username or similar identifier,
            marital status, title, date of birth, gender.
          </p>
          <p>
            Contact Data: billing address, delivery address, email address and telephone numbers.
          </p>
          <p>Financial Data: bank account and payment card details.</p>
          <p>
            Transaction Data: includes details about payments to and from you and details of in-Site
            purchases.
          </p>
          <p>
            Device Data: includes the type of mobile device you use, a unique device identifier,
            mobile network information, your mobile operating system, the type of mobile browser you
            use, time zone setting.
          </p>
          <p>
            Content Data: includes information stored on your Device, including login information,
            digital content.
          </p>
          <p>
            Profile Data: includes your username and password, in-Site purchase history, your
            interests, preferences, feedback and survey responses.
          </p>
          <p>
            Usage Data: includes details of your use of any of our applications or your visits to
            any of our Sites including, but not limited to, traffic data and other communication
            data, whether this is required for our own billing purposes or otherwise and the
            resources that you access.
          </p>
          <p>
            Marketing and Communications Data: includes your preferences in receiving marketing from
            us and our third parties and your communication preferences.
          </p>
          <p>
            Location Data: includes your current location disclosed by GPS technology or other
            location technologies.
          </p>
        </>
      </TriggerOnEnter>
    </div>
  </div>
);
function PrivacyPolicy() {
  return <TermsPrivacy title="Privacy policy" Content={Content} />;
}
export default PrivacyPolicy;

Content.propTypes = {
  wayPointCallback: func,
  offset: object,
  className: string,
};

Content.defaultProps = {
  wayPointCallback: () => {},
  offset: {
    topOffset: '0',
    bottomOffset: '0',
  },
};
