import React from 'react';
import { Link } from 'react-router-dom';
import { Epilog } from 'components';
import { Button } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './JobsEpilog.scss';

const CoverageFeaturesEpilog = () => (
  <Epilog
    className="jobs-epilog"
    title="Ready to apply?"
    description="Have a look at our hiring process at Finto before you apply."
    variant="white"
  >
    <div className="jobs-life__button-wrap">
      <Button
        className="jobs-life__button button--with-icon"
        to="/careers/hiring"
        buttontype="gradient-hoverable"
        color="text-white"
        size="medium"
        tagName={Link}
        isCentered
      >
        <div className="button__inner">
          <span>hiring process</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </Button>
    </div>
  </Epilog>
);
export default CoverageFeaturesEpilog;
