import React from 'react';
import cx from 'classnames';
import { node, string } from 'prop-types';
import './Form.scss';

const FormCol = ({ children, grid, ...rest }) => (
  <div
    className={cx('form__col', {
      [`form__col--${grid}`]: !!grid,
    })}
    {...rest}
  >
    {children}
  </div>
);

FormCol.defaultProps = {
  children: null,
  grid: 'grid-1',
};

FormCol.propTypes = {
  children: node,
  grid: string,
};

export default FormCol;
