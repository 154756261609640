export default {
  about: {
    title: 'About Us | Finto',
    description:
      "We're a group of creative technologists, developers, engineers & problem solvers dedicated to creating unique products that will transform & impact your business. Learn more.",
  },
  baap: {
    title: 'Banking as a Platform (Baap) | Finto',
    description:
      'Use Finto’s API banking platform to seamlessly integrate financial services into your digital products, access financial data, and generate new revenue streams.',
  },
  baas: {
    title: 'Banking As a Service (Baas) | Finto',
    description:
      'Create digital banking products or embed financial services into your customer experience at extraordinary speed with Finto’s fully licensed Banking as a Service solutions.',
  },
  cronica: {
    title: 'Blockchain-based document authentication platform',
    description:
      'Cronica is a blockchain-based document authentication platform for banks: a secure and scalable solution integrable into existing infrastructure',
  },
  'get-started': {
    title: 'Getting Started - Create An Account | Finto',
    description:
      'Create an account to get instant access to Finto’s APIs, SDKs and developer resources. Get started with Finto now.',
  },
  home: {
    title: 'Comprehensive digital banking infrastructure',
    description:
      'With Finto you can deploy innovative digital banking products or embed financial services into your customer experience at extraordinary speed. Get started today',
  },
  paas: {
    title: 'Payments As A Service (Paas) | Finto',
    description:
      'Create a seamless payment experience for your customers with Finto’s white label debit, prepaid, virtual and loyalty cards. Get started today.',
  },
  developers: {
    title: 'Developers | Finto',
    description:
      'Powerful products need powerful tools. Create a developer account to get immediate access to our API and start building the future of finance.',
  },
  contact: {
    title: 'Contact Us | Finto',
    description:
      'Our sales team are always on hand to answer any questions you might have, simply fill out the form and we will be in touch as soon as possible.',
  },
  jobs: {
    title: 'Careers - Jobs & Vacancies | Finto',
    description:
      'We are always on the lookout for talented people to join us, check out our jobs board to see our open positions.  Apply to join our team today.',
  },
  hiring: {
    title: "We're Hiring | Finto",
    description:
      'See what our hiring process looks like, read about our company culture and see if there’s a perfect job opening for you. Apply today',
  },
  culture: {
    title: 'Our Culture | Finto',
    description:
      'We approach problems from fresh perspectives, creating unique products that will have a transformative impact on the future of finance. Learn more about our culture.',
  },
  schedule_demo: {
    title: 'Schedule a Demo | Finto',
    description:
      'Schedule a demo to have a member of the Finto team give you an in depth walkthrough of our products and services.',
  },
  coverage: {
    title: 'Our global coverage | Finto',
    description:
      'We are constantly expanding our coverage and capabilities across the globe, sign up to get notified when we launch new features and in new regions.',
  },
  request_access: {
    title: 'Request access to our platform | Finto',
    description:
      'Request access to our platform. Simply fill out the form on this page and we will be in touch as soon as possible. Get in touch today.',
  },
  enterprise: {
    title: 'Digital Banking Enterprise Solutions | Finto',
    description:
      'Interested in our enterprise solutions? Our team are always on hand to help with any queries you might have.  Get in touch today.',
  },
  terms: {
    title: 'Terms and Conditions',
    description: 'Terms and Conditions',
  },
  legals: {
    title: 'Legal statements',
    description: 'Legal statements',
  },
  privacy: {
    title: 'Privacy & policy',
    description: 'Privacy & policy',
  },
  cookies: {
    title: 'Cookies policy',
    description: 'Cookies policy',
  },
  missing: {
    title: 'Finto',
    description: 'Finto',
  },
};
