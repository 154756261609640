/* eslint-disable no-shadow, react-hooks/exhaustive-deps */
import React from 'react';
import { Form } from 'react-final-form';
import memoize from 'lru-memoize';
import { useForm } from '@formspree/react';
import { Button, Input, Title, Description } from 'elements';
import { createValidator, email as emailValidation, required } from 'utils/validation';
import FormWrapper from '../FormWrapper/FormWrapper';
import './FeaturesForm.scss';

const validate = memoize(10)(
  createValidator({
    firstName: [required],
    secondName: [required],
    message: [required],
    email: [required, emailValidation],
  })
);

const FeaturesForm = () => {
  const [submittingState, submit] = useForm('mzbyerpy');

  return (
    <FormWrapper {...submittingState} variant="black">
      <div className="features-form">
        <Title
          tagName="h3"
          alignText="text-center"
          sizeText="size-2"
          className="features-form__title"
        >
          Request new features
        </Title>
        <Description
          alignText="text-center"
          sizeText="size-2"
          className="features-form__description"
        >
          We’re always improving our products but if there are any features you’d like us to
          implement let us know and we’ll add it to our roadmap.
        </Description>
        <Form
          onSubmit={submit}
          validate={validate}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <div className="features-form__row features-form__row--double-col">
                <Input variant="third" name="firstName" label="First name" />
                <Input variant="third" name="secondName" label="second name" />
              </div>
              <div className="features-form__row">
                <Input variant="third" name="email" label="Email address" />
              </div>
              <div className="features-form__row">
                <Input variant="third" name="message" NodeType="textarea" label="COMMENT FIELD" />
              </div>
              <Button
                disabled={submitting || pristine}
                className="feature-forms__submit-button"
                type="submit"
                size="medium"
                display="block"
                onClick={handleSubmit}
                buttontype="hoverable"
                isCentered
              >
                <div className="button__inner">
                  <span>submit</span>
                </div>
              </Button>
            </>
          )}
        />
      </div>
    </FormWrapper>
  );
};

export default FeaturesForm;
