/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { string } from 'prop-types';
import { ContentSwitcher } from 'elements';
import cx from 'classnames';
import { calculateTableColumnHeight } from './helpers';
import './NesterTableSlider.scss';

SwiperCore.use([Pagination]);

export default function NestedTableSlider({ className }) {
  return (
    <div className={cx('nested__container', { [className]: !!className })}>
      <ContentSwitcher mobileContent={<TableSlider />} desktopContent={<Table />} />
    </div>
  );
}

NestedTableSlider.propTypes = {
  className: string,
};

const Table = () => (
  <div className="nested__table table">
    <div className="table__title">
      <p>Purpose / Activity</p>
      <p>Type of data</p>
      <p>Lawful basis for processing</p>
    </div>
    <div className="table__box">
      <div className="table__row">
        <div className="table__col">
          <p>To register you as a new Site user </p>
        </div>
        <div className="table__col">
          <ul>
            <li>Identity</li>
            <li>Contact</li>
            <li>Device</li>
          </ul>
        </div>
        <div className="table__col">
          <p>Your consent </p>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <p>
            To process in-Site purchases and deliver Services including managing payments and
            collecting payments
          </p>
        </div>
        <div className="table__col">
          <ul>
            <li>Identity</li>
            <li>Contact</li>
            <li>Financial</li>
            <li>Transaction</li>
            <li>Device</li>
            <li>Marketing and Communications</li>
            <li>Location</li>
          </ul>
        </div>
        <div className="table__col">
          <ul>
            <li>Your consent</li>
            <li>Performance of a contract with you</li>
            <li>Necessary for our legitimate interests (to recover payments due to us)</li>
          </ul>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <p>
            To manage our relationship with you including notifying you of changes to the Site or
            any Services
          </p>
        </div>
        <div className="table__col">
          <ul>
            <li>Identity</li>
            <li>Contact</li>
            <li>Financial</li>
            <li>Profile</li>
            <li>Marketing and Communications</li>
          </ul>
        </div>
        <div className="table__col">
          <ul>
            <li>Your consent</li>
            <li>Performance of a contract with you</li>
            <li>
              Necessary for our legitimate interests (to keep records updated and to analyze how
              customers use our Services)
            </li>
            <li>
              Necessary to comply with legal obligations (to inform you of any changes to our Terms
              of Use, this Privacy Policy, etc.)
            </li>
          </ul>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <p>To enable you to participate in a prize draw, competition or complete a survey </p>
        </div>
        <div className="table__col">
          <ul>
            <li>Identity</li>
            <li>Contact</li>
            <li>Device</li>
            <li>Profile</li>
            <li>Marketing and Communications</li>
          </ul>
        </div>
        <div className="table__col">
          <ul>
            <li>Your consent</li>
            <li>Performance of a contract with you</li>
            <li>
              Necessary for our legitimate interests (to analyze how customers use our Services, to
              further develop such Services, and to grow our business)
            </li>
          </ul>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <p>
            To administer and protect our business and this Site including troubleshooting, data
            analysis and system testing
          </p>
        </div>
        <div className="table__col">
          <ul>
            <li>Identity</li>
            <li>Contact</li>
            <li>Device</li>
          </ul>
        </div>
        <div className="table__col">
          <p>
            Necessary for our legitimate interests (for running our business, provision of
            administration and IT services, and network security)
          </p>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <ul>
            <li>To deliver content and advertisements to you</li>
            <li>
              To make recommendations to you about products or services which may interest you
            </li>
            <li>To measure and analyze the effectiveness of the advertising we serve you</li>
            <li>To monitor trends so we can improve the Site</li>
          </ul>
        </div>
        <div className="table__col">
          <ul>
            <li>Identity</li>
            <li>Contact</li>
            <li>Device</li>
            <li>Content</li>
            <li>Profile</li>
            <li>Usage</li>
            <li>Marketing and Communications</li>
            <li>Location</li>
          </ul>
        </div>
        <div className="table__col">
          <ul>
            <li>Your consent</li>
            <li>Performance of a contract with you</li>
            <li>
              Necessary for our legitimate interests (to further develop our Services and to grow
              our business)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

const TableSlider = () => {
  const calculate = () => calculateTableColumnHeight('.nested__slider');
  useEffect(() => {
    calculate();
    document.addEventListener('resize', calculate);
    return () => {
      document.removeEventListener('resize', calculate);
    };
  }, []);

  return (
    <Swiper className="nested__slider" slidesPerView="auto" pagination={{ type: 'progressbar' }}>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose / Activity</span>
            <p>To register you as a new Site user </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Type of data</span>
            <ul>
              <li>Identity</li>
              <li>Contact</li>
              <li>Device</li>
            </ul>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Lawful basis for processing</span>
            <p>Your consent </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose / Activity</span>
            <p>
              To process in-Site purchases and deliver Services including managing payments and
              collecting payments
            </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Type of data</span>
            <ul>
              <li>Identity</li>
              <li>Contact</li>
              <li>Financial</li>
              <li>Transaction</li>
              <li>Device</li>
              <li>Marketing and Communications</li>
              <li>Location</li>
            </ul>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Lawful basis for processing</span>
            <ul>
              <li>Your consent</li>
              <li>Performance of a contract with you</li>
              <li>Necessary for our legitimate interests (to recover payments due to us)</li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose / Activity</span>
            <p>To enable you to participate in a prize draw, competition or complete a survey</p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Type of data</span>
            <ul>
              <li>Identity</li>
              <li>Contact</li>
              <li>Financial</li>
              <li>Profile</li>
              <li>Marketing and Communications</li>
            </ul>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Lawful basis for processing</span>
            <ul>
              <li>Your consent</li>
              <li>Performance of a contract with you</li>
              <li>
                Necessary for our legitimate interests (to analyze how customers use our Services,
                to further develop such Services, and to grow our business)
              </li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose / Activity</span>
            <p>
              To administer and protect our business and this Site including troubleshooting, data
              analysis and system testing
            </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Type of data</span>
            <ul>
              <li>Identity</li>
              <li>Contact</li>
              <li>Device</li>
            </ul>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Lawful basis for processing</span>
            <p>
              Necessary for our legitimate interests (for running our business, provision of
              administration and IT services, and network security)
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose / Activity</span>
            <ul>
              <li>To deliver content and advertisements to you</li>
              <li>
                To make recommendations to you about products or services which may interest you
              </li>
              <li>To measure and analyze the effectiveness of the advertising we serve you</li>
              <li>To monitor trends so we can improve the Site</li>
            </ul>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Type of data</span>
            <ul>
              <li>Identity</li>
              <li>Contact</li>
              <li>Device</li>
              <li>Content</li>
              <li>Profile</li>
              <li>Usage</li>
              <li>Marketing and Communications</li>
              <li>Location</li>
            </ul>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Lawful basis for processing</span>
            <ul>
              <li>Your consent</li>
              <li>
                Necessary for our legitimate interests (to further develop our Services and to grow
                our business
              </li>
            </ul>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
