/* eslint-disable no-shadow, react-hooks/exhaustive-deps */
import React from 'react';
import { Form } from 'react-final-form';
import memoize from 'lru-memoize';
import { useForm } from '@formspree/react';
import { Button, Input, Title, Description, FormSelect } from 'elements';
import { createValidator, email as emailValidation, required, phone } from 'utils/validation';
import FormWrapper from '../FormWrapper/FormWrapper';
import { COUNTRIES, ACTIVE_USERS } from '../../../data/selects_data';
import './EnterpriseForm.scss';

const validate = memoize(10)(
  createValidator({
    firstName: [required],
    lastName: [required],
    message: [required],
    phone: [required, phone],
    email: [required, emailValidation],
    country: [required],
    website: [],
    activeUsers: [],
  })
);

const EnterpriseForm = () => {
  const [submittingState, submit] = useForm('xayabdpz');

  return (
    <FormWrapper
      className="enterprise-form__wrapper"
      scrollXPos={0}
      iconColor="orange"
      {...submittingState}
    >
      <div className="enterprise-form">
        <Title
          tagName="h1"
          className="enterprise-form__title"
          alignText="text-center"
          sizeText="size-3"
        >
          Enterprise solutions
        </Title>
        <Description
          className="enterprise-form__description"
          alignText="text-center"
          sizeText="size-2"
        >
          Our team are always on hand to help with any queries you might have, simply fill out the
          form and we will be in touch as soon as possible.
        </Description>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            country: 'jordan',
          }}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <div className="enterprise-form__row enterprise-form__row--double-col">
                <Input variant="fourth" name="firstName" label="First name" />
                <Input variant="fourth" name="lastName" label="Last name" />
              </div>
              <div className="enterprise-form__row">
                <Input variant="fourth" name="email" label="work email" />
              </div>
              <div className="enterprise-form__row">
                <Input variant="fourth" name="phone" label="work phone" />
              </div>
              <div className="enterprise-form__row">
                <Input variant="fourth" name="website" label="Company website" />
              </div>
              <div className="enterprise-form__row">
                <FormSelect
                  options={ACTIVE_USERS}
                  variant="secondary"
                  name="activeUsers"
                  label="Active users"
                  placeholder="Select monthly amount"
                />
              </div>
              <div className="enterprise-form__row">
                <FormSelect
                  options={COUNTRIES}
                  variant="secondary"
                  name="country"
                  label="Country"
                />
              </div>
              <div className="enterprise-form__row">
                <Input variant="fourth" name="message" NodeType="textarea" label="message" />
              </div>
              <Button
                disabled={submitting || pristine}
                className="enterprise-form__submit-button button button-with-icon"
                type="submit"
                size="medium"
                display="block"
                onClick={handleSubmit}
                buttontype="gradient-hoverable"
                isCentered
              >
                <div className="button__inner">
                  <span>submit</span>
                </div>
              </Button>
            </>
          )}
        />
      </div>
    </FormWrapper>
  );
};

export default EnterpriseForm;
