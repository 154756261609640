/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, cloneElement, useEffect, isValidElement, useCallback } from 'react';
import {
  oneOfType,
  func,
  elementType,
  string,
  bool,
  node,
  element,
  number,
  oneOf,
} from 'prop-types';
import cn from 'classnames';
import { Waypoint } from 'react-waypoint';

function isDOMTypeElement(el) {
  return isValidElement(el) && typeof el.type === 'string';
}

function TriggerOnEnter(props) {
  const {
    children,
    WrapperNode,
    wrapperClassName,
    enteredClassName: enteredClassNameProp,
    triggerOnce,
    bypass,
    onEnter,
    onLeave,
    ...rest
  } = props;
  const [isEntered, setEntered] = useState(false);
  const enteredClassName = isEntered ? enteredClassNameProp : undefined;
  let innerContent;

  // Waypoint uses PureComponent so useCallback is used
  const handleOnEnter = useCallback((...args) => {
    if (!isEntered) {
      setEntered(true);
    }
    onEnter?.(...args);
  }, []);
  const handleOnLeave = useCallback((...args) => {
    if (!triggerOnce) {
      setEntered(false);
    }
    onLeave?.(...args);
  }, []);

  // clear entered
  // if bypass has been set to true at some point
  useEffect(() => {
    if (bypass) {
      setEntered(false);
    }
  }, [bypass]);

  if (isDOMTypeElement(children)) {
    innerContent = cloneElement(children, {
      className: cn(children.props.className, enteredClassName),
    });
  } else {
    const passedProps = {
      isEntered,
      enteredClassName,
    };
    const _children =
      typeof children === 'function'
        ? children(passedProps)
        : // only SINGLE child is supported by Waypoint
          cloneElement(children, passedProps);

    // additional wrapper is added to avoid ref forwarding.
    // Waypoint needs a DOM node to compute its boundaries.
    // WrapperNode can be disabled when DOM node is passed
    // or when child component has forwardRef.
    // https://github.com/civiccc/react-waypoint#children
    innerContent = WrapperNode ? (
      <WrapperNode className={cn('waypoint-wrapper', wrapperClassName)}>{_children}</WrapperNode>
    ) : (
      _children
    );
  }

  if (bypass) {
    return innerContent;
  }

  return (
    <Waypoint {...rest} onEnter={handleOnEnter} onLeave={handleOnLeave}>
      {innerContent}
    </Waypoint>
  );
}

TriggerOnEnter.propTypes = {
  children: oneOfType([func, element, elementType]).isRequired,
  enteredClassName: string,
  WrapperNode: node,
  wrapperClassName: string,
  triggerOnce: bool,
  bypass: bool,
  topOffset: oneOfType([string, number]),
  bottomOffset: oneOfType([string, number]),
  scrollableAncestor: oneOf(['window', element]),
  onEnter: func,
  onLeave: func,
};

TriggerOnEnter.defaultProps = {
  WrapperNode: 'div',
  enteredClassName: 'is-entered',
  triggerOnce: true,
  // should typically be a reference to a DOM node, but it will also work
  // to pass it the string "window" for the SSR.
  scrollableAncestor: 'window',
  bottomOffset: '10px',
};

export default TriggerOnEnter;
