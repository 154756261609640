import React from 'react';
import { oneOf } from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Container,
  Title,
  Description,
  Button,
  Tag,
  ContentSwitcher,
  ProgressSlider,
  JobCard,
} from 'elements';
import { JobsTable } from 'components';
import cx from 'classnames';
import ArrowSvg from 'images/icons/arrowTop.svg';
import JOBS from '../../../../data/data';
import './HiringRole.scss';

const data = JOBS.slice(0, 8);

const HiringRole = ({ variant }) => (
  <div className={cx(`hiring-role--${variant}`)}>
    <section className="hiring-role__section">
      <Container className="hiring-role__container">
        <div className="hiring-role__left-side">
          <Tag className="hiring-role__tag" align="left" color="purple" lineColor="purple">
            Current opportunities
          </Tag>
          <Title
            tagName="h2"
            sizeText="size-2"
            alignText="text-left"
            className="hiring-role__title"
          >
            Find your role at Finto
          </Title>
          <Description sizeText="size-2" alignText="text-left" className="hiring-role__description">
            We currently have <strong>{JOBS.length} roles</strong> open, have a look and see if
            there’s a position that’s perfect for you.
          </Description>
          <div className="hiring-role__button-wrap">
            <Button
              to="/careers/jobs"
              tagName={Link}
              className="hiring-role__button button--with-icon"
              buttontype="hoverable"
              background="white"
              border="border-white"
              color="text-white"
              size="medium"
              isCentered
            >
              <div className="button__inner">
                <span>See all jobs</span>
                <div className="button__icons">
                  <ArrowSvg className="button__icon" />
                  <ArrowSvg className="button__icon button__icon--next" />
                </div>
              </div>
            </Button>
          </div>
        </div>
        <ContentSwitcher
          mobileContent={
            <ProgressSlider className="hiring-role__slider">
              {data.map((c, index) => (
                <JobCard
                  variant={variant}
                  className="hiring-role__job-card"
                  key={index.toString()}
                  {...c}
                />
              ))}
            </ProgressSlider>
          }
          desktopContent={
            <JobsTable className="hiring-role__table" variant={variant} data={data} />
          }
        />
      </Container>
    </section>
  </div>
);

HiringRole.propTypes = {
  variant: oneOf(['black', 'white']),
};

HiringRole.defaultProps = {
  variant: 'white',
};

export default HiringRole;
