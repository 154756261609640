import React from 'react';
import { array, oneOf, string } from 'prop-types';
import cx from 'classnames';
import './JobsTable.scss';

const JobsTable = ({ data, variant, className }) => (
  <>
    <div className={cx('jobs-table', `jobs-table--${variant}`, { [className]: !!className })}>
      <div className="jobs-table__head">
        <div className="jobs-table__row">
          <div className="jobs-table__cell">ROLE</div>
          <div className="jobs-table__cell">TEAM</div>
          <div className="jobs-table__cell">CONTRACT TYPE</div>
          <div className="jobs-table__cell">Applications close</div>
        </div>
      </div>
      <div className="jobs-table__body">
        {data.map((c, index) => (
          <div key={index.toString()} className="jobs-table__row">
            <div className="jobs-table__cell">
              {c.role}
              <span>{c.location}</span>
            </div>
            <div className="jobs-table__cell">{c.team}</div>
            <div className="jobs-table__cell">{c.contract_type}</div>
            <div className="jobs-table__cell">{c.date}</div>
          </div>
        ))}
      </div>
    </div>
  </>
);

JobsTable.propTypes = {
  data: array,
  variant: oneOf(['white', 'black']),
  className: string,
};

JobsTable.defaultProps = {
  variant: 'white',
};

export default JobsTable;
