import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg
    width="175"
    height="174"
    viewBox="0 0 175 174"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M174.587 87.0002C174.587 135.038 135.644 173.98 87.6064 173.98C39.5686 173.98 0.626221 135.038 0.626221 87.0002C0.626221 38.9624 39.5686 0.0200195 87.6064 0.0200195C135.644 0.0200195 174.587 38.9624 174.587 87.0002Z"
      fill="#1E2132"
    />
    <path
      d="M57.1631 87.0002C57.1631 70.2642 70.8702 56.5571 87.6061 56.5571C91.8587 56.5571 96.1101 57.4683 100.057 59.2901C101.753 60.073 103.763 59.3325 104.546 57.6364C105.329 55.9402 104.588 53.9305 102.892 53.1476C98.0446 50.9103 92.8248 49.792 87.6061 49.792C67.1339 49.792 50.3979 66.528 50.3979 87.0002C50.3979 107.472 67.1339 124.208 87.6061 124.208C108.078 124.208 124.814 107.472 124.814 87.0002V83.9559C124.814 82.0877 123.3 80.5733 121.432 80.5733C119.564 80.5733 118.049 82.0877 118.049 83.9559V87.0002C118.049 103.736 104.342 117.443 87.6061 117.443C70.8702 117.443 57.1631 103.736 57.1631 87.0002Z"
      fill="url(#standart_1_linear_1_2761)"
    />
    <path
      d="M123.824 62.3315C125.145 61.0105 125.145 58.8688 123.824 57.5478C122.503 56.2269 120.361 56.2269 119.04 57.5478L87.6061 88.9817L79.8503 81.2258C78.5293 79.9048 76.3876 79.9048 75.0666 81.2258C73.7457 82.5468 73.7457 84.6885 75.0666 86.0095L85.2143 96.1572C86.5353 97.4781 88.677 97.4781 89.998 96.1572L123.824 62.3315Z"
      fill="url(#standart_1_linear_1_2761)"
    />
    <defs>
      <linearGradient
        id="standart_1_linear_1_2761"
        x1="88.1968"
        y1="22.6241"
        x2="87.6061"
        y2="124.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F165B0" />
        <stop offset="1" stopColor="#FF503C" />
      </linearGradient>
      <linearGradient
        id="standart_1_linear_1_2761"
        x1="88.1968"
        y1="22.6241"
        x2="87.6061"
        y2="124.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F165B0" />
        <stop offset="1" stopColor="#FF503C" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;
