import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Link } from 'react-router-dom';
import { Container, Title, Button } from 'elements';
import HouseSvg from 'images/icons/house.svg';
import NotFoundSvg from 'images/icons/not-found.svg';
import { setFlag } from '../../redux/modules/flags/flags';
import './NotFound.scss';

const NotFound = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFlag('isNotFound', true));
    return () => {
      dispatch(setFlag('isNotFound', false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = 404; // eslint-disable-line
        }
        return (
          <section className="not-found__section">
            <Container className="not-found__container">
              <Title className="not-found__title" tagName="h1" sizeText="size-3" color="vulcan">
                Oops!
              </Title>
              <NotFoundSvg className="not-found__icon-miss" />
              <Title className="not-found__sub-title" sizeText="size-7" tagName="h2" color="vulcan">
                404: The page was not found.
              </Title>
              <span className="not-found__description">Visit our homepage to start over</span>

              <Button
                background="dark"
                border="border-dark"
                color="text-white"
                size="regular"
                className="not-found__button button--with-icon button--icon-left"
                to="/"
                tagName={Link}
                buttontype="hoverable"
                isCentered
              >
                <div className="button__inner">
                  <div className="button__icons">
                    <HouseSvg className="button__icon" />
                    <HouseSvg className="button__icon button__icon--next" />
                  </div>
                </div>
                <span>Homepage</span>
              </Button>
            </Container>
          </section>
        );
      }}
    />
  );
};

export default NotFound;
