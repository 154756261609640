import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import ReactSelect, { components } from 'react-select';
import { func, object, oneOf, string, array, bool, number } from 'prop-types';
import GroupedValueContainer from './Components/GroupedValueContainer';
import CustomOption from './Components/CustomOption';
import './Select.scss';

function FormSelect(props) {
  const {
    variant,
    input,
    meta: { error, touched },
    label,
    type,
    onFocus,
    onBlur,
    onChange,
    className,
    selectClassName,
    options,
    isMulti,
    isClearable,
    isSearchable,
    closeMenuOnSelect,
    hideSelectedOptions,
    showSelectedCountAfter,
    isCustomOption,
    ...rest
  } = props;
  const focusHandler = (e) => {
    input.onFocus(e);
    if (onFocus) {
      onFocus(e);
    }
  };

  const blurHandler = (e) => {
    input.onBlur(e);
    if (onBlur) {
      onBlur(e);
    }
  };

  const changeHandler = (data) => {
    input.onChange(data);
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <div className={cx('form-select', `form-select--${variant}`, className)}>
      {label && (
        <div pointerEvents="none" className="form-select__label">
          {label}
        </div>
      )}
      <div className="form-select__wrapper">
        <ReactSelect
          className={cx('react-select-container', selectClassName)}
          classNamePrefix={`select react-select react-select-${variant}`}
          onChange={changeHandler}
          onBlur={blurHandler}
          onFocus={focusHandler}
          value={input.value}
          options={options}
          isMulti={isMulti}
          isClearable={isMulti ? false : isClearable}
          isSearchable={isMulti ? false : isSearchable}
          closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
          hideSelectedOptions={isMulti ? false : hideSelectedOptions}
          showSelectedCountAfter={showSelectedCountAfter}
          components={{
            Option: isCustomOption ? CustomOption : components.Option,
            ...(typeof showSelectedCountAfter === 'number' && {
              ValueContainer: GroupedValueContainer,
            }),
          }}
          {...rest}
        />
      </div>
      {touched && error && (
        <div className="form-select__error" pointerEvents="none">
          {error}
        </div>
      )}
    </div>
  );
}

FormSelect.propTypes = {
  input: object,
  meta: object,
  label: string,
  type: string,
  onBlur: func,
  onFocus: func,
  onChange: func,
  variant: oneOf(['primary', 'secondary']),
  className: string,
  selectClassName: string,
  options: array,
  isMulti: bool,
  isClearable: bool,
  isSearchable: bool,
  closeMenuOnSelect: bool,
  hideSelectedOptions: bool,
  showSelectedCountAfter: number,
  selectedCountFormatter: func,
  isCustomOption: bool,
};

FormSelect.defaultProps = {
  variant: 'primary',
  selectClassName: '',
  isMulti: false,
  isCustomOption: false,
  selectedCountFormatter: (selectedCount, placeholder) => (
    <>
      {placeholder} <span className="highlight">({selectedCount})</span>
    </>
  ),
  showSelectedCountAfter: undefined,
};

export const Select = (props) => (
  <ReactSelect className="react-select-container" classNamePrefix="react-select" {...props} />
);

// eslint-disable-next-line
export default ({ name, ...rest }) => (
  <Field name={name}>
    {({ input, meta }) => <FormSelect {...rest} input={input} meta={meta} />}
  </Field>
);
