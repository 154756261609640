import React from 'react';
import { bool, string } from 'prop-types';
import { components } from 'react-select';
import cx from 'classnames';

const CustomOption = (props) => {
  const { isSelected, label } = props;
  return (
    <components.Option className="custom-option" {...props}>
      <span className={cx('custom-option__box', { 'custom-option__box--checked': isSelected })} />
      <span className="custom-option__label">{label}</span>
    </components.Option>
  );
};

CustomOption.propTypes = {
  isSelected: bool,
  label: string,
};

export default CustomOption;
