import debounce from 'lodash/debounce';

const calculateTableColumnHeight = debounce((query, breakpoint = 1023) => {
  if (window.innerWidth > breakpoint || !query) return;
  const ADDITIONAL_OFFSET = 24;
  const table = document.querySelector(query);
  const rows = table.querySelectorAll('.table-slider__item');
  const heights = [];
  rows.forEach((row, index) => {
    const cols = row.querySelectorAll('.table-slider__col');
    cols.forEach((col, indx) => {
      if (!index) {
        heights.push(col.offsetHeight);
      } else {
        heights[indx] = Math.max(heights[indx], col.offsetHeight);
      }
    });
  });
  rows.forEach((row) => {
    const cols = row.querySelectorAll('.table-slider__col');
    cols.forEach((col, indx) => {
      // eslint-disable-next-line no-param-reassign
      col.style.height = `${heights[indx] + ADDITIONAL_OFFSET}px`;
    });
  });
}, 250);

export { calculateTableColumnHeight };
