import { createSelector } from 'reselect';
import { STATE_KEY } from './jobs';

const getState = (state) => state[STATE_KEY];

// getters

export const getPage = (state) => getState(state).page;
export const getJobs = (state) => getState(state).data;
export const getInitialData = (state) => getState(state).initialData;

// selectors

export const selectJobs = createSelector(getJobs, getPage, (data, page) =>
  data.slice(0, page * 10)
);
