import React from 'react';
import { array, bool } from 'prop-types';
import MenuItem from './MenuItem';
import './Menu.scss';
import './menu_variables.scss';

const Menu = ({ listMenu, isHeaderWhite }) => (
  <nav className="menu">
    <div className="menu__list">
      {listMenu.map((c) => (
        <MenuItem isHeaderWhite={isHeaderWhite} key={c.id} {...c} />
      ))}
    </div>
  </nav>
);

Menu.propTypes = {
  listMenu: array,
  isHeaderWhite: bool,
};

export default Menu;
