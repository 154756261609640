import jobs from '../../../../data/data';

export const JOBS = jobs;

export const TEAMS = [
  {
    value: 'Engineering',
    label: 'Engineering',
  },
  {
    value: 'Marketing',
    label: 'Marketing',
  },
  {
    value: 'Finance',
    label: 'Finance',
  },
  {
    value: 'Sales',
    label: 'Sales',
  },
  {
    value: 'Support',
    label: 'Support',
  },
  {
    value: 'Tech',
    label: 'Tech',
  },
  {
    value: 'Trade',
    label: 'Trade',
  },
];
export const CONTRACT_TYPES = [
  {
    label: 'Full Time',
    value: 'Full Time',
  },
  {
    label: 'Freelance',
    value: 'Freelance',
  },
  {
    value: 'Part Time',
    label: 'Part Time',
  },
  {
    value: 'Internship',
    label: 'Internship',
  },
  {
    value: 'Fixed Term',
    label: 'Fixed Term',
  },
];
export const LOCATIONS = [
  {
    value: 'Amman, Jordan',
    label: 'Amman, Jordan',
  },
  {
    value: 'Remote',
    label: 'Remote',
  },
];

export const MOBILE_FORM = [
  { title: 'Contract type', name: 'contract_type', list: CONTRACT_TYPES },
  { title: 'Location', name: 'location', list: LOCATIONS },
  { title: 'Teams', name: 'team', list: TEAMS },
];

export const AUTO_COMPLETE_DATA = [...new Set(JOBS.map((c) => Object.values(c)))];
