import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useResponsive } from 'hooks';
import { GetStarted } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './HomeHero.scss';

const HeroFixedButton = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { isDesktop } = useResponsive();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsVisible(currentScrollY <= 0);
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isDesktop) return null;
  return (
    <div className={cx('hero-fixed-wrapper', { 'hero-fixed-wrapper--hidden': !isVisible })}>
      <GetStarted
        className="button hero-fixed-wrapper__button  button--with-icon"
        to="https://developer.finto.io/"
        target="_blank"
        color="text-white"
        size="medium"
        buttontype="gradient-hoverable"
        isCentered
      >
        <div className="button__inner">
          <span>Get started</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </GetStarted>
    </div>
  );
};

export default HeroFixedButton;
