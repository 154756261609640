import React, { useEffect, useRef } from 'react';
import { bool, node, array, oneOf, string, number } from 'prop-types';
import cx from 'classnames';
import { Loader, FormSucceededView } from 'elements';
import './FormWrapper.scss';

function getElementCoords(elem, rectElemId) {
  let _elem = elem;
  if (rectElemId) {
    _elem = document.getElementById(rectElemId);
  }
  const box = _elem.getBoundingClientRect();
  return {
    top: box.top + window.scrollY,
    right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY,
    left: box.left + window.scrollX,
  };
}

function FormWrapper({
  className,
  submitting,
  succeeded,
  errors,
  children,
  variant,
  iconColor,
  rectElemId,
  scrollXPos,
}) {
  const containerRef = useRef(null);
  const isError = !!(errors && errors.length);
  const isLoading = submitting && !succeeded;
  const isSucceeded = !submitting && succeeded;

  useEffect(() => {
    if (isSucceeded) {
      const { top } = getElementCoords(containerRef.current, rectElemId);
      window.scrollTo({
        top: scrollXPos !== undefined ? scrollXPos : top,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [isSucceeded, scrollXPos, rectElemId]);

  return (
    <div
      ref={containerRef}
      className={cx('forms-wrapper__container', {
        [className]: !!className,
        'forms-wrapper__container--succeed': isSucceeded,
      })}
    >
      {isLoading && !isError && (
        <div className={cx('forms-wrapper__loader', `forms-wrapper__loader--${variant}`)}>
          <Loader width="50px" />
        </div>
      )}
      {!isSucceeded && children}
      {isSucceeded && <FormSucceededView variant={variant} iconColor={iconColor} />}
      {isError && (
        <ul className="forms-wrapper__errors">
          {errors.map((c, index) => {
            if (!c) return null;
            return (
              <li key={index.toString()} className="forms-wrapper__error">
                {c}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

FormWrapper.propTypes = {
  className: string,
  submitting: bool,
  succeeded: bool,
  errors: array,
  children: node,
  iconColor: string,
  variant: oneOf(['white', 'black']),
  scrollXPos: number,
  rectElemId: string,
};

FormWrapper.defaultProps = {
  variant: 'white',
};

export default FormWrapper;
