import React from 'react';
import { useResponsive } from 'hooks';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';

const Footer = () => {
  const { isDesktop } = useResponsive();
  return isDesktop ? <FooterDesktop /> : <FooterMobile />;
};

export default Footer;
