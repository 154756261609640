import React from 'react';
import { GetStarted } from 'elements';
import { Epilog } from 'components';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './BaapEpilog.scss';

const BaapEpilog = () => (
  <Epilog
    className="baap-epilog"
    title="Start building now"
    description="Create an account, get instant access to the Finto API and start building in minutes."
    variant="white"
  >
    <div className="baap-epilog__children">
      <GetStarted
        background="black"
        border="border-bold-white"
        color="text-white"
        size="medium"
        className="baap-epilog__button button--with-icon"
        to="https://developer.finto.io/"
        target="_blank"
        buttontype="hoverable"
        isCentered
      >
        <div className="button__inner">
          <span>Get started</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </GetStarted>
    </div>
  </Epilog>
);

export default BaapEpilog;
