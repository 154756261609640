import { cloneElement, useEffect, useRef } from 'react';
import * as basicScroll from 'basicscroll';
import { bool, number, object, oneOfType, string, element, any } from 'prop-types';

function preserveRef(ref, node) {
  if (ref) {
    if (typeof ref === 'function') {
      ref(node);
    } else if (ref.current) {
      ref.current = node;
    }
  }
}

function BasicScroll(props) {
  const {
    children,
    reference,
    from,
    to,
    props: propsData,
    direct,
    getInstance,
    ...restNativeProps
  } = props;
  const mutableObj = useRef({
    instance: null,
    ref: null,
  }).current;

  useEffect(() => {
    let node = reference;
    if (reference && reference.current) {
      node = reference.current;
    }

    const instance = basicScroll.create({
      from,
      to,
      props: propsData,
      direct,
      elem: node || mutableObj.ref,
      ...restNativeProps,
    });

    instance.start();
    mutableObj.instance = instance;
    getInstance?.(instance);

    return () => {
      instance.destroy();
    };
  }, []);

  return children
    ? cloneElement(children, {
        ref(node) {
          mutableObj.ref = node;
          preserveRef(children.ref, node);
        },
      })
    : null;
}

BasicScroll.propTypes = {
  from: oneOfType([string, number]),
  to: oneOfType([string, number]),
  props: object,
  reference: oneOfType([object, any]),
  children: element,
  direct: oneOfType([bool, any]),
};

BasicScroll.defaultProps = {
  from: 'top-bottom',
  to: 'bottom-top',
  direct: true,
};

export default BasicScroll;
