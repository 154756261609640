import React from 'react';
import { Container, Title, Tag, Description, ApiCard } from 'elements';
import nodeSvg from 'images/icons/node.svg';
import androidSvg from 'images/icons/android.svg';
import iosSvg from 'images/icons/ios.svg';
import pythonSvg from 'images/icons/python.svg';
import javaSvg from 'images/icons/java.svg';
import docsSvg from 'images/icons/document.svg';
import githubSvg from 'images/icons/github.svg';
import rubySvg from 'images/icons/ruby.svg';
import './DevelopersSdks.scss';

const data = [
  {
    title: 'Java',
    desc: 'Explore the Java SDK and start embedding Finto’s financial services.',
    icon: javaSvg,
    links: [
      {
        icon: docsSvg,
        label: 'documents',
        link: '#',
      },
      {
        icon: githubSvg,
        label: 'github',
        link: '#',
      },
    ],
  },
  {
    title: 'Node',
    desc: 'Explore the Node SDK and start embedding Finto’s financial services.',
    icon: nodeSvg,
    links: [
      {
        icon: docsSvg,
        label: 'documents',
        link: '#',
      },
      {
        icon: githubSvg,
        label: 'github',
        link: '#',
      },
    ],
  },
  {
    title: 'Python',
    desc: 'Explore the Python SDK and start embedding Finto’s financial services.',
    icon: pythonSvg,
    links: [
      {
        icon: docsSvg,
        label: 'documents',
        link: '#',
      },
      {
        icon: githubSvg,
        label: 'github',
        link: '#',
      },
    ],
  },
  {
    title: 'Ruby',
    desc: 'Explore the Ruby SDK and start embedding Finto’s financial services.',
    icon: rubySvg,
    links: [
      {
        icon: docsSvg,
        label: 'documents',
        link: '#',
      },
      {
        icon: githubSvg,
        label: 'github',
        link: '#',
      },
    ],
  },
  {
    title: 'Android',
    desc: 'Explore the Android SDK and start embedding Finto’s financial services.',
    icon: androidSvg,
    links: [
      {
        icon: docsSvg,
        label: 'documents',
        link: '#',
      },
      {
        icon: githubSvg,
        label: 'github',
        link: '#',
      },
    ],
  },
  {
    title: 'iOS',
    desc: 'Explore the Ios SDK and start embedding Finto’s financial services.',
    icon: iosSvg,
    links: [
      {
        icon: docsSvg,
        label: 'documents',
        link: '#',
      },
      {
        icon: githubSvg,
        label: 'github',
        link: '#',
      },
    ],
  },
];

const DevelopersSdks = () => (
  <section className="developers-sdks">
    <Container className="developers-sdks__container">
      <Tag align="center" className="developers-sdks__tag" color="purple">
        sdks
      </Tag>
      <Title
        tagName="h2"
        sizeText="size-2"
        color="black"
        alignText="text-center"
        className="developers-sdks__title"
      >
        Start building now
      </Title>
      <Description
        sizeText="size-2"
        color="black"
        alignText="text-center"
        className="developers-sdks__description"
      >
        We&apos;ve created SDKs for some of the most popular languages and platforms. Create account
        and start building in minutes.
      </Description>
      <div className="developers-sdks__api-cards">
        {data.map((c, indx) => (
          <ApiCard className="developers-sdks__api-card" key={indx.toString()} {...c} />
        ))}
      </div>
    </Container>
  </section>
);

export default DevelopersSdks;
