import React from 'react';
import { GetStarted } from 'elements';
import { Epilog } from 'components';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './DevelopersEpilog.scss';

const DevelopersEpilog = () => (
  <Epilog
    title="Start building now"
    description="Create an account, get instant access to the Finto API and start building in minutes."
    variant="white"
  >
    <GetStarted
      className="developers-epilog__button button--with-icon"
      to="https://developer.finto.io/"
      target="_blank"
      background="black"
      color="text-white"
      size="medium"
      buttontype="hoverable"
      isCentered
    >
      <div className="button__inner">
        <span>Get started</span>
        <div className="button__icons">
          <ArrowSvg className="button__icon" />
          <ArrowSvg className="button__icon button__icon--next" />
        </div>
      </div>
    </GetStarted>
  </Epilog>
);

export default DevelopersEpilog;
