import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  ContentSwitcher,
  FeatureCard,
  Image,
  ProgressSlider,
  Tag,
  Title,
  Description,
  Container,
  TriggerOnEnter,
} from 'elements';
import chartIcon from 'images/icons/chart-white.svg';
import settingsIcon from 'images/icons/settings-white.svg';
import thunderIcon from 'images/icons/thunder-white.svg';
import ArrowSvg from 'images/icons/arrowTop.svg';
import phoneRegular from 'images/phones/phone-regular-white.webp';
import phonePink from 'images/phones/phone-pink.webp';
import phoneYellow from 'images/phones/phone-yellow.webp';
import phoneRegularMobile from 'images/mobile/phones/phone-regular-white.webp';
import phonePinkMobile from 'images/mobile/phones/phone-pink.webp';
import phoneYellowMobile from 'images/mobile/phones/phone-yellow.webp';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './BaasHero.scss';

const sliderData = [
  {
    title: 'Actionable insights',
    description:
      'Utilise trusted financial data to unearth customer spending and saving patterns allowing you to develop new personalised products.',
    Icon: settingsIcon,
  },
  {
    title: 'Drive growth',
    description:
      'Outstrip the competition with cutting edge fintech features, proven to delight users, drive customer acquisition and increase retention.',
    Icon: chartIcon,
  },
  {
    title: 'Deploy fast',
    description:
      'Reduce your time to market with our white label product stack combined with our licensing and regulatory experience.',
    Icon: thunderIcon,
  },
];

const BaasHero = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="baas-hero__section">
      <Container className="baas-hero__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked}>
          <div className="baas-hero__top-content hero-animations">
            <div className="baas-hero__declaration">
              <Tag>baas</Tag>
              <Title tagName="h1" sizeText="size-3" color="vulcan">
                <div className="title__part">
                  Digital banking<span className="title__space">&nbsp;</span>
                </div>
                <div className="title__part">
                  designed services,<span className="title__space">&nbsp;</span>
                </div>
                <div className="title__part">for scale.</div>
              </Title>
              <Description sizeText="size-2">
                Create innovative new digital banking products or embed financial services into your
                customer experience at extraordinary speed with Finto’s fully licensed Banking as a
                Service solutions
              </Description>
              <div className="baas-hero__buttons buttons-holder">
                <Button
                  background="dark"
                  border="border-dark"
                  color="text-white"
                  size="regular"
                  className="baas-hero__button button--with-icon"
                  to="/baap"
                  tagName={Link}
                  buttontype="hoverable"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Get api access</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </Button>
                <Button
                  background="white"
                  border="border-bold-black"
                  color="text-black"
                  size="regular"
                  className="baas-hero__button button--with-icon baas-hero__button--white"
                  to="/contact"
                  tagName={Link}
                  buttontype="hoverable"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Contact sales</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </Button>
                <Button
                  background="dark"
                  border="border-dark"
                  color="text-white"
                  size="regular"
                  className="baas-hero__button baas-hero__button--mobile button--with-icon"
                  to="/baap"
                  tagName={Link}
                  buttontype="hoverable"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Get Api access</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </Button>
              </div>
            </div>
            <div className="baas-hero__images">
              <div className="baas-hero__image baas-hero__image--mobile-first">
                <Image src={phoneYellow} srcMobile={phoneYellowMobile} alt="phone" />
              </div>
              <div className="baas-hero__image baas-hero__image--first">
                <Image src={phoneRegular} srcMobile={phoneRegularMobile} alt="phone" />
              </div>
              <div className="baas-hero__image baas-hero__image--last">
                <Image src={phonePink} srcMobile={phonePinkMobile} alt="phone" />
              </div>
            </div>
          </div>
        </TriggerOnEnter>
        <ContentSwitcher
          mobileContent={
            <ProgressSlider className="baas-hero__slider">
              {sliderData.map((c) => (
                <FeatureCard key={c.title} {...c} />
              ))}
            </ProgressSlider>
          }
          desktopContent={
            <div className="baas-hero__cards">
              {sliderData.map((c) => (
                <FeatureCard key={c.title} {...c} />
              ))}
            </div>
          }
        />
      </Container>
    </section>
  );
};

export default BaasHero;
