import React from 'react';
import { useSelector } from 'react-redux';
import { Title, Container, Image, TriggerOnEnter, BasicScroll, Tag } from 'elements';
import codeUpload from 'images/phones/mob-upload.webp';
import codeDetails from 'images/phones/mob-details.webp';
import codeDoc from 'images/phones/mob-document.webp';
import cardUpload from 'images/phone_card/card-upload-c.webp';
import cardDetails from 'images/phone_card/card-account-c.webp';
import cardDoc from 'images/phone_card/card-document-c.webp';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './CronicaWorks.scss';

const CronicaWorks = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="cronica-works">
      <div className="cronica-works__header">How It Works</div>
      <Container className="cronica-works__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="15%">
          <div className="cronica-works__details advantages-animations">
            <Tag tagName="h2" color="red" lineClassName="paas-support__tag-line">
              step 1
            </Tag>
            <Title
              tagName="h2"
              sizeText="size-1"
              color="white"
              className="cronica-works__title title__part"
            >
              Upload A Document <br /> To The Finto Cronica Platform
            </Title>
          </div>
        </TriggerOnEnter>
        <div className="cronica-works__media">
          <div className="cronica-works__img_big">
            <Image src={codeUpload} alt="code" />
          </div>
          <BasicScroll
            props={{
              '--parallax-tx': {
                from: '30%',
                to: '-30%',
              },
            }}
          >
            <div className="cronica-works__img_small parallax parallax--horizontal">
              <Image src={cardUpload} alt="card" />
            </div>
          </BasicScroll>
        </div>
      </Container>
      <Container className="cronica-works__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="15%">
          <div className="cronica-works__details advantages-animations">
            <Tag tagName="h2" color="red" lineClassName="paas-support__tag-line">
              step 2
            </Tag>
            <Title
              tagName="h2"
              sizeText="size-1"
              color="white"
              className="cronica-works__title title__part"
            >
              Document is placed on the <br /> blockchain and assigned <br /> a digital key
            </Title>
          </div>
        </TriggerOnEnter>
        <div className="cronica-works__media">
          <div className="cronica-works__img_big">
            <Image src={codeDetails} alt="code" />
          </div>
          <BasicScroll
            props={{
              '--parallax-tx': {
                from: '30%',
                to: '-30%',
              },
            }}
          >
            <div className="cronica-works__img_small parallax parallax--horizontal">
              <Image src={cardDetails} alt="card" />
            </div>
          </BasicScroll>
        </div>
      </Container>
      <Container className="cronica-works__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="15%">
          <div className="cronica-works__details advantages-animations">
            <Tag tagName="h2" color="red" lineClassName="paas-support__tag-line">
              step 3
            </Tag>
            <Title
              tagName="h2"
              sizeText="size-1"
              color="white"
              className="cronica-works__title title__part"
            >
              Document can be retrieved <br /> and verified at anytime <br /> using the key
            </Title>
          </div>
        </TriggerOnEnter>
        <div className="cronica-works__media">
          <div className="cronica-works__img_big">
            <Image src={codeDoc} alt="code" />
          </div>
          <BasicScroll
            props={{
              '--parallax-tx': {
                from: '30%',
                to: '-30%',
              },
            }}
          >
            <div className="cronica-works__img_small parallax parallax--horizontal">
              <Image src={cardDoc} alt="code" />
            </div>
          </BasicScroll>
        </div>
      </Container>
    </section>
  );
};

export default CronicaWorks;
