import React from 'react';
import cx from 'classnames';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import './Container.scss';

const Container = ({ className, children }) => (
  <div className={cx('container', className)}>{children}</div>
);

Container.propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]),
};

export default Container;
