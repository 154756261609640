import React from 'react';
import { HelmetWrapper } from 'elements';
import { HiringHero, HiringRole, CultureLife } from 'components';

const Hiring = () => (
  <>
    <HelmetWrapper helmetKey="hiring" />
    <HiringHero />
    <HiringRole variant="black" />
    <CultureLife variant="white" />
  </>
);

export default Hiring;
