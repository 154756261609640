import React from 'react';
import { oneOfType, node, arrayOf } from 'prop-types';

function CustomMultiValue({ content }) {
  return (
    <div className="react-select__multi-value react-select__multi-value--custom" key="customChild">
      <div className="react-select__multi-value__label">{content}</div>
    </div>
  );
}

CustomMultiValue.propTypes = {
  content: oneOfType([node, arrayOf(node)]),
};

export default CustomMultiValue;
