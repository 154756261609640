// http://stackoverflow.com/a/30376660/3708754
export const getSvgLength = {
  /**
   * @param {node} el
   * @return the length of the line in px
   */
  line(el) {
    const x1 = el.getAttribute('x1');
    const x2 = el.getAttribute('x2');
    const y1 = el.getAttribute('y1');
    const y2 = el.getAttribute('y2');
    const lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)); // eslint-disable-line no-restricted-properties

    return lineLength;
  },
  /**
   * @param {node} el
   * @return the length of the circle in px
   */
  circle(el) {
    const r = el.getAttribute('r');
    const circleLength = 2 * Math.PI * r;

    return circleLength;
  },
  /**
   * @param {node} el is the rect element ex $('.rect')
   * @return the length of the rect in px
   */
  rect(el) {
    const w = el.getAttribute('width');
    const h = el.getAttribute('height');

    return w * 2 + h * 2;
  },
};

/**
 * helper to trigger callbacks
 * depending on the animation progress
 * @param {*} progressObj
 * @returns {function}
 */
export function handleAnimationProgressCbs(progressObj) {
  const progressEntries = Object.entries(progressObj || {});
  if (!progressEntries.length) {
    return () => {};
  }

  return (anim) => {
    const progress = Math.round(anim.progress);
    progressEntries.forEach(([progressToCall, cb], i) => {
      if (progress >= progressToCall) {
        cb(anim);
        // NOTE: splice mutates original array
        progressEntries.splice(i, 1);
      }
    });
  };
}
