import React from 'react';
import { HelmetWrapper } from 'elements';
import {
  CronicaHero,
  CronicaWorks,
  CronicaBenefits,
  CronicaDocuments,
  CronicaIntegration,
} from 'components';

const Cronica = () => (
  <>
    <HelmetWrapper helmetKey="cronica" />
    <CronicaHero />
    <CronicaWorks />
    <CronicaBenefits />
    <CronicaDocuments />
    <CronicaIntegration />
  </>
);

export default Cronica;
