import React from 'react';
import { Button, Tag, Title, Description, Container, Image } from 'elements';
import phone1 from 'images/phones/phone-google-pay.webp';
import phone2 from 'images/phones/phone-apple-pay.webp';
import phoneMobile1 from 'images/mobile/phones/phone-google-pay.webp';
import phoneMobile2 from 'images/mobile/phones/phone-apple-pay.webp';
import ApplePaySvg from 'images/icons/apple-pay.svg';
import GooglePaySvg from 'images/icons/google-pay.svg';
import './PaasWallet.scss';

const PaasWallet = () => (
  <section className="paas-wallet__section">
    <Container className="paas-wallet__container">
      <div className="paas-wallet__declaration">
        <Tag>DIGITAL FIRST</Tag>
        <Title tagName="h2" sizeText="size-2" color="vulcan">
          Digital wallet integration
        </Title>
        <Description sizeText="size-2">
          Enable your customers to load physical and virtual cards into their Apple or Google
          wallet. Enabling instant and access to their money in person or online with apple pay and
          google pay.
        </Description>
        <div className="paas-wallet__buttons">
          <Button
            background="white"
            border="border-bold-black"
            color="text-white"
            size="tiny"
            className="paas-wallet__button"
          >
            <ApplePaySvg />
          </Button>
          <Button
            background="white"
            border="border-bold-black"
            color="text-white"
            size="tiny"
            className="paas-wallet__button"
          >
            <GooglePaySvg />
          </Button>
        </div>
      </div>
      <div className="paas-wallet__images">
        <div className="paas-wallet__image">
          <Image src={phone1} srcMobile={phoneMobile1} alt="google-pay" />
        </div>
        <div className="paas-wallet__image">
          <Image src={phone2} srcMobile={phoneMobile2} alt="apple-pay" />
        </div>
      </div>
    </Container>
  </section>
);

export default PaasWallet;
