import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Title, Description } from 'elements';
import BubbleSvg from 'images/icons/bubble.svg';
import StarSvg from 'images/icons/star.svg';
import EditSvg from 'images/icons/edit.svg';
import './HiringHero.scss';

const data = [
  {
    label: 'step 01',
    icon: EditSvg,
    title: 'Apply',
    description: 'Find a role, then input your contact details and upload your resume to apply.',
  },
  {
    label: 'step 02',
    icon: BubbleSvg,
    title: 'First Interview',
    description:
      'If you’re a good fit for the role on paper, we’ll invite you in to tell us more about yourself, your experience and what you expect from working at Finto.',
  },
  {
    label: 'step 03',
    icon: BubbleSvg,
    title: 'Second Interview',
    description:
      'If we think you’ll be a good fit for us, we’ll schedule a second interview where you get to meet the heads of department and tell us more about yourself.',
  },
  {
    label: 'step 04',
    icon: StarSvg,
    title: 'Decision',
    description:
      'If you like what you see of Finto, and we like what we see in you, we’ll officially invite you to join the team.',
  },
];

const HiringHero = () => (
  <section className="hiring-hero__section">
    <Container className="hiring-hero__container">
      <Title tagName="h1" sizeText="size-5" alignText="text-center" className="hiring-hero__title">
        How we hire at Finto
      </Title>
      <Description sizeText="size-2" alignText="text-center" className="hiring-hero__description">
        We believe in making the best possible work, and being the best to team to work with. See
        what our hiring process looks like below, read about our{' '}
        <Link to="/careers/culture">company culture</Link> and see if there’s a perfect{' '}
        <Link to="/careers/jobs">job opening</Link> for you.
      </Description>
      <div className="hiring-hero__cards">
        {data.map((c) => {
          const Icon = c.icon;
          return (
            <div key={c.title} className="hiring-hero__card hiring-card">
              <div className="hiring-card__header">
                <div className="hiring-card__label-box">
                  <div className="hiring-card__label">
                    <span>{c.label}</span>
                  </div>
                </div>
                <div className="hiring-card__icon">
                  <Icon />
                </div>
              </div>
              <div className="hiring-card__body">
                <Title tagName="h3" sizeText="size-7" className="hiring-card__title">
                  {c.title}
                </Title>
                <Description sizeText="size-2" className="hiring-card__description">
                  {c.description}
                </Description>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  </section>
);

export default HiringHero;
