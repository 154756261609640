/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { string } from 'prop-types';
import { ContentSwitcher } from 'elements';
import cx from 'classnames';
import { calculateTableColumnHeight } from './helpers';
import './NesterTableSlider.scss';

SwiperCore.use([Pagination]);

export default function NestedTableSlider({ className }) {
  return (
    <div className={cx('nested__container', { [className]: !!className })}>
      <ContentSwitcher mobileContent={<TableSlider />} desktopContent={<Table />} />
    </div>
  );
}

NestedTableSlider.propTypes = {
  className: string,
};

const Table = () => (
  <div className="nested__table table">
    <div className="table__title">
      <p>Cookie Title Cookie Name</p>
      <p>Purpose</p>
      <p>More information</p>
    </div>
    <div className="table__box">
      <div className="table__row">
        <div className="table__col">
          <p>
            <b>Strictly Necessary Cookies</b>
          </p>
        </div>
        <div className="table__col">
          <p>To make the website operational</p>
        </div>
        <div className="table__col">
          <p>
            These cookies are necessary for the website to function and cannot be switched off in
            our systems. They are usually only set in response to actions made by you which amount
            to a request for services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you about these cookies,
            but some parts of the site will not then work. These cookies do not store any personally
            identifiable information.
          </p>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <p>
            <b>Analytical or performance cookies</b> “_ga”
          </p>
        </div>
        <div className="table__col">
          <p>To get the website usage statistics</p>
        </div>
        <div className="table__col">
          <p>
            These cookies allow us to count visits and traffic sources so we can measure and improve
            the performance of our site. They help us to know which pages are the most and least
            popular and see how visitors move around the site. All information these cookies collect
            is aggregated and therefore anonymous.
          </p>
        </div>
      </div>
      <div className="table__row">
        <div className="table__col">
          <p>
            <b>Functionality cookies</b> “OptanonConsent”, “OptanonAlertBoxClosed”,
            “optimizelyEndUserId”, “ajs_anonymous_id”
          </p>
        </div>
        <div className="table__col">
          <p>
            These type of cookies are set by the 3rd party apps we may use to extend the
            functionality of our website
          </p>
        </div>
        <div className="table__col">
          <p>
            These cookies enable the website to provide enhanced functionality and personalisation.
            They may be set by us or by third party providers whose services we have added to our
            pages.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const TableSlider = () => {
  const calculate = () => calculateTableColumnHeight('.nested__slider');
  useEffect(() => {
    calculate();
    document.addEventListener('resize', calculate);
    return () => {
      document.removeEventListener('resize', calculate);
    };
  }, []);

  return (
    <Swiper className="nested__slider" slidesPerView="auto" pagination={{ type: 'progressbar' }}>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Cookie Title Cookie Name</span>
            <p>
              <b>Strictly Necessary Cookies</b>
            </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose</span>
            <p>To make the website operational</p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">More information</span>
            <p>
              These cookies are necessary for the website to function and cannot be switched off in
              our systems. They are usually only set in response to actions made by you which amount
              to a request for services, such as setting your privacy preferences, logging in or
              filling in forms. You can set your browser to block or alert you about these cookies,
              but some parts of the site will not then work. These cookies do not store any
              personally identifiable information.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Cookie Title Cookie Name</span>
            <p>
              <b>Analytical or performance cookies</b> “_ga”
            </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose</span>
            <p>To get the website usage statistics</p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">More information</span>
            <p>
              These cookies allow us to count visits and traffic sources so we can measure and
              improve the performance of our site. They help us to know which pages are the most and
              least popular and see how visitors move around the site. All information these cookies
              collect is aggregated and therefore anonymous.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="table-slider__item">
          <div className="table-slider__col">
            <span className="table-slider__label">Cookie Title Cookie Name</span>
            <p>
              <b>Functionality cookies</b> “OptanonConsent”, “OptanonAlertBoxClosed”,
              “optimizelyEndUserId”, “ajs_anonymous_id”
            </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">Purpose</span>
            <p>
              These type of cookies are set by the 3rd party apps we may use to extend the
              functionality of our website
            </p>
          </div>
          <div className="table-slider__col">
            <span className="table-slider__label">More information</span>
            <p>
              These cookies enable the website to provide enhanced functionality and
              personalisation. They may be set by us or by third party providers whose services we
              have added to our pages.
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
