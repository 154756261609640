import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Title, Container, Description, Button, Image } from 'elements';
import { useResponsive } from 'hooks';
import CurvedArrowSvg from 'images/icons/curved-arrow.svg';
import story from 'images/about/story.webp';
import storyMobile from 'images/mobile/about/story.webp';
import sliderImg1 from 'images/about/slide1.webp';
import sliderImg2 from 'images/about/slide2.webp';
import sliderImg3 from 'images/about/slide3.webp';
import sliderImgMobile1 from 'images/mobile/about/slide1.webp';
import sliderImgMobile2 from 'images/mobile/about/slide2.webp';
import sliderImgMobile3 from 'images/mobile/about/slide3.webp';
import './AboutHero.scss';

const slides = [
  {
    desktop: story,
    mobile: storyMobile,
  },
  {
    desktop: sliderImg3,
    mobile: sliderImgMobile3,
  },
  {
    desktop: sliderImg2,
    mobile: sliderImgMobile2,
  },
  {
    desktop: sliderImg1,
    mobile: sliderImgMobile1,
  },
];

const AboutHero = () => {
  const { isDesktop } = useResponsive();
  return (
    <section className="about-hero__section">
      <div className="about-hero__patterns-wrapper">
        <div className="about-hero__patterns-wrapper--sec">
          <Container className="about-hero__container">
            <div className="about-hero__content-wrapper about-hero__content-wrapper--first">
              <Title
                tagName="h1"
                alignText="text-center"
                className="about-hero__title about-hero__title--first"
              >
                POWERING INNOVATION
              </Title>
              <Description alignText="text-center" sizeText="size-2">
                We’re dedicated to building a platform that will connect consumers, developers,
                entrepreneurs and financial institutions. No suits and ties. No complexity. No
                limits. Just a world of endless opportunities.
              </Description>
            </div>
          </Container>
          <div className="about-hero__flex-wrapper">
            <Container className="about-hero__container">
              <div className="about-hero__content-wrapper about-hero__content-wrapper--second">
                <Title
                  tagName="h2"
                  sizeText="size-3"
                  className="about-hero__title about-hero__title--second"
                  alignText="text-center"
                >
                  Who we are
                </Title>
                <Description alignText="text-center" sizeText="size-2">
                  We’re an ambitious group of creative technologists, developers, engineers and
                  problem solvers from all walks of life. Our eclectic backgrounds and interests
                  allow us to approach traditional problems from fresh perspectives, creating truly
                  unique products that will have a transformative impact on your business.
                </Description>
                <Button
                  className="about-hero__button button--with-icon button--with-icon-left"
                  background="purple"
                  color="text-white"
                  size="medium"
                  buttontype="gradient-hoverable"
                  isCentered
                  to="/careers/jobs"
                  tagName={Link}
                >
                  <div className="button__inner">
                    <div className="button__icons">
                      <CurvedArrowSvg className="button__icon" />
                      <CurvedArrowSvg className="button__icon button__icon--next" />
                    </div>
                    <span>Join the team</span>
                  </div>
                </Button>
              </div>
            </Container>
            <Swiper
              className="about-hero__slider"
              slidesPerView="auto"
              centeredSlides
              loop
              preloadImages
              spaceBetween={44}
              breakpoints={{
                320: {
                  centeredSlides: false,
                  spaceBetween: 24,
                },
                1024: {
                  centeredSlides: true,
                  spaceBetween: 44,
                },
              }}
            >
              {slides.map((c, index) => (
                <SwiperSlide className="about-hero__swiper-slide" key={index.toString()}>
                  <img src={isDesktop ? c.desktop : c.mobile} alt="slide" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Container className="about-hero__container">
            <div className="about-hero__content-wrapper about-hero__content-wrapper--third">
              <div className="about-hero__story-image">
                <Image src={story} srcMobile={storyMobile} alt="our story" />
              </div>
              <div className="about-hero__story-info">
                <Title
                  sizeText="size-2"
                  className="about-hero__title about-hero__title--third"
                  tagName="h2"
                >
                  Our story
                </Title>
                <Description sizeText="size-2">
                  Finto began life as the internal tech team at Bank al Etihad, the fastest growing
                  bank in Jordan. We spent the past few years working relentlessly to completely
                  overhaul the bank’s digital infrastructure and consumer products, transforming it
                  into the market leading digital bank in Jordan.
                </Description>
                <Description sizeText="size-2">
                  Today we’re a fintech company (with a full banking license) based in Amman, with
                  partners all over the globe. We have an array of ground-breaking digital products
                  designed to empower the entrepreneurs of tomorrow, and we’re just getting started.
                </Description>
                <Button
                  className="about-hero__button button--with-icon button--with-icon-left"
                  background="purple"
                  color="text-white"
                  size="medium"
                  buttontype="gradient-hoverable"
                  isCentered
                  to="/careers/jobs"
                  tagName={Link}
                >
                  <div className="button__inner">
                    <div className="button__icons">
                      <CurvedArrowSvg className="button__icon" />
                      <CurvedArrowSvg className="button__icon button__icon--next" />
                    </div>
                    <span>Join the team</span>
                  </div>
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
