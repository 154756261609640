import React from 'react';
import { HelmetWrapper } from 'elements';
import { Enterprise } from 'components';

const EnterprisePage = () => (
  <>
    <HelmetWrapper helmetKey="enterprise" />
    <Enterprise />
  </>
);

export default EnterprisePage;
