import React from 'react';
import { Container, Title, Description } from 'elements';
import person from 'images/culture/person.jpeg';
import CultureSlider from '../../../CultureSlider/CultureSlider';
import './CultureFeedback.scss';

const objData = {
  image: person,
  text:
    '"Finto is a place where a group of people become a family, where ideas become reality and where self-growth becomes a journey. Being surrounded by exceptional like-minded colleagues, Finto has offered me the chance to grow at a personal and professional level. Not only does Finto believe and invest in us, but also celebrates us on a daily basis and this has always inspired me to further challenge myself and repeatedly cross milestones. Now that’s something you won’t find anywhere else."',
  name: 'Qamar Jabsheh',
  position: 'Senior Transformation Associate',
};

const data = Array(1).fill(objData);

const CultureFeedback = () => (
  <section className="culture-feedback__section">
    <Container className="culture-feedback__container">
      <Title
        tagName="h2"
        sizeText="size-3"
        alignText="text-center"
        className="culture-feedback__title"
      >
        Life at Finto
      </Title>
      <Description
        sizeText="size-2"
        alignText="text-center"
        className="culture-feedback__description"
      >
        We’re an ambitious group of creative technologists, developers, engineers and problem
        solvers from all walks of life. Our eclectic backgrounds and interests allow us to approach
        traditional problems from fresh perspectives, creating truly unique products that we believe
        will have a transformative impact on the future of finance.
      </Description>
      <CultureSlider data={data} className="culture-feedback__slider" />
    </Container>
    {/* <div className="culture-feedback__image-wrapper">
      <Image className="culture-feedback__image" src={image1} srcMobile={mobileImage1} />
      <Image className="culture-feedback__image" src={image2} srcMobile={mobileImage2} />
      <Image className="culture-feedback__image" src={image3} srcMobile={mobileImage3} />
    </div> */}
  </section>
);

export default CultureFeedback;
