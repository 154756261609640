import React, { Fragment } from 'react';
import { arrayOf, node, oneOfType, string, oneOf, bool, elementType } from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Title, Container, Description, TriggerOnEnter } from 'elements';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './Epilog.scss';

const Epilog = ({ title, children, className, variant, description, hasAnimation, id }) => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);
  const ContentWrapper = hasAnimation ? TriggerOnEnter : Fragment;
  const contentWrapperProps = {};
  if (hasAnimation) {
    contentWrapperProps.bypass = !areAnimationsUnblocked;
    contentWrapperProps.bottomOffset = '15%';
  }

  return (
    <section
      id={id}
      className={cx('epilog__section', className, {
        [`epilog__section--${variant}`]: !!variant,
      })}
    >
      <div className="epilog__bg-wrapper">
        <Container className="epilog__container">
          <ContentWrapper {...contentWrapperProps}>
            <div className={cx('epilog__content', { 'advantages-animations': hasAnimation })}>
              <Title
                tagName="h2"
                sizeText="size-5"
                alignText="text-center"
                color={variant === 'black' ? 'white' : 'vulcan'}
              >
                {title}
              </Title>
              {description && (
                <Description
                  alignText="text-center"
                  color={variant === 'black' ? 'white' : 'vulcan'}
                  sizeText="size-2"
                >
                  {description}
                </Description>
              )}
              {children}
            </div>
          </ContentWrapper>
        </Container>
      </div>
    </section>
  );
};

Epilog.propTypes = {
  title: oneOfType([node, elementType]),
  className: string,
  children: oneOfType([arrayOf(node), node]),
  variant: string,
  description: string,
  hasAnimation: bool,
  id: string,
};

Epilog.defaultProps = {
  variant: oneOf(['white', 'black']),
};

export default Epilog;
