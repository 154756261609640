import React from 'react';
import { func, node } from 'prop-types';
import './Form.scss';

const Form = ({ children, onSubmit, ...rest }) => (
  <form className="form" onSubmit={onSubmit} {...rest}>
    {children}
  </form>
);

Form.defaultProps = {
  children: null,
  onSubmit: undefined,
};

Form.propTypes = {
  children: node,
  onSubmit: func,
};

export default Form;
