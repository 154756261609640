import React from 'react';
import { string, oneOf } from 'prop-types';
import { Title, Description, Image } from 'elements';
import cx from 'classnames';
import './ResourceCard.scss';

const ResourceCard = ({ className, image, title, desc, bgType }) => (
  <div className={cx('resource-card', className)}>
    <div className={cx('resource-card__image-wrapper', `resource-card__image-wrapper--${bgType}`)}>
      <Image className="resource-card__image" src={image} alt="resource" />
    </div>
    <div className="resource-card__declaration">
      <Title
        tagName="h3"
        sizeText="size-7"
        color="black"
        alignText="text-left"
        className="resource-card__title"
      >
        {title}
      </Title>
      <Description
        sizeText="size-1"
        color="black"
        alignText="text-left"
        className="resource-card__description"
      >
        {desc}
      </Description>
    </div>
  </div>
);

ResourceCard.propTypes = {
  className: string,
  title: string,
  desc: string,
  image: string,
  bgType: oneOf(['orange', 'red', 'purple']),
};

ResourceCard.defaultProps = {
  bgType: 'purple',
};

export default ResourceCard;
