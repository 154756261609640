import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = ({ fill = 'none', id = 'paint0_linear' }) => (
  <svg viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.50004 17.3334C1.25004 17.3334 1.08337 17.25 0.916707 17.0834C0.666707 16.8334 0.583373 16.5 0.75004 16.25L2.25004 11.8334C1.75004 10.8334 1.50004 9.66669 1.50004 8.58335C1.50004 5.58335 3.16671 2.83335 5.91671 1.50002C7.00004 0.916687 8.16671 0.666687 9.41671 0.666687H9.83337C13.9167 0.916687 17.0834 4.08335 17.3334 8.08335V8.58335C17.3334 9.83335 17.0834 11 16.5 12.1667C15.1667 14.8334 12.4167 16.5 9.41671 16.5C8.33337 16.5 7.16671 16.25 6.16671 15.8334L1.75004 17.3334C1.66671 17.3334 1.58337 17.3334 1.50004 17.3334ZM9.41671 2.33335C8.41671 2.33335 7.50004 2.58335 6.66671 3.00002C4.50004 4.08335 3.16671 6.16669 3.16671 8.58335C3.16671 9.58335 3.41671 10.5 3.83337 11.3334C3.91671 11.5 3.91671 11.75 3.91671 12L2.83337 15.1667L6.00004 14.0834C6.25004 14 6.41671 14 6.66671 14.1667C7.50004 14.5834 8.50004 14.8334 9.41671 14.8334C11.8334 14.8334 13.9167 13.5 15 11.4167C15.4167 10.5834 15.6667 9.58335 15.6667 8.66669V8.16669C15.5 5.00002 13 2.50002 9.75004 2.33335H9.41671Z"
      fill={`url(#${id})`}
    />
    <defs>
      <linearGradient
        id={id}
        x1="8.99835"
        y1="-18.0833"
        x2="8.99835"
        y2="36.9915"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.335286" stopColor="#8D93FF" />
        <stop offset="0.759165" stopColor="#823CD7" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;
