import React from 'react';
import { string } from 'prop-types';
import cx from 'classnames';
import './CultureCard.scss';

const CultureCard = ({ className, tag, title, description }) => (
  <div className={cx('culture-card__container', { [className]: !!className })}>
    <span className="culture-card__tag">{tag}</span>
    <h3 className="culture-card__title">{title}</h3>
    <h3 className="culture-card__description">{description}</h3>
  </div>
);

CultureCard.propTypes = {
  className: string,
  tag: string,
  title: string,
  description: string,
};

export default CultureCard;
