import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'elements';
import { Epilog } from 'components';
import CurvedArrowSvg from 'images/icons/curved-arrow.svg';
import './AboutEpilog.scss';

const AboutEpilog = () => (
  <Epilog className="about-epilog" title="Want to build the future of banking?" variant="white">
    <div className="about-epilog__children">
      <Button
        className="about-epilog__button button--with-icon button--with-icon-left"
        size="medium"
        buttontype="hoverable"
        isCentered
        to="/careers/jobs"
        tagName={Link}
      >
        <div className="button__inner">
          <div className="button__icons">
            <CurvedArrowSvg className="button__icon" />
            <CurvedArrowSvg className="button__icon button__icon--next" />
          </div>
          <span>Join the team</span>
        </div>
      </Button>
    </div>
  </Epilog>
);

export default AboutEpilog;
