import React from 'react';
import { Title, Description } from 'elements';
import { oneOf } from 'prop-types';
import CheckSVG from 'images/icons/check-rounded.svg';
import CheckOrangeSVG from 'images/icons/check-rounded-orange.svg';
import CheckRedSVG from 'images/icons/check-rounded-red.svg';
import './FormSucceededView.scss';

const getIcon = (color) => {
  switch (color) {
    case 'purple':
      return CheckSVG;
    case 'red':
      return CheckRedSVG;
    case 'orange':
      return CheckOrangeSVG;
    default:
      return CheckSVG;
  }
};

export default function FormSucceededView({ variant, iconColor }) {
  const Icon = getIcon(iconColor);
  return (
    <div className="form_succeeded_view">
      <Icon className="form_succeeded_view__icon" />
      <Title
        tagName="div"
        sizeText="size-2"
        color={variant === 'black' ? 'white' : 'vulcan'}
        alignText="text-center"
        className="form_succeeded_view__title"
      >
        Thank you
      </Title>
      <Description
        sizeText="size-2"
        color={variant === 'black' ? 'white' : 'vulcan'}
        alignText="text-center"
        className="form_succeeded_view__description"
      >
        Thanks for getting in touch. A member of the Finto team will be in touch as soon as
        possible.
      </Description>
    </div>
  );
}

FormSucceededView.propTypes = {
  variant: oneOf(['black', 'white']),
  iconColor: oneOf(['purple', 'orange', 'red']),
};

FormSucceededView.defaultProps = {
  variant: 'white',
  iconColor: 'purple',
};
