const getRandomCount = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const LIST_DATA = [
  {
    id: 'jordan',
    data: [
      {
        title: 'API PLATFORM (BAAP)',
        data: Array(getRandomCount(5, 8)).fill({
          name: 'Feature name',
        }),
      },
      {
        title: 'DIGTIAL BANKING SERVICES (BAAS)',
        data: Array(getRandomCount(5, 5)).fill({
          name: 'Feature name',
        }),
      },
      {
        title: 'CARD ISSUANCE SERVICES (PAAS)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
    ],
  },
  {
    id: 'london',
    data: [
      {
        title: 'API PLATFORM (BAAP)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
      {
        title: 'DIGTIAL BANKING SERVICES (BAAS)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
      {
        title: 'CARD ISSUANCE SERVICES (PAAS)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
    ],
  },
  {
    id: 'berlin',
    data: [
      {
        title: 'API PLATFORM (BAAP)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
      {
        title: 'DIGTIAL BANKING SERVICES (BAAS)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
      {
        title: 'CARD ISSUANCE SERVICES (PAAS)',
        data: Array(getRandomCount(5, 7)).fill({
          name: 'Feature name',
        }),
      },
    ],
  },
];
