import React from 'react';
import { Tag, Title, Description, Container } from 'elements';
import './PaasSupport.scss';

const PaasSupport = () => (
  <section className="paas-support__section">
    <div className="paas-support__background-wrapper">
      <Container className="paas-support__container">
        <div className="paas-support__declaration">
          <Tag tagName="h2" align="center" color="purple" lineClassName="paas-support__tag-line">
            Global payments
          </Tag>
          <Title alignText="text-center" tagName="h2" sizeText="size-2" color="white">
            Multi currency support
          </Title>
          <Description alignText="text-center" sizeText="size-2" color="white">
            Issue customer cards in an array of currencies providing more tangible value and
            increasing customer retention.
          </Description>
        </div>
      </Container>
    </div>
  </section>
);

export default PaasSupport;
