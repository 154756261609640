import React from 'react';
import CardSvg from 'images/icons/card.svg';
import ArrowsSvg from 'images/icons/crossing-arrows.svg';
import UserSvg from 'images/icons/user.svg';

export const code0 = [
  <span className="code">
    <span className="lang-syntax">git clone</span>&nbsp;
    <span className="value">https://github.com/BankalEtihad/partner-api-sdk.git</span>
  </span>,
  <span className="code">
    <span className="lang-syntax">cd</span>&nbsp;<span className="value">partner-api-sdk</span>,
  </span>,
  '',
  <span className="comment">
    # Copy the .env.example file to .env, then fill out BASE_URI, CERT, KEY, CLIENT_ID,
    CLIENT_SECRET and ACCESS_TOKEN_URI
  </span>,
  <span className="code">
    <span className="lang-syntax">cp</span>&nbsp;
    <span className="value">.env.example .env</span>
  </span>,
  '',
  <span className="comment"># Install dependencies</span>,
  <span className="lang-syntax">npm install</span>,
];

export const code1 = [
  <span className="comment"># Retrieve config values from .env</span>,
  <span className="code">
    <span className="lang-syntax">
      require(<span className="value">&apos;dotenv&apos;</span>).config();
    </span>
  </span>,
  <span className="comment"># Dependency to upload certificate files</span>,
  <span className="code">
    <span className="lang-syntax">const</span> fs = <span className="lang-syntax">require</span>(
    <span className="value">&apos;fs&apos;</span>);
  </span>,
  <span className="comment"># Import Partner API sdk</span>,
  <span className="comment"># Initialize SDK instance</span>,
  <span className="code">
    <span className="lang-syntax">const</span> PartnerApi = require(
    <span className="value">&apos;partner-api-sdk&apos;</span>);
  </span>,
  <span className="code">
    <span className="code content--full-line">
      <span className="lang-syntax">const </span>
      client =<span className="lang-syntax"> new </span>
      <span className="object">PartnerApi</span>
      {`({`}
    </span>
    {/* eslint-disable-next-line */}
    <span className="comment content--full-line">// base uri for call Partner APIs</span>
    <span className="code content--full-line content--left-padding">
      <span className="key">baseUri</span>:&nbsp;<span className="value">process.env.BASE_URI</span>
    </span>
    {/* eslint-disable-next-line */}
    <span className="comment content--full-line content--left-padding">
      // .crt file for mtls settings,
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">cert</span>:&nbsp;
      <span className="value">
        <span className="code">fs.</span>
        <span className="method">readFileSync</span>
        <span className="code">(</span>process.env.CERT<span className="code">)</span>
      </span>
    </span>
    {/* eslint-disable-next-line */}
    <span className="comment content--full-line content--left-padding">
      // .key file for mtls settings
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">key</span>:&nbsp;
      <span className="value">
        <span className="code">fs.</span>
        <span className="method">readFileSync</span>
        <span className="code">(</span>process.env.KEY<span className="code">)</span>
      </span>
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">headers</span>:&nbsp;
      <span className="value">{`{}`}</span>,
    </span>
    {/* eslint-disable-next-line */}
    <span className="comment content--full-line content--left-padding">
      // OAuth 2.0 access token uri
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">accessTokenUri</span>:&nbsp;
      <span className="value">process.env.ACCESS_TOKEN_URI</span>,
    </span>
    {/* eslint-disable-next-line */}
    <span className="comment content--full-line content--left-padding">// OAuth 2.0 client id</span>
    <span className="code content--full-line content--left-padding">
      <span className="key">clientId</span>:&nbsp;
      <span className="value">process.env.CLIENT_ID</span>,
    </span>
    {/* eslint-disable-next-line */}
    <span className="comment content--full-line content--left-padding">
      // OAuth 2.0 client secret
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">clientSecret</span>:&nbsp;
      <span className="value">process.env.CLIENT_SECRET</span>,
    </span>
    <span className="code">{`});`}</span>
  </span>,
  /* eslint-disable-next-line */
  <span className="code">
    <span className="lang-syntax">const</span>&nbsp;customerId&nbsp;=&nbsp;
    <span className="value">&apos;07fd7591-9213-47be-bf6b-18954167dc0f&apos;</span>
  </span>,
];

export const code2 = [
  <span className="comment"># Issue a new card.</span>,
  <span className="comment"># JSON request body for card creation</span>,
  <span className="code">
    <span className="code content--full-line">
      <span className="lang-syntax">const</span>&nbsp;request =&nbsp;
      <span>{`{`}</span>
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">&quot;MobileNumber&quot;</span>:&nbsp;
      <span className="value">&quot;123456789&quot;</span>,
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">&quot;CardHolderName&quot;</span>:&nbsp;
      <span className="value">&quot;CARD HOLDER&quot;</span>,
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">&quot;CurrencyCode&quot;</span>:&nbsp;
      <span className="value">&quot;JOD&quot;</span>,
    </span>
    <span className="code content--full-line content--left-padding">
      <span className="key">
        &quot;CreditDetails&quot;
        <span className="code">:&nbsp;=&nbsp;{`{`}</span>
      </span>
    </span>
    <span className="code content--full-line content--double-left-padding">
      <span className="key">&quot;AccountNumber&quot;</span>:&nbsp;
      <span className="value">&quot;001002003004005&quot;</span>,
    </span>
    <span className="code content--full-line content--double-left-padding">
      <span className="key">&quot;Amount&quot;</span>:&nbsp;
      <span className="value">&quot;100&quot;</span>,
    </span>
    <span className="code content--full-line content--double-left-padding">{`}`}</span>
    <span className="code content--full-line">{`}`}</span>
  </span>,
  <span className="comment"># Call Partner API for card creation</span>,
  <span className="code">
    <span className="code content--full-line">
      client.customers.customerId(<span className="value">customerId</span>)
    </span>
    <span className="code content--full-line content--double-left-padding">
      .cards.create(<span className="value">request</span>, <span className="value">{`{}`}</span>)
    </span>
    <span className="code content--full-line content--double-left-padding">
      .<span className="method">then</span>(<span className="value">value</span> =&gt;&nbsp;
      <span className="lang-syntax">console</span>.<span className="method">log</span>(
      <span className="value">value.body</span>));
    </span>
  </span>,
];

export default [
  {
    head: {
      title: 'Installation',
      icon: UserSvg,
    },
    body: {
      title: 'Installation',
      description: '',
      data: code0,
    },
  },
  {
    head: {
      title: 'Initialization',
      icon: ArrowsSvg,
    },
    body: {
      title: 'Initialization',
      description: '',
      data: code1,
    },
  },
  {
    head: {
      title: 'Issue a new card',
      icon: CardSvg,
    },
    body: {
      title: 'Issue a new card',
      description: '',
      data: code2,
    },
  },
];
