import React from 'react';
import { Title, TriggerOnEnter } from 'elements';
import { func, object, string } from 'prop-types';
import cx from 'classnames';
import TermsPrivacy from '../../TermsPrivacy/TermsPrivacy';
import CookiesTable from '../../NestedTableSlider/CookiesTable';

const Content = ({ wayPointCallback, className, offset }) => (
  <div className={cx({ [className]: !!className })}>
    <div className="content-box">
      <p>
        Our website and digital channels use cookies to distinguish you from other users. This helps
        us to provide you with a good experience when you browse our website and allows us to
        improve our site. For further information on how we use, store and keep your personal data
        secure, see our Privacy Policy.
      </p>
      <p>
        A cookie is a small file of letters and numbers that we store on your browser or the hard
        drive of your computer if you agree. Cookies contain information that is transferred to your
        computer&apos;s hard drive.
      </p>
    </div>
    <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('we-use-the-following-cookies')}>
      <>
        <div className="content-box">
          <Title
            id="we-use-the-following-cookies"
            className="content__title content__title--3"
            tagName="h3"
          >
            We use the following cookies:
          </Title>
          <ul className="content__list">
            <li>
              <b>Strictly necessary cookies.</b> These are cookies that are required for the
              operation of our website. They include, for example, cookies that enable you to log
              into secure areas of our website, or make use of e-billing services.
            </li>
            <li>
              <b>Analytical or performance cookies.</b> These allow us to recognise and count the
              number of visitors and to see how visitors move around our website when they are using
              it.This helps us to improve the way our website works, for example, by ensuring that
              users are finding what they are looking for easily.
            </li>
            <li>
              <b>Functionality cookies.</b> These are used to recognise you when you return to our
              website. This enables us to personalise our content for you, greet you by name and
              remember your preferences (for example, your choice of language or region).
            </li>
          </ul>
          <p>
            You can find more information about the individual cookies we use and the purposes for
            which we use them in the table below:
          </p>
        </div>
        <CookiesTable />
        <div className="content-box">
          <p>
            You can find more information about the individual cookies we use and the purposes for
            which we use them in the table below:Please note that third parties may also use
            cookies, over which we have no control. These third parties may include, for example,
            advertising networks and providers of external services like web traffic analysis
            services. These third-party cookies are likely to be analytical cookies or performance
            cookies or targeting cookies
          </p>
          <p>
            To deactivate the use of third-party advertising cookies, you may visit the consumer
            page to manage the use of these types of cookies.
          </p>
          <p>
            You can block cookies by activating the setting on your browser that allows you to
            refuse the setting of all or some cookies. However, if you use your browser settings to
            block all cookies (including essential cookies) you may not be able to access all or
            parts of our website.
          </p>
        </div>
      </>
    </TriggerOnEnter>
  </div>
);

Content.propTypes = {
  wayPointCallback: func,
  offset: object,
  className: string,
};

Content.defaultProps = {
  wayPointCallback: () => {},
  offset: {
    topOffset: '0',
    bottomOffset: '0',
  },
};

function CookiesPolicy() {
  return <TermsPrivacy title="Cookie Policy" Content={Content} />;
}
export default CookiesPolicy;
