import React from 'react';
import { Logo, CookiesButton } from 'elements';
import { Subscription } from 'components';
import FooterMenu from './FooterMenu';
import FooterNav from './FooterNav';
import './Footer.scss';
import { listMenu } from './constants';

const FooterDesktop = () => (
  <footer className="footer">
    <div className="footer__top">
      <div className="footer__logo">
        <Logo type="type-3" />
      </div>
      <div className="container footer__container footer__container--top">
        <div className="footer__navigation">
          {listMenu &&
            listMenu.map((c) => (
              <div key={c.id} className="footer__item">
                <FooterMenu key={c.id} {...c} />
              </div>
            ))}
        </div>
        <div className="footer__info">
          <Subscription className="footer__subscription" title="NEWSLETTER" />
        </div>
      </div>
    </div>
    <div className="footer__bot">
      <div className="container footer__container footer__container--bot">
        <FooterNav />
        <CookiesButton />
      </div>
    </div>
  </footer>
);

export default FooterDesktop;
