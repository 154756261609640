import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import anime from 'animejs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Loader } from '../../elements';
import { handleAnimationProgressCbs } from '../../utils/animationHelpers';
import { ANIMATION_EASING_IN, ANIMATION_LONG_DURATION } from '../../constants/animations';
import { setFlag } from '../../redux/modules/flags/flags';
import { getScrollbarWidth } from '../../utils/helpers';

function handleDisableScroll(ref) {
  const scrollBarWidth = getScrollbarWidth();
  if (scrollBarWidth > 0) {
    document.body.style.paddingRight = `${scrollBarWidth}px`;
  }
  disableBodyScroll(ref.current);
}

function handleEnableScroll(ref) {
  document.body.style.paddingRight = '';
  enableBodyScroll(ref.current);
}

function InitialLoader() {
  const [isInitialLoaderHidden, setInitialLoaderHidden] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    const progressHandler = handleAnimationProgressCbs({
      80: () => {
        dispatch(setFlag('areAnimationsUnblocked', true));
      },
    });

    handleDisableScroll(ref);
    // schedules a new macrotask to avoid possible significant flickering
    // and non-stable animation on the initial load
    setTimeout(() => {
      anime({
        targets: ref.current,
        duration: ANIMATION_LONG_DURATION,
        delay: 1500,
        easing: ANIMATION_EASING_IN,
        keyframes: [{ clipPath: 'inset(0 0 0 0)' }, { clipPath: 'inset(0 0 100% 0)' }],
        update(anim) {
          progressHandler(anim);
        },
        complete() {
          handleEnableScroll(ref);
          setInitialLoaderHidden(true);
        },
      });
    }, 0);
  }, []);

  if (isInitialLoaderHidden) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="initial-loader"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <Loader />
    </div>
  );
}

export default InitialLoader;
