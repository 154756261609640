import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.4269 35.9742C25.4269 45.1861 38.6897 41.1652 38.6897 44.616C38.6897 45.6762 37.6294 46.2663 35.0189 46.2663C33.0485 46.2663 31.3281 45.8462 30.588 45.4561V44.1259H25.5669V50.3772H30.588C32.2183 50.5972 33.8587 50.7172 35.509 50.7472C43.1106 50.7472 45.6511 47.6466 45.6511 43.8558C45.6511 34.6939 32.2583 38.9048 32.2583 35.244C32.2583 34.3538 33.1685 33.6637 35.509 33.6637C37.3294 33.6637 39.0797 34.0838 39.8199 34.4738V35.8041H44.8409V29.5528H39.8199C38.2796 29.3328 36.7292 29.2128 35.1689 29.1827C29.3877 29.1827 25.4169 31.3032 25.4169 35.9842"
      fill="#D2D3D6"
    />
    <path
      d="M10.6638 29.5928V34.7639H14.0345V45.2461H10.6638V50.4172H24.0766V45.2461H20.7059V34.7639H24.0766V29.5928H10.6638Z"
      fill="#D2D3D6"
    />
    <path
      d="M57.9437 29.1827C51.3023 29.1827 46.5513 33.0436 46.5513 39.965C46.5513 46.8864 51.3023 50.7472 57.9437 50.7472C64.5851 50.7472 69.336 46.8864 69.336 39.965C69.336 33.0436 64.5851 29.1827 57.9437 29.1827ZM57.9437 45.8562C55.3131 45.8562 53.6328 44.1859 53.6328 39.975C53.6328 35.7641 55.3031 34.0938 57.9437 34.0938C60.5842 34.0938 62.2546 35.7641 62.2546 39.975C62.2546 44.1859 60.5842 45.8562 57.9437 45.8562Z"
      fill="#D2D3D6"
    />
    <path
      d="M39.6199 16C35.2089 16 30.758 17.2103 26.7572 19.7608C23.7966 21.6412 21.286 24.1617 19.3956 27.1223H21.136C22.2362 25.542 23.5365 24.1217 24.9968 22.8714C27.4473 23.5316 29.9378 24.0117 32.4584 24.2817C32.1583 25.1819 31.8882 26.1321 31.6382 27.1323H33.1085C33.3585 26.1721 33.6286 25.2719 33.9187 24.4318C35.8191 24.6018 37.7895 24.6918 39.7799 24.6918C41.7703 24.6918 43.5507 24.6118 45.351 24.4618C45.6411 25.2819 45.9012 26.1821 46.1512 27.1323H47.6315C47.3915 26.1521 47.1214 25.2119 46.8214 24.3217C49.3619 24.0617 51.8724 23.5916 54.3429 22.9314C55.7732 24.1617 57.0535 25.572 58.1337 27.1223H59.8741C55.3131 19.9308 47.5515 16 39.6199 16ZM32.9685 22.8814C30.758 22.6514 28.5675 22.2713 26.4171 21.7512C29.2977 19.6408 32.6384 18.2305 36.1591 17.6203C35.0089 18.7806 33.8587 20.631 32.9685 22.8814ZM39.7899 23.2415C37.9895 23.2415 36.1991 23.1615 34.4688 23.0215C35.9491 19.4707 37.8295 17.3403 39.6499 17.3403C41.4702 17.3403 43.3606 19.4807 44.8409 23.0515C43.1906 23.1815 41.5002 23.2515 39.7999 23.2515L39.7899 23.2415ZM46.3212 22.9114C45.4311 20.651 44.2708 18.7906 43.1206 17.6203C46.6713 18.2305 50.032 19.6608 52.9326 21.8012C50.7522 22.3113 48.5417 22.6814 46.3212 22.9114Z"
      fill="#D2D3D6"
    />
    <path
      d="M46.3112 57.1286C48.5217 57.3586 50.7122 57.7387 52.8626 58.2588C49.982 60.3692 46.6413 61.7795 43.1206 62.3897C44.2608 61.2294 45.4211 59.379 46.3112 57.1286ZM26.3371 58.2088C28.5175 57.6987 30.728 57.3286 32.9585 57.0986C33.8486 59.359 35.0089 61.2194 36.1591 62.3897C32.6084 61.7795 29.2377 60.3492 26.3371 58.2088ZM34.4488 56.9685C36.0991 56.8385 37.7795 56.7685 39.4898 56.7685C41.3002 56.7685 43.0806 56.8485 44.8209 56.9885C43.3406 60.5393 41.4602 62.6697 39.6399 62.6697C37.8195 62.6697 35.9291 60.5293 34.4488 56.9585M58.1337 52.8877C57.0335 54.468 55.7332 55.8983 54.2629 57.1386C51.8124 56.4784 49.3219 56.0083 46.8013 55.7283C47.1014 54.8281 47.3715 53.8779 47.6215 52.8777H46.1512C45.9012 53.8379 45.6311 54.7481 45.341 55.5782C43.4406 55.4082 41.4702 55.3182 39.4798 55.3182C37.4894 55.3182 35.709 55.3982 33.9087 55.5482C33.6186 54.7281 33.3585 53.8279 33.1085 52.8777H31.6382C31.8782 53.8579 32.1483 54.7981 32.4484 55.6883C29.9078 55.9583 27.3973 56.4184 24.9268 57.0786C23.4965 55.8383 22.2162 54.438 21.136 52.8877H19.3956C23.9566 60.0592 31.6882 63.98 39.5998 64H39.7099C44.1008 64 48.5417 62.7797 52.5125 60.2392C55.4732 58.3588 57.9837 55.8383 59.8741 52.8777H58.1337V52.8877Z"
      fill="#D2D3D6"
    />
  </svg>
);

export default SVG;
