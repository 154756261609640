import React from 'react';
import cx from 'classnames';
import { oneOf, bool } from 'prop-types';
import './DotButton.scss';

const DotButton = ({ color, active, ...rest }) => (
  <button
    type="button"
    className={cx('dot-button', {
      [`dot-button--${color}`]: !!color,
      'dot-button--active': !!active,
    })}
    {...rest}
  />
);

DotButton.propTypes = {
  color: oneOf(['purple', 'red', 'orange', 'coral']),
  active: bool,
};

DotButton.defaultProps = {
  color: 'purple',
};

export default DotButton;
