/* eslint-disable react/no-danger */
import React from 'react';
import { Form } from 'react-final-form';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import DOMPurify from 'dompurify';
import cx from 'classnames';
import { string } from 'prop-types';
import memoize from 'lru-memoize';
import { createValidator, email, required } from 'utils/validation';
import { Button, Input, Loader } from 'elements';
import './Subscription.scss';

const URL = `https://finto.us4.list-manage.com/subscribe/post?u=6ef8ff386a998888f7ba1cb78&id=388f72d626`;

const validate = memoize(10)(
  createValidator({
    EMAIL: [required, email],
  })
);

const Subscription = ({ title, className }) => (
  <MailchimpSubscribe
    url={URL}
    render={({ subscribe, status, message }) => (
      <Form
        onSubmit={(formData) => subscribe(formData)}
        validate={validate}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <div className={cx('subscription', className)}>
            {title && <div className="subscription__label">{title}</div>}
            <div className="subscription__form">
              <Input
                className="subscription__field"
                variant="secondary"
                name="EMAIL"
                type="email"
                placeholder="Email address"
              />
              <Button
                className="button"
                disabled={submitting || pristine}
                onClick={handleSubmit}
                size="tiny"
                type="submit"
                buttontype="hoverable"
                isCentered
              >
                <div className="button__inner">
                  {status === 'sending' ? (
                    <div className="subscription__loader">
                      <Loader width="30px" />
                    </div>
                  ) : (
                    <span>submit</span>
                  )}
                </div>
              </Button>
            </div>
            <div className="subscription__statuses-wrapper">
              {status === 'error' && (
                <div
                  className="subscription__error-text"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
                />
              )}
              {status === 'success' && (
                <div className="subscription__success-text">Subscribed successfully!</div>
              )}
            </div>
          </div>
        )}
      />
    )}
  />
);

Subscription.propTypes = {
  title: string,
  className: string,
};

export default Subscription;
