import React from 'react';
import { HelmetWrapper } from 'elements';
import { Terms as TermsSection } from 'components';

const Terms = () => (
  <>
    <HelmetWrapper helmetKey="terms" />
    <TermsSection />
  </>
);

export default Terms;
