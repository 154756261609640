import React from 'react';
import { node, bool, func, string } from 'prop-types';
import cx from 'classnames';
import './List.scss';

const ListItem = ({ after, children, className, onClick, disabled, ...rest }) => (
  <div
    className={cx('list__item', {
      [className]: !!className,
      'is-disabled': !!disabled,
    })}
    onClick={onClick}
    aria-hidden="true"
    {...rest}
  >
    {children}
    {after && <div className="list__item_after">{after}</div>}
  </div>
);

ListItem.defaultProps = {
  after: null,
  children: null,
  className: '',
  disabled: false,
  onClick: undefined,
};

ListItem.propTypes = {
  after: node,
  children: node,
  className: string,
  disabled: bool,
  onClick: func,
};

export default ListItem;
