import React from 'react';
import { useSelector } from 'react-redux';
import { Title, Description, Container, TriggerOnEnter, Button } from 'elements';
import Download from 'images/icons/download.svg';
import Lock from 'images/icons/lock.svg';
import Crosshair from 'images/icons/crosshair.svg';
import Wallet from 'images/icons/wallet.svg';
import Smile2 from 'images/icons/smile2.svg';
import Arows from 'images/icons/arows-l-r.svg';
import File from 'images/icons/file.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './CronicaBenefits.scss';

const CronicaBenefits = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="cronica-benefits">
      <Container className="cronica-benefits__container">
        <div className="cronica-benefits__label">
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="modern-banking__details">
              <Title
                tagName="h2"
                sizeText="size-8"
                color="white"
                alignText="text-center"
                className="modern-banking__title"
              >
                <div className="title__part">Benefits</div>
              </Title>
            </div>
          </TriggerOnEnter>
        </div>
        <div className="cronica-benefits__row">
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="cronica-benefits__details advantages-animations">
              <div className="cronica-benefits__icon title-icon">
                <Lock />
              </div>
              <Title
                tagName="h2"
                sizeText="size-1"
                color="white"
                className="cronica-benefits__title"
              >
                {/* do not remove space */}
                <div className="title__part">Secure and Transparent</div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                className="cronica-benefits__description"
              >
                Cronica uses blockchain technology to combine the security of a vault with the ease
                and convenience of a website. Documents can be verified by anyone with the
                associated ID, but only read by permissioned parties.
              </Description>
            </div>
          </TriggerOnEnter>
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="cronica-benefits__details advantages-animations">
              <div className="cronica-benefits__icon title-icon">
                <Crosshair />
              </div>
              <Title
                tagName="h2"
                sizeText="size-1"
                color="white"
                className="cronica-benefits__title"
              >
                {/* do not remove space */}
                <div className="title__part">Fraud Prevention</div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                className="cronica-benefits__description"
              >
                Cronica prevents fraud by eliminating physical authentication. Cronica does not rely
                upon manual handling or human intermediaries. Sensitive data is always protected.
              </Description>
            </div>
          </TriggerOnEnter>
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="cronica-benefits__details advantages-animations">
              <div className="cronica-benefits__icon title-icon">
                <Wallet />
              </div>
              <Title
                tagName="h2"
                sizeText="size-1"
                color="white"
                className="cronica-benefits__title"
              >
                {/* do not remove space */}
                <div className="title__part">Reduces Costs</div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                className="cronica-benefits__description"
              >
                Cronica drastically reduces the administrative burden of document generation,
                authentication, and retrieval, and minimizes the possibility of human error.
                Documents cannot be lost or mishandled.
              </Description>
            </div>
          </TriggerOnEnter>
        </div>
        <div className="cronica-benefits__row cronica-benefits__row--border">
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="cronica-benefits__details advantages-animations">
              <div className="cronica-benefits__icon title-icon">
                <Smile2 />
              </div>
              <Title
                tagName="h2"
                sizeText="size-1"
                color="white"
                className="cronica-benefits__title"
              >
                {/* do not remove space */}
                <div className="title__part">Improved User Experience</div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                className="cronica-benefits__description"
              >
                Cronica’s simple interface provides frictionless document authorization and
                authentication – through document ID and QR codes – to encourage end-user adoption.
              </Description>
            </div>
          </TriggerOnEnter>
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="cronica-benefits__details advantages-animations">
              <div className="cronica-benefits__icon title-icon">
                <Arows />
              </div>
              <Title
                tagName="h2"
                sizeText="size-1"
                color="white"
                className="cronica-benefits__title"
              >
                {/* do not remove space */}
                <div className="title__part">Digital Transformation</div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                className="cronica-benefits__description"
              >
                Use Cronica to empower your digital transformation strategies. Eliminate physical
                steps in authenticating your customer’s documents for greater document security and
                accuracy, and fewer operational risks.
              </Description>
            </div>
          </TriggerOnEnter>
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
            <div className="cronica-benefits__details advantages-animations">
              <div className="cronica-benefits__icon title-icon">
                <File />
              </div>
              <Title
                tagName="h2"
                sizeText="size-1"
                color="white"
                className="cronica-benefits__title"
              >
                {/* do not remove space */}
                <div className="title__part">programmable documents</div>
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                className="cronica-benefits__description"
              >
                Each Cronica document is programmable and can be verified, changed, or revoked in
                real-time. Check and verify ‘live’ documents and have their status reflect the time
                of verification, not the time of issuance.
              </Description>
            </div>
          </TriggerOnEnter>
        </div>
        <div className="cronica-benefits__download">
          <div className="cronica-benefits__presentation">
            <Button
              background="red"
              color="text-white"
              size="small-text"
              className="button--with-icon button--with-icon-download"
              isCentered
              download="Download_presentation.pdf"
              href="presentation2-2.pdf"
              tagName="a"
            >
              <div className="button__inner">
                <span>Download presentation</span>
                <div className="button__icons">
                  <Download className="button__icon" />
                  <Download className="button__icon button__icon--next" />
                </div>
              </div>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CronicaBenefits;
