import React from 'react';
import { EnterpriseForm } from 'components';
import { Container } from 'elements';
import './Enterprise.scss';

const Enterprise = () => (
  <section className="enterprise__section">
    <Container className="enterprise__container">
      <EnterpriseForm />
    </Container>
  </section>
);
export default Enterprise;
