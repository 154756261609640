/* eslint-disable no-shadow, react-hooks/exhaustive-deps */
import React from 'react';
import { Form } from 'react-final-form';
import memoize from 'lru-memoize';
import { string } from 'prop-types';
import { useForm } from '@formspree/react';
import { Button, Input, Title, Description } from 'elements';
import { createValidator, email as emailValidation, required } from 'utils/validation';
import FormWrapper from '../FormWrapper/FormWrapper';
import './GetInTouchForm.scss';

const validate = memoize(10)(
  createValidator({
    firstName: [required],
    lastName: [required],
    message: [required],
    email: [required, emailValidation],
  })
);

const GetInTouchForm = ({ parentId }) => {
  const [submittingState, submit] = useForm('mbjqggvd');
  return (
    <FormWrapper className="get-in-form__wrapper" rectElemId={parentId} {...submittingState}>
      <div className="get-in-form">
        <Form
          onSubmit={submit}
          validate={validate}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <Title
                className="get-in-form__title"
                tagName="h2"
                sizeText="size-5"
                alignText="text-center"
                color="vulcan"
              >
                Get in touch
              </Title>
              <Description
                className="get-in-form__description"
                alignText="text-center"
                color="vulcan"
                sizeText="size-2"
              >
                Need more info? Reach out to us anytime we will be more than happy to answer any
                questions and walk you through our platform and services.
              </Description>
              <div className="get-in-form__row get-in-form__row--double-col">
                <Input name="firstName" label="First name" />
                <Input name="lastName" label="Last name" />
              </div>
              <div className="get-in-form__row">
                <Input name="email" label="Email address" />
              </div>
              <div className="get-in-form__row">
                <Input name="message" NodeType="textarea" label="message" />
              </div>
              <Button
                disabled={submitting || pristine}
                className="get-in-form__button button--with-icon"
                background="black"
                color="text-white"
                size="medium"
                buttontype="hoverable"
                onClick={handleSubmit}
                isCentered
              >
                <div className="button__inner">
                  <span>Get started</span>
                </div>
              </Button>
            </>
          )}
        />
      </div>
    </FormWrapper>
  );
};
GetInTouchForm.propTypes = {
  parentId: string,
};

export default GetInTouchForm;
