import React from 'react';
import { string, node } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import data from '../../utils/helmet';

function HelmetWrapper({ helmetKey, children }) {
  if (helmetKey === 'missing') {
    console.warn('Missing meta title and description');
  }
  const { title, description } = data[helmetKey];
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* twitter */}
      <meta property="twitter:title" name="twitter:title" content={title} />
      <meta property="twitter:description" name="twitter:description" content={description} />
      {children}
    </Helmet>
  );
}

HelmetWrapper.propTypes = {
  children: node,
  helmetKey: string,
};

HelmetWrapper.defaultProps = {
  children: undefined,
  helmetKey: 'missing',
};

export default HelmetWrapper;
