import React from 'react';
import { PresentationView } from 'components';
import './HomePresentationView.scss';

const data = [
  {
    tag: 'Baas',
    title: 'Digital Banking',
    description:
      'Deploy innovative digital banking products at extraordinary speed with Finto’s API powered white label apps and banking license',
    itemType: 'phone',
    video: {
      mp4: '/videos/slider_1_mobile.mp4',
      poster: '/videos/slider_1_mobile.webp',
    },
    link: '/baas',
  },
  {
    tag: 'PAAS',
    title: 'Card Issuance',
    description:
      'Create a seamless payment experience for your customers with Finto’s white label debit, prepaid, virtual and loyalty cards.',
    itemType: 'card',
    video: {
      mp4: '/videos/slider_1_card.mp4',
      poster: '/videos/slider_1_card.webp',
    },
    link: '/paas',
  },
  {
    tag: 'Baap',
    title: 'API Platform',
    description:
      'Use Finto’s API platform to seamlessly integrate financial services into your digital products, access financial data, and generate new revenue streams.',
    itemType: 'browser',
    video: {
      mp4: '/videos/slider_1_browser.mp4',
      poster: '/videos/slider_1_browser.webp',
    },
    link: '/baap',
  },
];

export default () => (
  <PresentationView className="home-presentation-view" variant="white" align="left" data={data} />
);
