import React, { memo, useEffect, useState } from 'react';
import { TriggerOnEnter } from '../../elements';

// DO NOT RE-RENDER THE COMPONENT
// DUE TO USING THREEJS NATIVE CODE
function areEqual() {
  return true;
}

function CardsSelector() {
  const [isEntered, setIsEntered] = useState(false);
  useEffect(() => {
    // the require statement is crucial. do not change to import
    const Card = require('./Card').default;
    let cardInstance;
    if (isEntered) {
      cardInstance = new Card({
        canvas: document.querySelector('.cards-selector .webgl'),
        buttons: document.querySelectorAll('.cards-selector .circle'),
        container: document.querySelector('.cards-selector'),
        cardNameColors: [0xd46693, 0xee3e9c, 0xff8c60, 0x83bec6],
      });
    }

    return () => {
      cardInstance?.destroy?.();
    };
  }, [isEntered]);

  return (
    <TriggerOnEnter onEnter={() => setIsEntered(true)} bottomOffset="-5%">
      <div className="cards-selector">
        <div className="nav-wrapper">
          <nav>
            <div className="circle selected" />
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
          </nav>
        </div>
        <canvas className="webgl" />
      </div>
    </TriggerOnEnter>
  );
}

export default memo(CardsSelector, areEqual);
