/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Title, TriggerOnEnter } from 'elements';
import { func, object, string } from 'prop-types';
import cx from 'classnames';
import TermsPrivacy from '../../TermsPrivacy/TermsPrivacy';

function LegalStatement() {
  return <TermsPrivacy title="Legal statements" Content={Content} />;
}
export default LegalStatement;

const Content = ({ wayPointCallback, className, offset }) => (
  <div className={cx({ [className]: !!className })}>
    <div className="content-box">
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('privacy_policy')}>
        <>
          <Title className="content__title content__title--2" sizeText="size-3" tagName="h2">
            Privacy
          </Title>
          <Title id="privacy_policy" className="content__title content__title--3" tagName="h3">
            Finto’s privacy policy
          </Title>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            <b>“incididunt ut labore”</b> et dolore magna aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in&nbsp;
            <a>Reprehenderit in Voluptate</a> velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </>
      </TriggerOnEnter>
    </div>
    <div className="content-box">
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('data_retention')}>
        <>
          <Title className="content__title content__title--2" tagName="h2">
            Security
          </Title>
          <Title className="content__title content__title--3" tagName="h3" id="data-retention">
            Data retention
          </Title>
          <ul className="content__list">
            <li>
              Lorem ipsum dolor sit amet: <b>“consectetur adipiscing”</b> elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </li>
            <li>
              <b>Ut enim ad minim veniam</b> – quis nostrud exercitation ullamco laboris nisi.
            </li>
            <li>
              Duis aute irure dolor in reprehenderit in voluptate&nbsp;
              <a>velit esse cillum</a>&nbsp; dolore.
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </li>
          </ul>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('age_limitations')}>
        <>
          <Title className="content__title content__title--3" tagName="h3" id="age-limitations">
            Age limitations
          </Title>
          <ul className="content__list">
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore <b>“magna aliqua”</b>. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco.
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </li>
            <li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</li>
            <li>
              Lorem ipsum dolor sit amet, <b>“consectetur adipiscing”</b> elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </li>
            <li>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </li>
          </ul>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('disclose_information')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="how-we-disclose-information"
          >
            How we disclose information
          </Title>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut <b>(enim ad minim)</b> veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('use_information')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="how-we-use-information"
          >
            How we use information
          </Title>
          <p>
            Sed ut perspiciatis – unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
            architecto beatae vitae dicta sunt explicabo:
          </p>
          <ul className="content__list">
            <li>
              <a>Lorem ipsum dolor.</a>
            </li>
            <li>
              <a>Consectetur adipiscing elit.</a>
            </li>
            <li>
              <a>Ut enim ad minim veniam.</a>
            </li>
            <li>
              <a>Sed do eiusmod tempor incididunt.</a>
            </li>
          </ul>
        </>
      </TriggerOnEnter>
    </div>
    <div className="content-box">
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('privacy')}>
        <>
          <Title className="content__title content__title--2" tagName="h2">
            Your rights
          </Title>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="global-privacy-rights"
          >
            Global privacy rights
          </Title>
          <p className="additional-margin">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut <a>(enim ad minim)</a> veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('protection')}>
        <>
          <Title
            className="content__title content__title--3"
            tagName="h3"
            id="data-protection-authority"
          >
            Data protection authority
          </Title>
          <p className="additional-margin">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium{' '}
            <b>doloremque laudantium</b>, totam rem aperiam, eaque ipsa quae ab illo inventore
            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt.
          </p>
        </>
      </TriggerOnEnter>
      <TriggerOnEnter {...offset} onEnter={() => wayPointCallback('contacting_finto')}>
        <>
          <Title
            className="content__title content__title--3 content__title"
            tagName="h3"
            id="contacting-finto"
          >
            Contacting Finto
          </Title>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore&nbsp;
            <a>lorem@finto.co</a>. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </>
      </TriggerOnEnter>
    </div>
  </div>
);

Content.propTypes = {
  wayPointCallback: func,
  offset: object,
  className: string,
};

Content.defaultProps = {
  wayPointCallback: () => {},
  offset: {
    topOffset: '0',
    bottomOffset: '0',
  },
};
