/* eslint-disable camelcase */
import React from 'react';
import { string, oneOf } from 'prop-types';
import cx from 'classnames';
import './JobCard.scss';

const JobCard = ({ role, location, team, contract_type, date, variant, className }) => (
  <div className={cx('job-card', `job-card--${variant}`, { [className]: !!className })}>
    <span className="job-card__role">{role}</span>
    <div className="job-card__info">
      <div className="job-card__info-wrap">
        <span className="job-card__info-name">Team</span>
        <span className="job-card__info-value">{team}</span>
      </div>
      <div className="job-card__info-wrap">
        <span className="job-card__info-name">Location</span>
        <span className="job-card__info-value">{location}</span>
      </div>
      <div className="job-card__info-wrap">
        <span className="job-card__info-name">Contract type</span>
        <span className="job-card__info-value">{contract_type}</span>
      </div>
      <div className="job-card__info-wrap">
        <span className="job-card__info-name">Deadline</span>
        <span className="job-card__info-value">{date}</span>
      </div>
    </div>
  </div>
);

JobCard.propTypes = {
  role: string,
  location: string,
  team: string,
  contract_type: string,
  date: string,
  className: string,
  variant: oneOf(['white', 'black']),
};

JobCard.defaultProps = {
  variant: 'white',
  className: '',
};

export default JobCard;
