import React from 'react';
import { Link } from 'react-router-dom';
import { Button, GetStarted } from 'elements';
import { Epilog } from 'components';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './BaasEpilog.scss';

const BaasEpilog = () => (
  <Epilog title="Start building the future of finance now" variant="black">
    <div className="baas-epilog__children">
      <Button
        background="white"
        border="border-bold-black"
        color="text-black"
        size="medium"
        className="baas-epilog__button button--with-icon"
        to="/contact"
        tagName={Link}
        buttontype="hoverable"
        isCentered
      >
        <div className="button__inner">
          <span>Contact sales</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </Button>
      <GetStarted
        background="transparent"
        border="border-bold-white"
        color="text-white"
        size="medium"
        className="baas-epilog__button baas-epilog__button--last button--with-icon"
        to="https://developer.finto.io/"
        buttontype="hoverable"
        isCentered
        target="_blank"
      >
        <div className="button__inner">
          <span>Get api access</span>
          <div className="button__icons">
            <ArrowSvg className="button__icon" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </GetStarted>
    </div>
  </Epilog>
);

export default BaasEpilog;
