import React from 'react';
import { GetStarted, Tag, Title, Description, Container } from 'elements';
import { CardsSelector } from 'components';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './PaasCustom.scss';

const PaasCustom = () => (
  <section className="paas-custom__section">
    <Container className="paas-custom__container">
      <div className="paas-custom__show-room">
        <CardsSelector />
      </div>
      <div className="paas-custom__declaration">
        <Tag>Your brand</Tag>
        <Title tagName="h2" sizeText="size-2" color="vulcan">
          Endlessly customisable
        </Title>
        <Description sizeText="size-2">
          Create a premium card experience for your product and your users. Design your cards to
          reflect your brand, and we’ll take care of the issuing, production and delivery.
        </Description>
        <div className="paas-custom__buttons">
          <GetStarted
            to="https://developer.finto.io/"
            target="_blank"
            background="black"
            border="border-bold-black"
            color="text-white"
            size="medium"
            className="paas-custom__button button--with-icon"
            buttontype="hoverable"
            isCentered
          >
            <div className="button__inner">
              <span>Get started</span>
              <div className="button__icons">
                <ArrowSvg className="button__icon" />
                <ArrowSvg className="button__icon button__icon--next" />
              </div>
            </div>
          </GetStarted>
        </div>
      </div>
    </Container>
  </section>
);

export default PaasCustom;
