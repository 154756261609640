import React from 'react';
import { Link } from 'react-router-dom';
import { array, string } from 'prop-types';
import './FooterMenu.scss';

const FooterMenu = ({ list, title }) => (
  <div className="footer_menu">
    {title && <div className="footer_menu__title">{title}</div>}
    <div className="footer_menu__list">
      {list &&
        list.map((c, i) => (
          <div key={i.toString()} className="footer_menu__list_item">
            {c.target === 'self' ? (
              <Link to={c.url}>{c.name}</Link>
            ) : (
              <a href={c.url} target="_blank" rel="noopener noreferrer">
                {c.name}
              </a>
            )}
          </div>
        ))}
    </div>
  </div>
);

FooterMenu.defaultProps = {
  list: [],
  title: '',
};

FooterMenu.propTypes = {
  list: array,
  title: string,
};

export default FooterMenu;
