import React from 'react';
import { useSelector } from 'react-redux';
import {
  Title,
  Description,
  GetStarted,
  Image,
  Container,
  TriggerOnEnter,
  BasicScroll,
} from 'elements';
import ChartSvg from 'images/icons/chart.svg';
import phone1 from 'images/phones/phone-regular-black.webp';
import phone2 from 'images/phones/phone-accelerate.webp';
import phone3 from 'images/phones/phone-purple-black.webp';
import phone4 from 'images/phones/parallax-phone.webp';
import phone5 from 'images/phones/phone-purple-head.webp';
import phone6 from 'images/phones/phone-coral.webp';

import phoneMobile1 from 'images/mobile/phones/phone-regular-black.webp';
import phoneMobile2 from 'images/mobile/phones/phone-accelerate.webp';
import phoneMobile3 from 'images/mobile/phones/phone-purple-black.webp';
import phoneMobile4 from 'images/mobile/phones/parallax-phone.webp';
import phoneMobile5 from 'images/mobile/phones/phone-purple-head.webp';
import phoneMobile6 from 'images/mobile/phones/phone-coral.webp';

import ArrowSvg from 'images/icons/arrowTop.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './HomeAccelerate.scss';

const HomeAccelerate = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="home_accelerate" variant="black">
      <div className="home_accelerate__background-wrapper">
        <Container className="home_accelerate__container">
          <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="5%">
            <div className="home_accelerate__details advantages-animations">
              <div className="home_accelerate__icon title-icon">
                <ChartSvg />
              </div>
              <Title
                tagName="h2"
                sizeText="size-2"
                color="white"
                alignText="text-center"
                className="home_accelerate__title title__part"
              >
                Accelerate growth
              </Title>
              <Description
                sizeText="size-2"
                color="white"
                alignText="text-center"
                className="home_accelerate__description"
              >
                Outstrip the competition with the latest features, proven to increase customer
                acquisition and retention.
              </Description>
              <div className="buttons-holder">
                <GetStarted
                  className="home_accelerate__button button--with-icon"
                  to="https://developer.finto.io/"
                  target="_blank"
                  buttontype="gradient-hoverable"
                  color="text-white"
                  size="medium"
                  isCentered
                >
                  <div className="button__inner">
                    <span>Get started</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </GetStarted>
              </div>
            </div>
          </TriggerOnEnter>
        </Container>
        <BasicScroll
          props={{
            '--parallax-tx': {
              from: '5%',
              to: '-60%',
            },
            '--parallax-tx-tablet-max': {
              from: '20%',
              to: '-20%',
            },
          }}
        >
          <div className="home_accelerate__images parallax parallax--horizontal">
            <div className="home_accelerate__img">
              <Image src={phone1} srcMobile={phoneMobile1} alt="phone" />
            </div>
            <div className="home_accelerate__img">
              <Image src={phone2} srcMobile={phoneMobile2} alt="phone" />
            </div>
            <div className="home_accelerate__img">
              <Image src={phone3} srcMobile={phoneMobile3} alt="phone" />
            </div>
            <div className="home_accelerate__img">
              <Image src={phone4} srcMobile={phoneMobile4} alt="phone" />
            </div>
            <div className="home_accelerate__img">
              <Image src={phone5} srcMobile={phoneMobile5} alt="phone" />
            </div>
            <div className="home_accelerate__img">
              <Image src={phone6} srcMobile={phoneMobile6} alt="phone" />
            </div>
          </div>
        </BasicScroll>
      </div>
    </section>
  );
};

export default HomeAccelerate;
