import React from 'react';
import CrossSvg from 'images/icons/cross-rounded-purple.svg';
import './styles.scss';
import { array, number, string } from 'prop-types';

const FormResultElements = ({
  data = [],
  typeLabel = '',
  typeAllLabel = '',
  onItemPress,
  PostElementComponent = DefaultPostElementComponent,
}) => {
  if (!data.length) return <EmptyData typeAllLabel={typeAllLabel} typeLabel={typeLabel} />;
  return data.map((item, index) => {
    const isFirst = index === 0;
    return (
      <span className="form-result__wrapper" key={item.label}>
        {isFirst && <FirstResultPreElement />}
        <span className="form-result__value" onClick={() => onItemPress(item.value)}>
          {item.label}
          <CrossSvg />
        </span>
        <PostElementComponent currenElementindex={index} data={data} />
      </span>
    );
  });
};

DefaultPostElementComponent.propTypes = {
  currenElementindex: number,
  data: array,
};

function DefaultPostElementComponent({ currenElementindex, data }) {
  const isLast = currenElementindex + 1 === data.length;
  return (
    <>
      {!isLast && ','}
      <WhiteSpace />
    </>
  );
}

export const WhiteSpace = () => <>&nbsp;</>;

const FirstResultPreElement = () => (
  <span>
    {/* <WhiteSpace /> */}
    in
    <WhiteSpace />
  </span>
);

EmptyData.propTypes = {
  typeLabel: string,
  typeAllLabel: string,
};

function EmptyData({ typeLabel, typeAllLabel }) {
  return (
    <span>
      in
      <WhiteSpace />
      {!!typeLabel && (
        <>
          <span className="form-result__value">{typeLabel}</span>
          <WhiteSpace />
        </>
      )}
      {typeAllLabel},
      <WhiteSpace />
    </span>
  );
}

export default FormResultElements;
