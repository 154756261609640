import { createSelector } from 'reselect';
import { STATE_KEY } from './forms';

const getState = (state) => state[STATE_KEY];

// getting site key
export const getSiteKeyState = (state) => getState(state).gettingKey;

export const isGettingSiteKey = createSelector(
  getSiteKeyState,
  (thatState) => thatState.loading && !thatState.loaded
);

export const getSiteKeyError = (state) => getSiteKeyState(state).error;

export const getSiteKey = (state) => getSiteKeyState(state).siteKey;

// getting token

export const getTokenState = (state) => getState(state).gettingToken;

export const isGettingToken = createSelector(
  getTokenState,
  (thatState) => thatState.loading && !thatState.loaded
);

export const getTokenError = (state) => getTokenState(state).error;

// registration
export const getRegistrationState = (state) => getState(state).registration;

export const isRegistrationProcess = createSelector(
  getRegistrationState,
  (thatState) => thatState.loading && !thatState.loaded
);

export const getRegistrationError = (state) => getRegistrationState(state).error;

// subscription
export const getSubscriptionState = (state) => getState(state).subscription;

export const isSubscriptionProcess = createSelector(
  getSubscriptionState,
  (thatState) => thatState.loading && !thatState.loaded
);

export const getSubscriptionError = (state) => getSubscriptionState(state).error;

//  common
export const isFormLoading = createSelector(
  [isGettingSiteKey, isGettingToken, isRegistrationProcess],
  (isKeyProcess, isTokenProcess, isRegistration) => isKeyProcess || isTokenProcess || isRegistration
);

export const selectFormError = createSelector(
  [getTokenError, getSiteKeyError, getRegistrationError],
  (tokenError, siteKeyError, registrationError) => tokenError || siteKeyError || registrationError
);

export const getStoredEmail = (state) => getState(state).userEmail;
