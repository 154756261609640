import React from 'react';
import { PresentationView } from 'components';

const data = [
  {
    tag: 'Baap',
    title: 'API Platform',
    description:
      'Use Finto’s API platform to seamlessly integrate financial services into your digital products, access financial data, and generate new revenue streams.',
    itemType: 'browser',
    video: {
      mp4: '/videos/slider_2_browser.mp4',
      poster: '/videos/slider_2_browser.webp',
    },
    link: '/baap',
  },
  {
    tag: 'Baas',
    title: 'Digital Banking',
    description:
      'Deploy innovative digital banking products at extraordinary speed with Finto’s API powered white label apps and banking license',
    itemType: 'phone',
    video: {
      mp4: '/videos/slider_2_mobile.mp4',
      poster: '/videos/slider_2_mobile.webp',
    },
    link: '/baas',
  },
];

export default () => <PresentationView className="paas-presentation" variant="black" data={data} />;
