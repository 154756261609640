import React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { bool, string, func, array } from 'prop-types';
import { GetStarted, Accordion, AccordionItem, Logo } from 'elements';
import { Contact, TagArrow, Products } from 'icons';
import ChevronSvg from 'images/icons/chevron.svg';
import CrossSvg from 'images/icons/cross.svg';
import { isHttps } from '../../utils/helpers';
import './MenuMobile.scss';

const MobileMenu = ({ isVisible, closeMenu, isHeaderWhite, listMenu }) => (
  <div
    className={cx(
      'mobile-menu',
      { 'mobile-menu--visible': !!isVisible },
      { 'mobile-menu--white': !!isHeaderWhite }
    )}
  >
    <div className="mobile-menu__container">
      <div className="mobile-menu__box">
        <div className="mobile-menu__header">
          <div className="mobile-menu__logo">
            <Logo onClick={closeMenu} type={isHeaderWhite ? 'type-2' : 'type-1'} />
          </div>
          <button onClick={closeMenu} className="mobile-menu__button" type="button">
            <CrossSvg
              className={cx('mobile-menu__button-icon', {
                'mobile-menu__button-icon--white': !!isHeaderWhite,
              })}
            />
          </button>
        </div>
        <div className="mobile-menu__list">
          <Accordion noAnimateSlide>
            {listMenu.map((c, indx) => (
              <AccordionItem
                key={indx.toString()}
                DefaultIcon={c.innerList && ChevronSvg}
                ActiveIcon={c.innerList && ChevronSvg}
                className={cx({ 'accordion__item--with-icon': !!c.innerList })}
                title={
                  <div className="mobile-menu__link mobile-menu__link--main">
                    <Item onClick={closeMenu} {...c} />
                  </div>
                }
              >
                {c.innerList &&
                  c.innerList.map((el, index) => (
                    <div
                      className="mobile-menu__link mobile-menu__link--sub"
                      key={index.toString()}
                    >
                      <Item onClick={closeMenu} {...el} />
                    </div>
                  ))}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
      <GetStarted
        onClick={closeMenu}
        background={isHeaderWhite ? 'white' : 'vulcan'}
        border={!isHeaderWhite ? 'border-bold-white' : 'border-bold-black'}
        color={isHeaderWhite ? 'text-black' : 'text-white'}
        size="medium"
        className="mobile-menu__bottom-button button--with-icon"
        to="https://developer.finto.io/"
        target="_blank"
      >
        Get started
      </GetStarted>
    </div>
  </div>
);

MobileMenu.propTypes = {
  isVisible: bool,
  closeMenu: func,
  isHeaderWhite: bool,
  listMenu: array,
};

const Item = ({ url, title, name, Icon, onClick }) => {
  const history = useHistory();
  const onItemPress = () => {
    if (isHttps(url)) {
      window.open(url, '_blank').focus();
      onClick();
    } else if (url?.length) {
      history.push(url);
      onClick();
    }
    return null;
  };

  const getIcon = () => {
    switch (name) {
      case 'Contact':
        return <Contact id="contact_icon_mobile_menu" />;
      case 'Developers':
        return <TagArrow id="mobile_menu" />;
      case 'Products':
        return <Products id="mobile_menu" />;
      default:
        return <Icon />;
    }
  };

  return (
    <div onClick={onItemPress}>
      {Icon && <span className="svg-wrap">{getIcon()}</span>}
      <span>{title || name}</span>
    </div>
  );
};

Item.propTypes = {
  url: string,
  title: string,
  name: string,
  Icon: func,
  onClick: func,
};

export default MobileMenu;
