import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Title, Description, Container, TriggerOnEnter } from 'elements';
import { Standart1, Standart2, Standart3, Standart4, Standart5, Standart6, Standart7 } from 'icons';
import ArrowSvg from 'images/icons/arrowTop.svg';

import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './CronicaIntegration.scss';

const CronicaIntegration = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="cronica-integration__section">
      <Container className="cronica-integration__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked}>
          <div className="cronica-integration__top-content hero-animations">
            <div className="cronica-integration__declaration">
              <Title alignText="left" tagName="h1" sizeText="size-8" color="white">
                <div className="title__part">Integration</div>
              </Title>
              <Description sizeText="size-2" color="white">
                Finto Cronica can be integrated into existing infrastructure, as a standalone or
                supplementary authentication solution
              </Description>
              <div className="cronica-integration__buttons buttons-holder">
                <Button
                  // border="border-white"
                  color="text-white"
                  background="red"
                  size="small-text"
                  className="cronica-integration__button button--with-icon"
                  to="/contact"
                  tagName={Link}
                  isCentered
                >
                  <div className="button__inner">
                    <span>Contact us</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </Button>
              </div>
            </div>
            <div className="cronica-integration__images">
              <div className="cronica-integration__box">
                <div className="cronica-integration__standart-1">
                  <Standart1 />
                </div>
                <div className="cronica-integration__standart-2">
                  <Standart2 />
                </div>
                <div className="cronica-integration__standart-3">
                  <Standart3 />
                </div>
                <div className="cronica-integration__standart-4">
                  <Standart4 />
                </div>
                <div className="cronica-integration__standart-5">
                  <Standart5 />
                </div>
                <div className="cronica-integration__standart-6">
                  <Standart6 />
                </div>
                <div className="cronica-integration__standart-7">
                  <Standart7 />
                </div>
              </div>
            </div>
          </div>
        </TriggerOnEnter>
      </Container>
    </section>
  );
};

export default CronicaIntegration;
