/* eslint-disable no-shadow, react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { bool, func, string } from 'prop-types';
import memoize from 'lru-memoize';
import {
  isFormLoading,
  selectFormError,
  getSiteKey,
  getStoredEmail,
} from 'modules/forms/formsSelectors';
import { actions } from 'modules/forms/forms';
import { Button, Input, Loader, Title, Description } from 'elements';
import { createValidator, email as emailValidation, required } from 'utils/validation';
import GithubSvg from 'images/icons/github-regular.svg';
import GoogleSvg from 'images/icons/google.svg';

const URL = 'https://developer.finto.io/login';
const AUTH_WITH_GOOGLE_URL = `${URL}?authway=google`;
const AUTH_WITH_GITHUB_URL = `${URL}?authway=github`;

const validate = memoize(10)(
  createValidator({
    email: [required, emailValidation],
  })
);

const mapStateToProps = (state) => ({
  isLoading: isFormLoading(state),
  error: selectFormError(state),
  siteKey: getSiteKey(state),
  userStoredEmail: getStoredEmail(state),
});

const mapDispatchToProps = {
  getSiteKey: actions.getSiteKey,
  getToken: actions.getToken,
  registration: actions.registration,
  clear: actions.clear,
};

const ERROR_TEXT = 'Failed to finish registration. Please try again later.';

const SignUpForm = ({
  siteKey,
  isLoading,
  error,
  getSiteKey,
  getToken,
  registration,
  clear,
  userStoredEmail,
}) => {
  const recaptchaRef = useRef();
  const formRef = useRef();
  const [localError, setLocalError] = useState('');
  const [isRegistrationFinished, setRegistrationIsFinished] = useState(false);

  useEffect(() => {
    getSiteKey();
    return () => {
      clear();
    };
  }, []);

  const redirectTo = (key) => {
    const dir = key === 'google' ? AUTH_WITH_GOOGLE_URL : AUTH_WITH_GITHUB_URL;
    window.open(dir, '_blank').focus();
  };

  const submit = async ({ email }) => {
    setLocalError('');
    if (!recaptchaRef.current) return;
    const captchaValue = await recaptchaRef.current.executeAsync();
    getToken({ captchaValue }).then((res) => {
      const {
        data: { token },
      } = res;
      registration({ email, token }).then(() => setRegistrationIsFinished(true));
    });
  };

  const handleReset = () => {
    clear();
    formRef.current.reset();
    setRegistrationIsFinished(false);
    getSiteKey();
  };

  return (
    <>
      <Info
        handleReset={handleReset}
        flag={isRegistrationFinished}
        userStoredEmail={userStoredEmail}
      />
      {isLoading && !error && (
        <div className="started_sing_up__loader">
          <Loader width="50px" />
        </div>
      )}
      {!isRegistrationFinished && (
        <>
          <Form
            onSubmit={submit}
            validate={validate}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine, form }) => {
              formRef.current = form;
              return (
                <>
                  {siteKey && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={siteKey}
                      onErrored={() => setLocalError(ERROR_TEXT)}
                    />
                  )}
                  <div className="started_sing_up__row">
                    <Input name="email" label="Email address" />
                  </div>
                  <div className="started_sing_up__row started_sing_up__row--submit">
                    <Button
                      type="submit"
                      disabled={submitting || pristine}
                      onClick={handleSubmit}
                      className="started_sing_up__account-button"
                      color="text-white"
                      size="medium"
                      display="block"
                      buttontype="gradient-hoverable"
                      isCentered
                    >
                      <div className="button__inner">
                        <span>Create account</span>
                      </div>
                    </Button>
                  </div>
                </>
              );
            }}
          />
          {(error || localError) && (
            <div className="started_sing_up__error">{error || localError}</div>
          )}
        </>
      )}
      <div className="started_sing_up__authway authway">
        <span className="authway__title">Or login in with</span>
        <div className="authway__links-group">
          <Button
            onClick={() => redirectTo('github')}
            size="small"
            className="authway__link"
            buttontype="hoverable"
            isCentered
          >
            <div className="button__inner">
              <GithubSvg className="authway__icon" />
              <span>Git hub</span>
            </div>
          </Button>
          <Button
            onClick={() => redirectTo('google')}
            size="small"
            className="authway__link"
            buttontype="hoverable"
            isCentered
          >
            <div className="button__inner">
              <GoogleSvg className="authway__icon" />
              <span>Google</span>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

SignUpForm.propTypes = {
  siteKey: string,
  isLoading: bool,
  error: string,
  userStoredEmail: string,
  getSiteKey: func.isRequired,
  getToken: func.isRequired,
  registration: func.isRequired,
  clear: func.isRequired,
};

const Info = ({ flag, userStoredEmail, handleReset }) => {
  if (flag) {
    return (
      <div className="started_sing_up__details">
        <Title
          tagName="h2"
          sizeText="size-4"
          color="black"
          alignText="text-center"
          className="started_sing_up__title"
        >
          Welcome!
        </Title>
        <Description
          className="started_sing_up__description started_sing_up__description--reset"
          sizeText="size-1"
          color="black"
          alignText="text-center"
        >
          We have received your registration and send an email to {userStoredEmail} to confirm your
          identity. Please check your SPAM folder in case no email appeared.
        </Description>
        <Button
          onClick={handleReset}
          display="block"
          background="dark"
          border="border-dark"
          color="text-white"
          size="medium"
          className="started_sing_up__reset-button"
        >
          Back
        </Button>
      </div>
    );
  }
  return (
    <div className="started_sing_up__details">
      <Title
        tagName="h1"
        sizeText="size-4"
        color="black"
        alignText="text-center"
        className="started_sing_up__title"
      >
        Create account
      </Title>
      <Description
        className="started_sing_up__description"
        sizeText="size-1"
        color="black"
        alignText="text-center"
      >
        Create an account to get instant access to Finto’s APIs, SDKs and developer resources.
      </Description>
    </div>
  );
};
Info.propTypes = {
  flag: bool,
  userStoredEmail: string,
  handleReset: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
