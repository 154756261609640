import React from 'react';
import { Logo, Accordion, AccordionItem, CookiesButton } from 'elements';
import { Subscription } from 'components';
import PlusSvg from 'images/icons/plus.svg';
import MinusSvg from 'images/icons/minus.svg';
import FooterMenu from './FooterMenu';
import FooterNav from './FooterNav';
import './Footer.scss';
import { listMenu } from './constants';

const FooterMobile = () => (
  <footer className="footer">
    <div className="footer__top">
      <div className="container footer__container footer__container--top">
        <div className="footer__navigation">
          <Accordion noAnimateSlide>
            {listMenu &&
              listMenu.map((c) => (
                <AccordionItem
                  key={c.id}
                  title={c.title}
                  position={c.position}
                  DefaultIcon={PlusSvg}
                  ActiveIcon={MinusSvg}
                >
                  <FooterMenu key={c.id} {...c} title={null} />
                </AccordionItem>
              ))}
          </Accordion>
        </div>
        <div className="footer__info">
          <Subscription className="footer__subscription" title="NEWSLETTER" />
        </div>
      </div>
    </div>
    <div className="footer__bot">
      <div className="container footer__container footer__container--bot">
        <FooterNav />
        <CookiesButton />
        <div className="footer__logo">
          <Logo type="type-4" />
        </div>
      </div>
    </div>
  </footer>
);

export default FooterMobile;
