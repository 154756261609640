import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Title, Description, Button, Image } from 'elements';
import heroImage from 'images/culture/main.webp';
import heroImageMobile from 'images/mobile/culture/main.webp';
import ArrowSvg from 'images/icons/arrowTop.svg';
import './CultureHero.scss';

const CultureHero = () => (
  <section className="culture-hero__section">
    <Container className="culture-hero__container">
      <Title tagName="h1" sizeText="size-5" alignText="text-center" className="culture-hero__title">
        Build with the best
      </Title>
      <Description sizeText="size-2" alignText="text-center" className="culture-hero__description">
        We are always on the lookout for talented people to join us, check out our{' '}
        <Link to="/careers/jobs">jobs board</Link> to see our open positions. Can’t find the perfect
        role for you? Email your CV and cover letter to{' '}
        <a className="description__link--decorated" href="mailto:work@finto.io">
          work@finto.io
        </a>
      </Description>
      <div className="culture-hero__button-wrapper">
        <Button
          className="jobs-life__button button--with-icon"
          to="/careers/hiring"
          buttontype="gradient-hoverable"
          color="text-white"
          size="medium"
          isCentered
          tagName={Link}
        >
          <div className="button__inner">
            <span>hiring process</span>
            <div className="button__icons">
              <ArrowSvg className="button__icon" />
              <ArrowSvg className="button__icon button__icon--next" />
            </div>
          </div>
        </Button>
      </div>
    </Container>
    <div className="culture-hero__image-wrapper">
      <Image
        className="culture-hero__image"
        src={heroImage}
        srcMobile={heroImageMobile}
        alt="company"
      />
    </div>
  </section>
);

export default CultureHero;
