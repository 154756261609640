import React from 'react';
import { HelmetWrapper } from 'elements';
import { JobsHero, CultureLife, JobsEpilog } from 'components';

const Jobs = () => (
  <>
    <HelmetWrapper helmetKey="jobs" />
    <JobsHero />
    <CultureLife variant="black" />
    <JobsEpilog />
  </>
);

export default Jobs;
