import React from 'react';
import { GetStarted, Tag, Title, Description, Container, Image } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import phone from 'images/phones/phone-card-settings.webp';
import phoneMobile from 'images/mobile/phones/phone-card-settings.webp';
import BankCardSvg from 'images/bank_cards/red-card-cards-page.svg';
import './PaasControl.scss';

const PaasControl = () => (
  <section className="paas-control__section">
    <Container className="paas-control__container">
      <div className="paas-control__declaration">
        <Tag>EMPOWER USERS</Tag>
        <Title tagName="h2" sizeText="size-2" color="vulcan">
          Dynamic card control
        </Title>
        <Description sizeText="size-2">
          Finto’s API gives you unprecedented levels of control; let users set spending limits,
          change PIN, order replacements, block and unblock cards effortlessly.
        </Description>
        <div className="paas-control__buttons">
          <GetStarted
            to="https://developer.finto.io/"
            target="_blank"
            background="black"
            border="border-bold-black"
            color="text-white"
            size="medium"
            className="paas-control__button button--with-icon"
            buttontype="hoverable"
            isCentered
          >
            <div className="button__inner">
              <span>Get started</span>
              <div className="button__icons">
                <ArrowSvg className="button__icon" />
                <ArrowSvg className="button__icon button__icon--next" />
              </div>
            </div>
          </GetStarted>
        </div>
      </div>
      <div className="paas-control__images">
        <div className="paas-control__image">
          <Image src={phone} srcMobile={phoneMobile} alt="phone-settings" />
        </div>
        <BankCardSvg className="paas-control__card" />
      </div>
    </Container>
  </section>
);

export default PaasControl;
