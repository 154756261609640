import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as reducers from './modules';

export default function createReducer({ history = undefined }) {
  return combineReducers({
    router: connectRouter(history),
    ...reducers,
  });
}
