import React from 'react';
import { Container, Title, Tag, ResourceCard, ContentSwitcher, ProgressSlider } from 'elements';
import image1 from 'images/dev/dev-code.webp';
import image2 from 'images/dev/dev-api.webp';
import image3 from 'images/dev/dev-disc.webp';
import './DevelopersResources.scss';

const data = [
  {
    title: 'Quick start guide',
    desc: 'Get up running fast with the Finto quick start guide.',
    image: image1,
    bgType: 'purple',
  },
  {
    title: 'API reference',
    desc: 'Dive into the detail with the complete Finto API reference guide.',
    image: image2,
    bgType: 'orange',
  },
  {
    title: 'Discussions',
    desc: 'Stuck with something? Ask the Finto community anytime.',
    image: image3,
    bgType: 'red',
  },
];

const DevelopersResources = () => (
  <section className="developers-resources">
    <Container className="developers-resources__container">
      <Tag align="center" className="developers-resources__tag" color="purple">
        DOCUMENTATION
      </Tag>
      <Title
        tagName="h2"
        sizeText="size-2"
        color="white"
        alignText="text-center"
        className="developers-resources__title"
      >
        Developer resources
      </Title>

      <div className="developers-resources__cards">
        <ContentSwitcher
          mobileContent={
            <ProgressSlider className="developers-resources__slider">
              {data.map((c) => (
                <ResourceCard className="developers-resources__card" key={c.title} {...c} />
              ))}
            </ProgressSlider>
          }
          desktopContent={
            <>
              {data.map((c) => (
                <ResourceCard className="developers-resources__card" key={c.title} {...c} />
              ))}
            </>
          }
        />
      </div>
    </Container>
  </section>
);

export default DevelopersResources;
