import React from 'react';
import { HelmetWrapper } from 'elements';
import { PrivacyPolicy as PrivacySection } from 'components';

const Terms = () => (
  <>
    <HelmetWrapper helmetKey="privacy" />
    <PrivacySection />
  </>
);

export default Terms;
