import React from 'react';
import { HelmetWrapper } from 'elements';
import { AboutHero, AboutEpilog } from 'components';

const About = () => (
  <>
    <HelmetWrapper helmetKey="about" />
    <AboutHero />
    <AboutEpilog />
  </>
);

export default About;
