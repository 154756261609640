import React, { useState } from 'react';
import { Select } from 'elements';
import { LIST_DATA } from './test';
import FeatureList from './FeaturesList/FeaturesList';
import { COUNTRIES } from '../../data/selects_data';
import './FeatureByCountry.scss';

const FeaturesByCountry = () => {
  const [listData, setListData] = useState(null);
  const onSelectChange = (data) => {
    const d = LIST_DATA.find((c) => c.id === data.value);
    if (d && d.data) {
      setListData(d.data);
    }
  };
  return (
    <div className="features-by-country">
      <div className="features-by-country__select-wrapper">
        <span className="features-by-country__title">Availible regions</span>
        <Select options={COUNTRIES} onChange={onSelectChange} />
      </div>
      <FeatureList data={listData} />
    </div>
  );
};
export default FeaturesByCountry;
