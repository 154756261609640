import React from 'react';
import { Epilog } from 'components';
import { Button } from 'elements';
import './CoverageFeaturesEpilog.scss';

const CoverageFeaturesEpilog = () => (
  <Epilog
    className="coverage-features-epilog"
    title="Get in touch"
    description="Need more info? Reach out to us anytime we will be more than happy to answer any questions and walk you through our platform and services."
    variant="white"
  >
    <Button
      className="about-epilog__button button--with-icon button--with-icon-left"
      size="medium"
      buttontype="hoverable"
      isCentered
    >
      <div className="button__inner">
        <span>Contact us</span>
      </div>
    </Button>
  </Epilog>
);
export default CoverageFeaturesEpilog;
