import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="62.646"
      cy="62.2922"
      r="62.1287"
      transform="rotate(-180 62.646 62.2922)"
      fill="#1E2132"
    />
    <g clipPath="url(#standart_4_1_2751)">
      <path
        d="M36.1327 81.1187L68.9872 85.6676V40.8782C68.2417 41.0515 67.546 41.2114 66.8486 41.378C62.7872 42.3526 58.7276 43.3286 54.6696 44.3059C52.2516 44.8879 49.8333 45.4679 47.4147 46.046C47.1662 46.1052 47.0436 46.2126 47.0751 46.475C47.0828 46.6112 47.0828 46.7478 47.0751 46.884C47.0751 56.5771 47.079 66.27 47.0867 75.9625C47.1167 76.1645 47.0696 76.3704 46.9548 76.5388C46.8401 76.7073 46.6661 76.8258 46.468 76.8705C43.0803 78.1766 39.7043 79.5177 36.3224 80.8488C36.1568 80.9146 35.9911 80.9896 35.7882 81.0779C35.7689 80.9755 35.7559 80.872 35.7492 80.768C35.7448 68.542 35.7363 56.3158 35.7236 44.0893C35.7048 43.9432 35.7412 43.7952 35.8255 43.6746C35.9098 43.5541 36.036 43.4696 36.1791 43.438C41.0201 41.6215 45.8586 39.7973 50.6947 37.9653C56.3783 35.8203 62.0686 33.6962 67.7373 31.5147C68.5455 31.1752 69.4474 31.1359 70.2818 31.4039C75.8006 33.0465 81.3359 34.6317 86.8697 36.2202C86.9665 36.2304 87.0603 36.2605 87.1452 36.3086C87.23 36.3567 87.3042 36.4218 87.363 36.4999C87.4218 36.578 87.4641 36.6673 87.4873 36.7625C87.5104 36.8577 87.5139 36.9566 87.4975 37.0532C87.4815 53.9349 87.4815 70.8166 87.4975 87.6984C87.4975 88.1565 87.3815 88.3456 86.9318 88.4681C83.0786 89.5184 79.2329 90.5963 75.3848 91.6659C73.3969 92.219 71.409 92.7812 69.4146 93.316C69.1566 93.3804 68.8856 93.3682 68.6343 93.281C64.1092 91.6112 59.5876 89.9319 55.0696 88.2431C50.0398 86.375 45.0085 84.5106 39.9759 82.6497C38.8031 82.2149 37.6303 81.7806 36.4574 81.3469C36.3381 81.3028 36.2272 81.2378 36.112 81.1803L36.1327 81.1187Z"
        fill="#EB3C03"
      />
    </g>
    <defs>
      <clipPath id="standart_4_1_2751">
        <rect width="51.7739" height="62.1287" fill="white" transform="translate(35.7239 31.228)" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
