import React, { useState } from 'react';
import { Image, Accordion, AccordionItem, Tag, Title, Description, Container } from 'elements';
import phoneRegular from 'images/phones/phone-regular-black.webp';
import phonePurple from 'images/phones/phone-purple-black.webp';
import phonePurpleHead from 'images/phones/phone-purple-head.webp';
import phoneYellow from 'images/phones/phone-yellow-black.webp';
import phoneCoral from 'images/phones/phone-coral.webp';
import ChevronSvg from 'images/icons/chevron.svg';
import ChevronPurpleSvg from 'images/icons/chevron-purple.svg';
import phoneRegularMobile from 'images/mobile/phones/phone-regular-black.webp';
import phonePurpleMobile from 'images/mobile/phones/phone-purple-black.webp';
import phonePurpleHeadMobile from 'images/mobile/phones/phone-purple-head.webp';
import phoneYellowMobile from 'images/mobile/phones/phone-yellow-black.webp';
import phoneCoralMobile from 'images/mobile/phones/phone-coral.webp';

import './BaasInnovation.scss';

const list = [
  {
    id: 0,
    title: 'Digital KYC',
    text: 'Use biometrics and ID documents to verify and onboard users securely and quickly.',
    image: phonePurple,
    imageMobile: phonePurpleMobile,
  },
  {
    id: 1,
    title: 'Realtime analytics',
    text: 'Use real time data to provide powerful and engaging insights and analytics.',
    image: phoneRegular,
    imageMobile: phoneRegularMobile,
  },
  {
    id: 2,
    title: 'Chat support',
    text: 'Instant in app customer support with live chat or automated messaging',
    image: phoneYellow,
    imageMobile: phoneYellowMobile,
  },
  {
    id: 3,
    title: 'Push notifications',
    text: 'Use push and in app notifications to promote new products and features',
    image: phonePurpleHead,
    imageMobile: phonePurpleHeadMobile,
  },
  {
    id: 4,
    title: 'Global payments',
    text: 'Enable global money transfers and payments with western union integration ',
    image: phoneCoral,
    imageMobile: phoneCoralMobile,
  },
];

const BaasInnovation = () => {
  const [itemIndx, setItemIndx] = useState(0);
  const handleAccordionChange = (indx) => {
    if (indx === itemIndx) return;
    setItemIndx(indx);
  };
  const imagePath = list[itemIndx].image;
  const imageMobilePath = list[itemIndx].mobileImage;
  return (
    <section className="baas-innovation__section">
      <Container className="baas-innovation__container">
        <div className="baas-innovation__declaration">
          <Tag color="purple">MODERN FEATURES</Tag>
          <Title tagName="h2" sizeText="size-2" color="vulcan">
            Innovation built in
          </Title>
          <Description sizeText="size-2">
            Finto’s white label products are covered by Finto’s banking license, so you can spend
            more time focusing on growing your business, and less on meeting regulatory compliance.
          </Description>
          <Accordion
            defaultOpen={0}
            onChange={handleAccordionChange}
            className="baas-innovation__accordion"
          >
            {list.map((c) => (
              <AccordionItem
                key={c.id.toString()}
                className="baas-innovation__accordion__item"
                title={c.title}
                DefaultIcon={ChevronSvg}
                ActiveIcon={ChevronPurpleSvg}
              >
                <p className="baas-innovation__accordion__item__text">{c.text}</p>
                <div className="baas-innovation__accordion__item__image-container">
                  <div className="baas-innovation__accordion__item__image">
                    <Image src={c.image} srcMobile={c.imageMobile} />
                  </div>
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="baas-innovation__images">
          <div className="baas-innovation__image">
            <Image src={imagePath} srcMobile={imageMobilePath} alt="phone" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BaasInnovation;
