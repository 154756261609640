import React from 'react';
import { bool, string, func, node, oneOfType } from 'prop-types';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import './Accordion.scss';

const AccordionItem = ({
  className,
  children,
  isOpen,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  title,
  DefaultIcon,
  ActiveIcon,
}) => (
  <div
    className={cx('accordion__item', {
      [className]: !!className,
      'is-open': isOpen,
    })}
  >
    <div
      className={cx('accordion__header')}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="button"
      tabIndex={0}
      aria-hidden="true"
    >
      {title}
      <AccordionIcon Icon={isOpen ? ActiveIcon : DefaultIcon} isOpen={isOpen} />
    </div>
    <Collapse className="accordion__content" isOpen={isOpen}>
      <div className="accordion__content">{children}</div>
    </Collapse>
  </div>
);

AccordionItem.defaultProps = {
  className: '',
  children: undefined,
  handleClick: () => {},
  handleMouseEnter: () => {},
  handleMouseLeave: () => {},
  isOpen: false,
  title: '',
};

AccordionItem.propTypes = {
  className: string,
  children: node,
  handleClick: func,
  handleMouseEnter: func,
  handleMouseLeave: func,
  isOpen: bool,
  title: oneOfType([node, string]),
  ActiveIcon: oneOfType([node, func]),
  DefaultIcon: oneOfType([node, func]),
};

const AccordionIcon = ({ Icon, isOpen }) => {
  if (!Icon) return null;
  return (
    <span
      className={cx('accordion__icon', {
        'accordion__icon--is-open': !!isOpen,
      })}
    >
      <Icon />
    </span>
  );
};

AccordionIcon.propTypes = {
  isOpen: bool,
  Icon: oneOfType([node, func]),
};

export default AccordionItem;
