import React from 'react';
import { useSelector } from 'react-redux';
import { Title, Description, GetStarted, Container, TriggerOnEnter, BasicScroll } from 'elements';
import AppFirstSvg from 'images/charts/app-1.svg';
import AppSecondSvg from 'images/charts/app-2.svg';
import AppThirdSvg from 'images/charts/app-3.svg';
import TrendingSvg from 'images/icons/trending.svg';
import ArrowSvg from 'images/icons/arrowTop.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './HomeApps.scss';

const HomeApps = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className="home_apps">
      <Container className="home_apps__container">
        <BasicScroll
          props={{
            '--parallax-ty': {
              from: '20%',
              to: '-20%',
            },
          }}
        >
          <div className="home_apps__images parallax parallax--vertical">
            <div className="home_apps__img home_apps__img--first">
              <AppFirstSvg />
            </div>
            <div className="home_apps__img home_apps__img--second">
              <AppSecondSvg />
            </div>
            <div className="home_apps__img home_apps__img--third">
              <AppThirdSvg />
            </div>
          </div>
        </BasicScroll>
        <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
          <div className="home_apps__details advantages-animations">
            <TrendingSvg className="home_apps__icon title-icon" />
            <Title tagName="h2" sizeText="size-2" color="black" className="home_apps__title">
              {/* do not remove space */}
              <div className="title__part">Generate </div>
              <div className="title__part">new revenue</div>
            </Title>
            <Description sizeText="size-2" color="black" className="home_apps__description">
              Utilise secure financial data to unearth spending patterns and develop new
              personalised products and services.
            </Description>
            <div className="buttons-holder">
              <GetStarted
                className="home_apps__button button--with-icon"
                to="https://developer.finto.io/"
                target="_blank"
                buttontype="gradient-hoverable"
                color="text-white"
                size="medium"
                isCentered
              >
                <div className="button__inner">
                  <span>Get started</span>
                  <div className="button__icons">
                    <ArrowSvg className="button__icon" />
                    <ArrowSvg className="button__icon button__icon--next" />
                  </div>
                </div>
              </GetStarted>
            </div>
          </div>
        </TriggerOnEnter>
      </Container>
    </section>
  );
};

export default HomeApps;
