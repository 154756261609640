import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import './List.scss';

const List = ({ children, className, header, ...rest }) => (
  <div
    className={cx('list', {
      [className]: !!className,
    })}
    {...rest}
  >
    {header && <div className="list__header">{header}</div>}
    <div className="list__items">{children}</div>
  </div>
);

List.defaultProps = {
  children: null,
  className: '',
  header: '',
};

List.propTypes = {
  children: node,
  className: string,
  header: string,
};

export default List;
