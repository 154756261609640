import React from 'react';
import { HelmetWrapper } from 'elements';
import {
  HomeHero,
  HomeEpilog,
  HomeApi,
  HomeApps,
  HomeAccelerate,
  ModernBanking,
  HomePresentationView,
} from 'components';

const Home = () => (
  <>
    <HelmetWrapper helmetKey="home" />
    <HomeHero />
    <HomeApi />
    <HomeApps />
    <HomeAccelerate />
    <ModernBanking className="home-modern-banking" />
    <HomePresentationView />
    <HomeEpilog />
  </>
);

export default Home;
