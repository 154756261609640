/* eslint react-hooks/exhaustive-deps: 0 */
import { useCallback, useEffect, useRef } from 'react';

export default function useHover({ onShow, onHide, delay = [100, 200] }) {
  const data = useRef({ timer: null, isVisible: false }).current;
  const [delayOnShow, delayOnHide] = delay;

  const handleMouseEnter = useCallback(() => {
    clearTimeout(data.timer);
    data.timer = setTimeout(() => {
      if (!data.isVisible) {
        data.isVisible = true;
        onShow?.();
      }
    }, delayOnShow);
  }, []);
  const handleMouseLeave = useCallback(() => {
    clearTimeout(data.timer);
    data.timer = setTimeout(() => {
      if (data.isVisible) {
        data.isVisible = false;
        onHide?.();
      }
    }, delayOnHide);
  }, []);

  useEffect(
    () => () => {
      clearTimeout(data.timer);
    },
    []
  );

  return [handleMouseEnter, handleMouseLeave];
}
