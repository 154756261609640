/**
 * reducer with flags
 * that can enable, disable or modify components
 */
export const STATE_KEY = 'flags';

const SET_FLAG = `${STATE_KEY}/SET_FLAG`;
const SET_MULTIPLE_FLAGS = `${STATE_KEY}/SET_MULTIPLE_FLAGS`;

const initialState = {
  areAnimationsUnblocked: false,
  isNotFound: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FLAG: {
      const { flag, value } = action.payload;
      if (!state.hasOwnProperty(flag)) {
        console.error('wrong or missing flag');
        return state;
      }
      return { ...state, [flag]: !!value };
    }
    case SET_MULTIPLE_FLAGS: {
      const { flags } = action.payload;
      const nextFlags = Object.entries(flags).reduce((acc, c) => {
        const [flag, value] = c;
        if (!state.hasOwnProperty(flag)) {
          console.error(`wrong or missing flag: ${flag}`);
        } else {
          acc[flag] = !!value;
        }
        return acc;
      }, {});
      return { ...state, ...nextFlags };
    }
    default:
      return state;
  }
}

/**
 * @param {string} flag - flag name to set
 * @param {bool} value - flag value to set
 * @returns {object}
 */
export function setFlag(flag, value) {
  return { type: SET_FLAG, payload: { flag, value } };
}

/**
 * can set multiple flags
 * @param {object} flags - object with multiple flags to set
 * @param {object} flags.flag - flag name to set
 * @param {object} flags.value - flag value to set
 * @returns {object}
 */
export function setMultipleFlags(flags = {}) {
  return { type: SET_MULTIPLE_FLAGS, payload: { flags } };
}
