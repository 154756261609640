import React from 'react';
import { bool, oneOfType, array, number, string, func } from 'prop-types';
import LazyLoad from 'react-lazyload';

const Image = ({ offset, once, src, srcMobile, onLoad, bypass, ...rest }) => {
  const inner = (
    <picture onLoad={onLoad}>
      <source media="(max-width: 1024px)" srcSet={srcMobile} />
      <img src={src} alt="pic" {...rest} />
    </picture>
  );
  if (bypass) {
    return inner;
  }
  return (
    <LazyLoad offset={offset} height={200} once={once}>
      {inner}
    </LazyLoad>
  );
};

Image.propTypes = {
  offset: oneOfType([number, array]),
  once: bool,
  src: string,
  srcMobile: string,
  onLoad: func,
  bypass: bool,
};

Image.defaultProps = {
  offset: 500,
  once: true,
};

export default Image;
