import { node } from 'prop-types';
import { useResponsive } from 'hooks';

const ContentSwitcher = ({ desktopContent, mobileContent }) => {
  const { isDesktop } = useResponsive();
  return isDesktop ? desktopContent || null : mobileContent || null;
};

ContentSwitcher.propTypes = {
  desktopContent: node,
  mobileContent: node,
};

export default ContentSwitcher;
