import React from 'react';
import { HelmetWrapper } from 'elements';
import {
  BaapHero,
  CommonCards,
  BaapAccelerate,
  BaapPayments,
  BaapApi,
  ModernBanking,
  BaapEpilog,
  BaapPresentationView,
} from 'components';

const Baap = () => (
  <>
    <HelmetWrapper helmetKey="baap" />
    <BaapHero />
    <BaapAccelerate />
    <BaapPayments />
    <CommonCards
      className="baap-common-cards"
      tag="accelerate GROWTH"
      title="Issue custom payment cards"
      description="Create a seamless payment experience for your customers with Finto’s white label debit, prepaid, virtual and loyalty cards."
    />
    <BaapApi />
    <ModernBanking className="baap-modern-banking" />
    <BaapEpilog />
    <BaapPresentationView />
  </>
);

export default Baap;
