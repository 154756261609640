/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import cx from 'classnames';
import { bool, string } from 'prop-types';
import { Logo, Button, GetStarted } from 'elements';
import { Link } from 'react-router-dom';
import { Menu, MenuMobile } from 'components';
import { useSelector } from 'react-redux';
import { getIsNotFoundPage } from '../../redux/modules/flags/flagsSelectors';
import { listMenu as mainList, careerMenuList } from '../Menu/constants';
import { useResponsive } from 'hooks';
import ArrowSvg from 'images/icons/arrowTop.svg';
import ArrowPurpleSvg from 'images/icons/arrowTopPurple.svg';
import HamburgerSvg from 'images/icons/hamburger.svg';
import './Header.scss';

const policyRoutes = [
  'legal-statements',
  'terms-and-conditions',
  'cookies-policy',
  'privacy-policy',
];

const careersRoutes = ['jobs', 'hiring', 'culture'];
const routesWithWhiteHeaders = [
  'baas',
  'paas',
  'get-started',
  'contact',
  ...careersRoutes,
  ...policyRoutes,
];
const getIsHeaderWhite = (currentPathName) => {
  return routesWithWhiteHeaders.some((c) => currentPathName.includes(c));
};

const getIsCareerRoute = (currentPathName) => {
  return careersRoutes.some((c) => currentPathName.includes(c));
};

const getIsPolicyRoute = (currentPathName) => {
  return policyRoutes.some((c) => currentPathName.includes(c));
};

const Header = ({ currentPathName, isEntered, ...rest }) => {
  const mutableObj = useRef({ prevScroll: 0 }).current;
  const [isCareerRoute, setIsCareerRoute] = useState(false);
  const [isHeaderVisible, setHeaderVisibility] = useState(false);
  const [isHeaderWhite, setIsHeaderWhite] = useState(() => getIsHeaderWhite(currentPathName));
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(true);
  const [isInitComplete, setInitComplete] = useState(false);
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);
  const [isPolicyRoute, setIsPolicyRoute] = useState(false);

  const isNotFoundPage = useSelector(getIsNotFoundPage);

  const { isDesktop } = useResponsive();

  useEffect(() => {
    const handleScroll = () => {
      // do not proceed until initialize is complete
      if (!isInitComplete) {
        return;
      }
      const prevScroll = mutableObj.prevScroll;
      const currentScroll = window.scrollY;

      let isVisible = true;
      if (currentScroll > prevScroll) {
        isVisible = false;
      }

      if (isHeaderVisible !== isVisible) {
        setHeaderVisibility(isVisible);
        dispatchHeaderEvent(isVisible);
      }
      if (!isHeaderTransparent && currentScroll <= 0) {
        setIsHeaderTransparent(true);
      } else if (isHeaderTransparent && currentScroll > 0) {
        setIsHeaderTransparent(false);
      }
      mutableObj.prevScroll = currentScroll;
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isInitComplete, isHeaderVisible, isHeaderTransparent]);

  useEffect(() => {
    const isWhite = getIsHeaderWhite(currentPathName);
    const isCareer = getIsCareerRoute(currentPathName);
    const isPolicy = getIsPolicyRoute(currentPathName);
    setIsCareerRoute(isCareer);
    setIsHeaderWhite(isWhite);
    setIsPolicyRoute(isPolicy);
  }, [currentPathName]);

  useEffect(() => {
    if (isEntered) {
      setHeaderVisibility(true);
    }
  }, [isEntered]);

  const dispatchHeaderEvent = (flag) => {
    const event = new CustomEvent('header_stick', {
      detail: {
        visible: flag,
      },
    });
    document.dispatchEvent(event);
  };

  const toggleMobileMenu = () => {
    if (isMobileMenuActive) {
      setMobileMenuActive(false);
      document.body.classList.remove('menu-mobile-open');
    } else {
      setMobileMenuActive(true);
      document.body.classList.add('menu-mobile-open');
    }
  };

  const menuItems = isCareerRoute ? careerMenuList : mainList;
  return (
    <>
      <header
        className={cx('header', {
          'header--is-white': !!isHeaderWhite,
          'header--is-hidden': !isHeaderVisible,
          'header--is-transparent': isHeaderTransparent,
          'header--is-init-complete': isInitComplete,
          'header--is-not-found': isNotFoundPage,
          'header--is-bottom-border': isPolicyRoute,
        })}
        onTransitionEnd={!isInitComplete ? () => setInitComplete(true) : undefined}
      >
        <div className="header__container">
          <div className="header__logo">
            <Logo type={isHeaderWhite || isNotFoundPage ? 'type-2' : 'type-1'} />
          </div>
          {isDesktop ? (
            <Fragment>
              <div className="header__nav">
                <Menu isHeaderWhite={isHeaderWhite} listMenu={menuItems} />
              </div>
              <SideButton isCareerRoute={isCareerRoute} />
            </Fragment>
          ) : (
            <button onClick={toggleMobileMenu} className="mobile-menu__button" type="button">
              <HamburgerSvg
                className={cx('mobile-menu__button-icon mobile-menu__button-icon--hamburger', {
                  ['mobile-menu__button-icon--white']: !!isHeaderWhite || !!isNotFoundPage,
                })}
              />
            </button>
          )}
        </div>
      </header>
      <MenuMobile
        listMenu={menuItems}
        isHeaderWhite={isHeaderWhite || isNotFoundPage}
        closeMenu={toggleMobileMenu}
        isVisible={isMobileMenuActive}
      />
    </>
  );
};

const SideButton = ({ isCareerRoute }) => {
  if (isCareerRoute) {
    return (
      <Button
        to="/"
        tagName={Link}
        className="header__purple-button button--with-icon"
        buttontype="gradient-hoverable"
        color="text-white"
        size="medium"
        isCentered
      >
        <div className="button__inner">
          <span>main site</span>
          <div className="button__icons">
            <ArrowPurpleSvg className="button__icon button__icon--first" />
            <ArrowSvg className="button__icon button__icon--next" />
          </div>
        </div>
      </Button>
    );
  } else {
    return (
      <GetStarted
        to="https://developer.finto.io/"
        target="_blank"
        size="small"
        className="header__btn"
        buttontype="hoverable"
        isCentered
      >
        <div className="button__inner">
          <span>Get started</span>
        </div>
      </GetStarted>
    );
  }
};

Header.propTypes = {
  currentPathName: string,
  isEntered: bool,
};

export default Header;
