import React from 'react';
import {
  Title,
  Tag,
  Container,
  FeatureCard,
  ContentSwitcher,
  ProgressSlider,
  TriggerOnEnter,
} from 'elements';
import cx from 'classnames';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import checkIcon from 'images/icons/check-white.svg';
import shieldIcon from 'images/icons/shield.svg';
import modularIcon from 'images/icons/modular.svg';
import intuativeIcon from 'images/icons/intuative.svg';
import thunderIcon from 'images/icons/thunder-white.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import './ModernBanking.scss';

const sliderData = [
  {
    title: 'Fully licensed',
    description: 'Finto is a technology company with a full banking license.',
    Icon: checkIcon,
  },
  {
    title: 'Secure',
    description: 'Rigorously tried and tested to the highest standards.',
    Icon: shieldIcon,
  },
  {
    title: 'Scalable',
    description: 'Built and designed to scale with your business.',
    Icon: checkIcon,
  },
  {
    title: 'Fast',
    description: 'Zero legacy. Built for today, using the latest technology.',
    Icon: thunderIcon,
  },
  {
    title: 'Modular',
    description: 'Designed to be as infinitely сustomizable as you need it to be.',
    Icon: modularIcon,
  },
  {
    title: 'Intuitive',
    description: 'Designed by developers for developers with ease of use in mind.',
    Icon: intuativeIcon,
  },
];

const ModernBanking = ({ className }) => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);

  return (
    <section className={cx('modern-banking', className)}>
      <Container className="modern-banking__container">
        <TriggerOnEnter bypass={!areAnimationsUnblocked} bottomOffset="20%">
          <div className="modern-banking__details">
            <Tag align="center" color="purple">
              WHY FINTO
            </Tag>
            <Title
              tagName="h2"
              sizeText="size-3"
              color="white"
              alignText="text-center"
              className="modern-banking__title"
            >
              <div className="title__part">Modern end-to-end </div>
              <div className="title__part">digital first banking</div>
            </Title>
            <ContentSwitcher
              mobileContent={
                <ProgressSlider className="modern-banking__slider">
                  {sliderData.map((c) => (
                    <FeatureCard variant="black" key={c.title} {...c} />
                  ))}
                </ProgressSlider>
              }
              desktopContent={
                <div className="modern-banking__cards">
                  {sliderData.map((c) => (
                    <FeatureCard variant="black" key={c.title} {...c} />
                  ))}
                </div>
              }
            />
          </div>
        </TriggerOnEnter>
      </Container>
    </section>
  );
};

ModernBanking.propTypes = {
  className: string,
};

export default ModernBanking;
