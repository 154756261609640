import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Title, Description, Button, Image } from 'elements';
import ArrowSvg from 'images/icons/arrowTop.svg';
import Image1 from 'images/contacts/contacts1.webp';
import Image2 from 'images/contacts/contacts2.webp';
import Image3 from 'images/contacts/contacts3.webp';
import MobileImage1 from 'images/mobile/contacts/contacts1.webp';
import MobileImage2 from 'images/mobile/contacts/contacts2.webp';
import MobileImage3 from 'images/mobile/contacts/contacts3.webp';
import './ContactsCareers.scss';

const ContactsCareers = () => {
  const history = useHistory();
  const handlePress = () => {
    history.push('/about');
  };
  return (
    <section id="career" className="contacts-careers__section">
      <Container className="contacts-careers__container">
        <Title
          tagName="h2"
          className="contacts-careers__title"
          alignText="text-center"
          sizeText="size-2"
        >
          Careers
        </Title>
        <Description
          className="contacts-careers__description"
          alignText="text-center"
          sizeText="size-2"
        >
          We’re always on the look out for talented new people to grow the Finto team. Find out more
          about who we are and what we do <span onClick={handlePress}>here</span> and to apply for
          one of our open positions check out our careers page.
        </Description>
        <Button
          tagName={Link}
          className="contacts-careers__button button--with-icon"
          to="/careers/jobs"
          buttontype="gradient-hoverable"
          color="text-white"
          size="medium"
          isCentered
        >
          <div className="button__inner">
            <span>Join the team</span>
            <div className="button__icons">
              <ArrowSvg className="button__icon" />
              <ArrowSvg className="button__icon button__icon--next" />
            </div>
          </div>
        </Button>
      </Container>
      <div className="contacts-careers__images">
        <Image className="contacts-careers__image" src={Image1} srcMobile={MobileImage1} />
        <Image className="contacts-careers__image" src={Image2} srcMobile={MobileImage2} />
        <Image className="contacts-careers__image" src={Image3} srcMobile={MobileImage3} />
      </div>
      {/* {isDesktop ? (

      ) : (
        <TeamSlider
          data={[
            { desktop: Image1, mobile: MobileImage1 },
            { desktop: Image2, mobile: MobileImage2 },
            { desktop: Image3, mobile: MobileImage3 },
          ]}
        />
      )} */}
    </section>
  );
};
export default ContactsCareers;
