// https://reactrouter.com/web/guides/scroll-restoration
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollOn() {
  const { hash, pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);

  const scrollToPos = ({ top = 0, left = 0, behavior = 'smooth' } = {}) => {
    window.scrollTo({
      top,
      left,
      behavior,
    });
  };

  useEffect(() => {
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        const { top } = target.getBoundingClientRect();
        scrollToPos({ top });
      }
    } else {
      scrollToPos({ behavior: pathname === prevPathname ? 'smooth' : 'auto' });
    }
    setPrevPathname(pathname);
  }, [hash, pathname]); // eslint-disable-line

  return null;
}
