import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.4543 38.9987L61.3681 39.9841C61.9791 38.8874 62.7154 37.6795 63.4517 36.7417L64.4543 38.9987Z"
      fill="#D2D3D6"
    />
    <path
      d="M23.8636 34.2224V30.4237H25.1795C25.1795 30.4237 27.0751 30.5032 27.2318 31.8224C27.2631 31.9177 27.2631 32.5694 27.2318 32.6012C26.9811 34.1429 25.3362 34.2224 25.3362 34.2224H23.8636Z"
      fill="#D2D3D6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.2715 16L60.3185 29.6689C59.6311 30.6208 57.5719 33.5067 55.6339 36.3344V27.2609H50.1509V39.4084C49.3946 38.8886 48.5988 38.5231 47.8322 38.3921V37.7828C47.8322 37.7351 47.8008 37.7192 47.7695 37.751C47.7695 37.751 47.1742 38.2278 44.5737 38.498C43.8374 38.5775 43.4771 38.5457 43.2108 38.498C39.5136 37.8622 39.3413 35.1285 39.3413 35.1285C39.3413 35.1022 39.3366 35.0663 39.3323 35.0341C39.3288 35.0077 39.3256 34.9838 39.3256 34.9695V33.9205C39.3256 33.8728 39.3256 33.7934 39.3413 33.7616C39.3413 33.7616 39.592 30.8212 43.2108 30.5033H44.5737C46.1559 30.7099 47.4405 31.5046 47.4405 31.5046C47.4875 31.5205 47.5188 31.5046 47.5188 31.4569V27.7695C47.5188 27.7218 47.4875 27.6742 47.4405 27.6424C47.4405 27.6424 46.7199 27.1655 44.5267 27.0384C44.417 26.9748 42.4901 26.9589 41.9732 27.0384C33.9836 27.69 33.6859 33.5549 33.6859 33.8093V35.2397C33.6859 35.4146 33.686 41.4066 41.9732 41.9311C42.6381 41.983 43.8144 41.9502 44.3095 41.9364C44.4208 41.9333 44.4979 41.9311 44.5267 41.9311C44.5356 41.9311 44.5445 41.9311 44.5533 41.9311C44.7279 42.9146 45.2072 43.9695 45.8747 44.943L22.2663 52.4927L10 18.543L54.2715 16ZM56.2449 22.6675C56.2449 24.4477 54.741 25.8781 52.8924 25.8781C51.0438 25.8781 49.5399 24.4318 49.5399 22.6675C49.5399 20.8874 51.0438 19.457 52.8924 19.457C54.741 19.457 56.2449 20.8874 56.2449 22.6675ZM18.4589 41.7561C18.4589 41.8038 18.4902 41.8356 18.5372 41.8356H23.8793C23.9263 41.8356 23.9576 41.8038 23.9576 41.7561V37.5283C23.9576 37.4806 23.9889 37.4489 24.0359 37.4489C24.0359 37.4489 32.5738 38.0687 32.5738 32.2674C32.5738 27.674 27.2161 27.1813 25.4615 27.2608H18.5372C18.4902 27.2608 18.4589 27.2926 18.4589 27.3403V41.7561Z"
      fill="#D2D3D6"
    />
    <path
      d="M53.0496 48.8212C53.4882 48.8212 53.8329 48.8212 54.3969 48.5669C56.3394 47.5338 62.9034 31.2742 69.8277 26.2675C69.8747 26.2358 69.9217 26.1881 69.953 26.1404C70 26.0768 70 26.0132 70 26.0132C70 26.0132 70 25.6795 68.9817 25.6795C62.8251 25.5046 56.4178 38.6172 53.0496 43.7987C53.0026 43.8623 52.7833 43.7987 52.7833 43.7987C52.7833 43.7987 50.5274 41.0967 48.5692 40.0636C48.5222 40.0477 48.3029 39.9682 48.0679 39.9841C47.9112 39.9841 47.0026 40.1748 46.5796 40.6199C46.0783 41.1603 46.094 41.4623 46.094 42.1139C46.094 42.1616 46.1253 42.3841 46.188 42.4954C46.6736 43.3536 48.8512 46.4053 50.6527 48.0901C50.9191 48.2967 51.342 48.8212 53.0496 48.8212Z"
      fill="#D2D3D6"
    />
    <path
      d="M53.5547 57.086L55.991 56.9321C56.049 57.3346 56.1534 57.6424 56.3158 57.8555C56.5827 58.1989 56.9539 58.3646 57.4412 58.3646C57.8008 58.3646 58.0909 58.2817 58.2881 58.1041C58.4853 57.9265 58.5897 57.7253 58.5897 57.5003C58.5897 57.2872 58.4969 57.086 58.3113 56.9084C58.1257 56.7308 57.6848 56.5769 57.0003 56.423C55.875 56.1625 55.0861 55.8192 54.5988 55.393C54.1116 54.9668 53.8795 54.4222 53.8795 53.7592C53.8795 53.3211 54.0071 52.9186 54.2508 52.5279C54.4944 52.1372 54.8657 51.8412 55.3645 51.6163C55.8634 51.3913 56.5363 51.2848 57.3948 51.2848C58.4505 51.2848 59.2626 51.486 59.8079 51.8886C60.3647 52.2911 60.6896 52.9304 60.794 53.8065L58.3809 53.9486C58.3113 53.5697 58.1837 53.2974 57.9748 53.1198C57.766 52.9423 57.4876 52.8594 57.1279 52.8594C56.8263 52.8594 56.6059 52.9186 56.4551 53.0488C56.3042 53.179 56.2346 53.3329 56.2346 53.5224C56.2346 53.6526 56.2926 53.7828 56.4203 53.8894C56.5363 53.9959 56.8263 54.1025 57.2788 54.1972C58.3925 54.4458 59.193 54.6945 59.6803 54.9431C60.1675 55.1917 60.5156 55.5114 60.736 55.8784C60.9564 56.2454 61.0608 56.6716 61.0608 57.1333C61.0608 57.6779 60.91 58.1752 60.62 58.6251C60.3299 59.0868 59.9123 59.4301 59.3902 59.6669C58.8682 59.9037 58.1953 60.0221 57.3948 60.0221C55.991 60.0221 55.0165 59.7498 54.4712 59.1933C53.9375 58.6369 53.6359 57.9384 53.5547 57.086Z"
      fill="#D2D3D6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.0417 51.4387H45.248V59.8682H49.0417C49.4942 59.8682 50.0046 59.7971 50.5615 59.6432C50.9676 59.5248 51.3504 59.2999 51.7101 58.9684C52.0697 58.6251 52.3481 58.2107 52.5454 57.7134C52.7426 57.2162 52.847 56.5177 52.847 55.6179C52.847 55.0378 52.7774 54.4932 52.6382 53.9841C52.5106 53.475 52.2901 53.0251 51.9885 52.6344C51.6985 52.2556 51.3156 51.9596 50.8516 51.7465C50.3991 51.5452 49.7958 51.4387 49.0417 51.4387ZM47.8004 57.9502V53.3448H48.4384C49.1113 53.3448 49.5754 53.5105 49.8654 53.842C50.1555 54.1735 50.2947 54.7773 50.2947 55.6771C50.2947 56.3519 50.2251 56.8492 50.0975 57.1452C49.9698 57.4411 49.7842 57.6542 49.5638 57.7726C49.3434 57.891 48.9605 57.9502 48.4268 57.9502H47.8004Z"
      fill="#D2D3D6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.45 61.6558C48.2179 61.8926 48.1019 62.2241 48.1019 62.6503C48.1019 62.9582 48.1483 63.1831 48.2759 63.3962C48.4035 63.6093 48.5544 63.7514 48.74 63.8461C48.9372 63.9408 49.1692 63.9882 49.4593 63.9882C49.7377 63.9882 49.9813 63.929 50.167 63.8224C50.3642 63.7159 50.5034 63.562 50.6078 63.3725C50.7122 63.1831 50.7586 62.9345 50.7586 62.6267C50.7586 62.2005 50.6426 61.8808 50.4106 61.644C50.1786 61.4191 49.8537 61.3007 49.4245 61.3007C49.0068 61.3007 48.682 61.4191 48.45 61.6558ZM49.03 63.2186C48.9372 63.1002 48.8908 62.9108 48.8908 62.6503C48.8908 62.3899 48.9372 62.2004 49.03 62.1057C49.1228 61.9873 49.2505 61.9281 49.4129 61.9281C49.5753 61.9281 49.7145 61.9755 49.8073 62.0939C49.9117 62.2004 49.9581 62.378 49.9581 62.6267C49.9581 62.9108 49.9117 63.1121 49.8189 63.2305C49.7261 63.337 49.5985 63.3962 49.4245 63.3962C49.2505 63.3962 49.1228 63.337 49.03 63.2186Z"
      fill="#D2D3D6"
    />
    <path
      d="M47.8235 63.1002L47.1274 62.8871C47.0926 63.0529 47.0346 63.1713 46.9418 63.2778C46.8722 63.3607 46.7561 63.408 46.6053 63.408C46.4545 63.408 46.3269 63.3488 46.2457 63.2423C46.1645 63.1357 46.1181 62.9345 46.1181 62.6503C46.1181 62.4254 46.1529 62.2478 46.2225 62.1413C46.3153 61.9873 46.4545 61.9163 46.6285 61.9163C46.6981 61.9163 46.7677 61.9281 46.8374 61.9637C46.907 61.9992 46.9534 62.0465 46.9998 62.1057C47.0346 62.1413 47.0578 62.2004 47.081 62.2833L47.7771 62.1294C47.6959 61.8453 47.5566 61.644 47.371 61.5138C47.1854 61.3835 46.9418 61.3125 46.6169 61.3125C46.2109 61.3125 45.886 61.4191 45.6656 61.6558C45.4452 61.8926 45.3292 62.2241 45.3292 62.6503C45.3292 62.97 45.3988 63.2423 45.5264 63.4436C45.654 63.6567 45.8048 63.7987 45.9788 63.8816C46.1645 63.9645 46.3849 64 46.6633 64C46.8954 64 47.081 63.9645 47.2318 63.8935C47.3826 63.8343 47.4986 63.7277 47.6031 63.5975C47.7075 63.4672 47.7771 63.3015 47.8235 63.1002Z"
      fill="#D2D3D6"
    />
    <path
      d="M52.1972 61.348L52.5917 62.9345L52.9861 61.348H54.0187V63.9526H53.369V61.9637L52.8701 63.9526H52.29L51.7912 61.9637V63.9526H51.1415V61.348H52.1972Z"
      fill="#D2D3D6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.8401 61.348H54.5291V63.9645H55.318V62.9937H55.7473C56.0605 62.9937 56.3041 62.9226 56.455 62.7687C56.6058 62.6267 56.687 62.4136 56.687 62.1531C56.687 61.8926 56.6174 61.7032 56.4782 61.5611C56.339 61.4191 56.1301 61.348 55.8401 61.348ZM55.5152 62.4609H55.3296V61.8808H55.5501C55.7009 61.8808 55.7937 61.9045 55.8517 61.9637C55.9097 62.0229 55.9329 62.0939 55.9329 62.1768C55.9329 62.2596 55.8981 62.3188 55.8401 62.378C55.7705 62.4372 55.6661 62.4609 55.5152 62.4609Z"
      fill="#D2D3D6"
    />
    <path d="M57.1046 61.348H57.8935V63.3133H59.1233V63.9526H57.1046V61.348Z" fill="#D2D3D6" />
    <path d="M60.3066 61.348H59.5177V63.9526H60.3066V61.348Z" fill="#D2D3D6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.4413 63.5146H61.548L61.4204 63.9408H60.6199L61.5828 61.3362H62.4413L63.4042 63.9408H62.5805L62.4413 63.5146ZM62.2789 62.9582L62.0005 62.0229L61.722 62.9582H62.2789Z"
      fill="#D2D3D6"
    />
    <path
      d="M64.3788 61.348H63.6479V63.9526H64.3904V62.5201L65.3417 63.9526H66.0842V61.348H65.3417V62.7924L64.3788 61.348Z"
      fill="#D2D3D6"
    />
    <path
      d="M66.4438 61.348H68.8337V61.9873H68.0332V63.9526H67.2443V61.9873H66.4438V61.348Z"
      fill="#D2D3D6"
    />
    <path
      d="M64.1469 56.9321L61.7106 57.086C61.7802 57.9384 62.0818 58.6369 62.6271 59.1933C63.1723 59.7498 64.1469 60.0221 65.5506 60.0221C66.3511 60.0221 67.024 59.9037 67.5461 59.6669C68.0682 59.4301 68.4858 59.0868 68.7759 58.6251C69.0659 58.1752 69.2167 57.6779 69.2167 57.1333C69.2167 56.6716 69.1123 56.2454 68.8919 55.8784C68.6714 55.5114 68.3234 55.1917 67.8361 54.9431C67.3489 54.6945 66.5484 54.4458 65.4346 54.1972C64.9822 54.1025 64.6921 53.9959 64.5761 53.8894C64.4485 53.7828 64.3905 53.6526 64.3905 53.5224C64.3905 53.3329 64.4601 53.179 64.6109 53.0488C64.7617 52.9186 64.9822 52.8594 65.2838 52.8594C65.6435 52.8594 65.9219 52.9423 66.1307 53.1198C66.3395 53.2974 66.4672 53.5697 66.5368 53.9486L68.9499 53.8065C68.8455 52.9304 68.5206 52.2911 67.9637 51.8886C67.4185 51.486 66.6064 51.2848 65.5506 51.2848C64.6921 51.2848 64.0193 51.3913 63.5204 51.6163C63.0215 51.8412 62.6503 52.1372 62.4067 52.5279C62.163 52.9186 62.0354 53.3211 62.0354 53.7592C62.0354 54.4222 62.279 54.9668 62.7547 55.393C63.242 55.8192 64.0309 56.1625 65.1562 56.423C65.8407 56.5769 66.2815 56.7308 66.4672 56.9084C66.6528 57.086 66.7456 57.2872 66.7456 57.5003C66.7456 57.7253 66.6412 57.9265 66.444 58.1041C66.2467 58.2817 65.9567 58.3646 65.5971 58.3646C65.1098 58.3646 64.7385 58.1989 64.4717 57.8555C64.3093 57.6424 64.2049 57.3346 64.1469 56.9321Z"
      fill="#D2D3D6"
    />
  </svg>
);

export default SVG;
