import React from 'react';
import './CookiesButton.scss';

export default function CookiesButton() {
  const handleClick = () => {
    window.cookiehub.openSettings();
  };
  return (
    <div className="cookies__container">
      <span onClick={handleClick} className="cookies__button">
        manage cookies
      </span>
    </div>
  );
}
