import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import {
  Title,
  Description,
  Container,
  Image,
  TriggerOnEnter,
  BasicScroll,
  GetStarted,
} from 'elements';
import { Partners } from 'components';
import browser from 'images/browsers/browser-overview.webp';
import phone from 'images/phones/phone-regular-black.webp';
import browserMobile from 'images/mobile/browsers/browser-overview.webp';
import phoneMobile from 'images/mobile/phones/phone-regular-black.webp';
import ArrowSvg from 'images/icons/arrowTop.svg';
import CardSvg from 'images/bank_cards/home-hero-card.svg';
import CardSvgMobile from 'images/bank_cards/bank-card-home-hero-mobile.svg';
import { getAreAnimationsUnblocked } from '../../../../redux/modules/flags/flagsSelectors';
import HeroFixedButton from './HeroFixedButton';
import './HomeHero.scss';

const HomeHero = () => {
  const areAnimationsUnblocked = useSelector(getAreAnimationsUnblocked);
  const [isImagesAnimationComplete, setImagesAnimationComplete] = useState(false);
  const parallaxAnchorRef = useRef();
  const phoneImageRef = useRef();
  const cardImageRef = useRef();

  return (
    <section className="home_hero">
      <div
        ref={parallaxAnchorRef}
        className="home_hero__parallax-anchor"
        style={{
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '-1',
          pointerEvents: 'none',
        }}
      />
      <TriggerOnEnter bypass={!areAnimationsUnblocked}>
        {({ enteredClassName }) => (
          <Container className={cx('home_hero__container', enteredClassName)}>
            <div className="home_hero__details">
              <Title
                tagName="h1"
                sizeText="size-3"
                color="white"
                alignText="text-center"
                className="home_hero__title"
              >
                <div className="home_hero__title-part">Comprehensive digital</div>
                <div className="home_hero__title-part">banking infrastructure</div>
              </Title>
              <Description
                sizeText="size-1"
                color="white"
                alignText="text-center"
                className="home_hero__description"
              >
                Deploy innovative digital banking products or embed financial services into your
                customer experience at extraordinary speed with Finto’s powerful Banking as a
                Service solutions, API platform and white label services.
              </Description>
              <div className="home_hero__buttons">
                <GetStarted
                  className="home_hero__button button--with-icon"
                  to="https://developer.finto.io/"
                  color="text-white"
                  size="medium"
                  buttontype="gradient-hoverable"
                  isCentered
                  target="_blank"
                >
                  <div className="button__inner">
                    <span>Get started</span>
                    <div className="button__icons">
                      <ArrowSvg className="button__icon" />
                      <ArrowSvg className="button__icon button__icon--next" />
                    </div>
                  </div>
                </GetStarted>
              </div>
            </div>
            <div
              className="home_hero__media"
              onAnimationEnd={() => setImagesAnimationComplete(true)}
            >
              <div ref={phoneImageRef} className="home_hero__img_left parallax parallax--vertical">
                <Image src={phone} srcMobile={phoneMobile} alt="phone" bypass />
              </div>
              <div className="home_hero__img_center">
                <Image src={browser} srcMobile={browserMobile} alt="browser" bypass />
              </div>
              <div className="home_hero__img_right parallax parallax--vertical" ref={cardImageRef}>
                <CardSvg className="home_hero__svg" />
                <CardSvgMobile className="home_hero__svg--mobile" />
              </div>
              {isImagesAnimationComplete && (
                <>
                  <BasicScroll
                    reference={parallaxAnchorRef}
                    direct={phoneImageRef.current}
                    from="bottom-bottom"
                    props={{
                      '--parallax-ty': {
                        from: '0%',
                        to: '-30%',
                      },
                      '--parallax-ty-tablet-max': {
                        from: '0%',
                        to: '-15%',
                      },
                    }}
                  />
                  <BasicScroll
                    reference={parallaxAnchorRef}
                    direct={cardImageRef.current}
                    from="bottom-bottom"
                    props={{
                      '--parallax-ty': {
                        from: '0%',
                        to: '-20%',
                      },
                      '--parallax-ty-tablet-max': {
                        from: '0%',
                        to: '-100%',
                      },
                    }}
                  />
                </>
              )}
            </div>
          </Container>
        )}
      </TriggerOnEnter>
      <div className="home_hero__partners">
        <Partners />
      </div>
      <HeroFixedButton />
    </section>
  );
};

export default HomeHero;
