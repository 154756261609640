/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { bool, arrayOf, node, oneOfType, func, string } from 'prop-types';
import cx from 'classnames';
import { Button } from 'elements';
import ArrowLeftSvg from 'images/icons/arrow-left.svg';
import './Modal.scss';

function Modal({
  isVisible,
  children,
  onOpen,
  onClose,
  title,
  className,
  bodyClassName,
  headerClassName,
  footerClassName,
  footer,
}) {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = 'hidden';
      onOpen && onOpen();
    } else {
      document.body.style.overflowY = 'initial';
    }
  }, [isVisible, onOpen]);
  const isHeader = title || onClose;
  return (
    <div className={cx('modal', { 'modal--is-visible': !!isVisible, [className]: !!className })}>
      {isHeader && (
        <div className={cx('modal__header', { [headerClassName]: !!headerClassName })}>
          {onClose && (
            <Button className="modal__closer" onClick={onClose}>
              <ArrowLeftSvg className="modal__closer-icon" />
            </Button>
          )}
          {title && <h2 className="modal__header-title">{title}</h2>}
        </div>
      )}
      <div className={cx('modal__body', { [bodyClassName]: !!bodyClassName })}>{children}</div>
      {footer && (
        <div className={cx('modal__footer', { [footerClassName]: !!footerClassName })}>
          {footer}
        </div>
      )}
    </div>
  );
}

Modal.propTypes = {
  isVisible: bool,
  onClose: func,
  onOpen: func,
  title: string,
  className: string,
  bodyClassName: string,
  headerClassName: string,
  footerClassName: string,
  children: oneOfType([node, arrayOf(node)]),
  footer: oneOfType([node, arrayOf(node)]),
};

export default Modal;
