import React from 'react';
import { array, string, func, bool } from 'prop-types';
import cx from 'classnames';
import { useResponsive } from 'hooks';
import CrossSvg from 'images/icons/cross.svg';
import './SideMenu.scss';

const scrollToPos = ({ top = 0, left = 0, behavior = 'smooth' } = {}) => {
  window.scrollTo({
    top,
    left,
    behavior,
  });
};

const ANIMATION_TIME = 700;

const SideMenu = ({ data, waypointText, toggleMenu, isOffset, setWaypoint }) => {
  const { isDesktop } = useResponsive();

  const scrollToTarget = (hash, id) => {
    const mainHeader = document.querySelector('.header');
    const sideMenuHeader = document.querySelector('.side-menu__toggle-button');
    const target = document.querySelector(hash);
    const { top } = target.getBoundingClientRect();
    const destinationPosition = top + window.scrollY;
    const { height: headerHeight } = mainHeader.getBoundingClientRect();
    const { height: sideMenuHeight } = sideMenuHeader.getBoundingClientRect();
    const additionalOffset =
      destinationPosition > window.scrollY ? sideMenuHeight : sideMenuHeight + headerHeight;
    setWaypoint(id, 1000);
    scrollToPos({ top: destinationPosition - additionalOffset });
  };

  const onLinkPress = (hash, id) => {
    if (!isDesktop) {
      toggleMenu();
      setTimeout(() => {
        scrollToTarget(hash, id);
      }, ANIMATION_TIME);
    } else {
      scrollToTarget(hash, id);
    }
  };

  if (!data.length) return null;

  return (
    <div className={cx('side-menu', { 'side-menu--offset': isOffset })}>
      <button className="side-menu__toggle-button" type="button" onClick={toggleMenu}>
        <CrossSvg />
        <span>{waypointText}</span>
      </button>
      <div className="side-menu__list-wrapper">
        {data.map((c, indx) => (
          <div className="side-menu__box" key={c.title || indx.toString()}>
            {!!c.title && <span className="side-menu__title">{c.title}</span>}
            <ul className="side-menu__list">
              {c.list.map((cc, index) => (
                <li
                  className={cx('side-menu__list-item', {
                    'side-menu__list-item--active':
                      cc.text === waypointText || (!indx && !index && !waypointText),
                  })}
                  key={cc.text}
                >
                  <a
                    href={cc.href}
                    onClick={(e) => {
                      e.preventDefault();
                      onLinkPress(cc.href, cc.id);
                    }}
                  >
                    {cc.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  data: array,
  waypointText: string,
  setWaypoint: func,
  toggleMenu: func,
  isOffset: bool,
};

SideMenu.defaultProps = {
  data: [],
  setWaypoint: () => {},
};

export default SideMenu;
