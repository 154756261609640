import React from 'react';
import cx from 'classnames';
import { object, string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Logo.scss';

const mapStateToProps = (state) => ({
  location: state.router.location,
});

const mapDispatchToProps = {};

const Logo = ({ type, location, onClick }) => {
  if (location && location.pathname === '/') {
    return (
      <span
        className={cx('logo', {
          [`logo--${type}`]: !!type,
        })}
      />
    );
  }
  return (
    <Link
      to="/"
      onClick={onClick}
      className={cx('logo', {
        [`logo--${type}`]: !!type,
      })}
    />
  );
};

Logo.defaultProps = {
  location: null,
  type: 'type-1',
  onClick: () => {},
};

Logo.propTypes = {
  location: object,
  type: string,
  onClick: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
