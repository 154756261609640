import React from 'react';
import { ContactsForm } from 'components';
import { Container } from 'elements';
import './ContactsHero.scss';

const ContactsHero = () => (
  <section className="contacts-hero__section">
    <Container className="contacts-hero__container">
      <ContactsForm />
    </Container>
  </section>
);
export default ContactsHero;
