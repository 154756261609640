/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect } from 'react';
import { string, object, func } from 'prop-types';
import cx from 'classnames';
import { Title, Tag, Description, Image } from 'elements';
import { useResponsive } from 'hooks';
import { useHistory } from 'react-router';

const PresentationItem = ({ contentColor, contentAlign, item, onMouseEnter, onMouseLeave }) => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const ref = useRef();
  const history = useHistory();
  const mouseEnter = (event) => {
    isDesktop && onMouseEnter && onMouseEnter(event, { video: ref.current });
  };
  const mouseLeave = (event) => {
    isDesktop && onMouseLeave && onMouseLeave(event, { video: ref.current });
  };
  const onClick = () => {
    if (item.link) {
      history.push(item.link);
    }
  };

  useEffect(() => {
    if (ref.current) {
      // fix safari first hover bug
      ref.current.currentTime = 0.001;
    }
    return () => {};
  }, []);

  return (
    <div
      className={cx('presentation-view__item', `presentation-view__item--${item.itemType}`)}
      onMouseEnter={isDesktop ? mouseEnter : undefined}
      onMouseLeave={isDesktop ? mouseLeave : undefined}
      onClick={onClick}
    >
      {item.video && isDesktop && (
        <>
          <video
            ref={ref}
            preload="auto"
            controls={false}
            loop={false}
            muted
            className="presentation-view__item-video"
            poster={item.video?.poster}
          >
            {item.video?.mp4 && <source src={item.video.mp4} type="video/mp4" />}
          </video>
          <div className="presentation-view__item-blur" />
        </>
      )}
      {(isMobile || isTablet) && (
        <Image
          src={item?.video?.poster}
          alt={item.tag}
          className="presentation-view__item-poster"
        />
      )}
      <div className="presentation-view__item-content">
        <div className={cx('presentation-view__info', `presentation-view__info--${contentAlign}`)}>
          {item.tag && (
            <div className="presentation-view__tag-holder">
              <Tag
                color="purple"
                align={contentAlign}
                lineClassName="presentation-view__tag-line"
                className="presentation-view__tag"
              >
                {item.tag}
              </Tag>
            </div>
          )}
          {item.title && (
            <Title
              tagName="h3"
              sizeText="size-7"
              color={contentColor}
              alignText={`text-${contentAlign}`}
              className="presentation-view__title"
            >
              {item.title}
            </Title>
          )}
          {item.description && (
            <Description
              sizeText="size-1"
              color={contentColor}
              alignText={`text-${contentAlign}`}
              className="presentation-view__description"
            >
              {item.description}
            </Description>
          )}
        </div>
      </div>
    </div>
  );
};

PresentationItem.propTypes = {
  contentColor: string,
  contentAlign: string,
  item: object,
  onMouseEnter: func,
  onMouseLeave: func,
};

export default PresentationItem;
