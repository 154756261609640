import React from 'react';
import { node } from 'prop-types';
import './HelperText.scss';

const HelperText = ({ children, ...rest }) => (
  <div className="helper_text" {...rest}>
    {children}
  </div>
);

HelperText.defaultProps = {
  children: null,
};

HelperText.propTypes = {
  children: node,
};

export default HelperText;
