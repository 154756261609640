import React from 'react';
import { Field } from 'react-final-form';
import { string, object, array } from 'prop-types';
import CheckSVG from 'images/icons/check-regular.svg';
import './FormCheckboxGroup.scss';

const Group = (props) => {
  const {
    options,
    input: { value, onChange },
  } = props;
  const onPress = (checkboxValue, isChecked) => {
    if (isChecked) {
      onChange(value.filter((x) => x !== checkboxValue));
    } else {
      onChange([...value, checkboxValue]);
    }
  };
  return (
    <>
      {options.map((c) => {
        const checked = value.includes(c.value);
        return (
          <div className="checkbox__row" key={c.label}>
            <label htmlFor={c.value} className="checkbox__label">
              <span>{c.label}</span>
              <div className="checkbox__input-wrap">
                <input
                  id={c.value}
                  name={c.label}
                  className="checkbox__input"
                  checked={checked}
                  onChange={() => onPress(c.value, checked)}
                  type="checkbox"
                />
                <CheckSVG className="checkbox__input-icon" />
              </div>
            </label>
          </div>
        );
      })}
    </>
  );
};

Group.propTypes = {
  options: array,
  input: object,
};

const FormCheckboxGroup = ({ name, ...rest }) => (
  <Field name={name}>{({ input, meta }) => <Group input={input} meta={meta} {...rest} />}</Field>
);

FormCheckboxGroup.propTypes = {
  name: string,
};

export default FormCheckboxGroup;
