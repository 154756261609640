import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import './Description.scss';

const Description = ({ alignText, className, color, children, sizeText, ...rest }) => (
  <p
    className={cx('description', {
      [`description--${color}`]: !!color,
      [`description--${alignText}`]: !!alignText,
      [`description--${sizeText}`]: !!sizeText,
      [className]: !!className,
    })}
    {...rest}
  >
    {children}
  </p>
);

Description.defaultProps = {
  alignText: 'text-left',
  className: '',
  color: '',
  children: undefined,
  sizeText: 'size-1',
};

Description.propTypes = {
  alignText: string,
  color: string,
  className: string,
  children: node,
  sizeText: string,
};

export default Description;
