import React from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';
import { Container, Title, Tag, CultureCard } from 'elements';
import './CultureValue.scss';

const data = [
  {
    tag: '01',
    title: 'Inclusive',
    description:
      'Diverse companies simply do better. Our eclectic backgrounds and interests allow us to approach problems from fresh perspectives with better results.',
  },
  {
    tag: '02',
    title: 'Ambitious',
    description:
      'We are driven to make the best possible work of our careers, creating technology that will enrich the lives of our customers and their users.',
  },
  {
    tag: '03',
    title: 'Collaborative',
    description:
      "By supporting, nurturing and contributing to each other's ideas we make creative leaps that wouldn’t be possible otherwise.",
  },
  {
    tag: '04',
    title: 'Inquisitive',
    description:
      'We cultivate curious minds, challenging existing norms, empowering everyone at Finto to pursue new ideas and approaches to problems.',
  },
];

const CultureValue = ({ withTopOffset }) => (
  <section
    className={cx('culture-value__section', {
      'culture-value__section--top-offset': !!withTopOffset,
    })}
  >
    <Container className="culture-value__container">
      <Tag color="purple" className="culture-value__tag" lineClassName="culture-value__tag-line">
        Our VALUES
      </Tag>
      <Title
        tagName="h2"
        sizeText="size-2"
        alignText="text-center"
        color="white"
        className="culture-value__title"
      >
        Our values are at the core of everything we do
      </Title>
      <div className="culture-value__cards">
        {data.map((c) => (
          <CultureCard className="culture-value__card" key={c.tag} {...c} />
        ))}
      </div>
    </Container>
  </section>
);

CultureValue.propTypes = {
  withTopOffset: bool,
};

CultureValue.defaultProps = {
  withTopOffset: false,
};

export default CultureValue;
