import React from 'react';

// eslint-disable-next-line react/prop-types
const SVG = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.411 45.623C23.411 46.0694 23.1222 46.5157 22.3958 46.0694L7.99913 30.5C7.08019 29.7211 5.9337 29.3185 4.76972 29.3185C4.24461 29.3185 3.71075 29.406 3.1944 29.5723C1.34777 30.1849 0.078766 31.874 0 33.8169V50.6816H4.34088V34.6658C4.35839 33.9219 5.28607 33.9657 5.63614 34.342L19.1226 48.9575C21.3456 50.8303 23.2272 50.8391 24.9426 50.174C26.5354 49.5001 27.6381 48.0123 27.8131 46.2969V29.5723H23.411V45.623Z"
      fill="#D2D3D6"
    />
    <path d="M30.6225 29.5723H30.6312V29.5635L30.6225 29.5723Z" fill="#D2D3D6" />
    <path
      d="M43.9602 29.5723C41.9998 29.7123 37.6939 30.885 37.6939 36.1186C37.7114 40.407 42.2623 42.4199 44.4503 42.3411H57.1841C58.2081 42.4724 58.9782 43.3301 59.0045 44.3628C58.9695 45.343 58.2343 46.1569 57.2629 46.3057H37.6939C36.3549 46.3057 35.0246 46.3057 35.0246 44.6866V29.5723L30.6225 29.5723V44.9404C30.9725 47.1546 31.0688 47.7147 32.2415 48.8262C33.4143 49.9377 34.5958 50.6816 37.904 50.6816H57.9193C60.7111 50.6816 63.3454 48.5024 63.3366 44.1265C63.3279 39.7506 59.9584 37.9827 57.9193 37.9827H44.3803C40.9233 37.4664 42.4374 33.8344 44.5378 34.0182H66.6185V50.6816H70.9857V34.0269H80V29.5723H43.9602Z"
      fill="#D2D3D6"
    />
  </svg>
);

export default SVG;
