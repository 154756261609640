import React from 'react';
import { Epilog } from 'components';
import GetInTouchForm from '../../../Forms/GetInTouchForm/GetInTouchForm';
import './StartedEpilog.scss';

const StartedEpilog = () => (
  <Epilog id="epilog-get-in-touch-section" variant="white" className="started_epilog">
    <GetInTouchForm parentId="epilog-get-in-touch-section" />
  </Epilog>
);

export default StartedEpilog;
