import React from 'react';
import { HelmetWrapper } from 'elements';
import {
  PaasHero,
  PaasCustom,
  PaasWallet,
  PaasControl,
  PaasSupport,
  PaasEpilog,
  PaasPresentationView,
} from 'components';

const Paas = () => (
  <>
    <HelmetWrapper helmetKey="paas" />
    <PaasHero />
    <PaasCustom />
    <PaasWallet />
    <PaasControl />
    <PaasSupport />
    <PaasEpilog />
    <PaasPresentationView />
  </>
);

export default Paas;
