import React from 'react';
import { Image, Tag, Title, Description, Container } from 'elements';
import phoneRegular from 'images/phones/phone-regular-white.webp';
import phoneRegularMobile from 'images/mobile/phones/phone-regular-white.webp';
import browser from 'images/browsers/browser.webp';
import browserMobile from 'images/mobile/browsers/browser.webp';
import CheckIcon from 'images/icons/check.svg';
import './BaasTailored.scss';

const list = [
  'Your product, your branding, no compromises',
  'Modularity allows you to fine tune functionality',
  'Designed to scale with your business',
  'Fully liscensed',
];

const BaasTailored = () => (
  <section className="baas-tailored__section">
    <Container className="baas-tailored__container">
      <div className="baas-tailored__images">
        <div className="baas-tailored__browser-image">
          <Image src={browser} srcMobile={browserMobile} alt="browser" />
        </div>
        <div className="baas-tailored__phone-image">
          <Image src={phoneRegular} srcMobile={phoneRegularMobile} alt="phone" />
        </div>
      </div>
      <div className="baas-tailored__declaration">
        <Tag>WHITE LABEL APPLICATIONS</Tag>
        <Title tagName="h2" sizeText="size-2" color="vulcan">
          Tailored to you
        </Title>
        <Description sizeText="size-2">
          Offer digital bank accounts with cutting edge features and packed with the latest
          innovations, using Finto’s endlessly customizable white label apps as a starting point.
        </Description>
        <ul className="baas-tailored__list">
          {list.map((c, index) => (
            <li className="baas-tailored__list-item" key={index.toString()}>
              <CheckIcon className="baas-tailored__list-item__icon" />
              <span>{c}</span>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
);

export default BaasTailored;
