/* eslint-disable react/prop-types */
import React, { useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FormSelect, Input } from 'elements';
import SearchSvg from 'images/icons/search.svg';
import { TEAMS, CONTRACT_TYPES, LOCATIONS, AUTO_COMPLETE_DATA } from '../jobsData';
import FormResult from './FormResult';
import './styles.scss';

function autoComplete({ maxVariants = 10, data, key, minKeyLength = 2 }) {
  const showError = (text) => {
    console.warn(text);
  };
  if (typeof maxVariants !== 'number') {
    showError('maxVariants option is number');
    return [];
  }
  if (typeof minKeyLength !== 'number') {
    showError('minKeyLength option is number');
    return [];
  }
  if (!key || !data.length) return [];
  if (key.length < minKeyLength) return [];
  const d = data.filter((item) => {
    const k = key.toLowerCase();
    return item.some((c) => c.toLowerCase === k);
  });
  if (maxVariants) {
    return d.slice(0, maxVariants);
  }
  return d;
}

export default forwardRef(({ onDesktopFormChange, ...rest }, ref) => {
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const onSearchChange = (e) => {
    const { value } = e.target;
    const result = autoComplete({ data: AUTO_COMPLETE_DATA, key: value });
    setAutoCompleteData(result);
  };

  return (
    <div className="job-desktop-form">
      <Form
        onSubmit={() => {}}
        render={(form) => {
          // eslint-disable-next-line no-param-reassign
          ref.current = form;
          return (
            <div className="job-desktop-form__container">
              <div className="job-desktop-form__selectors">
                <div className="job-desktop-form__selector">
                  <FormSelect
                    isMulti
                    isCustomOption
                    showSelectedCountAfter={0}
                    name="team"
                    placeholder="Team"
                    variant="secondary"
                    options={TEAMS}
                  />
                </div>
                <div className="job-desktop-form__selector">
                  <FormSelect
                    isMulti
                    isCustomOption
                    showSelectedCountAfter={0}
                    name="contract_type"
                    placeholder="Contract type"
                    variant="secondary"
                    options={CONTRACT_TYPES}
                  />
                </div>
                <div className="job-desktop-form__selector">
                  <FormSelect
                    isMulti
                    isCustomOption
                    showSelectedCountAfter={0}
                    name="location"
                    placeholder="Locations"
                    variant="secondary"
                    options={LOCATIONS}
                  />
                </div>
                <div className="job-desktop-form__selector">
                  <Input
                    className="job-desktop-form__search-input"
                    placeholder="Search"
                    name="search"
                    variant="fourth"
                    onChange={onSearchChange}
                  />
                  <SearchSvg className="job-desktop-form__search-icon" />
                  {!!autoCompleteData.length && (
                    <div className="job-desktop-form__autocomplete autocomplete">
                      {autoCompleteData.map((c) => (
                        <div
                          key={c}
                          className="autocomplete__item"
                          onClick={() => {
                            form.change('search', c);
                            setAutoCompleteData([]);
                          }}
                        >
                          {c}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <FormResult form={form} {...rest} />
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => onDesktopFormChange(values)}
              />
            </div>
          );
        }}
      />
    </div>
  );
});
