import { API_URL } from 'constants/api';

export const STATE_KEY = 'forms';

const GET_SITE_KEY = `${STATE_KEY}/GET_SITE_KEY`;
const GET_SITE_KEY_SUCCESS = `${STATE_KEY}/GET_SITE_KEY_SUCCESS`;
const GET_SITE_KEY_FAIL = `${STATE_KEY}/GET_SITE_KEY_FAIL`;

const GET_REGISTRATION_TOKEN = `${STATE_KEY}/GET_REGISTRATION_TOKEN`;
const GET_REGISTRATION_TOKEN_SUCCESS = `${STATE_KEY}/GET_REGISTRATION_TOKEN_SUCCESS`;
const GET_REGISTRATION_TOKEN_FAIL = `${STATE_KEY}/GET_REGISTRATION_TOKEN_FAIL`;

const REGISTRATION = `${STATE_KEY}/REGISTRATION`;
const REGISTRATION_SUCCESS = `${STATE_KEY}/REGISTRATION_SUCCESS`;
const REGISTRATION_FAIL = `${STATE_KEY}/REGISTRATION_FAIL`;

const SUBSCRIPTION = `${STATE_KEY}/SUBSCRIPTION`;
const SUBSCRIPTION_SUCCESS = `${STATE_KEY}/SUBSCRIPTION_SUCCESS`;
const SUBSCRIPTION_FAIL = `${STATE_KEY}/SUBSCRIPTION_FAIL`;

const CLEAR = `${STATE_KEY}/CLEAR`;

const initialState = {
  gettingKey: {
    siteKey: null,
    loading: false,
    loaded: false,
    error: null,
  },
  gettingToken: {
    loading: false,
    loaded: false,
    error: null,
  },
  registration: {
    loading: false,
    loaded: false,
    error: null,
  },
  subscription: {
    loading: false,
    loaded: false,
    error: null,
  },
  userEmail: null,
};

export default function reducer(state = initialState, action = {}) {
  const { error } = action;
  switch (action.type) {
    case GET_SITE_KEY: {
      return {
        ...state,
        gettingKey: {
          ...state.gettingKey,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    }
    case GET_SITE_KEY_SUCCESS: {
      const siteKey = action.result.requirements.properties.response.recaptchaSiteKey;
      return {
        ...state,
        gettingKey: {
          ...state.gettingKey,
          loading: false,
          loaded: true,
          siteKey,
        },
      };
    }
    case GET_SITE_KEY_FAIL: {
      return {
        ...state,
        gettingKey: {
          ...state.gettingKey,
          loading: false,
          loaded: false,
          error,
        },
      };
    }
    case GET_REGISTRATION_TOKEN: {
      return {
        ...state,
        gettingToken: {
          ...state.gettingToken,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    }
    case GET_REGISTRATION_TOKEN_SUCCESS: {
      return {
        ...state,
        gettingToken: {
          ...state.gettingToken,
          loading: false,
          loaded: true,
        },
      };
    }
    case GET_REGISTRATION_TOKEN_FAIL: {
      return {
        ...state,
        gettingToken: {
          ...state.gettingToken,
          loading: false,
          loaded: false,
          error,
        },
      };
    }
    case REGISTRATION: {
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    }
    case REGISTRATION_SUCCESS: {
      const { email } = action;
      return {
        ...state,
        userEmail: email,
        registration: {
          ...state.registration,
          loading: false,
          loaded: true,
        },
      };
    }
    case REGISTRATION_FAIL: {
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: false,
          loaded: false,
          error,
        },
      };
    }
    case SUBSCRIPTION: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    }
    case SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: false,
          loaded: true,
        },
      };
    }
    case SUBSCRIPTION_FAIL: {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: false,
          loaded: false,
          error,
        },
      };
    }
    case CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
}

const getSiteKey = () => ({
  types: [GET_SITE_KEY, GET_SITE_KEY_SUCCESS, GET_SITE_KEY_FAIL],
  promise: (client) =>
    client.get(`${API_URL}/proxy/registration`, {
      timeout: 5000,
    }),
});

const getToken = ({ captchaValue }) => ({
  types: [GET_SITE_KEY, GET_SITE_KEY_SUCCESS, GET_SITE_KEY_FAIL],
  promise: (client) =>
    client.post(`${API_URL}/proxy/registration?_action=submitRequirements`, {
      timeout: 5000,
      data: {
        input: {
          response: captchaValue,
        },
      },
    }),
});

const registration = ({ email, token }) => ({
  types: [REGISTRATION, REGISTRATION_SUCCESS, REGISTRATION_FAIL],
  promise: (client) =>
    client.post(`${API_URL}/proxy/registration?_action=submitRequirements`, {
      timeout: 5000,
      data: {
        token,
        input: {
          mail: email,
        },
      },
    }),
  email,
});

const clear = () => ({
  type: CLEAR,
});

export const actions = {
  getSiteKey,
  getToken,
  registration,
  clear,
};
