import React from 'react';
import { string, bool, any, oneOf } from 'prop-types';
import cx from 'classnames';
import './FeatureCard.scss';

const FeatureCard = ({ className, title, description, Icon, shadow, variant }) => (
  <div
    className={cx('feature-card', className, `feature-card--${variant}`, {
      [`feature-card--shadow`]: !!shadow,
    })}
  >
    <div className="feature-card__content">
      {Icon && (
        <div className="feature-card__icon-wrap">
          <Icon />
        </div>
      )}
      <h3 className="feature-card__title">{title}</h3>
      <p className="feature-card__description">{description}</p>
    </div>
  </div>
);

FeatureCard.propTypes = {
  className: string,
  title: string,
  description: string,
  Icon: any,
  shadow: bool,
  variant: oneOf(['white', 'black']),
};

FeatureCard.defaultProps = {
  variant: 'white',
};

export default FeatureCard;
